import { Alert } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import * as XLSX from 'xlsx';
import { CaseType } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import { EventForProject } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { getCaseAndPlural, multiLevelGroupBy, val } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import { getDataFromTableColumns, getTitleFromTableColumns } from '../projectReports';
import { AbstractReportsGrouped, AbstractReportsGroupedProps } from './abstractReportsGrouped';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, AbstractReportsGroupedProps {
}

interface State {
    pagination: {
        currentPage: number;
        pageSize: number;
    }
}

export class ReportsGroupedByProjectAndUserCore extends AbstractReportsGrouped<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            pagination: {
                currentPage: 1,
                pageSize: 8,
            }
        };
    }

    handleTableChange = (page: number, pageSize: number) => this.setState({ pagination: { currentPage: page, pageSize } });

    downloadProjectEvents = () => {
        const { intl, eventsForProjects, displayDetails } = this.props;

        if (eventsForProjects) {
            const parsedData = this.parseData(eventsForProjects, 0, eventsForProjects.length)
            if (parsedData) {
                const { columns } = this.getColumns();
                exportToExcel(parsedData, columns, intl, displayDetails);
            }
        }
    }

    parseData = (data: EventForProject[] | undefined, startIndex?: number, endIndex?: number) => {
        const indexes = multiLevelGroupBy<EventForProject, 2>(data ?? [], [
            (e) => e.projectId?.toString(),
            (e) => e.userId?.toString(),
        ]);

        return flattenGroupedDataWithIDs(indexes, startIndex, endIndex);
    }

    removeDetails = (key: React.Key | undefined) => {
        switch (key) {
            case 'title':
            case 'startDate':
            case 'startTime':
            case 'endTime':
            case 'overtimes':
            case 'effectiveSeconds':
            case 'userNote':
                return false;
            default:
                return true
        }
    }

    getColumns = () => {
        const { displayDetails } = this.props;

        let columns = this.eventsColumnsByProjectByUser;
        let paginationTotal = (elem1: number, elem2: number, total: number) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: elem1, range1: elem2, total }} />
        if (!displayDetails) {
            columns = columns.filter(c => this.removeDetails(c.key));
            paginationTotal = (elem1: number, elem2: number, total: number) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total}'} values={{ range0: elem1, range1: elem2, total }} />
        }

        return { columns, paginationTotal };
    }

    render() {
        const { height, eventsForProjects } = this.props;
        const { pagination } = this.state;
        const { currentPage } = pagination;

        const pageSize = Math.round((height - 175) / 50);

        const totalEffectiveSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.effectiveSeconds);
        }, 0.0);
        const totalOvertimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.totalSecondsWithOvertimes) - val(event.totalSeconds);
        }, 0.0);
        const totalPaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.totalSecondsWithoutPaidBreaktimes);
        }, 0.0);
        const totalUnpaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            const totalSeconds = val(event.totalSeconds);
            const totalSecondsWithoutUnpaidBreaktimes = val(event.totalSecondsWithoutUnpaidBreaktimes);
            if (totalSeconds > totalSecondsWithoutUnpaidBreaktimes) {
                return prevValue + val(totalSeconds) - val(totalSecondsWithoutUnpaidBreaktimes);
            }
            return prevValue;
        }, 0.0);

        const totalEffectiveHours = totalEffectiveSeconds ? totalEffectiveSeconds / 3600 : 0;
        const totalOvertimesHours = totalOvertimesSeconds ? totalOvertimesSeconds / 3600 : 0;
        const totalPaidBreaktimesHours = totalPaidBreaktimesSeconds ? totalPaidBreaktimesSeconds / 3600 : 0;
        const totalNotPaidBreaktimesHours = totalUnpaidBreaktimesSeconds ? totalUnpaidBreaktimesSeconds / 3600 : 0;

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const tableData = eventsForProjects ? this.parseData(eventsForProjects, startIndex, endIndex) : null;

        const { columns, paginationTotal } = this.getColumns();

        return (
            <>
                {
                    tableData ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                            <Alert type='info'
                                message={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <div>
                                            <span><i><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</i> {val(totalNotPaidBreaktimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Paid breaks'} />{':'} {val(totalPaidBreaktimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Overtimes'} />{':'}  {val(totalOvertimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Effective hours'} />{':'} </b> {val(totalEffectiveHours).toFixed(2)}</span>
                                        </div>
                                    </div>
                                }
                            />
                            <Table
                                bordered
                                columns={columns}
                                dataSource={tableData ?? []}
                                scroll={{ x: true }}
                                rowKey={(record: EventForProjectByProjectByUser) => `byprojectbyuser-${record.id}-${record.projectId}-${record.userId}-${record.startDate}`}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: eventsForProjects?.length,
                                    showSizeChanger: false,
                                    onChange: this.handleTableChange,
                                    responsive: true,
                                    size: "small",
                                    showTotal: (total, range) => paginationTotal(range[0], range[1], total)
                                }}
                            />
                        </div>
                        : <p>Problem data</p>
                }
            </>
        );
    }

    eventsColumnsByProjectByUser: ColumnsType<EventForProjectByProjectByUser> = [
        {
            title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
            key: 'projectTitle',
            className: '__width_250 __ellipsis-text',
            onCell: (record) => ({
                rowSpan: record.projectIdRowSpan,
            }),
            render: (e: EventForProjectByProjectByUser) => {
                const projectTitle = e.projectTitle;
                return <span title={projectTitle}>{projectTitle}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'userFullName',
            className: '__min-width-250 __ellipsis-text',
            onCell: (record) => ({
                rowSpan: record.userIdRowSpan,
            }),
            render: (value) => {
                const fullName = `${value.userLastName} ${value.userFirstName}`;
                return <span title={fullName}>{fullName}</span>
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Event'} />,
            key: 'title',
            className: '__width_250 __ __ellipsis-text',
            render: (e: EventForProjectByProjectByUser) => {
                const title = e.title;
                return <span title={title} >{title}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'startDate',
            className: '__width_110',
            render: (e: EventForProjectByProjectByUser) => {
                const c = e.startDate;
                return c && moment.utc(c).format(getFormat('DATE'));
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Start'} />,
            key: 'startTime',
            className: '__width_70 __centered-text',
            render: (e: EventForProjectByProjectByUser) => {
                const startTime = e.startDate;
                return moment.utc(startTime).format(getFormat('TIME_SHORT'));
            }
        },
        {
            title: <FormattedMessage defaultMessage={'End'} />,
            key: 'endTime',
            className: '__width_70 __centered-text',
            render: (e: EventForProjectByProjectByUser) => {
                const endTime = e.endDate;
                return moment.utc(endTime).format(getFormat('TIME_SHORT'));
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Over'} />,
            key: 'overtimes',
            className: '__width_70 __centered-text',
            render: (e: EventForProjectByProjectByUser) => {
                const totalSecondsWithOvertimes = e.totalSecondsWithOvertimes;
                const totalSeconds = e.totalSeconds;
                return ((totalSecondsWithOvertimes - totalSeconds) / 3600).toFixed(2)
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Total'} />,
            key: 'effectiveSeconds',
            className: '__width_70 __centered-text',
            render: (e: EventForProjectByProjectByUser) => {
                const effectiveSeconds = e.effectiveSeconds;
                return (effectiveSeconds / 3600).toFixed(2);
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Comment'} />,
            key: 'userNote',
            className: '__min-width-220 __ellipsis-text',
            render: (e: EventForProjectByProjectByUser) => {
                const value = e.userNote;
                return <span title={value}>{value}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Effective hours'} />,
            key: 'allEffectiveSeconds',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'User'} />,
                    key: 'userEffectiveSeconds',
                    className: '__width_100  __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.userIdRowSpan,
                    }),
                    render: (e: EventForProjectByProjectByUser) => {
                        const userEffectiveSeconds = e.userEffectiveSeconds ?? 0;
                        return (userEffectiveSeconds / 3600).toFixed(3);
                    }
                },
                {
                    title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
                    key: 'projectEffectiveSeconds',
                    className: '__width_100 __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.projectIdRowSpan,
                    }),
                    render: (e: EventForProjectByProjectByUser) => {
                        const projectEffectiveSeconds = e.projectEffectiveSeconds ?? 0;
                        return (projectEffectiveSeconds / 3600).toFixed(3);
                    }
                },
            ]
        }

    ];

}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    sidebarType: state.window.sidebarType,
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

const ReportsGroupedByProjectAndUser = connector(ReportsGroupedByProjectAndUserCore);

const ForwardedReportsGroupedByProjectAndUser = forwardRef<ReportsGroupedByProjectAndUserCore, any>((props: Props, ref) => {
    const intl = useIntl();

    return <ReportsGroupedByProjectAndUser {...props} ref={ref as React.Ref<ReportsGroupedByProjectAndUserCore>} intl={intl} />;
});

ForwardedReportsGroupedByProjectAndUser.displayName = "ForwardedReportsGroupedByProjectAndUser";


export default ForwardedReportsGroupedByProjectAndUser;

// #region All utils
// Fonction utilitaire pour extraire le texte d'un JSX ou d'un composant React
type EventForProjectByProjectByUser = EventForProject & {
    projectIdRowSpan?: number;
    projectEffectiveSeconds?: number;
    userIdRowSpan?: number;
    userEffectiveSeconds?: number;
};

const exportToExcel = (dataSource: EventForProjectByProjectByUser[], eventsColumnsByProjectByUser: ColumnsType<EventForProjectByProjectByUser>, intl: IntlShape, displayDetails: boolean) => {
    const headers: string[] = [];
    eventsColumnsByProjectByUser.forEach((column) => {
        if ("children" in column && column.children.length > 0) {
            column.children.forEach(subColumn => headers.push(getTitleFromTableColumns(subColumn, intl)));
        } else {
            headers.push(getTitleFromTableColumns(column, intl));
        }
    })

    const colWidths: number[] = new Array(headers.length).fill(0).map((e, index) => {
        return Math.max(0, headers[index].length);
    });

    const worksheetData: string[][] = [];
    worksheetData.push(headers);

    const merges: XLSX.Range[] = [];
    let currentRow = 1;

    dataSource.forEach((row) => {
        const rowData: string[] = [];
        let index = 0;
        eventsColumnsByProjectByUser.forEach((column) => {
            let valueString = "";
            if ("children" in column && column.children.length > 0) {
                column.children.forEach(subColumn => {
                    valueString = getDataFromTableColumns(subColumn, currentRow, row);
                    colWidths[index] = Math.max(colWidths[index], valueString.length);
                    rowData.push(valueString);
                    index++;
                });
            } else {
                const valueString = getDataFromTableColumns(column, currentRow, row)
                colWidths[index] = Math.max(colWidths[index], valueString.length);
                rowData.push(valueString);
                index++;
            }
        });

        worksheetData.push(rowData);

        // Gestion des fusions pour "Project" (colonne 0, 9) basé sur rowSpan
        if (row.projectIdRowSpan && row.projectIdRowSpan > 1) {
            const col = displayDetails ? 10 : 3;
            merges.push({
                s: { r: currentRow, c: 0 },
                e: { r: currentRow + row.projectIdRowSpan - 1, c: 0 },
            });
            merges.push({
                s: { r: currentRow, c: col },
                e: { r: currentRow + row.projectIdRowSpan - 1, c: col },
            });
        }

        // Gestion des fusions pour "Employee" (colonne 1, 8) basé sur rowSpan
        if (displayDetails && row.userIdRowSpan && row.userIdRowSpan > 1) {
            merges.push({
                s: { r: currentRow, c: 1 },
                e: { r: currentRow + row.userIdRowSpan - 1, c: 1 },
            });
            merges.push({
                s: { r: currentRow, c: 9 },
                e: { r: currentRow + row.userIdRowSpan - 1, c: 9 },
            });
        }

        currentRow++;
    });

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    ws['!merges'] = merges;

    for (let i = 0; i < headers.length; i++) {
        const cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
        if (cell) {
            cell.s = {
                font: { bold: true },
                alignment: { horizontal: 'center' },
            };
        }
    }

    const columnWidths = colWidths.map(width => ({ wch: width + 2 }));
    ws['!cols'] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'export_by_project_user.xlsx');
};

const flattenGroupedDataWithIDs = (groupedData: ReturnType<typeof multiLevelGroupBy<EventForProject, 2>>, startIndex?: number, endIndex?: number): EventForProjectByProjectByUser[] | null => {
    const rows: EventForProjectByProjectByUser[] = [];
    let calculated_index = 0;
    if (startIndex !== undefined && endIndex === undefined || (startIndex === undefined && endIndex !== undefined)) return null;
    for (const projectId in groupedData) {
        const userGroups = groupedData[projectId];

        const projectEffectiveSeconds = Object.values(userGroups).reduce((prevValue, event) => {
            return prevValue + val(event.reduce((prevValue, event) => {
                return prevValue + val(event.effectiveSeconds);
            }, 0.0));
        }, 0.0);

        let first_project_event_calculated_index = -1;

        for (const userId in userGroups) {
            const events = userGroups[userId];
            const userEffectiveSeconds = events.reduce((prevValue, event) => {
                return prevValue + val(event.effectiveSeconds);
            }, 0.0);
            let first_user_event_calculated_index = -1;

            events.forEach((event) => {
                if ((startIndex === undefined || endIndex === undefined) || (calculated_index >= startIndex && calculated_index < endIndex)) {
                    rows.push({
                        ...event,
                        projectIdRowSpan: 0,
                        projectEffectiveSeconds: projectEffectiveSeconds,
                        userIdRowSpan: 0,
                        userEffectiveSeconds: userEffectiveSeconds
                    });
                    if (startIndex !== undefined && calculated_index >= startIndex && first_project_event_calculated_index === -1) first_project_event_calculated_index = rows.length - 1;
                    if (startIndex !== undefined && calculated_index >= startIndex && first_user_event_calculated_index === -1) first_user_event_calculated_index = rows.length - 1;
                }
                calculated_index++;
            });

            if (first_user_event_calculated_index > -1) {
                rows[first_user_event_calculated_index].userIdRowSpan = rows.length - first_user_event_calculated_index;
            }

        }
        if (first_project_event_calculated_index > -1) {
            rows[first_project_event_calculated_index].projectIdRowSpan = rows.length - first_project_event_calculated_index;
        }
    }

    return rows;
}
// #endregion