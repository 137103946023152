import { Col, Empty, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React from 'react';
import { TbTemplate } from 'react-icons/tb';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { RouterProps } from '../../../utils/types/generalTypes';
import { GeneralResponse } from '../../../utils/types/networkTypes';
import { PlanningTemplate } from '../../../utils/types/planningTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { convertNetworkTemplatesToPlanningTemplates, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Anticon from '../../common/general/anticon';
import Card from '../../common/general/card';
import SpaceContent from '../../common/general/spaceContent';
type ReduxProps = ConnectedProps<typeof connector>

interface Props extends RouterProps, ReduxProps, IntlProps {

}

interface State {
    loading: boolean;
    updatedAt?: Moment;
    templates: PlanningTemplate[];
    selectedTemplates: number[]
}

class TimeClocktemplates extends React.Component<Props, State> {
    columns: ColumnsType<PlanningTemplate> = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__min-width-200',
        },
        {
            title: <FormattedMessage defaultMessage={'Type of day'} />,
            key: 'typeOfDay',
            dataIndex: ['typeOfDay', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Type of vacation'} />,
            key: 'typeOfDayOff',
            dataIndex: ['typeOfDayOff', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Project'} />,
            key: 'project',
            dataIndex: ['project', 'title'],
            className: '__width_180',
        },
        {
            title: <FormattedMessage defaultMessage={'Color'} />,
            key: 'color',
            dataIndex: ['color', 'color'],
            className: '__width_100',
            render(color) {
                return (
                    <div style={{ height: '25px', width: '50px', backgroundColor: color, borderRadius: '18px' }} />
                )
            },
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            updatedAt: undefined,
            templates: [],
            selectedTemplates: [],
        };

    }
    componentDidMount() {
        this.getTimeClockTemplates();
    }

    /**
     * Fetch stopped and waiting for confirmation event clockeds
     */
    getTimeClockTemplates = (force = false) => {
        // get all timeclocked events
        if (force || !this.state.loading) {
            this.setState({ loading: true }, () => {
                Network.getTimeClocktemplates().then(
                    (response: GeneralResponse) => {
                        this.setState({ loading: false, templates: convertNetworkTemplatesToPlanningTemplates(response.data), updatedAt: moment() });
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the timeclock templates' }), "warning");
                        this.setState({ loading: false, templates: [], updatedAt: undefined });
                    }
                );
            })

        }

    }

    render() {
        const { height, intl } = this.props;
        const { loading, templates, updatedAt } = this.state;
        let tableHeight = height - 570;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <>
                <Card
                    icon={<Anticon><TbTemplate /></Anticon>}
                    title={
                        <SpaceContent>
                            <p style={{ marginRight: '10px' }}>
                                <FormattedMessage defaultMessage={'Templates'} />
                            </p>
                        </SpaceContent>
                    }
                    headerElements={[
                        <Tooltip
                            key={`timeclock-templates-header-refresh`}
                            placement='left'
                            open={updatedAt ? undefined : false}
                            title={
                                <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}>
                                    <FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: updatedAt?.format(getFormat('DATE_AND_ON_TIME_SHORT')) }} />
                                </p>
                            }>
                            <CircleButton
                                small
                                withoutTooltip
                                title={intl.formatMessage({ defaultMessage: 'Force refresh' })}
                                icon={<FAIcon prefix={'fad'} name="rotate" />}
                                onClick={() => this.getTimeClockTemplates(true)}
                                disabled={loading}
                                loading={loading} />
                        </Tooltip>,
                        // <CircleButton
                        //     small
                        //     withoutTooltip
                        //     key={`timeclock-templates-header-add-template`}
                        //     icon={<PlusOutlined />}
                        //     title={"Ajouter un modèle"}
                        //     disabled={loading}
                        // />
                    ]}>


                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }}>
                            <Table
                                loading={loading}
                                rowKey={(u: PlanningTemplate) => `${u.id}`}
                                dataSource={templates}
                                columns={this.columns}
                                pagination={false}
                                scroll={{ x: true, y: tableHeight }}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No template'} />} /> }}
                            />
                        </Col>
                    </Row >
                </Card >
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
});

const connector = connect(mapStateToProps)

export default connector(withRouter(injectIntl(TimeClocktemplates)));
