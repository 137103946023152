import { createSelector } from "@reduxjs/toolkit";
import { ProjectDefaultMessage } from "../../hooks/useProjectName";
import { CaseType } from "../../utils/constants";
import { ApplicationState } from "../../utils/types/storeTypes";
import { getCaseAndPlural, isNullOrEmpty } from "../../utils/utils";

const selectProjects = (state:ApplicationState) => state.configurations.project;

export const selectActiveProjects = createSelector(
    [selectProjects],
    (projects) => projects.filter((project) => !project.isArchived)
);

export const selectInactiveProjects = createSelector(
    [selectProjects],
    (projects) => projects.filter((project) => project.isArchived)
);

export const selectProjectsSortTitle = createSelector(
    [selectProjects],
    (projects) => projects.sort((a, b) => {
        if(a.title && !b.title) return -1;
        if(!a.title && !b.title) return 0;
        if(!a.title && b.title) return 1;
        return a.title!.localeCompare(b.title!)
    })
);

export const selectActiveProjectsSortTitle = createSelector(
    [selectProjects],
    (projects) => projects.filter((project) => !project.isArchived).sort((a, b) => {
        if(a.title && !b.title) return -1;
        if(!a.title && !b.title) return 0;
        if(!a.title && b.title) return 1;
        return a.title!.localeCompare(b.title!)
    })
);

export const selectInactiveProjectsSortTitle = createSelector(
    [selectProjects],
    (projects) => projects.filter((project) => project.isArchived).sort((a, b) => {
        if(a.title && !b.title) return -1;
        if(!a.title && !b.title) return 0;
        if(!a.title && b.title) return 1;
        return a.title!.localeCompare(b.title!)
    })
);

const selectProjectName = (state: ApplicationState) => state.user.company?.projectDisplayText;

export const selectParsedProjectName = createSelector(
    [selectProjectName, (state: ApplicationState, isPlural: boolean, caseType: CaseType) => ({ isPlural, caseType })],
    (projectName, { isPlural, caseType }) => {
        if (isNullOrEmpty(projectName)) {
            if (isPlural)return ProjectDefaultMessage.PROJECTS
            return ProjectDefaultMessage.PROJECT
        }
    
        return getCaseAndPlural(projectName, isPlural, caseType);
    }
);

