import { NotificationApi } from "antd/lib/notification";
import { Moment } from "moment";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { SidebarType } from "../../components/common/navigations/containerTabs";
import { AppLanguage } from "../constants";
import { ICustomerState, IMandateState, IMissionState, ISiteState } from "./customerTypes";
import { AppVersion, CodeDiscount, Company, CompanyLevel, CompanyProduct, CountriesWithUpdate, Country, Course, Department, DepartmentWithUpdate, FullSMSAccount, Group, IIntegrations, Language, Medal, News, POI, PoiWithUpdate, Sector, SMSAccount, StaffType, TypeOfContract, TypeOfContractWithUpdate, TypeOfVehicle, TypeOfVehicleWithUpdate, User, UserSummary } from "./generalTypes";
import { TEnabledIntegrationsState, TIntegrationDetailsState } from "./integrationTypes";
import { Loaded } from "./networkTypes";
import { PlanningEvent, PlanningOccupancyRate, PlanningOvertime, PlanningSettings, PlanningTemplate, PlanningUserRow, PlanningVacations, PreloadPlanningUserRow, Project, ReportSettings, TypeOfDay, UserAvailability, UserAvailabilityWithUpdate } from "./planningTypes";
export type StoreDispatch = ThunkDispatch<any, void, StoreAction>;

export enum StatusType {
    NONE = "none",
    PENDING = "pending",
    FULFILLED = "fulfilled",
    REJECTED = "rejected",
}

/**
 * Application store
 */
export interface ApplicationState {
    user: UserState;
    window: WindowState;
    planning: PlanningState;
    location: LocationState;
    teamManagement: TeamManagementState;
    customerManagement: CustomerManagementState;
    configurations: ConfigurationsState;
    posts: PostsState;
    crm: CRMState;
    messages: MessagesState;
    integrations: IntegrationsState;
}

/**
 * Store action
 */
export interface StoreAction extends Action {
    type: string;
    data?: any;
    forceReload?: boolean;
}

/**
 * Window store state
 */
export interface WindowState {
    width: number;
    height: number;
    containerHeight: number;
    sidebarType: SidebarType;
    isSmartphone: boolean;
    isTablet: boolean;
    navigationCollapsed: boolean;
    darkMode: boolean;
    fullscreen: boolean;
}

/**
 * User store state
 */
export interface UserState {
    loading: boolean; //boolean for indicating a loading during API call made with redux
    currentUser: User | undefined; //the current user details
    company: Company | undefined; //the current company
}

/**
 * User dispatch props
 */
export interface UserDispatchProps {
    getCurrentUser?: () => void;
    toggleLoading?: (b: boolean) => StoreAction;
    changeUserDetails?: (u: User) => StoreAction;
    changeCompany?: (c: Company) => StoreAction;
    changeIntegrations?: (i: IIntegrations) => StoreAction;
}

/**
 * Planning store state
 */
export interface PlanningState {
    userRows: PlanningUserRow[];
    preloadUserRows?: PreloadPlanningUserRow;
    loadingPrevPlanning: boolean;
    loadingNowPlanning: boolean;
    loadingNextPlanning: boolean;
    loadingPlanning: boolean;
    loadingSettings: boolean;
    selectGroups?: number[];
    selectUsers?: number[];
    displayWorkingTime: boolean; //display only the working time
    templates?: PlanningTemplate[]; //all the templates
    settings: PlanningSettings; //the planning settings
    reportSettings?: ReportSettings; //the planning settings
    cellsPerRow?: number; //the number of cells per row
    addUserLoading?: number; //loading progress percentage during network call to get added user's event
    draggedGroupEventUserId?: number; //the id of the user from which the user start the Drag and Drop, if the event is for a group or global
    draggedUserEventUserId?: number; //the id of the user from which the user start the Drag and Drop, if the event is for a user only
    teamAvailabilities: UserAvailabilityWithUpdate[];
    loadingAvailabilities: boolean;
    // shared methods
    onClickEvent?: (event: PlanningEvent) => void;
    onEditEvent?: (event: PlanningEvent) => void;
    onDeleteEvent?: (event: PlanningEvent) => void;
    onEditOvertime?: (overtime: PlanningOvertime) => void;
    onDeleteOvertime?: (overtime: PlanningOvertime) => void;
}

export type PlanningEffect = ThunkAction<any, PlanningState, any, StoreAction>;

/**
 * Planning dispatch methods
 */
export interface PlanningDispatchProps {
    changeUserRows?: (t: PlanningUserRow[]) => StoreAction;
    changePrevUserRows?: (t: PlanningUserRow[]) => StoreAction;
    changeNowUserRows?: (t: PlanningUserRow[]) => StoreAction;
    changeNextUserRows?: (t: PlanningUserRow[]) => StoreAction;
    changePrevLoading?: (l: boolean) => StoreAction;
    changeNowLoading?: (l: boolean) => StoreAction;
    changeNextLoading?: (l: boolean) => StoreAction;
    toggleLoadingPlanning?: (l: boolean) => StoreAction;
    changeSelectGroups?: (sg?: number[]) => StoreAction;
    changeSelectUsers?: (su?: number[]) => StoreAction;
    toggleDisplayWorkingTime?: () => StoreAction;
    changeVacations?: (v: PlanningVacations[]) => StoreAction;
    changeTemplates?: (t: PlanningTemplate[]) => StoreAction;
    changeCellsPerRow?: (c: number) => StoreAction;
    changeSettings?: (s: PlanningSettings) => StoreAction;
    addUser?: (u: User, from: string, to: string, onlyOne?: boolean) => PlanningEffect;
    changeAddUserLoading?: (p: number) => StoreAction;
    addGroup?: (g: Group, from: string, to: string) => PlanningEffect;
    addAllUser?: (from: string, to: string) => PlanningEffect;
    getPlannedUsers?: (from: string, to: string, group?: Group) => PlanningEffect;
    getAvailableUsers?: (from: string, to: string, group?: Group) => PlanningEffect;
    changeDraggedGroupEventUserId?: (id?: number) => StoreAction;
    changeDraggedUserEventUserId?: (id?: number) => StoreAction;
    loadUserAvailabilities?: (userId: number, year: Moment, forceReload?: boolean) => void;
    changeUserAvailabilities?: (userId: number, userAvailabilities: UserAvailability[]) => StoreAction;
    changeTeamAvailabilities?: (teamAvailabilities: UserAvailabilityWithUpdate[]) => StoreAction;
    setLoadingUserAvailabilities?: (loading: boolean) => StoreAction;
    setEventMethods?: (onClickEvent: (e: PlanningEvent) => void, onEditEvent: (e: PlanningEvent) => void, onDeleteEvent: (e: PlanningEvent) => void, onEditOvertime: (o: PlanningOvertime) => void, onDeleteOvertime: (o: PlanningOvertime) => void) => StoreAction;
    reset?: () => StoreAction;
}

/**
 * TeamManagement store state
 */
export interface TeamManagementState {
    user: User | undefined; //the user details
    users: User[]; //all users
    usersGroups: Group[] | undefined; //the user's groups
    group: Group | undefined; //the group details
    groups: Group[] | undefined; //all groups
    groupsUsers: UserSummary[] | undefined; //the group's users
    usersLoading: boolean; //if the users request is loading
}

export interface CustomerManagementState {
    customers: ICustomerState;
    mandates: IMandateState;
    missions: IMissionState;
    sites: ISiteState;
}

export interface LanguageState {
    current: AppLanguage;
}

/**
 * TeamManagement dispatch methods
 */
export interface TeamManagementDispatchProps {
    changeUser?: (u: User) => StoreAction;
    changeUsers?: (u: User[]) => StoreAction;
    changeUsersGroups?: (g: Group[]) => StoreAction;
    changeGroup?: (g: Group) => StoreAction;
    changeGroups?: (g: Group[]) => StoreAction;
    changeGroupsUsers?: (u: UserSummary[] | undefined) => StoreAction;
    toggleUsersLoading?: (b: boolean) => StoreAction;
    reset?: () => StoreAction;
}

/**
 * Location store state
 */
export interface LocationState {
    courses?: Course[];
    sectors?: Sector[];
    pois?: PoiWithUpdate;
    basePois?: PoiWithUpdate;
    loadingPois: boolean;
}

/**
 * Location dispatch props
 */
export interface LocationDispatchProps {
    changeCourses?: (courses: Course[]) => StoreAction;
    changeSectors?: (sectors: Sector[]) => StoreAction;
    changeAllPois?: (pois: POI[]) => StoreAction;
    changeOnlyPois?: (pois: POI[]) => StoreAction;
    changeOnlyBasePois?: (pois: POI[]) => StoreAction;

    loadPois?: (forceReload?: boolean) => void;
}

/**
 * Configurations store state
 */
export interface ConfigurationsState {
    appVersion?: { last_updated: Moment | undefined; data: AppVersion };
    permanentNotifApi?: NotificationApi;

    occupancyRates: PlanningOccupancyRate[];
    typesOfDay: TypeOfDay[];
    typesOfDayOff: TypeOfDay[];
    project: Project[];

    typesOfContract: TypeOfContractWithUpdate;
    countries: CountriesWithUpdate;

    departments: DepartmentWithUpdate;
    vehicles: TypeOfVehicleWithUpdate;

    staffTypes: Loaded<StaffType[]>;
}

/**
 * Configurations dispatch methods
 */
export interface ConfigurationsDispatchProps {
    setPermanentNotifApi?: (api: NotificationApi) => StoreAction;
    changeAppVersion?: (appVersion: AppVersion) => StoreAction;

    changeOccupancyRates?: (o: PlanningOccupancyRate[]) => StoreAction;
    changeTypesOfDay?: (o: TypeOfDay[]) => StoreAction;
    changeTypesOfDayOff?: (o: TypeOfDay[]) => StoreAction;
    changeProject?: (o: Project[]) => StoreAction;

    changeTypesOfContract?: (c: TypeOfContract[]) => StoreAction;
    changeTypesOfVehicles?: (v: TypeOfVehicle[]) => StoreAction;
    changeDepartments?: (d: Department[]) => StoreAction;

    loadTypesOfContract?: (forceReload?: boolean) => void;
    loadDepartments?: (forceReload?: boolean) => void;
    loadTypesOfVehicle?: (forceReload?: boolean) => void;

    reset?: () => StoreAction;
}
export interface PostsState {
    news: News[];
}
export interface PostsStateDispatchProps {
    changeNews: (news: News[]) => StoreAction;
}

/**
 * Swisskischool store state
 */
export interface CRMState {
    companyDetails: Company | undefined;
    signature: string | undefined;
    medals: Medal[];
    countries: Country[];
    languages?: Language[];
    companyProducts: CompanyProduct[];
    loadingCompanyProducts: boolean;
    companyLevels: CompanyLevel[];
    loadingCompanyLevels: boolean;
    companyCodeDiscounts?: CodeDiscount[];
    loadingCompanyCodeDiscounts: boolean;
    companyEditProduct?: CompanyProduct;
}

/**
 * Swisskischool dispatch methods
 */
export interface CRMDispatchProps {
    changeCompanyDetails?: (c: Company) => StoreAction;
    changeCompanyProducts?: (c: CompanyProduct[]) => StoreAction;
    changeCompanyCodesDiscount?: (c: CodeDiscount[]) => StoreAction;
    changeSignature?: (s: string) => StoreAction;
    changeMedals?: (m: Medal[]) => StoreAction;
    changeCountries?: (c: Country[]) => StoreAction;
    changeLanguages?: (l: Language[]) => StoreAction;
    reset?: () => StoreAction;
}

export interface MessagesState {
    smsAccount?: FullSMSAccount;
    smsAccountLoading: boolean;
}

/**
 * Swisskischool dispatch methods
 */
export interface MessagesDispatchProps {
    changeSMSAccount?: (smsAccount: SMSAccount) => StoreAction;
    smsAccountLoading?: (loading: boolean) => StoreAction;
    reset?: () => StoreAction;
}

export interface IntegrationsState {
    enabledIntegrations: TEnabledIntegrationsState;
    hotelaDetails: TIntegrationDetailsState;
    globalOfficeDetails: TIntegrationDetailsState;
    officeMakerDetails: TIntegrationDetailsState;
}
