import { UserOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { UserVacationsSummary } from '../../utils/types/networkTypes';
import { alert, roundDecimals } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import Card from '../common/general/card';

interface IProps { }

type Props = IProps & RouterProps & IntlProps;

interface State {
    summaries: UserVacationsSummary[] | undefined;
    year: number;
}

/**
 * Component that represent the vacations tab for the report page
 */
class VacationsTabv2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            summaries: undefined,
            year: moment().year(),
        };
    }

    componentDidMount() {
        // get all user's vacations summary
        Network.getUserVacationsSummary(this.state.year).then((response) => {
            this.setState({ summaries: response });
        })
            .catch(() => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations' }), "warning"))
    }

    /**
     * Render days
     * @param days the days to render
     * @returns the string with the rendered days
     */
    renderDays = (days: number) => <FormattedMessage defaultMessage={'{count, plural, one {1 day} other {{count} days}}'} values={{ count: roundDecimals(days) || 0 }} />;

    columns: ColumnsType<UserVacationsSummary> = [
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: ['user', 'last_name'],
            key: 'last_name',
            fixed: true,
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.user.last_name.localeCompare(b.user.last_name),
            defaultSortOrder: 'ascend',
            width: 140,
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: ['user', 'first_name'],
            key: 'first_name',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.user.first_name.localeCompare(b.user.first_name),
            width: 140,
        },
        {
            title: <FormattedMessage defaultMessage={'Yearly'} />,
            dataIndex: 'yearlyVacationDays',
            key: 'yearlyVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.yearlyVacationDays !== undefined && b.yearlyVacationDays !== undefined ? a.yearlyVacationDays - b.yearlyVacationDays : -1,
            render: this.renderDays,
            width: 150,
            align: "right"
        },
        {
            title: <FormattedMessage defaultMessage={'Used'} />,
            dataIndex: 'takenVacationDays',
            key: 'takenVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.takenVacationDays !== undefined && b.takenVacationDays !== undefined ? a.takenVacationDays - b.takenVacationDays : -1,
            render: this.renderDays,
            width: 150,
            align: "right"
        },
        {
            title: <FormattedMessage defaultMessage={'Remaining'} />,
            dataIndex: 'remainingVacationDays',
            key: 'remainingVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.remainingVacationDays !== undefined && b.remainingVacationDays !== undefined ? a.remainingVacationDays - b.remainingVacationDays : -1,
            render: (days: number) => (
                <p style={{ fontWeight: 'bold' }}>
                    {this.renderDays(days)}
                </p>
            ),
            width: 150,
            align: "right"
        },
        {
            title: <FormattedMessage defaultMessage={'Additionnal'} />,
            dataIndex: 'availableExtraVacationDays',
            key: 'availableExtraVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.availableExtraVacationDays !== undefined && b.availableExtraVacationDays !== undefined ? a.availableExtraVacationDays - b.availableExtraVacationDays : -1,
            render: this.renderDays,
            width: 150,
            align: "right"
        },
        {
            title: <FormattedMessage defaultMessage={'Used additionnal'} />,
            dataIndex: 'extraVacationDays',
            key: 'extraVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.extraVacationDays !== undefined && b.extraVacationDays !== undefined ? a.extraVacationDays - b.extraVacationDays : -1,
            render: this.renderDays,
            width: 175,
            align: "right"
        },
        {
            title: <FormattedMessage defaultMessage={'Remaining additionnal'} />,
            // dataIndex: 'totalVacationDays',
            key: 'totalVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => {
                const nbDaysA: number = (a.availableExtraVacationDays ? a.availableExtraVacationDays : 0) - (a.extraVacationDays ? a.extraVacationDays : 0);
                const nbDaysB: number = (b.availableExtraVacationDays ? b.availableExtraVacationDays : 0) - (b.extraVacationDays ? b.extraVacationDays : 0);
                return nbDaysA !== undefined && nbDaysB !== undefined ? nbDaysA - nbDaysB : -1
            },
            render: (days: number, summary: UserVacationsSummary) => {
                const nbDays: number = (summary.availableExtraVacationDays ? summary.availableExtraVacationDays : 0) - (summary.extraVacationDays ? summary.extraVacationDays : 0);
                return <p style={{ fontWeight: 'bold' }}>
                    {this.renderDays(nbDays)}
                </p>
            },
            width: 175,
            align: "right"
        },
    ];

    /**
     * Give the users table's rows some attributes
     * @param record the data source for the row
     */
    onRow = (record: UserVacationsSummary) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/vacations?id=${record.user.id}`),
    })

    render() {
        return (
            <div>
                <Card title={<FormattedMessage defaultMessage={'Vacations reports'} />} icon={<UserOutlined />}>
                    <Table
                        dataSource={this.state.summaries}
                        columns={this.columns}
                        loading={this.state.summaries === undefined}
                        pagination={false}
                        rowKey={s => `report-vacations-summary-${s.user.id}`}
                        onRow={this.onRow}
                        scroll={{ x: true }} />
                </Card>
            </div>
        )
    }
}

export default withRouter(injectIntl(VacationsTabv2));