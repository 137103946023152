import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { IntlShape } from "react-intl";
import { TravelZonesIds } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import { TypeOfContract, User } from "../../../utils/types/generalTypes";
import { CctSecurityUserEventsByMonthOverview, CctSecurityUserEventsByMonthRecap, CctSecurityUserEventsByMonthSummary, CctSecurityUserEventsByMonthTravelSummary } from '../../../utils/types/reportTypes';
import { decimalize, pickBestTextColor, val } from '../../../utils/utils';

export type ReportEventType = 'CCTSECURITY' | 'QUADRIGIS'

export interface ReportEventConfig {
    type: ReportEventType;
    creator: string;
    orientation: 'landscape' | 'portrait';
    fitToPage?: boolean;
    fitToWidth?: number;
    fitToHeight?: number;
}

export interface ReportData {
    user: User;
    contract: {
        name: string;
        type: TypeOfContract;
    };
    data: {
        month: number;
        summary: CctSecurityUserEventsByMonthSummary[];
        recap: CctSecurityUserEventsByMonthRecap[];
        overview: CctSecurityUserEventsByMonthOverview[];
        travel: CctSecurityUserEventsByMonthTravelSummary[]
    }
}


export const generateCCTReportToExcelNew = (config: ReportEventConfig, reportData: ReportData[], month: Moment, intl: IntlShape) => {
    const lastMonth = month.clone();
    const firstMonth = month.clone().startOf('year')
    const year = lastMonth.format('YYYY')

    if(reportData.length > 0) {
        // Generate workbook
        const workbook = new ExcelJS.Workbook();
        workbook.creator = config.creator;
        workbook.lastModifiedBy = config.creator;
        workbook.created = new Date();
        workbook.properties.date1904 = true;
        workbook.calcProperties.fullCalcOnLoad = true;
        const filename = `${firstMonth.format('MM')}-${lastMonth.format('MM')}-${year}_reports.xlsx`;

        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 0, visibility: 'visible'
            }
        ];

        reportData.forEach(userData => {
            const user = userData.user;
            const userFullname = `${user.first_name} ${user.last_name}`;

            const currentMonth = userData.data.month.toString().padStart(2, '0');            

            const daysInMonth = lastMonth.daysInMonth();

            const worksheetName = `${year}-${currentMonth}_${userFullname}`;
            const reportSheet = workbook.addWorksheet(worksheetName, {
                headerFooter: {firstHeader: `${intl.formatMessage({defaultMessage: 'Report'})}_${userFullname}`}
            });

            reportSheet.pageSetup = {orientation: config.orientation, fitToPage: config.fitToPage, fitToWidth: config.fitToWidth, fitToHeight: config.fitToHeight};
            initColumns(reportSheet);
            let rowIndex = 1;
            if(config.type !== "QUADRIGIS") {
                generateHeader(reportSheet, intl, userData, year, currentMonth);
                rowIndex = 9;
            }
            rowIndex = generateMainColumns(reportSheet, intl, rowIndex);
            rowIndex = generateContent(reportSheet, intl, userData, daysInMonth, parseInt(year), parseInt(currentMonth), config.type, rowIndex);
            rowIndex++;
            generateMonthlyHoursRecap(reportSheet, intl, userData, rowIndex);
            generateTravelRecap(reportSheet, intl, userData, rowIndex);
            generateTotalRecap(reportSheet, intl, userData, rowIndex);
        })

        workbook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), filename))
            .catch(err => console.log('Error writing excel export', err));
    }
}

const initColumns = (reportSheet: ExcelJS.Worksheet) => {
    //date
    reportSheet.getColumn(1).width = 24;
    reportSheet.getColumn(1).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(1).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //title
    reportSheet.getColumn(2).width = 25;
    reportSheet.getColumn(2).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(2).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //type of day
    reportSheet.getColumn(3).width = 10;
    reportSheet.getColumn(3).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(3).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //type of day II
    reportSheet.getColumn(4).width = 10;
    reportSheet.getColumn(4).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(4).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //hours per event
    reportSheet.getColumn(5).width = 10;
    reportSheet.getColumn(5).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(5).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //overtimes
    reportSheet.getColumn(6).width = 10;
    reportSheet.getColumn(6).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(6).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //breaktimes
    reportSheet.getColumn(7).width = 10;
    reportSheet.getColumn(7).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(7).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //hours total
    reportSheet.getColumn(8).width = 10;
    reportSheet.getColumn(8).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(8).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //majoration (hours)
    reportSheet.getColumn(9).width = 10;
    reportSheet.getColumn(9).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(9).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //majoration (vac)
    reportSheet.getColumn(10).width = 10;
    reportSheet.getColumn(10).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(10).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //soldes (grand total)
    reportSheet.getColumn(11).width = 10;
    reportSheet.getColumn(11).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(11).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //project
    reportSheet.getColumn(12).width = 25;
    reportSheet.getColumn(12).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(12).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //address
    reportSheet.getColumn(13).width = 30;
    reportSheet.getColumn(13).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(13).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //engagement place
    reportSheet.getColumn(14).width = 25;
    reportSheet.getColumn(14).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(14).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //vehicle
    reportSheet.getColumn(15).width = 20;
    reportSheet.getColumn(15).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(15).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //zone
    reportSheet.getColumn(16).width = 15;
    reportSheet.getColumn(16).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(16).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //km OneWay
    reportSheet.getColumn(17).width = 11;
    reportSheet.getColumn(17).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(17).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //km compensated
    reportSheet.getColumn(18).width = 11;
    reportSheet.getColumn(18).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(18).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };

    //remarques
    reportSheet.getColumn(19).width = 30;
    reportSheet.getColumn(19).font = {
        name: "Calibri"
    };
    reportSheet.getColumn(19).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
}

const generateHeader = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, userData: ReportData, year: string, month: string) => {
    reportSheet.getCell('D2').value = intl.formatMessage({ defaultMessage: 'Monthly report {month} - {year} / {user}' }, { month, year, user: `${userData.user.last_name.toString()} ${userData.user.first_name.toString()}` });
    reportSheet.getCell('D2').alignment = { wrapText: false };
    reportSheet.getCell('D2').alignment = { horizontal: 'center', vertical: 'middle' };
    reportSheet.getCell('D2').font = { bold: true, size: 14 };
    reportSheet.mergeCells('D2', 'K3');

    reportSheet.getCell('A2').value = intl.formatMessage({ defaultMessage: 'Last name' });
    reportSheet.getCell('A2').alignment = { horizontal: 'left' };
    reportSheet.getCell('B2').value = userData.user.last_name.toString();
    reportSheet.getCell('B2').alignment = { horizontal: 'left' };

    reportSheet.getCell('A3').value = intl.formatMessage({ defaultMessage: 'First name' });
    reportSheet.getCell('A3').alignment = { horizontal: 'left' };
    reportSheet.getCell('B3').value = userData.user.first_name.toString();
    reportSheet.getCell('B3').alignment = { horizontal: 'left' };

    reportSheet.getCell('A4').value = intl.formatMessage({ defaultMessage: 'Contract' });
    reportSheet.getCell('A4').alignment = { horizontal: 'left' };
    reportSheet.getCell('B4').value = userData.contract.name;
    reportSheet.getCell('B4').alignment = { horizontal: 'left' };

    reportSheet.getCell('A5').value = intl.formatMessage({ defaultMessage: 'Contract type' });
    reportSheet.getCell('A5').alignment = { horizontal: 'left' };
    reportSheet.getCell('B5').value = userData.contract.type.name;
    reportSheet.getCell('B5').alignment = { horizontal: 'left' };

    reportSheet.getCell('A8').value = `${month} - ${year}`;
    reportSheet.mergeCells('A8', 'S8');
    reportSheet.getCell('A8').font = { bold: true };
    reportSheet.getCell('A8').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
}

const generateMainColumns = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, rowIndex: number) => {
    reportSheet.getCell(`A${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Dates' });
    reportSheet.getCell(`B${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Title' });
    reportSheet.getCell(`C${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Type of day' });
    reportSheet.getCell(`E${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Hours' });
    reportSheet.mergeCells(`E${rowIndex}`, `H${rowIndex}`);
    reportSheet.getCell(`I${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Bonus' });
    reportSheet.mergeCells(`I${rowIndex}`, `J${rowIndex}`);
    reportSheet.getCell(`K${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Balance' });
    reportSheet.getCell(`L${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Project / Event' })
    reportSheet.getCell(`M${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Mission address' });
    reportSheet.getCell(`N${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Place of employment' });
    reportSheet.getCell(`O${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Vehicle' });
    reportSheet.getCell(`P${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Flat-rate zone' });
    reportSheet.getCell(`Q${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Km' });
    reportSheet.mergeCells(`Q${rowIndex}`, `R${rowIndex}`);
    reportSheet.getCell(`S${rowIndex}`).value = intl.formatMessage({ defaultMessage: 'Notes' });
    reportSheet.getRow(rowIndex).font = { bold: true };
    reportSheet.getRow(rowIndex).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    reportSheet.mergeCells(`A${rowIndex}`, `A${rowIndex+1}`);
    reportSheet.mergeCells(`B${rowIndex}`, `B${rowIndex+1}`);
    reportSheet.mergeCells(`C${rowIndex}`, `D${rowIndex+1}`);
    reportSheet.getCell(`E${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Total' });
    reportSheet.getCell(`F${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Over.' });
    reportSheet.getCell(`G${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Breaks' });
    reportSheet.getCell(`H${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Effectives' });
    reportSheet.getCell(`I${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Hours' });
    reportSheet.getCell(`J${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Vacations' });
    reportSheet.mergeCells(`K${rowIndex}`, `K${rowIndex+1}`);
    reportSheet.mergeCells(`L${rowIndex}`, `L${rowIndex+1}`);
    reportSheet.mergeCells(`M${rowIndex}`, `M${rowIndex+1}`);
    reportSheet.mergeCells(`N${rowIndex}`, `N${rowIndex+1}`);
    reportSheet.mergeCells(`O${rowIndex}`, `O${rowIndex+1}`);
    reportSheet.mergeCells(`P${rowIndex}`, `P${rowIndex+1}`);
    reportSheet.getCell(`Q${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Outward' });
    reportSheet.getCell(`R${rowIndex+1}`).value = intl.formatMessage({ defaultMessage: 'Compensated' });
    reportSheet.mergeCells(`S${rowIndex}`, `S${rowIndex+1}`);
    reportSheet.getRow(rowIndex+1).font = { bold: true };
    reportSheet.getRow(rowIndex+1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    return ++rowIndex;
}

const generateContent = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, userData: ReportData, daysInMonth: number, year: number, month: number, type: ReportEventType, rowIndex: number) => {
    let cellName = "";

    const daysOfMonth = Array.from({ length: daysInMonth }, (e, i) => i);

    daysOfMonth.forEach((day, idx) => {
        const date = moment({year, month: month-1, day: idx + 1})

        const eventsPerDay = userData.data.recap.filter(events => events.date.isSame(date, 'day'))

        let previousEventDate: string;

        if(eventsPerDay && eventsPerDay.length > 0) {
            eventsPerDay.forEach(event => {
                rowIndex++;

                reportSheet.getRow(rowIndex).alignment = { wrapText: true };
                cellName = "A" + rowIndex.toString();
                const eventDate = event.date.format('DD/MM/YYYY');

                if (previousEventDate !== undefined) {
                    if (eventDate === previousEventDate && type !== "QUADRIGIS" ) {
                        reportSheet.getCell(cellName).value = event.schedule;
                        reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                    } else {
                        reportSheet.getCell(cellName).value = event.date.format(getFormat('DATE')) + " " + event.schedule;
                        reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };
                    }
                } else {
                    reportSheet.getCell(cellName).value = event.date.format(getFormat('DATE')) + " " + event.schedule;
                    reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };
                }

                previousEventDate = cloneDeep(eventDate);

                //title
                cellName = "B" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.title;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: (event.color) },
                };
                reportSheet.getCell(cellName).font = {
                    name: 'Calibri'
                };
                if (event.color) {
                    const textColor = "ff" + pickBestTextColor("#" + event.color?.substring(2)).substring(1);
                    reportSheet.getCell(cellName).font.color = { argb: (textColor) };

                }

                //Type of day
                cellName = "C" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.typeOfDay;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };
                const cell1 = "C" + rowIndex.toString();
                const cell2 = "D" + rowIndex.toString();
                reportSheet.mergeCells(cell1, cell2);

                //hours per event
                cellName = "E" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.eventHours;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //overtimes
                cellName = "F" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.overtime;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //breaktimes
                cellName = "G" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.breaktime;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //total effective hours
                cellName = "H" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.effectiveHours;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //majoration (hours)
                cellName = "I" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.increasedHours;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //majoration (Vac)
                cellName = "J" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.vacationIncreasedHours;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.0000';

                //Solde
                cellName = "K" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.totalHours;
                reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'top', wrapText: true };
                reportSheet.getCell(cellName).numFmt = '0.00';

                //project
                cellName = "L" + rowIndex.toString();
                reportSheet.getCell(cellName).value = type === "QUADRIGIS" ? event.projectNumber:  event.project;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                //address
                cellName = "M" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.address;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                //engagmenet place
                cellName = "N" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.startingPoint;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                //vehicle
                cellName = "O" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.vehicle;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                //zone
                cellName = "P" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.travelZone;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                //Km OneWay
                cellName = "Q" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.distanceMade;
                reportSheet.getCell(cellName).alignment = { horizontal: 'center', vertical: 'top', wrapText: true };

                //km compensated
                cellName = "R" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.distanceCompensated;
                reportSheet.getCell(cellName).alignment = { horizontal: 'center', vertical: 'top', wrapText: true };

                //Remarks
                cellName = "S" + rowIndex.toString();
                reportSheet.getCell(cellName).value = event.remarks;
                reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'top', wrapText: true };

                reportSheet.getRow(rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            })
        }
        else if(type !== 'QUADRIGIS') {
            rowIndex++;
            //date
            cellName = "A" + rowIndex.toString();
            reportSheet.getCell(cellName).value = date.format(getFormat('DATE'));
            reportSheet.getCell(cellName).alignment = { horizontal: 'left' };

            //title
            cellName = "B" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //Type of day
            cellName = "C" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";
            const cell1 = "C" + rowIndex.toString();
            const cell2 = "D" + rowIndex.toString();
            reportSheet.mergeCells(cell1, cell2);

            //hours per event
            cellName = "E" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //overtimes
            cellName = "F" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //breaktimes
            cellName = "G" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //total hours
            cellName = "H" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //majoration (hours)
            cellName = "I" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //majoration (hours)
            cellName = "J" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //Solde
            cellName = "K" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //project
            cellName = "L" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //address
            cellName = "M" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //engagmenet place
            cellName = "N" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //vehicle
            cellName = "O" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //zone
            cellName = "P" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //Km OneWay
            cellName = "Q" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //km compensated
            cellName = "R" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";

            //Remarks
            cellName = "S" + rowIndex.toString();
            reportSheet.getCell(cellName).value = "";
            reportSheet.getRow(rowIndex).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }
    })


    const eventHours = userData.data.summary.find(s => s.name === 'Heures brutes')?.value;
    const overtime = userData.data.summary.find(s => s.name === 'Heures supplémentaires')?.value;
    const subTotal = userData.data.summary.find(s => s.name === 'Heures effectives')?.value;
    const increaseHours = userData.data.summary.find(s => s.name === 'Majoration heures')?.value;
    const increaseVacations = userData.data.summary.find(s => s.name === 'Majoration vacances')?.value;
    const total = userData.data.summary.find(s => s.name === 'Solde total')?.value;
    //Total Line (bottom of main table)-------------------------------------------------------------------------------------
    rowIndex++;
    //date (total title)
    cellName = "A" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Totals' });
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };

    //title None
    cellName = "B" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //Type of day None
    cellName = "C" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";
    cellName = "D" + rowIndex.toString();
    const cell1 = "C" + rowIndex.toString();
    const cell2 = "D" + rowIndex.toString();
    reportSheet.mergeCells(cell1, cell2);

    //hours per event 
    cellName = "E" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(eventHours), 2);
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    //overtimes
    cellName = "F" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(overtime), 2);
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    //breaktimes
    cellName = "G" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //total hours
    cellName = "H" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(subTotal), 2);
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    //majoration (hours)
    cellName = "I" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(increaseHours), 2);
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    //majoration (vac)
    cellName = "J" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(increaseVacations), 2);
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    //Solde (grand total)
    cellName = "K" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(total), 2);
    reportSheet.getCell(cellName).numFmt = '0.00';

    //project
    cellName = "L" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //address
    cellName = "M" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //engagmenet place
    cellName = "N" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //vehicle
    cellName = "O" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //zone
    cellName = "P" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //Km OneWay
    cellName = "Q" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //km compensated
    cellName = "R" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";

    //Remarks
    cellName = "S" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "";
    reportSheet.getRow(rowIndex).font = { bold: true };
    reportSheet.getRow(rowIndex).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    return ++rowIndex;
}

const generateMonthlyHoursRecap = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, userData: ReportData, rowIndex:number) => {
    const eventHours = userData.data.summary.find(s => s.name === 'Heures brutes')
    const overtime = userData.data.summary.find(s => s.name === 'Heures supplémentaires')
    const paidPauses = userData.data.summary.find(s => s.name === 'Heures de pause payée')
    const unpaidPauses = userData.data.summary.find(s => s.name === 'Heures de pause non-payées')
    const subTotal = userData.data.summary.find(s => s.name === 'Heures effectives')
    const increaseHours = userData.data.summary.find(s => s.name === 'Majoration heures')
    const increaseVacations = userData.data.summary.find(s => s.name === 'Majoration vacances')
    const total = userData.data.summary.find(s => s.name === 'Solde total')

    let cellName = ''

    // Header
    reportSheet.getRow(rowIndex).height = 30;
    reportSheet.getRow(rowIndex).alignment = { vertical: 'middle' };
    reportSheet.getRow(rowIndex).font = { bold: true };

    cellName = "A" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Monthly overview' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'middle' };
    reportSheet.getCell(cellName);
    cellName = "B" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Hours' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right', vertical: 'middle' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    if(eventHours)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Brut hours' }), eventHours.value, rowIndex)

    if(overtime)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Overtimes' }), overtime.value, rowIndex)

    if(paidPauses)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Paid break hours' }), paidPauses.value, rowIndex)

    if(unpaidPauses)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Unpaid break hours' }), unpaidPauses.value, rowIndex, true)

    if(subTotal)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Total hours' }), subTotal.value, rowIndex)

    if(increaseHours)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Bonus (Hours)' }), increaseHours.value, rowIndex)

    if(increaseVacations)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Bonus (Vacations)' }), increaseVacations.value, rowIndex)

    if(total)
        rowIndex = generateMonthlyRecapCell(reportSheet, intl.formatMessage({ defaultMessage: 'Total balance' }), total.value, rowIndex)

    return rowIndex;
}

const generateMonthlyRecapCell = (reportSheet: ExcelJS.Worksheet, title: string, value: number, rowIndex: number, special=false) => {
    rowIndex++;
    let cellName = "A" + rowIndex.toString();
    reportSheet.getCell(cellName).value = title
    if(special)
        reportSheet.getCell(cellName).font = {
            color: { argb: 'FF555555' },
            name: 'Calibri',
            italic: true
        };
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };
    cellName = "B" + rowIndex.toString();
    reportSheet.getCell(cellName).value = decimalize(val(value), 2);
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    reportSheet.getCell(cellName).numFmt = '0.00';

    return rowIndex
}

const generateTravelRecap = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, userData: ReportData, rowIndex: number) => {
    const traveRecap = userData.data.travel;
    let cellName = '';
    let cellName2 = '';

    cellName = "D" + rowIndex.toString();
    cellName2 = "E" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Travel' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left', vertical: 'middle' };
    reportSheet.mergeCells(cellName, cellName2);
    cellName = "F" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Passenger' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' };
    cellName = "G" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Personnal vehicle' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' };
    cellName = "H" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Work vehicle' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' };

    rowIndex++;//+1

    //TODO stylise to be visibly not compensated
    cellName = "D" + rowIndex.toString();
    cellName2 = "E" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Primary engagement zone' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).font = {
        color: { argb: 'FF555555' },
        name: 'Calibri',
        italic: true
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };
    reportSheet.mergeCells(cellName, cellName2);
    cellName = "F" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "( " + (traveRecap[TravelZonesIds.ENGAGEMENT]?.passengerVehicle ? traveRecap[TravelZonesIds.ENGAGEMENT].passengerVehicle : "0") + " )";
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).font = {
        color: { argb: 'FF555555' },
        name: 'Calibri',
        italic: true
    };
    cellName = "G" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "( " + (traveRecap[TravelZonesIds.ENGAGEMENT]?.ownVehicle ? traveRecap[TravelZonesIds.ENGAGEMENT].ownVehicle : "0") + " )";
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).font = {
        color: { argb: 'FF555555' },
        name: 'Calibri',
        italic: true
    };
    cellName = "H" + rowIndex.toString();
    reportSheet.getCell(cellName).value = "( " + (traveRecap[TravelZonesIds.ENGAGEMENT]?.workVehicle ? traveRecap[TravelZonesIds.ENGAGEMENT].workVehicle : "0") + " )";
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).font = {
        color: { argb: 'FF555555' },
        name: 'Calibri',
        italic: true
    };

    if(traveRecap[TravelZonesIds.FLATRATEZONE1])
        rowIndex = generateTravelRecapCell(reportSheet, intl, traveRecap[TravelZonesIds.FLATRATEZONE1], rowIndex, `${intl.formatMessage({ defaultMessage: 'Flat-rate zone' })} 1`)
    if(traveRecap[TravelZonesIds.FLATRATEZONE2])
        rowIndex = generateTravelRecapCell(reportSheet, intl, traveRecap[TravelZonesIds.FLATRATEZONE2], rowIndex, `${intl.formatMessage({ defaultMessage: 'Flat-rate zone' })} 2`)
    if(traveRecap[TravelZonesIds.ENGAGEMENT2])
        rowIndex = generateTravelRecapCell(reportSheet, intl, traveRecap[TravelZonesIds.ENGAGEMENT2], rowIndex, intl.formatMessage({ defaultMessage: 'Secondary engagement zone' }))
    if(traveRecap[TravelZonesIds.DYNAMICRATEZONE])
        rowIndex = generateTravelRecapCell(reportSheet, intl, traveRecap[TravelZonesIds.DYNAMICRATEZONE], rowIndex, intl.formatMessage({ defaultMessage: 'Regie zone' }), true);

    return rowIndex;
}

const generateTravelRecapCell = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, travelData: CctSecurityUserEventsByMonthTravelSummary, rowIndex: number, title: string, isKm=false) => {
    rowIndex++;//+5

    let cellName = '';
    let cellName2 = '';

    cellName = "D" + rowIndex.toString();
    cellName2 = "E" + rowIndex.toString();

    reportSheet.getCell(cellName).value = title;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };
    reportSheet.mergeCells(cellName, cellName2);
    cellName = "F" + rowIndex.toString();
    reportSheet.getCell(cellName).value = `${travelData.passengerVehicle} ${isKm ? "km": ""}`;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellName = "G" + rowIndex.toString();
    reportSheet.getCell(cellName).value = `${travelData.ownVehicle} ${isKm ? "km": ""}`;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    cellName = "H" + rowIndex.toString();
    reportSheet.getCell(cellName).value = `${travelData.workVehicle} ${isKm ? "km": ""}`;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    return rowIndex;
}

const generateTotalRecap = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, userData: ReportData, rowIndex: number) => {
    const totalRecap = userData.data.overview;
    let cellName = '';
    let cellName2 = '';

    cellName = "J" + rowIndex.toString();
    cellName2 = "N" + rowIndex.toString();
    const mergeName = cellName + ':' + cellName2;
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Hours and vacations overview' });
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'center', vertical: 'middle' };
    reportSheet.mergeCells(mergeName);

    rowIndex++;//+1

    cellName = "J" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Overview' });
    reportSheet.getCell(cellName).font = { bold: true };
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };
    const cellname2 = "K" + rowIndex.toString();
    reportSheet.mergeCells(cellName, cellname2);

    cellName = "L" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Last month' });
    reportSheet.getCell(cellName).font = { bold: true };
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    cellName = "M" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'This month' });
    reportSheet.getCell(cellName).font = { bold: true };
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    cellName = "N" + rowIndex.toString();
    reportSheet.getCell(cellName).value = intl.formatMessage({ defaultMessage: 'Total' });
    reportSheet.getCell(cellName).font = { bold: true };
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };

    totalRecap.forEach(recap => (rowIndex = generateTotalRecapCell(reportSheet, intl, recap, rowIndex)));

    return rowIndex;
}

const generateTotalRecapCell = (reportSheet: ExcelJS.Worksheet, intl: IntlShape, data: CctSecurityUserEventsByMonthOverview, rowIndex: number) => {
    let cellName = '';
    rowIndex++;
    // reportSheet.getRow(rowIndex).height = 20;
    cellName = "J" + rowIndex.toString();
    reportSheet.getCell(cellName).value = data.name;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'left' };
    const cellname2 = "K" + rowIndex.toString();
    reportSheet.mergeCells(cellName, cellname2);

    cellName = "L" + rowIndex.toString();
    reportSheet.getCell(cellName).value = data.prevMonthBalance;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    cellName = "M" + rowIndex.toString();
    reportSheet.getCell(cellName).value = data.currentMonthBalance;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    cellName = "N" + rowIndex.toString();
    reportSheet.getCell(cellName).value = data.nextMonthBalance;
    reportSheet.getCell(cellName).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
    reportSheet.getCell(cellName).alignment = { horizontal: 'right' };
    
    return rowIndex;
}