import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../../rbacRules';
import Network from '../../../utils/network';
import { IMandate } from '../../../utils/types/customerTypes';
import { ContainerTabsState, RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import Can from '../../common/general/can';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateSidebars, Sidebar } from '../../common/navigations/containerTabs';
import { CustomerManagementTabLink } from '../customersManagement';
import Missions from '../missions/missions';
import Mandate from './mandate';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, IntlProps {
}

interface State extends ContainerTabsState {
    mandate?: IMandate;
    loadingMandate: boolean;
}

export const enum MandateTabLink {
    DETAILS = "details",
    MISSIONS = "missions",
}

class MandateManagement extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tabItems: [
                {
                    key: MandateTabLink.DETAILS,
                    label: props.intl.formatMessage({ defaultMessage: 'Informations' }),
                    children: null
                },
                {
                    key: MandateTabLink.MISSIONS,
                    label: props.intl.formatMessage({ defaultMessage: 'Missions' }),
                    children: null
                },
            ],
            loadingMandate: false
        }
    }

    componentDidMount(): void {
        if (this.props.match.params.mandateId) {
            const mandateId = parseInt(this.props.match.params.mandateId);

            this.setState({ loadingMandate: true })
            Network.getMandate(mandateId).then(
                (response) => {
                    this.setState({
                        mandate: response.data,
                        loadingMandate: false,
                        tabItems: [
                            {
                                key: MandateTabLink.DETAILS,
                                label: this.props.intl.formatMessage({ defaultMessage: 'Informations' }),
                                children: (
                                    <Mandate mandate={response.data} keyLink={MandateTabLink.DETAILS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                                )
                            },
                            {
                                key: MandateTabLink.MISSIONS,
                                label: this.props.intl.formatMessage({ defaultMessage: 'Missions' }),
                                children: <Missions mandate={response.data} keyLink={MandateTabLink.MISSIONS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateSidebars={this.addOrUpdateSidebars} />
                            },
                        ],
                    });
                },
                () => {
                    this.setState({ loadingMandate: false });
                    this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
                }
            );
        } else {
            this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
        }
    }

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));

    addOrUpdateSidebars = (content: Sidebar[], key: string) => this.setState(prevState => addOrUpdateSidebars(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfSidebars, mandate } = this.state;
        const { intl } = this.props;
        return (
            <>
                <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                    <ContainerTabs
                        backUrl={`/crm/${CustomerManagementTabLink.CUSTOMERS.slice(0, -1)}/${mandate?.customerId}/mandates`}
                        contentClassName='__new-right-panel'
                        breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'CRM' }), link: `` }]}
                        tabItems={tabItems}
                        tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                        tabSidebars={tabListOfSidebars?.find(item => item.key === this.props.match.params.tab)?.content}
                    />
                </Can>
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(MandateManagement));