import { StoreAction, UserState } from '../../utils/types/storeTypes';

export const USER_INIT_STATE: UserState = {
    loading: false,
    currentUser: undefined,
    company: undefined,
}

export const GET_CURRENT_USER_DETAILS_STARTED = 'GET_CURRENT_USER_DETAILS_STARTED'
export const GET_CURRENT_USER_DETAILS_FINISHED = 'GET_CURRENT_USER_DETAILS_FINISHED'
export const CHANGE_CURRENT_COMPANY = 'CHANGE_CURRENT_COMPANY'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const RESET = 'RESET_USER'

const user = (state: UserState = USER_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case GET_CURRENT_USER_DETAILS_STARTED:
            return {
                ...state,
            }
        case GET_CURRENT_USER_DETAILS_FINISHED:
            return {
                ...state,
                currentUser: action.data,
            }
        case CHANGE_CURRENT_COMPANY:
            return {
                ...state,
                company: action.data,
            }
        case TOGGLE_LOADING:
            return {
                ...state,
                loading: action.data,
            }
        case RESET:
            return USER_INIT_STATE
        default:
            return state
    }
}

export default user
