
import { Moment } from 'moment';
import 'moment/locale/fr';
import { IntlShape } from 'react-intl';
import { CctSecurityUserEventsByMonth, CctSecurityUserEventsByMonthOverview, CctSecurityUserEventsByMonthRecap, CctSecurityUserEventsByMonthSummary, CctSecurityUserEventsByMonthTravelSummary } from '../../../utils/types/reportTypes';
import { isNullOrEmpty } from '../../../utils/utils';
import { generateCCTReportToExcelNew, ReportEventType } from '../common/generateReportNew';

export const downloadReportToExcel = (intl: IntlShape, type: ReportEventType, cctSecurityEventsByMonth?: CctSecurityUserEventsByMonth, targetMonth?: Moment) => {
    const cctSecurityEventsByMonthlist: CctSecurityUserEventsByMonth[] = [];
    if (cctSecurityEventsByMonth !== undefined) {
        cctSecurityEventsByMonthlist.push(cctSecurityEventsByMonth);
        if (!isNullOrEmpty(cctSecurityEventsByMonthlist) && targetMonth) {
            generateCCTReportToExcelNew(
                { creator: 'Sunkhronos', orientation: 'landscape', type },
                cctSecurityEventsByMonthlist.filter(data => data.user).map(data => {
                    const overview: CctSecurityUserEventsByMonthOverview[] = isNullOrEmpty(data.months) || !data.months[0].overview ? [] : data.months[0].overview;
                    const recap: CctSecurityUserEventsByMonthRecap[] = isNullOrEmpty(data.months) || !data.months[0].recap ? [] : data.months[0].recap;
                    const summary: CctSecurityUserEventsByMonthSummary[] = isNullOrEmpty(data.months) || !data.months[0].summary ? [] : data.months[0].summary;
                    const travel: CctSecurityUserEventsByMonthTravelSummary[] = isNullOrEmpty(data.months) || !data.months[0].travel ? [] : data.months[0].travel;

                    return ({
                        user: data.user!,
                        contract: {
                            name: data.contractName || intl.formatMessage({ defaultMessage: 'Contract without name' }),
                            type: data.contractType || { hoursMax: 10, hoursMin: 10, name: intl.formatMessage({ defaultMessage: 'Unnamed' }) }
                        },
                        data: {
                            month: data.months?.[0].monthNumber || 10,
                            overview,
                            recap,
                            summary,
                            travel
                        }
                    });
                }),
                targetMonth,
                intl
            );
        }
    }
};