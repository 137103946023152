import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { Divider, Dropdown, MenuProps, Popconfirm, Tooltip } from "antd";
import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import getFormat from "../../../../utils/Lang";
import { PlanningCell } from "../../../../utils/types/generalTypes";
import { EventCopyError, EventCopyErrorCodes } from "../../../../utils/types/planningTypes";
import { colorIsBright } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import EventPerf, { onEventActionCallback } from './eventPerf';

interface Props {
    userId: number;
    data: PlanningCell;
    day: Moment;
    canEdit: boolean;
    isLocked: boolean;
    dayStr: string;
    isBusinessDay: boolean;
    selectedEventIds: number[];
    errorEvents: EventCopyError[];
    forceCallback?: (exec: boolean) => void;
    onCreateEvent: (userId: number, dayStr: string) => void;
    onCopySelectedEvents: (userId: number, dayStr: string) => void;
    onOpenEvent: (eventId: number, canEdit: boolean) => void;
    onCopyEvent: onEventActionCallback<true>;
    onDeleteEvent: (eventId: number, userId: number, dayStr: string) => any;
    onCutEvent: onEventActionCallback<true>;
    onSelectEvent: onEventActionCallback<true>;
    onConfirmEvent: (userId: number, eventId: number) => void;
    onEditEvent: (eventId: number) => void | Promise<void>;
    onPaste: (userId: number, dayStr: string) => void | Promise<void>;
}

const Cell = (props: Props) => {
    const { dayStr, userId } = props;
    const eventsCount = useMemo(() => props.data.events.length, [props.data.events.length]);

    const onCreateEvent = useCallback(() => {
        if (props.canEdit && !props.isLocked)
            props.onCreateEvent(userId, dayStr);
    }, [userId, dayStr, props]);

    const menuItems: MenuProps['items'] = useMemo(() => ([
        {
            label: <FormattedMessage defaultMessage={'Add an event'} />,
            key: `mp-menu-add-u${userId}-empty`,
            icon: <PlusOutlined />,
            onClick: onCreateEvent,
            style: { color: 'var(--positive-color)' },
        },
    ]), [userId, onCreateEvent]);

    const availabilitiesClassname = useMemo(() => props.data.unavailabilities ? props.data.unavailabilities.type === 'P' ? '__mp-cell-partially' : '__mp-cell-blocked' : '', [props.data.unavailabilities]);

    const content = (
        <div>
            {props.data.events.map(e => (
                <EventPerf
                    key={e.id}
                    event={e}
                    day={props.day}
                    dayStr={props.dayStr}
                    selected={props.selectedEventIds.includes(e.id)}
                    hasSelectedEventsOnCurrentDay={props.selectedEventIds.length ? true : false}
                    hasSelectedEventsonAnyDay={false}
                    isCopied={false}
                    isCutted={false}
                    cellAlreadyLocked={props.isLocked}
                    canEdit={props.canEdit}
                    onOpen={() => props.onOpenEvent(e.id, e.eventLocked || props.isLocked)}
                    onCopy={props.onCopyEvent}
                    onDelete={props.onDeleteEvent}
                    onCopySelectedEvents={props.onCopySelectedEvents}
                    onCut={props.onCutEvent}
                    onConfirm={props.onConfirmEvent}
                    onEdit={props.onEditEvent}
                    onCreate={props.onCreateEvent}
                    onSelect={props.onSelectEvent}
                />
            ))}
        </div>
    );

    const tooltip = useMemo(() => {
        const ranges = props.data.unavailabilities?.type === 'P' ? props.data.unavailabilities.ranges : undefined;
        if (ranges) {
            const strs = [];
            for (const range of ranges) {
                strs.push(`${moment(range[0], "HHmmss").format(getFormat('TIME_SHORT'))} - ${moment(range[1], "HHmmss").format(getFormat('TIME_SHORT'))}`);
            }
            return strs.join(' | ');
        }
        return undefined;
    }, [props]);

    const cellFiller = useMemo(() => (eventsCount > 0 || props.isLocked || !props.canEdit
        ?
        <div className={`__mp-fill-td ${availabilitiesClassname} ${props.canEdit ? '' : 'disable'}`} data-toggle="tooltip" data-html="true" title={tooltip} onDoubleClick={onCreateEvent} />
        :
        <Dropdown trigger={['contextMenu']}
            menu={{ items: menuItems }}
            className={`__mp-fill-td ${availabilitiesClassname} ${props.canEdit ? '' : 'disable'}`}
        ><div onDoubleClick={onCreateEvent} title={tooltip} data-toggle="tooltip" data-html="true" /></Dropdown>
    ), [eventsCount, props.isLocked, props.canEdit, availabilitiesClassname, tooltip, onCreateEvent, menuItems]);

    const paste = useCallback(() => {
        props.canEdit && props.onPaste(userId, dayStr);
    }, [props, userId, dayStr]);

    let cellContent = (
        <>
            {
                props.isLocked ?
                    <Tooltip title={<FormattedMessage defaultMessage={'Day locked'} />} mouseEnterDelay={0.5}>
                        {cellFiller}
                        {content}
                        <div className='cell-lock' />
                    </Tooltip>
                    :
                    <>
                        {cellFiller}
                        {content}
                        <div className={`__mp-paste  ${props.canEdit ? '' : 'disable'}`} onClick={paste} />
                    </>
            }
        </>
    );
    const getCodeMessage = useCallback((code?: EventCopyErrorCodes) => {
        switch (code) {
            case 'NOT_IN_PERIODS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Date of copy outside mission period'} /></span><br /></>;
            case 'TOO_MANY_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many scheduled hours'} /></span><br /></>;
            case 'TOO_MANY_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many hours scheduled in the event'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled in the event'} /></span><br /></>;
            case 'TOO_MANY_HUMANS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Overload of human needs for the mission'} /></span><br /></>;
            default:
                return <><span>{' - '}<FormattedMessage defaultMessage={'Unknown error'} /></span><br /></>;
        }
    }, []);

    if (props.forceCallback) {
        cellContent = (
            <Popconfirm
                open={true}
                title={
                    <div className='__mp-force-container'>
                        <p style={{ fontWeight: 'bolder' }}><WarningOutlined style={{ color: '#ff2626' }} /> <FormattedMessage defaultMessage={'Force copy'} /></p>
                        <Divider style={{ marginTop: 10 }} />
                        {
                            props.errorEvents.map((event, idx) => {
                                const eventcolor = event.event.color || event.event.colorHex || 'var(--primary-color)';
                                return (
                                    <div key={`event-${idx}`} className='__mp-force-event-container'>
                                        <div className='__mp-force-icons-container'>
                                            <div className='__mp-force-icon'>
                                                {
                                                    event.errors.findIndex(e => e.type && e.type === 'unavailabilities') !== -1 ?
                                                        <Tooltip
                                                            title={<FormattedMessage defaultMessage={'User not available'} />}
                                                        >
                                                            <FAIcon name='user-slash' prefix='far' className='__mp-force-icon-fa' color={'black'} />
                                                        </Tooltip>
                                                        :
                                                        <FAIcon name='user-slash' prefix='far' className='__mp-force-icon-fa' color={'#ececec'} />
                                                }
                                            </div>
                                            <div className='__mp-force-icon'>
                                                {
                                                    event.errors.findIndex(e => e.type && e.type === 'overlap' || e.type && e.type === 'new-events-overlap') !== -1 ?
                                                        <Tooltip
                                                            title={<FormattedMessage defaultMessage={'Event overlap'} />}
                                                        >
                                                            <FAIcon name='circles-overlap' prefix='far' className='__mp-force-icon-fa' color={'black'} />
                                                        </Tooltip>
                                                        :
                                                        <FAIcon name='circles-overlap' prefix='far' className='__mp-force-icon-fa' color={'#ececec'} />
                                                }
                                            </div>
                                            <div className='__mp-force-icon'>
                                                {
                                                    event.errors.findIndex(e => e.type && e.type === 'missionMissmatch') !== -1 ?
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <p><FormattedMessage defaultMessage={'CRM Error'} /></p>
                                                                    {event.errors[event.errors.findIndex(e => e.type && e.type === 'missionMissmatch')].codes?.map(code => getCodeMessage(code))}
                                                                </>
                                                            }

                                                        >
                                                            <FAIcon name='user-gear' prefix='far' className='__mp-force-icon-fa' color={'black'} />
                                                        </Tooltip>
                                                        :
                                                        <FAIcon name='user-gear' prefix='far' className='__mp-force-icon-fa' color={'#ececec'} />
                                                }
                                            </div>
                                        </div>
                                        <div className='__mp-force-event' style={{ background: eventcolor, color: colorIsBright(eventcolor) ? '#373737' : 'white' }}>
                                            <div className='__mp-force-event-title'>{event.event.title}</div>
                                            <div className='__mp-force-event-hours'>{`${event.event.startTime} - ${event.event.endTime}`}</div>
                                        </div>

                                        {/* <div style={{marginBottom: -20}}>
                                            <div style={{fontStyle: 'italic'}}>{event.event.title}</div>
                                            {getTypeMessage(event.type)}
                                        </div>
                                        <div>
                                            {
                                                event.codes && event.codes?.length > 0 &&
                                                <>
                                                    <br />
                                                    <p><FormattedMessage defaultMessage={'Informations'} />{':'}</p>
                                                    {
                                                        event.codes.map(code => getCodeMessage(code))
                                                    }
                                                </>
                                            }
                                        </div> */}
                                    </div>
                                );
                            })
                        }

                        <br />
                        <p><strong><FormattedMessage defaultMessage={"Do you really want to force copy this event?"} /></strong></p>
                    </div>
                }
                okText={<FormattedMessage defaultMessage={'Force copy'} />}
                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                okButtonProps={{ type: 'link' }}
                cancelButtonProps={{ type: 'link' }}
                icon={<></>}
                // icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                placement={'bottom'}
                onConfirm={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    props.forceCallback && props.forceCallback(true);
                }}
                onCancel={() => props.forceCallback && props.forceCallback(false)}>
                {cellContent}
            </Popconfirm>
        );
    }

    return (
        <>{cellContent}</>
    );
};

Cell.displayName = "cell";

export default Cell;