

import { Component } from 'react';
import { EventForProject } from '../../../../utils/types/planningTypes';

export interface AbstractReportsGroupedProps {
    displayDetails: boolean;
    eventsForProjects?: EventForProject[];
    height: number;
}

export abstract class AbstractReportsGrouped<Props extends AbstractReportsGroupedProps, State> extends Component<Props, State> {
    abstract downloadProjectEvents: () => void
}