import { Component } from 'react';
import { Sidebar } from './containerTabs';

export interface ContainerTabsItemProps {
    addOrUpdateExtra: (actions: React.ReactNode, key: string) => void;
    addOrUpdateSidebars: (sidebars: Sidebar[], key: string) => void;
    keyLink: string
}

export abstract class ContainerTabsItem<Props extends ContainerTabsItemProps, State> extends Component<Props, State> {
    getExtra = (): React.ReactNode => null;
    getSidebars = (): Sidebar[] | undefined => undefined;
}

export default ContainerTabsItem;