import { CloseOutlined, DashOutlined, MoreOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Divider, Popconfirm, Popover, Segmented, Select, Space, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../../rbacRules';
import { customersFetched, mandatesFetched } from '../../../../store/features/customerManagement';
import { MOMENT_FORMAT, MOMENT_TIME_FORMAT } from '../../../../utils/constants';
import { convertIAggregatedEventToIAggregatedEventWithUser } from '../../../../utils/dataConverter/customerConverters';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { IAccountingCombination, IAggregatedEventWithUser, ISplittedEvent } from '../../../../utils/types/customerTypes';
import { RouterProps } from '../../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { checkRBACRule, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import SpeedDial from '../../../common/fields/speedDial';
import Anticon from '../../../common/general/anticon';
import Can from '../../../common/general/can';
import Card from '../../../common/general/card';
import VirtualTable from '../../../common/general/virtualTable';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, RouterProps, IntlProps { }

interface State {
    aggregatedEvents: IAggregatedEventWithUser[];
    updatedAt?: string;
    loading: boolean;
    loadingSet: boolean;
    selected: number[];
    selectedDate: Moment;
    display: 'weekly' | 'monthly';
    showFilters: boolean;
    filters: {
        users: number[];
        mandates: number[];
        projects: number[];
        accountings: number[];
        customers: number[];
        typeOfDay: number[];
        typeOfDayOff: number[];
    };
    accountingCombinations: IAccountingCombination[];
}

class ShiftsValidation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            aggregatedEvents: [],
            loading: false,
            loadingSet: false,
            selected: [],
            selectedDate: moment(),
            display: 'monthly',
            showFilters: false,
            filters: {
                users: [],
                mandates: [],
                projects: [],
                accountings: [],
                customers: [],
                typeOfDay: [],
                typeOfDayOff: []
            },
            accountingCombinations: []
        };
    }

    renderSplittedEventsCount = (record: IAggregatedEventWithUser) => {
        const columns: ColumnProps<ISplittedEvent>[] = [
            {
                title: <FormattedMessage defaultMessage={'Start'} />,
                key: 'startDatetime',
                dataIndex: 'startDatetime',
                align: 'center',
                render: (_, record) => moment(record.startDatetime, MOMENT_FORMAT).format(getFormat('TIME_SHORT'))
            },
            {
                title: <FormattedMessage defaultMessage={'End'} />,
                key: 'endDatetime',
                dataIndex: 'endDatetime',
                align: 'center',
                render: (_, record) => moment(record.endDatetime, MOMENT_FORMAT).format(getFormat('TIME_SHORT'))
            },
            {
                title: <FormattedMessage defaultMessage={'Duration'} />,
                key: 'duration',
                dataIndex: 'duration',
                render: (_, record) => moment(record.duration, MOMENT_TIME_FORMAT).format(getFormat('TIME'))
            }
        ];
        if (record.splittedEvents.length > 0)
            return (
                <Popover
                    style={{ width: 300 }}
                    content={
                        <Table
                            dataSource={record.splittedEvents.sort((a, b) => (moment(a.startDatetime).unix() < moment(b.startDatetime).unix()) ? -1 : 1)}
                            columns={columns}
                            pagination={false}
                        />
                    }
                    popupVisible={record.splittedEvents.length > 0}
                >
                    <div className='shift-validations-events-count'>
                        {record.splittedEvents.length}
                    </div>
                </Popover>
            );
        else
            return record.splittedEvents.length;
    };

    formatDuration = (duration: moment.Duration) => {
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const dayPart = days > 0 ? `${days}j ` : '';
        const timePart = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        return dayPart + timePart;
    };

    tableColumns: ColumnProps<IAggregatedEventWithUser>[] = [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            className: '__min-width-200',
            sorter: (a, b) => {
                const aFullName = `${a.user?.first_name} ${a.user?.last_name}`.toLocaleLowerCase();
                const bFullName = `${b.user?.first_name} ${b.user?.last_name}`.toLocaleLowerCase();
                return aFullName.localeCompare(bFullName);
            },
            render: (_, record) => `${record.user?.first_name} ${record.user?.last_name}`,
        },
        {
            title: <FormattedMessage defaultMessage={'Customer'} />,
            key: 'customer',
            dataIndex: 'customerId',
            className: '__width_300',
            render: (record) => record && this.props.customers.data?.find(customer => customer.id === record)?.title || '-',
        },
        {
            title: <FormattedMessage defaultMessage={'Mandate'} />,
            key: 'mandate',
            dataIndex: 'mandateId',
            className: '__width_200',
            sorter: (a, b) => {
                const titleA = this.props.mandates.data?.find(m => m.id === a.mandateId)?.title.toLocaleLowerCase() || '';
                const titleB = this.props.mandates.data?.find(m => m.id === b.mandateId)?.title.toLocaleLowerCase() || '';
                return titleA.localeCompare(titleB);
            },
            render: (record) => record && this.props.mandates.data?.find(mandate => mandate.id === record)?.title || '-',
        },
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            key: 'day',
            className: '__width_200',
            render: (record) => {
                if (record.typeOfDayId || record.typeOfDayOffId) {
                    if (record.typeOfDayId) {
                        const typeOfDay = this.props.typeOfDay.find(d => d.id === record.typeOfDayId);
                        return typeOfDay ? typeOfDay.title : '-';
                    } else {
                        const typeOfDayOff = this.props.typeOfDayOff.find(d => d.id === record.typeOfDayOffId);
                        return typeOfDayOff ? <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}><FAIcon name='calendar-xmark' prefix='fad' style={{ color: '' }} /> {typeOfDayOff.title}</div> : '-';
                    }
                }
                return '-';
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Project'} />,
            key: 'project',
            dataIndex: 'projectId',
            className: '__width_200',
            render: (record) => record && this.props.project?.find(project => project.id === record)?.title || '-',
        },
        {
            title: <FormattedMessage defaultMessage={'Accounting code'} />,
            key: 'accounting',
            dataIndex: 'accountingCombination',
            className: '__width_150 __centered-text',
            sorter: (a: IAggregatedEventWithUser, b: IAggregatedEventWithUser) => {
                const aAccounting = `${a.accountingCombination?.rubric?.code || ''}-${a.accountingCombination?.analyticPlan?.code || ''}`;
                const bAccounting = `${b.accountingCombination?.rubric?.code || ''}-${b.accountingCombination?.analyticPlan?.code || ''}`;

                return aAccounting.localeCompare(bAccounting);
            },
            render: (record: IAccountingCombination) => record ? `${record.rubric?.code || '' || ''}-${record.analyticPlan?.code || '' || ''}` : '-',
        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            dataIndex: 'date',
            className: '__width_140',
            sorter: (a, b) => {
                return moment(a.date).diff(moment(b.date));
            },
            render: (value) => moment(value).format(getFormat('DATE')),
        },
        {
            title: <FormattedMessage defaultMessage={'Duration'} />,
            key: 'duration',
            dataIndex: 'duration',
            sorter: (a, b) => {
                return (a.duration < b.duration) ? -1 : (a.duration > b.duration) ? 1 : 0;
            },
            render: (_, record) => {
                const duration = moment.duration(record.duration, 'seconds');
                return this.formatDuration(duration);
            },
            className: '__width_120'
        },
        {
            title: <FormattedMessage defaultMessage={'Events'} />,
            key: 'splittedEvents',
            className: '__width_120 __centered-text',
            sorter: (a, b) => {
                return a.splittedEvents.length < b.splittedEvents.length ? -1 : 1;
            },
            render: (_, record) => this.renderSplittedEventsCount(record),
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            fixed: 'right',
            className: '__width_80 __centered-text',
            render: (_, record) => {
                return (
                    <Popconfirm
                        title={
                            <>
                                <p style={{ marginBottom: 10 }}><strong><FormattedMessage defaultMessage={'Confirm and lock the hours.'} /></strong></p>
                                <p><FormattedMessage defaultMessage={'I confirm the selected hours.'} /></p>
                                <p style={{ marginBottom: 20 }}><FormattedMessage defaultMessage={'I lock events in the schedule.'} /></p>
                                <p style={{ fontWeight: 'bold', color: 'red' }}><FormattedMessage defaultMessage={'This action is irreversible !'} /></p>
                            </>
                        }
                        icon={<FAIcon name='triangle-exclamation' prefix='far' color='red' />}
                        onConfirm={() => this.onValidateAggregatedEvents(record.id)}
                        disabled={this.state.loadingSet}
                        okText={<FormattedMessage defaultMessage={'Confirm and lock'} />}
                        placement='left'
                    >
                        <CircleButton
                            icon={<Anticon><FAIcon name='check' prefix='fal' /></Anticon>}
                            // icon={<FAIcon name='check' prefix='fal' />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Confirm and lock' })}
                            placement='left'
                            loading={this.state.loadingSet}
                            small
                        />
                    </Popconfirm>

                );
            },
        }
    ];

    componentDidMount() {
        this.loadEvents();
        this.loadAccountingCodes();
        this.props.mandatesFetched();
        this.props.customersFetched();
    }

    loadEvents = () => {
        const { display, selectedDate } = this.state;
        const endOrStartOf = display === 'monthly' ? 'month' : 'week';
        this.setState({ loading: true });
        Network.getAggregatedEventsForGlobalOfficeShifts(selectedDate.clone().startOf(endOrStartOf), selectedDate.clone().endOf(endOrStartOf)).then(
            (response) => {
                this.setState({ aggregatedEvents: convertIAggregatedEventToIAggregatedEventWithUser(response.data, this.props.users), updatedAt: moment().toISOString() });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the employees hours' }), "error")
        ).finally(() => this.setState({ loading: false }));
    };

    loadAccountingCodes = () => {
        Network.getAccountingCombinations().then(
            (response) => {
                this.setState({ accountingCombinations: response.data });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the accounting combinations' }), 'error');
            }
        );
    };

    getExtra = () => null;
    getFilters = () => null;

    onChangeAggregated = (keys: React.Key[]) => !this.state.loadingSet && this.setState({ selected: keys as number[] });

    onValidateAggregatedEvents = (id?: number) => {
        const { selected, selectedDate, display } = this.state;
        const endOrStartOf = display === 'monthly' ? 'month' : 'week';

        const ids: number[] = id !== undefined ? [id] : selected;

        if (ids.length > 0) {
            this.setState({ loadingSet: true });
            Network.setAggregatedEvents(selectedDate.clone().startOf(endOrStartOf), selectedDate.clone().endOf(endOrStartOf), ids).then(
                response => {
                    this.setState(prevState => ({ loadingSet: false, selected: id ? prevState.selected.filter(e => e !== id) : [], aggregatedEvents: convertIAggregatedEventToIAggregatedEventWithUser(response.data, this.props.users), updatedAt: moment().toISOString() }));
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The hours have been validated successfully' }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), "error")
            ).finally(() => this.setState({ loadingSet: false }));
        }
    };

    customMonthStartEndFormat = (value: Moment) => `${value.clone().startOf('month').format(getFormat('MONTH'))}`;
    customWeekStartEndFormat = (value: Moment) => `${value.clone().startOf('week').format(getFormat('DAY_SHORT_AND_MONTH_HALF'))} - ${value.clone().endOf('week').format(getFormat('DAY_SHORT_AND_MONTH_HALF'))}`;

    onDateChange = (sD: Moment | null) => {
        const { selectedDate } = this.state;
        if (sD != null && sD !== selectedDate) {
            this.setState({ selectedDate: sD }, () => this.loadEvents());
        }
    };

    renderCustomDatePicker = () => {
        const { selectedDate, display, loading } = this.state;
        return (
            <div key={`custom-date-picker-div-${selectedDate.format('YYYYMMDD')}`} className='work_control-datepicker-input-container'>
                {
                    display === 'weekly' ?
                        <DatePicker.WeekPicker
                            className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                            key={`week-picker-${selectedDate.format("YY")}-${selectedDate.week()}`}
                            //picker='week'
                            suffixIcon={null}
                            placement={'bottomLeft'}
                            size='large'
                            bordered={false}
                            format={(value) => this.customWeekStartEndFormat(value)}
                            value={selectedDate}
                            onChange={this.onDateChange}
                            allowClear={false}
                            inputReadOnly
                            style={{
                                textAlign: 'center'
                            }}
                            disabled={loading}
                        />
                        :
                        <DatePicker
                            className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                            key={`year-picker-${selectedDate.format("YY-MM")}-${selectedDate.month()}`}
                            //picker='week'
                            suffixIcon={null}
                            placement={'bottomLeft'}
                            size='large'
                            picker={'month'}
                            bordered={false}
                            format={(value) => this.customMonthStartEndFormat(value)}
                            value={selectedDate}
                            onChange={this.onDateChange}
                            allowClear={false}
                            inputReadOnly
                            style={{
                                textAlign: 'center'
                            }}
                            disabled={loading}
                        />
                }
            </div>


        );
    };

    onNext = () => {
        const { display, selectedDate } = this.state;
        let next: Moment;
        if (display === 'monthly')
            next = selectedDate.clone().endOf('month').add(1, 'day');
        else
            next = selectedDate.clone().endOf('week').add(1, 'day');

        this.onDateChange(next);
    };
    onLast = () => {
        let last: Moment;
        const { display, selectedDate } = this.state;
        if (display === 'monthly')
            last = selectedDate.clone().startOf('month').subtract(1, 'day');
        else
            last = selectedDate.clone().startOf('week').subtract(1, 'day');

        this.onDateChange(last);
    };

    resetDate = () => {
        this.onDateChange(moment());
    };

    resetFilters = () => {
        this.setState({ filters: { accountings: [], projects: [], mandates: [], users: [], customers: [], typeOfDay: [], typeOfDayOff: [] } });
    };

    filterAggregatedEvents = () => {
        const { filters, aggregatedEvents } = this.state;

        let tmpEvents = cloneDeep(aggregatedEvents);

        if (filters.users.length > 0)
            tmpEvents = tmpEvents.filter(event => filters.users.includes(event.userId));
        if (filters.mandates.length > 0)
            tmpEvents = tmpEvents.filter(event => event.mandateId && filters.mandates.includes(event.mandateId));
        if (filters.projects.length > 0)
            tmpEvents = tmpEvents.filter(event => event.projectId && filters.projects.includes(event.projectId));
        if (filters.accountings.length > 0) {
            tmpEvents = tmpEvents.filter(event => (filters.accountings.includes(-1) && event.accountingCombination == null) || event.accountingCombination?.id && filters.accountings.includes(event.accountingCombination.id));
        }
        if (filters.customers.length > 0) {
            tmpEvents = tmpEvents.filter(event => (filters.customers.includes(-1) && event.customerId == null) || (event.customerId && filters.customers.includes(event.customerId)));
        }
        if (filters.typeOfDay.length > 0) {
            tmpEvents = tmpEvents.filter(event => (filters.typeOfDay.includes(-1) && event.typeOfDayId == null) || (event.typeOfDayId && filters.typeOfDay.includes(event.typeOfDayId)));
        }
        if (filters.typeOfDayOff.length > 0) {
            tmpEvents = tmpEvents.filter(event => (filters.typeOfDayOff.includes(-1) && event.typeOfDayOffId == null) || (event.typeOfDayOffId && filters.typeOfDayOff.includes(event.typeOfDayOffId)));
        }

        return tmpEvents;
    };

    hasFilterActive = () => {
        const { filters } = this.state;
        return filters.users.length > 0 || filters.mandates.length > 0 || filters.accountings.length > 0;
    };

    render() {
        const { loading, loadingSet, selected, display, selectedDate, filters, accountingCombinations } = this.state;
        const { width, isSmartphone, intl } = this.props;
        let tableHeight = this.props.height - 240;
        if (tableHeight < 250) tableHeight = 250;

        let filteredTableColumns = this.tableColumns;
        if (accountingCombinations.length > 0)
            filteredTableColumns = filteredTableColumns.filter(col => col.key !== 'accounting');

        if (checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id))
            filteredTableColumns = this.tableColumns.filter(col => col.key !== 'project');
        else
            filteredTableColumns = this.tableColumns.filter(col => !['mandate', 'customer'].includes(col.key as string));

        const mobileButtons: ReactNode[] = [];


        const validateButton = (<Popconfirm
            title={
                <>
                    <p style={{ marginBottom: 10 }}><strong><FormattedMessage defaultMessage={'Confirm and lock the hours.'} /></strong></p>
                    <p><FormattedMessage defaultMessage={'I confirm the selected hours.'} /></p>
                    <p style={{ marginBottom: 20 }}><FormattedMessage defaultMessage={'I lock events in the schedule.'} /></p>
                    <p style={{ fontWeight: 'bold', color: 'red' }}><FormattedMessage defaultMessage={'This action is irreversible !'} /></p>
                </>
            }
            icon={<FAIcon name='triangle-exclamation' prefix='far' color='red' />}
            onConfirm={() => this.onValidateAggregatedEvents()}
            disabled={selected.length <= 0}
            okText={<FormattedMessage defaultMessage={'Confirm and lock'} />}
        >
            {
                width > 1070 ?
                    <Button
                        loading={loading || loadingSet}
                        type='primary'
                        disabled={selected.length <= 0}
                    >
                        <FormattedMessage defaultMessage={'Confirm and lock'} />
                    </Button>
                    :
                    <CircleButton
                        loading={loading || loadingSet}
                        type='primary'
                        small
                        disabled={selected.length <= 0}
                        icon={<FAIcon name='check' prefix='far' />}
                        title={intl.formatMessage({ defaultMessage: 'Confirm and lock' })}
                    />
            }
        </Popconfirm>);

        const refreshButton = (<CircleButton small icon={<FAIcon prefix='fad' name='rotate' />} onClick={() => this.loadEvents()} disabled={loading || loadingSet} />);
        const homeButton = (
            <>
                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                <CircleButton
                    small
                    icon={<FAIcon prefix='fad' name='house' />}
                    onClick={() => this.resetDate()}
                    disabled={loading || loadingSet || selectedDate.isSame(moment(), display === 'monthly' ? 'month' : 'week')}
                />
            </>
        );
        const filterButton = (<CircleButton small type={this.state.showFilters ? 'primary' : 'default'} icon={<Badge dot={this.hasFilterActive()}><FAIcon prefix='fad' name='filters' color={this.state.showFilters ? 'white' : 'black'} /></Badge>} onClick={() => this.setState({ showFilters: !this.state.showFilters })} />);

        if (width <= 470) {
            mobileButtons.push(homeButton);
            mobileButtons.push(refreshButton);
        }
        mobileButtons.push(validateButton);
        mobileButtons.push(filterButton);

        return (
            <Can rule={Rules.Report.Visit} redirect="/dashboard">
                <Card
                    title={
                        <Space>
                            <CircleButton small icon={<FAIcon prefix='fal' name='chevron-left' />} onClick={() => this.onLast()} disabled={loading || loadingSet} />
                            {this.renderCustomDatePicker()}
                            <CircleButton small icon={<FAIcon prefix='fal' name='chevron-right' />} onClick={() => this.onNext()} disabled={loading || loadingSet} />
                            {
                                width > 470 &&
                                <>
                                    {homeButton}
                                    {refreshButton}
                                </>
                            }
                        </Space>
                    }
                    icon={<FAIcon prefix='fad' name='user-clock' />}
                    headerElements={[
                        <>
                            {
                                isSmartphone ?
                                    <SpeedDial
                                        small={true}
                                        key='course-actions-speeddial'
                                        title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                        icon={<MoreOutlined />}
                                        openIcon={<DashOutlined />}
                                        style={{ right: '35px' }}
                                        buttons={mobileButtons}
                                    />
                                    :
                                    <>
                                        {validateButton}
                                        <Divider type='vertical' />
                                        {
                                            width > 1500 &&
                                            <Segmented
                                                block
                                                disabled={loading}
                                                style={{ width: "300px" }}
                                                key={'accounting_globaloffice-show-options'}
                                                value={display}
                                                options={[
                                                    { value: 'weekly', label: intl.formatMessage({ defaultMessage: 'Weekly' }) },
                                                    { value: 'monthly', label: intl.formatMessage({ defaultMessage: 'Monthly' }) }
                                                ]}
                                                onChange={(value) => {
                                                    this.setState({ display: value === 'monthly' ? 'monthly' : 'weekly' }, () => this.loadEvents());
                                                }}
                                            />
                                        }
                                        {filterButton}
                                    </>
                            }
                        </>

                    ]}
                >
                    <Spin spinning={loading || loadingSet}>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <VirtualTable
                                rowKey={(ae: any) => ae.id}
                                dataSource={this.filterAggregatedEvents()}
                                columns={filteredTableColumns}
                                loading={loading}
                                style={{ flex: 1, overflow: 'auto' }}
                                scroll={{ x: true, y: tableHeight }}
                                pagination={false}
                                rowSelection={{ type: 'checkbox', onChange: this.onChangeAggregated, selectedRowKeys: selected }}
                            />
                            {
                                this.state.showFilters &&
                                <div style={{ maxWidth: 400 }} className='integrations-filters'>
                                    <p className='__mp-sider-title'><FormattedMessage defaultMessage={'Filters'} /></p>
                                    <CircleButton small className='reset-filters-button' icon={<FAIcon prefix='fad' name='rotate-right' />} onClick={() => this.resetFilters()} />
                                    <div className='__mp-main-siders-content'>

                                        <div className='filters'>
                                            <div className="filter-group">
                                                <div className="title"><FormattedMessage defaultMessage={'Users'} /></div>
                                                <div>
                                                    <Select
                                                        className="__filter-users-group-select"
                                                        listHeight={500}
                                                        mode="multiple"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        value={filters.users}
                                                        onChange={(e) => this.setState({ filters: { ...filters, users: e } })}
                                                        filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                        optionFilterProp="label"
                                                        showArrow
                                                        maxTagCount={"responsive"}
                                                        options={this.props.users.map(user => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="filter-group">
                                                <div className="title"><FormattedMessage defaultMessage={'Type of days'} /></div>
                                                <div>
                                                    <Select
                                                        className="__filter-users-group-select"
                                                        listHeight={500}
                                                        mode="multiple"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        value={filters.typeOfDay}
                                                        onChange={(e) => this.setState({ filters: { ...filters, typeOfDay: e } })}
                                                        filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                        optionFilterProp="label"
                                                        showArrow
                                                        maxTagCount={"responsive"}
                                                        options={this.props.typeOfDay.map(day => ({ value: day.id, label: day.title }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="filter-group">
                                                <div className="title"><FormattedMessage defaultMessage={'Types of day off'} /></div>
                                                <div>
                                                    <Select
                                                        className="__filter-users-group-select"
                                                        listHeight={500}
                                                        mode="multiple"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        value={filters.typeOfDayOff}
                                                        onChange={(e) => this.setState({ filters: { ...filters, typeOfDayOff: e } })}
                                                        filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                        optionFilterProp="label"
                                                        showArrow
                                                        maxTagCount={"responsive"}
                                                        options={this.props.typeOfDayOff.map(day => ({ value: day.id, label: day.title }))}
                                                    />
                                                </div>
                                            </div>

                                            {checkRBACRule(Rules.CustomerManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) ?
                                                <>
                                                    <div className="filter-group">
                                                        <div className="title"><FormattedMessage defaultMessage={'Mandates'} /></div>
                                                        <div>
                                                            <Select
                                                                className="__filter-users-group-select"
                                                                listHeight={500}
                                                                mode="multiple"
                                                                allowClear
                                                                loading={this.props.mandates.loading}
                                                                style={{ width: '100%' }}
                                                                value={filters.mandates}
                                                                onChange={(e) => this.setState({ filters: { ...filters, mandates: e } })}
                                                                filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                                optionFilterProp="label"
                                                                showArrow
                                                                maxTagCount={"responsive"}
                                                                disabled={this.props.mandates.loading === false && (this.props.mandates.data === undefined || this.props.mandates.data.length === 0)}
                                                                options={this.props.mandates.data?.map(mandate => ({ value: mandate.id, label: mandate.title }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="filter-group">
                                                        <div className="title"><FormattedMessage defaultMessage={'Customers'} /></div>
                                                        <div>
                                                            <Select
                                                                className="__filter-users-group-select"
                                                                listHeight={500}
                                                                mode="multiple"
                                                                allowClear
                                                                loading={this.props.customers.loading}
                                                                style={{ width: '100%' }}
                                                                value={filters.customers}
                                                                onChange={(e) => this.setState({ filters: { ...filters, customers: e } })}
                                                                filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                                optionFilterProp="label"
                                                                showArrow
                                                                maxTagCount={"responsive"}
                                                                disabled={this.props.customers.loading === false && (this.props.customers.data === undefined || this.props.customers.data.length === 0)}
                                                                options={this.props.customers.data?.map(customer => ({ value: customer.id, label: customer.title }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="filter-group">
                                                    <div className="title"><FormattedMessage defaultMessage={'Projects'} /></div>
                                                    <div>
                                                        <Select
                                                            className="__filter-users-group-select"
                                                            listHeight={500}
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            value={filters.projects}
                                                            onChange={(e) => this.setState({ filters: { ...filters, projects: e } })}
                                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                            optionFilterProp="label"
                                                            showArrow
                                                            maxTagCount={"responsive"}
                                                            options={this.props.project?.map(project => ({ value: project.id, label: project.title }))}
                                                        />
                                                    </div>
                                                </div>

                                            }
                                            {
                                                this.state.accountingCombinations.length > 0 &&
                                                <div className="filter-group">
                                                    <div className="title"><FormattedMessage defaultMessage={'Accounting codes'} /></div>
                                                    <div>
                                                        <Select
                                                            className="__filter-users-group-select"
                                                            listHeight={500}
                                                            mode="multiple"
                                                            allowClear
                                                            loading={this.props.mandates.loading}
                                                            style={{ width: '100%' }}
                                                            value={filters.accountings}
                                                            onChange={(e) => this.setState({ filters: { ...filters, accountings: e } })}
                                                            filterOption={(input, option) => (option?.label?.toString().toLocaleLowerCase() ?? '')?.includes(input.toLocaleLowerCase())}
                                                            optionFilterProp="label"
                                                            showArrow
                                                            maxTagCount={"responsive"}
                                                            options={[{ label: "Sans codes comptable", value: -1 }].concat(this.state.accountingCombinations.map(comb => ({ value: comb.id, label: `${comb.rubric?.code || ''}-${comb.analyticPlan?.code || ''} (${comb.title})` })))}
                                                            tagRender={(record) => (
                                                                <span className='ant-select-selection-item' >
                                                                    <span className='ant-select-selection-item-content'>{record.label?.toString().split(' ')[0]}</span>
                                                                    <span className='ant-select-selection-item-remove' onClick={record.onClose}>
                                                                        <CloseOutlined />
                                                                    </span>
                                                                </span>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Spin>
                </Card>
            </Can>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    width: state.window.width,
    users: state.teamManagement.users,
    mandates: state.customerManagement.mandates,
    project: state.configurations.project,
    currentUser: state.user.currentUser,
    customers: state.customerManagement.customers,
    typeOfDay: state.configurations.typesOfDay,
    typeOfDayOff: state.configurations.typesOfDayOff
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    mandatesFetched: () => dispatch(mandatesFetched({ forceReload: false })),
    customersFetched: () => dispatch(customersFetched({ forceReload: false })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(ShiftsValidation)));
