import { Alert, TimePicker as AntdTimePicker, Col, DatePicker, Row } from 'antd';
import React, { ReactNode } from 'react';
// import TimePicker from 'react-time-picker'
import moment, { Moment } from 'moment';
import { roundDecimals } from '../../../utils/utils';

import { SxProps } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimeView } from '@mui/x-date-pickers/models';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { defineMessage, defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import getFormat from '../../../utils/Lang';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';

const enum DateTimePickerError {
    FROMAFTERTO = 'fromafterto',
    FROMBEFOREEVENT = 'frombeforeevent',
    FROMAFTEREVENT = 'fromafterevent',
    TOBEFOREEVENT = 'tobeforeevent',
    TOAFTEREVENT = 'toafterevent'
}

const ErrorMessages = defineMessages<DateTimePickerError>({
    [DateTimePickerError.FROMAFTERTO]: { defaultMessage: 'The start date is later than the end date' },
    [DateTimePickerError.FROMBEFOREEVENT]: { defaultMessage: 'The start date is before the event' },
    [DateTimePickerError.FROMAFTEREVENT]: { defaultMessage: 'The start date is later than the event' },
    [DateTimePickerError.TOBEFOREEVENT]: { defaultMessage: 'The end date is before the event' },
    [DateTimePickerError.TOAFTEREVENT]: { defaultMessage: 'The end date is after the event' },
});

const DATETIME_PICKER_ERROR = {
    TOOLONG: defineMessage({ defaultMessage: 'Unusually long event duration' }),
    TOOLONGVAL: 13,
};

const TimePickerStyle: SxProps = [
    {
        '.MuiFormControl-root': {
        }
    },
    {
        '.MuiInputBase-root': {
            borderRadius: '30px',
        }
    },
    {
        '.MuiInputBase-root:hover fieldset': {
            borderColor: '#c78034',
        }
    },
    {
        '.Mui-focused fieldset': {
            borderColor: '#c78034!important',
            borderWidth: '1px!important'
        }
    },
    {
        input: {
            padding: '0px 10px',
            height: '33px',
            color: '#373737',
            fontSize: '16px',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
        }
    },
    {
        fieldset: {
            borderColor: 'var(--border-color)',
        }
    },
    {
        width: '135px',
    }
];

export interface TooLongInterface {
    errorStr: string;
    duration: number,
}

export const enum DateTimePickerType {
    PICKER = 1,
    RANGE = 2,
}
export const enum DateTimePickerVersion {
    PICKER = 1,
    FIELD = 2,
}
type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    valueFrom?: Moment;
    defaultValueFrom?: Moment;
    minFrom?: Moment;
    maxFrom?: Moment;
    titleFrom?: string | ReactNode;
    placeHolderDateFrom?: string;
    placeHolderTimeFrom?: string;
    formatDateFrom?: string;
    formatTimeFrom?: string;
    disableDatePickerFrom?: boolean;
    disableTimePickerFrom?: boolean;
    timeViewFrom?: TimeView[];
    styleFromDatePicker?: React.CSSProperties;
    styleFromTimePicker?: React.CSSProperties;
    onChangeFrom: (date: Moment | null) => void;
    onBlur?: () => void;

    disableDateFrom?: (date: Moment) => boolean;
    disableTimeFrom?: (date: Moment | null) => any;//DisabledTimes;

    valueTo?: Moment;
    defaultValueTo?: Moment;
    minTo?: Moment;
    maxTo?: Moment;
    titleTo?: string | ReactNode;
    placeHolderDateTo?: string;
    placeHolderTimeTo?: string;
    formatDateTo?: string;
    formatTimeTo?: string;
    disableDatePickerTo?: boolean;
    disableTimePickerTo?: boolean;
    timeViewTo?: TimeView[];
    styleToDatePicker?: React.CSSProperties;
    styleToTimePicker?: React.CSSProperties;
    onChangeTo?: (date: Moment | null) => void;
    disableDateTo?: (date: Moment) => boolean;
    disableTimeTo?: (date: Moment | null) => any;

    allowClear?: boolean;
    showTime?: boolean;
    success?: boolean;
    type?: DateTimePickerType;
    version?: DateTimePickerVersion;
    setError?: (error: boolean) => void;
    tooLong?: TooLongInterface;

    direction?: 'column' | 'row';
}

interface State {
    error: boolean;
    errorMessage: string;
    success: boolean;
    edited: boolean;
    tooLong: TooLongInterface;
}

/**
 * Component that represent a delete circle button with pop confirm
 */
class DateTimePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: '',
            success: this.props.success ?? false,
            edited: false,
            tooLong: this.props.tooLong ? this.props.tooLong : {
                duration: DATETIME_PICKER_ERROR.TOOLONGVAL,
                errorStr: props.intl.formatMessage(DATETIME_PICKER_ERROR.TOOLONG)
            }
        };
    }

    __changeFrom = (date: Moment | null, onlyTime = false) => {
        const { minFrom, maxFrom, minTo, maxTo, valueTo, intl } = this.props;
        let valueFrom = this.props.valueFrom?.clone();

        if (date || this.props.allowClear) {
            if (date) {
                if (onlyTime) {
                    if (valueFrom === undefined)
                        valueFrom = moment();
                    // if (this.props.onBlur)
                    //     this.props.onBlur();
                    valueFrom = valueFrom.hours(date.hours()).minutes(date.minutes()).seconds(date.seconds());
                    if (minFrom) valueFrom = valueFrom.year(minFrom.year()).month(minFrom.month()).date(minFrom.date());
                } else {
                    valueFrom = date;
                }

                if (valueTo && valueFrom?.isAfter(this.props.valueTo, "seconds")) {
                    this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMAFTERTO]) });
                    this.props.setError && this.props.setError(true);
                } else if (maxFrom && valueFrom?.isAfter(maxFrom, "seconds")) {
                    this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMAFTEREVENT]) });
                    this.props.setError && this.props.setError(true);
                } else if (minFrom && valueFrom?.isBefore(minFrom, "seconds")) {
                    this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMBEFOREEVENT]) });
                    this.props.setError && this.props.setError(true);
                } else if (maxTo && valueTo?.isAfter(maxTo, "seconds")) {
                    this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.TOAFTEREVENT]) });
                    this.props.setError && this.props.setError(true);
                } else if (minTo && valueTo?.isBefore(minTo, "seconds")) {
                    this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.TOBEFOREEVENT]) });
                    this.props.setError && this.props.setError(true);
                } else {
                    this.setState({ success: true, error: false, errorMessage: '' });
                    this.props.setError && this.props.setError(false);
                }
            }

            this.setState({ edited: true });
            this.props.onChangeFrom(valueFrom ?? null);
        }
    };

    /**
     * Check if a date must be disabled
     * @param date the current date to verify
     * @returns true if the date must be disabled, false otherwise
     */
    __disableDateFrom = (date: Moment): boolean => {
        let disableDateFrom = false;

        if (this.props.disableDateFrom)
            disableDateFrom = this.props.disableDateFrom(date);

        if (disableDateFrom)
            return disableDateFrom;
        else
            return disableDateFrom;
        // return date.isAfter(this.props.valueTo, "day")
    };

    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    __disableTimeFrom = (date: Moment | null) => {
        let disableTimeFrom = {};

        if (this.props.disableTimeFrom)
            disableTimeFrom = this.props.disableTimeFrom(date);

        //TODO internal disable time

        return disableTimeFrom;
    };
    __onBlur = () => {
        if (this.props.onBlur)
            this.props.onBlur();
    };

    __changeTo = (date: Moment | null, onlyTime = false) => {
        const { minFrom, maxFrom, minTo, maxTo, valueFrom, intl } = this.props;
        let valueTo = this.props.valueTo?.clone();

        if (date || this.props.allowClear) {
            if (date) {
                if (onlyTime) {
                    if (valueTo === undefined)
                        valueTo = moment();
                    // if (this.props.onBlur)
                    //     this.props.onBlur();
                    valueTo = valueTo.hours(date.hours()).minutes(date.minutes()).seconds(date.seconds());
                    if (minTo) valueTo = valueTo.year(minTo.year()).month(minTo.month()).date(minTo.date());
                } else {
                    valueTo = date;
                }

                if (valueTo) {
                    if (valueTo?.isBefore(this.props.valueFrom, "seconds")) {
                        this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMAFTERTO]) });
                        this.props.setError && this.props.setError(true);
                    } else if (maxFrom && valueFrom?.isAfter(maxFrom, "seconds")) {
                        this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMAFTEREVENT]) });
                        this.props.setError && this.props.setError(true);
                    } else if (minFrom && valueFrom?.isBefore(minFrom, "seconds")) {
                        this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.FROMBEFOREEVENT]) });
                        this.props.setError && this.props.setError(true);
                    } else if (maxTo && valueTo?.isAfter(maxTo, "seconds")) {
                        this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.TOAFTEREVENT]) });
                        this.props.setError && this.props.setError(true);
                    } else if (minTo && valueTo?.isBefore(minTo, "seconds")) {
                        this.setState({ error: true, errorMessage: intl.formatMessage(ErrorMessages[DateTimePickerError.TOBEFOREEVENT]) });
                        this.props.setError && this.props.setError(true);
                    } else {
                        this.setState({ success: true, error: false, errorMessage: '' });
                        this.props.setError && this.props.setError(false);
                    }
                }
            }
            if (this.props.onChangeTo) {
                this.setState({ edited: true });
                this.props.onChangeTo(valueTo ?? null);
            }
        }

    };

    /**
     * Check if a date must be disabled
     * @param date the current date to verify
     * @returns true if the date must be disabled, false otherwise
     */
    __disableDateTo = (date: Moment): boolean => {
        let disableDateTo = false;

        if (this.props.disableDateTo)
            disableDateTo = this.props.disableDateTo(date);

        if (disableDateTo)
            return disableDateTo;
        else
            return disableDateTo;
        // return date.isBefore(this.props.valueFrom, "day")
    };

    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    __disableTimeTo = (date: Moment | null) => {
        let disableTimeTo = {};

        if (this.props.disableTimeTo)
            disableTimeTo = this.props.disableTimeTo(date);

        //TODO internal disable time

        return disableTimeTo;
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!this.props.valueFrom?.isSame(prevProps.valueFrom, 's')) {
            this.__changeFrom(this.props.valueFrom ?? null, false);
        } else if (!this.props.valueTo?.isSame(prevProps.valueTo, 's')) {
            this.__changeTo(this.props.valueTo ?? null, false);
        }
    }

    render() {
        const {
            valueFrom, titleFrom, placeHolderDateFrom, placeHolderTimeFrom, formatDateFrom, formatTimeFrom, disableDatePickerFrom, disableTimePickerFrom, timeViewFrom, styleFromDatePicker, styleFromTimePicker,
            valueTo, titleTo, placeHolderDateTo, placeHolderTimeTo, formatDateTo, formatTimeTo, disableDatePickerTo, disableTimePickerTo, timeViewTo, styleToDatePicker, styleToTimePicker,
            showTime, allowClear, type, version, defaultValueFrom, defaultValueTo
        } = this.props;

        const { error, errorMessage, edited } = this.state;
        const CustomTimePicker = this.props.isSmartphone ? MobileTimePicker : TimePicker;

        return (
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <Row gutter={[10, 10]}>
                    {!this.props.direction || this.props.direction === 'column' ? <>
                        <Col xs={{ span: 24 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                                <p>{titleFrom ?? <FormattedMessage defaultMessage={'Start'} />}</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
                                    <DatePicker
                                        placeholder={placeHolderDateFrom}
                                        style={styleFromDatePicker ?? { width: '130px' }}
                                        format={formatDateFrom ?? getFormat('DATE')}
                                        value={valueFrom}
                                        allowClear={allowClear}
                                        onChange={e => this.__changeFrom(e)}
                                        disabledDate={this.__disableDateFrom}
                                        onBlur={this.__onBlur}
                                        status={error ? "error" : ""}
                                        disabled={disableDatePickerFrom}
                                        defaultValue={defaultValueFrom}
                                    />
                                    {
                                        showTime !== false &&
                                            version === DateTimePickerVersion.FIELD ?
                                            <div onBlur={this.__onBlur} style={{ display: 'flex' }}>
                                                <CustomTimePicker
                                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                    label={null}
                                                    value={valueFrom}
                                                    onAccept={this.__onBlur}
                                                    onChange={e => this.__changeFrom(e, true)}
                                                    format={formatTimeFrom ?? getFormat('TIME')}
                                                    views={timeViewFrom ?? ['hours', 'minutes', 'seconds']}
                                                    sx={TimePickerStyle}
                                                    disabled={disableTimePickerFrom}
                                                    defaultValue={defaultValueFrom}

                                                />
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid var(--border-color)', borderLeft: 'none', paddingRight: '10px', borderRadius: '0 30px 30px 0' }}>
                                                <ClockCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                            </div> */}
                                            </div>
                                            :
                                            <AntdTimePicker
                                                placeholder={placeHolderTimeFrom}
                                                style={styleFromTimePicker ?? { width: '120px' }}
                                                format={formatTimeFrom ?? getFormat('TIME')}
                                                value={valueFrom}
                                                allowClear={allowClear}
                                                onBlur={this.__onBlur}
                                                onChange={e => this.__changeFrom(e, true)}
                                                disabledTime={this.__disableTimeFrom}
                                                status={error ? "error" : ""}
                                                disabled={disableTimePickerFrom}
                                                defaultValue={defaultValueFrom}
                                            />
                                    }
                                </div>
                            </div>
                        </Col>
                        {
                            type === DateTimePickerType.RANGE &&
                            <Col xs={{ span: 24 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                                    <p>{titleTo ?? <FormattedMessage defaultMessage={'End'} />}</p>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
                                        <DatePicker
                                            placeholder={placeHolderDateTo}
                                            style={styleToDatePicker ?? { width: '130px' }}
                                            format={formatDateTo ?? getFormat('DATE')}
                                            value={valueTo}
                                            onBlur={this.__onBlur}
                                            allowClear={allowClear}
                                            onChange={e => this.__changeTo(e)}
                                            disabledDate={this.__disableDateTo}
                                            status={error ? "error" : ""}
                                            disabled={disableDatePickerTo}
                                            defaultValue={defaultValueTo}
                                        />
                                        {
                                            showTime &&
                                                version === DateTimePickerVersion.FIELD ?
                                                <div onBlur={this.__onBlur} style={{ display: 'flex' }}>
                                                    <CustomTimePicker

                                                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                        label={null}
                                                        value={valueTo}
                                                        onChange={e => this.__changeTo(e, true)}
                                                        format={formatTimeTo ?? getFormat('TIME')}
                                                        views={timeViewTo ?? ["hours", "minutes", "seconds"]}
                                                        sx={TimePickerStyle}
                                                        disabled={disableTimePickerTo}
                                                        onAccept={this.__onBlur}
                                                        defaultValue={defaultValueTo}
                                                    />
                                                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid var(--border-color)', borderLeft: 'none', paddingRight: '10px', borderRadius: '0 30px 30px 0' }}>
                                                    <ClockCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                                </div> */}
                                                </div>
                                                :
                                                <AntdTimePicker
                                                    clearIcon={null}
                                                    placeholder={placeHolderTimeTo}
                                                    style={styleToTimePicker ?? { width: '120px' }}
                                                    format={formatTimeTo ?? getFormat('TIME')}
                                                    value={valueTo}
                                                    allowClear={allowClear}
                                                    onBlur={this.__onBlur}
                                                    onChange={e => this.__changeTo(e, true)}
                                                    disabledTime={this.__disableTimeTo}
                                                    status={error ? "error" : ""}
                                                    disabled={disableTimePickerTo}
                                                    defaultValue={defaultValueTo}
                                                />
                                        }
                                    </div>
                                </div>
                            </Col>
                        }
                    </>
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                                <p>{titleFrom ?? <FormattedMessage defaultMessage={'Start'} />}</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
                                    <DatePicker
                                        placeholder={placeHolderDateFrom}
                                        style={styleFromDatePicker ?? { width: '130px' }}
                                        format={formatDateFrom ?? getFormat('DATE')}
                                        value={valueFrom}
                                        className="date-picker"
                                        allowClear={allowClear}
                                        onChange={e => this.__changeFrom(e)}
                                        disabledDate={this.__disableDateFrom}
                                        onBlur={this.__onBlur}
                                        status={error ? "error" : ""}
                                        disabled={disableDatePickerFrom}
                                    />
                                    {
                                        showTime &&
                                            version === DateTimePickerVersion.FIELD ?
                                            <div onBlur={this.__onBlur} style={{ display: 'flex' }} className="time-picker">
                                                <CustomTimePicker
                                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                    label={null}
                                                    value={valueFrom}
                                                    onAccept={this.__onBlur}
                                                    onChange={e => this.__changeFrom(e, true)}
                                                    format={formatTimeFrom ?? getFormat('TIME')}
                                                    views={timeViewFrom ?? ['hours', 'minutes', 'seconds']}
                                                    sx={TimePickerStyle}
                                                    disabled={disableTimePickerFrom}

                                                />
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid var(--border-color)', borderLeft: 'none', paddingRight: '10px', borderRadius: '0 30px 30px 0' }}>
                                                <ClockCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                            </div> */}
                                            </div>
                                            :
                                            <AntdTimePicker
                                                placeholder={placeHolderTimeFrom}
                                                style={styleFromTimePicker ?? { width: '120px' }}
                                                format={formatTimeFrom ?? getFormat('TIME')}
                                                value={valueFrom}
                                                allowClear={allowClear}
                                                onBlur={this.__onBlur}
                                                onChange={e => this.__changeFrom(e, true)}
                                                disabledTime={this.__disableTimeFrom}
                                                status={error ? "error" : ""}
                                                disabled={disableTimePickerFrom}
                                            />
                                    }
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                                <p>{titleTo ?? <FormattedMessage defaultMessage={'End'} />}</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
                                    <DatePicker
                                        className="date-picker"
                                        placeholder={placeHolderDateTo}
                                        style={styleToDatePicker ?? { width: '130px' }}
                                        format={formatDateTo ?? getFormat('DATE')}
                                        value={valueTo}
                                        onBlur={this.__onBlur}
                                        allowClear={allowClear}
                                        onChange={e => this.__changeTo(e)}
                                        disabledDate={this.__disableDateTo}
                                        status={error ? "error" : ""}
                                        disabled={disableDatePickerTo}
                                    />
                                    {
                                        showTime !== false ?
                                            version === DateTimePickerVersion.FIELD ?
                                                <div onBlur={this.__onBlur} style={{ display: 'flex' }} className="time-picker">
                                                    <CustomTimePicker
                                                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                        label={null}
                                                        value={valueTo}
                                                        onChange={e => this.__changeTo(e, true)}
                                                        format={formatTimeTo ?? getFormat('TIME')}
                                                        views={timeViewTo ?? ["hours", "minutes", "seconds"]}
                                                        sx={TimePickerStyle}
                                                        disabled={disableTimePickerTo}
                                                        onAccept={this.__onBlur}
                                                    />
                                                </div>
                                                :
                                                <AntdTimePicker
                                                    clearIcon={null}
                                                    placeholder={placeHolderTimeTo}
                                                    style={styleToTimePicker ?? { width: '120px' }}
                                                    format={formatTimeTo ?? getFormat('TIME')}
                                                    value={valueTo}
                                                    allowClear={allowClear}
                                                    onBlur={this.__onBlur}
                                                    onChange={e => this.__changeTo(e, true)}
                                                    disabledTime={this.__disableTimeTo}
                                                    status={error ? "error" : ""}
                                                    disabled={disableTimePickerTo}
                                                />
                                            : null
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {
                        edited && valueFrom && valueTo && valueFrom.isDST() !== valueTo.isDST() &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="info" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={<FormattedMessage defaultMessage={'There is a time change planned ({time})'} values={{ time: valueFrom.isDST() ? '-1h' : '+1h' }} />} />
                        </Col>
                    }
                    {
                        edited && valueTo && valueTo.diff(valueFrom, 'hours', true) > this.state.tooLong.duration &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="warning" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={`${this.state.tooLong.errorStr} (${valueTo && roundDecimals(valueTo.diff(valueFrom, 'hours', true))}h).`} />
                        </Col>
                    }
                    {
                        edited && error &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="error" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={errorMessage} />
                        </Col>
                    }
                </Row>
            </LocalizationProvider>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(DateTimePicker));