import { Divider, Empty } from 'antd';
import moment from 'moment';
import { RiMapPin2Line } from 'react-icons/ri';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../rbacRules';
import getFormat from '../../../utils/Lang';
import { IMandate } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import Anticon from '../../common/general/anticon';
import Can from '../../common/general/can';
import SpaceContent from '../../common/general/spaceContent';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
    mandate: IMandate;
}

interface State { }


export class Mandate extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { mandate, intl } = this.props;
        const { title, code, city, adress, zip, startDate, endDate, description } = mandate;
        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <div style={{ display: 'grid', gridTemplateColumns: '500px 1fr', gap: '20px' }}>
                    <SpaceContent space={20} style={{ background: 'var(--background-card-color)', borderRadius: 'var(--border-radius)', padding: '20px', width: '100%', maxWidth: '100%' }}>

                        <SpaceContent direction='vertical' align='flex-start'>
                            <p style={{ fontWeight: 'bold' }}>{title} <span style={{ fontStyle: 'italic', fontSize: '90%' }}>({code})</span></p>
                            <SpaceContent align='center'>
                                <Anticon icon={<RiMapPin2Line />} />
                                <p>{`${adress}, ${zip} ${city}`}</p>
                            </SpaceContent>
                        </SpaceContent>
                    </SpaceContent>

                    <SpaceContent align='flex-start' direction='vertical' space={20} style={{ background: 'var(--background-card-color)', borderRadius: 'var(--border-radius)', padding: '20px', width: '100%', maxWidth: '100%' }}>
                        <p>
                            <FormattedMessage
                                defaultMessage={'Mandate valide {from} {start} to {end}'}
                                values={{
                                    from: endDate ? intl.formatMessage({ defaultMessage: 'from' }) : intl.formatMessage({ defaultMessage: 'as of' }),
                                    start: moment(startDate).format(getFormat('DATE_DOW')),
                                    end: moment(endDate).format(getFormat('DATE_DOW'))
                                }} />
                        </p>
                        <p>{description}</p>
                    </SpaceContent>
                </div>
                <Divider orientation='left'><FormattedMessage defaultMessage={'Statistics'} /></Divider>
                <Empty description={<FormattedMessage defaultMessage={'No statistics currently available'} />} />
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
})

const connector = connect(mapStateToProps)

export default withRouter(connector(injectIntl(Mandate)));