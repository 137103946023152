import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Empty, Select, Space, Switch, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { type ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { GoPencil } from 'react-icons/go';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MissionAction, missionsFetched, updateMissions } from '../../../store/features/customerManagement';
import { MOMENT_FORMAT_DISPLAY_DATE } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { ICustomer, IMandate, IMission, IMissionEdit, IMissionState } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { generateNegativeUniqueId, isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Anticon from '../../common/general/anticon';
import SpaceContent from '../../common/general/spaceContent';
import VirtualTable from '../../common/general/virtualTable';
import { FilterSidebar } from '../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';
import EditMissionModal from './editMission/editMissionModal';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
    customer?: ICustomer;
    mandate?: IMandate;
}

interface State {
    filteredMissions?: IMission[];
    pageSize: number;
    currentPage: number;
    searchValue: string;
    selectedCustomers: number[];
    startDate?: Moment;
    endDate?: Moment;
    columns: ColumnsType<IMission>;
    partialMissions: IMissionState;
    editMission?: IMissionEdit;
    loadingMission: boolean;
    hideOld: boolean;
}

export class Missions extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 1,
            pageSize: 12,
            searchValue: '',
            columns: this.getInitialColumns(),
            partialMissions: { loading: false },
            selectedCustomers: [],
            loadingMission: false,
            hideOld: false
        };
        this.missionsFetched();
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);


        this.missionsFetched();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isEqual(this.props.missions, prevProps.missions) ||
            !isEqual(this.state.partialMissions, prevState.partialMissions)) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
        if (!isEqual(this.props.customers, prevProps.customers) ||

            this.hasFilterChange(prevState, this.state)) {
            this.setState({ columns: this.getInitialColumns() })
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        }
    }

    hasFilterChange = (prevState: Readonly<State>, state: Readonly<State>) => {
        return (
            prevState.searchValue !== state.searchValue ||
            !isEqual(prevState.selectedCustomers, state.selectedCustomers) ||
            prevState.hideOld !== state.hideOld
        )
    }

    getInitialColumns = (loading = false): ColumnsType<IMission> => {
        const { intl } = this.props;
        return [
            {
                title: <FormattedMessage defaultMessage={'Title'} />,
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                className: '__min-width-200',
            },
            {
                title: <FormattedMessage defaultMessage={'Code'} />,
                key: 'code',
                dataIndex: 'code',
                className: '__width_120',
            },
            {
                title: <FormattedMessage defaultMessage={'Customer'} />,
                key: 'customerId',
                dataIndex: 'customerId',
                className: '__width_300',
                sorter: (dataA, dataB) => {
                    const customerA = this.props.customers.data?.find(c => c.id === dataA.customerId)?.title.toLocaleLowerCase();
                    const customerB = this.props.customers.data?.find(c => c.id === dataB.customerId)?.title.toLocaleLowerCase();
                    if (!customerA && !customerB) return 0;
                    if (customerA && !customerB) return -1;
                    if (!customerA && customerB) return 1;
                    if (customerA && customerB) return customerA.localeCompare(customerB);
                    return 0;
                },
                render: (customerId) => {
                    return this.props.customers.data?.find(c => c.id === customerId)?.title
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Start date'} />,
                key: 'startDate',
                dataIndex: 'startDate',
                className: '__width_150',
                sorter: (a, b) => {
                    const dataA = a.startDate;
                    const dataB = b.startDate;
                    if (!dataA && !dataB) return 0;
                    if (dataA && !dataB) return -1;
                    if (!dataA && dataB) return 1;
                    if (dataA && dataB) return moment(dataA).diff(moment(dataB));
                    return 0;
                },
                render: (startDate) => {
                    return startDate ? moment(startDate).format(MOMENT_FORMAT_DISPLAY_DATE) : '';
                }
            },
            {
                title: <FormattedMessage defaultMessage={'End date'} />,
                key: 'endDate',
                dataIndex: 'endDate',
                className: '__width_130',
                sorter: (a, b) => {
                    const dataA = a.endDate;
                    const dataB = b.endDate;
                    if (!dataA && !dataB) return 0;
                    if (dataA && !dataB) return -1;
                    if (!dataA && dataB) return 1;
                    if (dataA && dataB) return moment(dataA).diff(moment(dataB));
                    return 0;
                },
                render: (endDate) => {
                    return endDate ? moment(endDate).format(MOMENT_FORMAT_DISPLAY_DATE) : '';
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Actions'} />,
                key: 'actions',
                fixed: 'right',
                className: '__width_120 __centered-text',
                render: (_, record) => {
                    return (
                        <Space>
                            <DeleteButton
                                text={<FormattedMessage defaultMessage={'Do you want to delete this mission?'} />}
                                key="template-modal-delete-color"
                                onConfirm={() => this.deleteMission(record.id)}
                                placement="topRight"
                                buttonPlacement="right"
                            />
                            <CircleButton
                                loading={loading}
                                icon={<Anticon><GoPencil /></Anticon>}
                                title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                placement="left"
                                onClick={() => this.editMission(record.id)} />
                        </Space>
                    );
                },
            }
        ];
    }

    updateMissions = (mission: IMission) => {
        if (this.props.customer || this.props.mandate) {
            const missions = cloneDeep(this.getMissions());
            let found = false;
            let newMissions: IMission[] = [];
            if (missions.data !== undefined) {
                newMissions = missions.data.map((m) => {
                    if (m.id === mission.id) {
                        found = true;
                        return mission;
                    }

                    return m;
                });

                if (!found) {
                    newMissions.push(mission);
                }
            } else {
                newMissions = [mission];
            }

            const missionsValue = {
                loading: false,
                updatedAt: moment().toISOString(),
                data: newMissions
            };
            this.setState({
                partialMissions: missionsValue,
                editMission: undefined
            });
        } else {
            this.setState({
                editMission: undefined
            });
            this.props.updateMissions([mission], mission.id && mission.id > 0 ? MissionAction.UPDATE : MissionAction.ADD);

        }

    }

    deleteMission = (missionId: number) => {

        Network.deleteMission(missionId).then(
            () => {

                if (this.props.customer || this.props.mandate) {

                    this.setState(prevState => ({
                        partialMissions: {
                            updatedAt: moment().toISOString(),
                            loading: false,
                            data: prevState.partialMissions.data?.filter(m => m.id !== missionId)
                        }
                    }))
                } else {
                    const mission = this.getMissions().data?.find(m => m.id === missionId);
                    if (mission) {
                        this.props.updateMissions([mission], MissionAction.REMOVE)
                    }
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the mission' }), "error")
            }
        ).finally(() => this.setState({ loadingMission: false, columns: this.getInitialColumns() }));
    }

    editMission = (missionId: number) => {
        this.setState({ loadingMission: true, columns: this.getInitialColumns() });
        Network.getMission(missionId).then(
            (response) => {
                this.setState({ editMission: cloneDeep(response.data) })
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the mission' }), "error")
            }
        ).finally(() => this.setState({ loadingMission: false, columns: this.getInitialColumns() }));
    }

    getMissions = () => {
        if (this.props.customer || this.props.mandate) return this.state.partialMissions;
        else return this.props.missions;
    }

    getExtra = () => {
        const { mandate, intl } = this.props;
        const missions = this.getMissions();
        return (
            <>
                <Tooltip placement='left' open={missions.updatedAt ? undefined : false} title={<p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: moment(missions.updatedAt).format(getFormat('DATE_AND_ON_TIME')) }} /></p>}>
                    <CircleButton
                        small
                        withoutTooltip
                        title={intl.formatMessage({ defaultMessage: 'Force update' })}
                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                        onClick={() => this.missionsFetched(true)}
                        loading={missions.loading} />
                </Tooltip>
                <CircleButton
                    small
                    disabled={isEmpty(mandate)}
                    key="mission-add"
                    title={isEmpty(mandate) ? intl.formatMessage({ defaultMessage: 'Creation of a mission available from a mandate only' }) : intl.formatMessage({ defaultMessage: 'Add a mission' })}
                    icon={<PlusOutlined />}
                    onClick={() => this.setState({ editMission: { mandateId: mandate?.id, rules: [{ id: generateNegativeUniqueId(), title: intl.formatMessage({ defaultMessage: 'Single rule' }), humanQuantity: 1, hoursQuantity: 1 }] } })}
                    withoutTooltip />
            </>
        )
    }

    getSidebars = () => {
        const { intl } = this.props;
        const content = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Search
                    value={this.state.searchValue}
                    placeholder={intl.formatMessage({ defaultMessage: 'Search by title' })}
                    allowClear
                    key={`search-mission`}
                    onChange={(event) => this.searchCustomers(event.target.value)} />
                {
                    !this.props.customer && !this.props.mandate ?
                        <Select
                            value={this.state.selectedCustomers}
                            mode="multiple"
                            maxTagCount={"responsive"}
                            className="configurations-width-100"
                            onChange={(values) => this.setState({ selectedCustomers: values }, () => this.filterCustomers())}
                            allowClear
                            showSearch
                            showArrow
                            filterOption={true}
                            placeholder={<FormattedMessage defaultMessage={'Filter by customer(s)'} />}
                            optionFilterProp="label">
                            {this.props.customers.data?.map(c => <Select.Option label={c.title} value={c.id} key={`mission-customers-select-${c.id}`}>{c.title}</Select.Option>)}
                        </Select>
                        : null
                }
                <SpaceContent>
                    <DatePicker placeholder={intl.formatMessage({ defaultMessage: 'Start date' })} format={getFormat('DATE')} onChange={this.changeStartDate} value={this.state.startDate} />
                    <span>-</span>
                    <DatePicker placeholder={intl.formatMessage({ defaultMessage: 'End date' })} format={getFormat('DATE')} onChange={this.changeEndDate} value={this.state.endDate} />
                </SpaceContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <span><FormattedMessage defaultMessage={'Hide missions in the past'} /></span>
                    <Switch
                        checked={this.state.hideOld}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={this.hideOld}
                    />
                </div>
            </div>
        );

        return [FilterSidebar(content, intl)];
    }

    missionsFetched = (forceReload = false) => {
        const { customer, mandate } = this.props;
        if (customer) {
            this.setState(prevState => ({ partialMissions: { ...prevState, loading: true } }));
            Network.getCustomerMissions(customer.id).then(
                (response) => {
                    this.setState({ partialMissions: { data: response.data, loading: false, updatedAt: moment().toISOString() } });
                },
                () => {
                    this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
                }
            );
        } else if (mandate) {
            this.setState(prevState => ({ partialMissions: { ...prevState, loading: true } }));
            Network.getMandateMissions(mandate.id).then(
                (response) => {
                    this.setState({ partialMissions: { data: response.data, loading: false, updatedAt: moment().toISOString() } });
                },
                () => {
                    this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
                }
            );
        } else {
            this.props.missionsFetched(forceReload);
        }
    }

    hideOld = (checked: boolean) => {
        this.setState({ hideOld: checked }, () => this.filterCustomers());
    }

    searchCustomers = (value: string) => {
        const search = value.toLocaleLowerCase()
        this.setState({ searchValue: search }, () => this.filterCustomers());
    }

    changeStartDate = (date: Moment | null) => {
        this.setState({ startDate: date ?? undefined }, () => this.filterCustomers());
    }

    changeEndDate = (date: Moment | null) => {
        this.setState({ endDate: date ?? undefined }, () => this.filterCustomers());
    }

    filterCustomers = () => {
        const { searchValue, selectedCustomers, startDate, endDate, hideOld } = this.state;
        let filteredMissions = cloneDeep(this.getMissions().data);

        if (isNullOrEmpty(searchValue) && isNullOrEmpty(selectedCustomers) && !startDate && !endDate && !hideOld)
            this.setState({ filteredMissions: undefined });

        //TODO: Chercher startDate dans les données enddate
        if (startDate) {
            filteredMissions = filteredMissions?.filter(mission => !mission.startDate || moment(mission.startDate).isSameOrAfter(moment(startDate), "days"));
        }

        if (endDate) {
            filteredMissions = filteredMissions?.filter(mission => !mission.endDate || moment(mission.endDate).isSameOrBefore(moment(endDate), "days"));
        }

        if (!isNullOrEmpty(searchValue)) {
            filteredMissions = filteredMissions?.filter(mission => `${mission.title}`.toLocaleLowerCase().indexOf(searchValue) >= 0);
        }

        if (!isNullOrEmpty(selectedCustomers)) {
            filteredMissions = filteredMissions?.filter(mission => selectedCustomers.find(c => c === mission.customerId));
        }

        if (hideOld) {
            filteredMissions = filteredMissions?.filter(mission => mission.endDate === null || moment(mission.endDate).isSameOrAfter(moment(), "days"));
        }

        this.setState({ filteredMissions: filteredMissions ?? [] })
    }

    render() {
        const { filteredMissions, columns, editMission } = this.state;
        const missions = this.getMissions();
        const missionsData = filteredMissions ?? missions.data;

        let tableHeight = this.props.height - 168;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <>
                <VirtualTable
                    loading={missions.loading}
                    rowKey={(mission: IMission) => `mission-${mission.id}`}
                    dataSource={missionsData}
                    scroll={{ x: true, y: tableHeight }}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No mission'} />} /> }}
                />
                <EditMissionModal mission={editMission} onCancel={() => this.setState({ editMission: undefined })} onOk={this.updateMissions} />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.currentUser,
    customers: state.customerManagement.customers,
    missions: state.customerManagement.missions,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    height: state.window.height
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    missionsFetched: (forceReload?: boolean) => dispatch(missionsFetched({ forceReload: forceReload ?? false })),
    updateMissions: (missions: IMission[], action: MissionAction) => dispatch(updateMissions({ missions, action })),
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default withRouter(connector(injectIntl(Missions)));