import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DashOutlined, EyeInvisibleOutlined, EyeOutlined, MoreOutlined, ShopOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Modal, Popconfirm, Row, Select, Switch, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { convertToHTML } from 'draft-convert';
import React from 'react';
import { GrGroup, GrUser } from 'react-icons/gr';
import { connect } from 'react-redux';
import { changeCompanyProducts } from '../../store/actions/crm';
import axiosapi from '../../utils/axiosapi';
import { Company, CompanyProduct, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState, CRMDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import Card from '../common/general/card';
import { tableColumnTextFilterConfig } from '../courseManagement/tableSearch';

import { ContentState, convertFromHTML, DraftEditorCommand, Editor, EditorState, RichUtils } from 'draft-js';
import { fetchAllProducts } from '../../actions/products';
import { IMAGES_ADDRESS, IMAGES_EXTENSION, IMAGES_EXTENSION_ALLIANCE, IMAGES_EXTENSION_BS } from '../../utils/constants';
import { showNotification } from '../../utils/utils';
import CircleButton from '../common/fields/circleButton';
import InputField from '../common/fields/inputField';
import SpeedDial from '../common/fields/speedDial';
// import { Editable } from 'slate-react'

import { FormattedMessage, injectIntl } from 'react-intl';
import deUrl from '../../../src/images/lang/de.webp';
import enUrl from '../../../src/images/lang/en.webp';
import frUrl from '../../../src/images/lang/fr.webp';
import itUrl from '../../../src/images/lang/it.webp';
import { IntlProps } from '../app/LanguageProvider';

interface State {
    showCompanyProduct: boolean;
    companyProductToShow?: CompanyProduct;
    loadingUpdate: boolean;
    editorState: EditorState;
    editCompanyProductTitle: boolean;
    editCompanyProductDescription: boolean;
    edited: boolean;
    language: string,
}

interface IProps {
    isSmartphone: boolean;
    companyProducts: CompanyProduct[];
    companyEditProduct?: CompanyProduct;
    loadingCompanyProducts: boolean;
    companyDetails?: Company;
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & CRMDispatchProps & IntlProps;

/**
 * Component for the clients tab in the CRM page
 */
class ProductsTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showCompanyProduct: false,
            loadingUpdate: false,
            editorState: EditorState.createEmpty(),
            editCompanyProductTitle: false,
            editCompanyProductDescription: false,
            edited: false,
            language: "fr"
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { companyEditProduct } = this.props;
        if (companyEditProduct && companyEditProduct !== prevProps.companyEditProduct) {
            this.setState({ companyProductToShow: { ...companyEditProduct } });
        }
    }


    poiOnRow = (record: CompanyProduct) => ({
        onClick: () => this.setState({ showCompanyProduct: true, companyProductToShow: { ...record } })
    });

    closeShowDrawer = () => {
        this.resetState();
        this.setState({ showCompanyProduct: false, companyProductToShow: undefined, language: "fr" });
    };

    saveCompanyProduct = (close = true) => {
        const { companyProductToShow } = this.state;
        if (companyProductToShow) {
            axiosapi.patch(`/lessons/${companyProductToShow.id}/`, {
                // description: companyProductToEdit.description
                description: companyProductToShow.description,
                title: companyProductToShow.title,
                active: companyProductToShow.active,
                isVisible: companyProductToShow.is_visible
            }, {
                headers: { "accept-language": this.state.language }
            }).then(response => {
                const { companyProducts } = this.props;
                const theCompanyProduct = companyProducts.find(cp => cp.id === companyProductToShow?.id);
                if (theCompanyProduct) {
                    theCompanyProduct.description = response.data.description;
                    theCompanyProduct.title = response.data.title;
                }
                // It has the last language saved, overwrite the french on table
                // this.props.changeCompanyProducts!(companyProducts);
                // Temporary solution, not the best
                this.props.companyDetails && this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
                if (close) {
                    this.closeShowDrawer();
                } else {
                    this.resetState();
                }
            }).catch(error => {
                console.error("NOOOO", error);
            });
        }
    };

    editorChange = (e: EditorState): void => {
        const { companyProductToShow } = this.state;
        if (companyProductToShow) {
            companyProductToShow.description = convertToHTML(e.getCurrentContent());
        }
        this.setState({ editorState: e, companyProductToShow, edited: true });
    };


    handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.editorChange(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    _onBoldClick() {
        this.editorChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
    }

    startEditor = () => {
        if (this.state.companyProductToShow) {
            const blocksFromHTML = convertFromHTML(this.state.companyProductToShow.description);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            this.setState({ editorState: EditorState.createWithContent(state), editCompanyProductDescription: true, editCompanyProductTitle: false });
        }
        // this.setState({ editCompanyProduct: true, companyProductToEdit: this.state.companyProductToShow ? { ...this.state.companyProductToShow } : undefined })
    };

    resetState = () => {
        this.setState({ editCompanyProductDescription: false, editCompanyProductTitle: false, edited: false });

    };

    changeLang = (language: string) => {
        if (this.state.language !== language) {
            this.resetState();
            this.setState({ language }, () => {
                this.props.companyDetails && this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, external_id: this.state.companyProductToShow?.externalId, external_activity_id: this.state.companyProductToShow?.externalActivityId, school__version: this.props.companyDetails.version }, this.state.language));
            });
        }
    };

    renderFlagUrl = () => {
        switch (this.state.language) {
            case 'en':
                return enUrl;
            case 'de':
                return deUrl;
            case 'it':
                return itUrl;
            default:
                return frUrl;
        }
    };

    getProductImageUrl = () => {
        const { companyDetails } = this.props;
        const { companyProductToShow } = this.state;
        if (companyProductToShow) {
            if (companyDetails?.version === 1) {
                return IMAGES_ADDRESS + companyProductToShow.school.name.replace(/ /g, "-").normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() + `/lessons/${companyProductToShow.externalId}-${companyProductToShow.externalActivityId}` + IMAGES_EXTENSION_ALLIANCE;
            } else if (companyDetails?.version === 2) {
                return IMAGES_ADDRESS + companyProductToShow.school.name.replace(/ /g, "-").normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() + `/lessons/${companyProductToShow.externalId}-${companyProductToShow.externalActivityId}` + IMAGES_EXTENSION_BS;
            } else {
                if (companyProductToShow.school.name.includes("ESS Villars")) {
                    return IMAGES_ADDRESS + companyProductToShow.school.name.replace('ESS ', 'ess').replace(/ /g, "-").normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() + `/lessons/${companyProductToShow.externalId}-${companyProductToShow.externalActivityId}` + IMAGES_EXTENSION;
                } else {
                    return IMAGES_ADDRESS + companyProductToShow.school.name.replace('ESS ', '').replace(/ /g, "-").normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() + `/lessons/${companyProductToShow.externalId}-${companyProductToShow.externalActivityId}` + IMAGES_EXTENSION;
                }
            }
        }
        return null;
    };


    poiColumnsActions = (record: CompanyProduct) => {
        const { intl } = this.props;
        return [
            <CircleButton
                key='company-products-table-active'
                className="__card-button-space"
                icon={record.active ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                title={record.active ? intl.formatMessage({ defaultMessage: 'Hide' }) : intl.formatMessage({ defaultMessage: 'Show' })}
                placement="topRight"
                onClick={(e) => {
                    e.stopPropagation();
                    axiosapi.patch(`/lessons/${record.id}/`, {
                        active: !record.active
                    }, {
                        headers: { "accept-language": this.state.language }
                    }).then(response => {
                        const { companyProducts } = this.props;
                        const theCompanyProduct = companyProducts.find(cp => cp.id === record?.id);
                        if (theCompanyProduct) {
                            theCompanyProduct.description = response.data.description;
                            theCompanyProduct.title = response.data.title;
                        }
                        // It has the last language saved, overwrite the french on table
                        // this.props.changeCompanyProducts!(companyProducts);
                        // Temporary solution, not the best
                        this.props.companyDetails && this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
                        this.resetState();
                        showNotification(intl.formatMessage({ defaultMessage: 'Successfully updated' }), "success");
                    }).catch(() => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while the update' }), "error");
                    });
                }}
            />,
            <CircleButton
                key='company-products-table-visible'
                className="__card-button-space"
                icon={record.is_visible ? <CloseOutlined /> : <CheckOutlined />}
                title={record.is_visible ? intl.formatMessage({ defaultMessage: 'Disable sales' }) : intl.formatMessage({ defaultMessage: 'Enable sales' })}
                placement="topRight"
                onClick={(e) => {
                    e.stopPropagation();
                    axiosapi.patch(`/lessons/${record.id}/`, {
                        isVisible: !record.is_visible
                    }, {
                        headers: { "accept-language": this.state.language }
                    }).then(response => {
                        const { companyProducts } = this.props;
                        const theCompanyProduct = companyProducts.find(cp => cp.id === record?.id);
                        if (theCompanyProduct) {
                            theCompanyProduct.description = response.data.description;
                            theCompanyProduct.title = response.data.title;
                        }
                        // It has the last language saved, overwrite the french on table
                        // this.props.changeCompanyProducts!(companyProducts);
                        // Temporary solution, not the best
                        this.props.companyDetails && this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
                        this.resetState();
                        showNotification(intl.formatMessage({ defaultMessage: 'Successfully updated' }), "success");
                    }).catch(() => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while the update' }), "error");
                    });
                }}
            />
        ];
    };

    render() {
        const { companyProducts } = this.props;
        const { companyProductToShow, showCompanyProduct, editCompanyProductDescription, editCompanyProductTitle, language, edited } = this.state;
        return (
            <Card icon={<ShopOutlined />} title={<FormattedMessage defaultMessage={'Products'} />}>
                <Table
                    loading={this.props.loadingCompanyProducts}
                    key="table-company-products"
                    className='__poi-list'
                    rowKey={(p: CompanyProduct) => p.id}
                    dataSource={companyProducts}
                    columns={this.poiColumns}
                    // loading={this.state.groupsLoading}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No product'} />} /> }}
                    pagination={{
                        defaultPageSize: 16,
                        pageSizeOptions: ["8", "16", "24", "32", "64", "72"],
                        showSizeChanger: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} products'} values={{ range0: range[0], range1: range[1], total }} />
                    }}
                    scroll={{ x: 'max-content' }}
                    onRow={this.poiOnRow}
                // rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedPoi }}
                />
                <Modal
                    className="__product-edit-modal"
                    keyboard={!edited}
                    closable={true}
                    closeIcon={
                        <Select
                            disabled={edited}
                            className="configurations-width-100"
                            value={language}
                            bordered={false}
                            showArrow={false}
                            onChange={n => this.changeLang(n)}>


                            <Select.Option value={"fr"} key={`language-fr`}><img alt="" style={{ maxWidth: "100%" }} src={frUrl} /></Select.Option>
                            <Select.Option value={"en"} key={`language-en`}><img alt="" style={{ maxWidth: "100%" }} src={enUrl} /></Select.Option>
                            <Select.Option value={"de"} key={`language-de`}><img alt="" style={{ maxWidth: "100%" }} src={deUrl} /></Select.Option>
                            <Select.Option value={"it"} key={`language-it`}><img alt="" style={{ maxWidth: "100%" }} src={itUrl} /></Select.Option>
                        </Select>
                    }
                    maskClosable={false}
                    // onCancel={this.closeShowDrawer}
                    title={companyProductToShow?.title}
                    visible={showCompanyProduct}
                    footer={[
                        edited ?
                            <Popconfirm
                                title={<FormattedMessage defaultMessage={'Do you want to cancel the changes?'} />}
                                onConfirm={() => {
                                    this.closeShowDrawer();
                                }}
                                okText={<FormattedMessage defaultMessage={'Yes'} />}
                                cancelText={<FormattedMessage defaultMessage={'No'} />}
                            >
                                <Button type="dashed" key="occupancy-rate-modal-button-cancel">
                                    <FormattedMessage defaultMessage={'Cancel'} />
                                </Button>
                            </Popconfirm>
                            :
                            <Button type="dashed" onClick={this.closeShowDrawer} key="occupancy-rate-modal-button-close">
                                <FormattedMessage defaultMessage={'Close'} />
                            </Button>,
                        <Button disabled={!edited} type="primary" onClick={() => this.saveCompanyProduct(false)} key="occupancy-rate-modal-button-ok">
                            <FormattedMessage defaultMessage={'Save'} />
                        </Button>,
                        <Button disabled={!edited} type="primary" onClick={() => this.saveCompanyProduct()} key="occupancy-rate-modal-button-ok-close">
                            <FormattedMessage defaultMessage={'Save and close'} />
                        </Button>
                    ]}
                    width="892px"
                >
                    {
                        companyProductToShow &&
                        <>
                            <Row className="__company-product-show">
                                <Col xxl={{ span: 6 }} className="__company-product-show-image" >
                                    <div style={{ backgroundImage: `url(${this.getProductImageUrl()})` }} />
                                </Col>
                                <Col xxl={{ span: 18 }}>
                                    <div className="__company-product-show-content">
                                        <div>
                                            <div className={`__company-product-show-content-title  ${editCompanyProductTitle ? "__company-product-show-content-title-b" : ""}`} onClick={() => this.setState({ editCompanyProductDescription: false, editCompanyProductTitle: true })}>
                                                {
                                                    editCompanyProductTitle ?
                                                        <InputField className="__company-product-show-content-title-input" value={companyProductToShow.title} onChange={(e) => {
                                                            companyProductToShow && (companyProductToShow.title = e.target.value);
                                                            this.setState({ companyProductToShow, edited: true });
                                                        }} />
                                                        : <div style={{ cursor: 'pointer' }}>{companyProductToShow.title}</div>
                                                }
                                            </div>

                                            <div className={`__company-product-show-content-description ${editCompanyProductDescription ? "__company-product-show-content-description-b" : ""}`} onClick={() => !editCompanyProductDescription && this.startEditor()}>
                                                {
                                                    editCompanyProductDescription ?
                                                        <div className="__company-product-edit">
                                                            {/* <button onClick={this._onBoldClick.bind(this)}>Bold</button> */}
                                                            <Editor
                                                                editorState={this.state.editorState}
                                                                onChange={(e) => this.editorChange(e)}
                                                                handleKeyCommand={this.handleKeyCommand}
                                                            />
                                                        </div>
                                                        : <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: companyProductToShow.description }} />
                                                }
                                            </div>
                                        </div>
                                        <div className="__company-product-show-content-last-line">
                                            <span style={{ color: '#212121', fontSize: "16px", lineHeight: "42px" }}><FormattedMessage defaultMessage={'School:'} /> <span style={{ color: "#595959", fontSize: "16px", }}>{companyProductToShow.school.name}</span></span>
                                            <button><FormattedMessage defaultMessage={'Check availability'} /></button>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-start' }}>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginRight: '5px' }}><FormattedMessage defaultMessage={'Visible?'} /></p>
                                    <Switch checked={companyProductToShow.active} onChange={(e) => {
                                        companyProductToShow && (companyProductToShow.active = e);
                                        this.setState({ companyProductToShow, edited: true });
                                    }} />
                                </div>
                                <div style={{ display: 'flex', marginLeft: '20px' }}>
                                    <p style={{ marginRight: '5px' }}><FormattedMessage defaultMessage={'Activated?'} /></p>
                                    <Switch checked={companyProductToShow.is_visible} onChange={(e) => {
                                        companyProductToShow && (companyProductToShow.is_visible = e);
                                        this.setState({ companyProductToShow, edited: true });
                                    }} />
                                </div>
                            </Row>
                        </>
                    }
                </Modal>
            </Card>
        );
    }

    poiColumns: ColumnProps<CompanyProduct>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<CompanyProduct>(),
            sorter: (a: CompanyProduct, b: CompanyProduct) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Product ID'} />,
            dataIndex: 'externalId',
            key: 'externalId',
            responsive: ["xxl"],
            onFilter: (value, record) => {
                return record.externalId!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<CompanyProduct>(),
            sorter: (a: CompanyProduct, b: CompanyProduct) => !a.externalId ? -1 : !b.externalId ? 1 : a.externalId < b.externalId ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Activity ID'} />,
            dataIndex: 'externalActivityId',
            key: 'externalActivityId',
            responsive: ["xxl"],
            onFilter: (value, record) => {
                return record.externalActivityId!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<CompanyProduct>(),
            sorter: (a: CompanyProduct, b: CompanyProduct) => !a.externalActivityId ? -1 : !b.externalActivityId ? 1 : a.externalActivityId < b.externalActivityId ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            dataIndex: 'lesson_type',
            key: 'lesson_type',
            width: "80px",
            responsive: ["lg"],
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Group'} />,
                    value: 'G',
                },
                {
                    text: <FormattedMessage defaultMessage={'Private'} />,
                    value: 'P',
                },
            ],
            onFilter: (value, record) => record.lesson_type.indexOf(value.toString()) === 0,
            render: (lesson_type: string) => {
                if (lesson_type === "G") {
                    return <GrGroup />;
                } else {
                    return <GrUser />;

                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Visible'} />,
            dataIndex: 'active',
            key: 'active',
            width: "80px",
            responsive: ["md"],
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Visible'} />,
                    value: true,
                },
                {
                    text: <FormattedMessage defaultMessage={'Hidden'} />,
                    value: false,
                },
            ],
            onFilter: (value, record) => record.active === value,
            render: (is_visible: boolean) => {
                if (is_visible) {
                    return <EyeOutlined style={{ color: "#4caf50" }} />;
                } else {
                    return <EyeInvisibleOutlined style={{ color: "#d84315" }} />;

                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Activated'} />,
            dataIndex: 'is_visible',
            key: 'is_visible',
            width: "80px",
            responsive: ["lg"],
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Enabled'} />,
                    value: true,
                },
                {
                    text: <FormattedMessage defaultMessage={'Disabled'} />,
                    value: false,
                },
            ],
            onFilter: (value, record) => record.is_visible === value,
            render: (active: boolean) => {
                if (active) {
                    return <CheckCircleOutlined style={{ color: "#4caf50" }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: "#d84315" }} />;

                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '80px',
            // className: '__poi-actions',
            render: (record: CompanyProduct) => {
                return (
                    <SpeedDial
                        key='company-product-actions-speeddial'
                        title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        buttons={this.poiColumnsActions(record)} />
                );
            },
        }
    ];
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    companyDetails: state.crm.companyDetails,
    companyProducts: state.crm.companyProducts,
    companyEditProduct: state.crm.companyEditProduct,
    loadingCompanyProducts: state.crm.loadingCompanyProducts
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeCompanyProducts: (c: CompanyProduct[]) => dispatch(changeCompanyProducts(c)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductsTab));