import {
    ArrowUpOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    DashOutlined,
    EditOutlined,
    EyeOutlined,
    FilterOutlined,
    InfoCircleOutlined,
    LeftOutlined,
    MoreOutlined,
    RightOutlined,
    SettingOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {
    Alert,
    Button,
    Col,
    Collapse,
    DatePicker,
    Drawer,
    Dropdown,
    Menu,
    Modal, notification, Radio,
    Row,
    Select,
    Spin,
    Tag
} from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Privileges } from "../../privileges";
import { Rules } from "../../rbacRules";
import {
    changeProject,
    changeTypesOfDay,
    changeTypesOfDayOff
} from "../../store/actions/configurations";
import {
    changeCellsPerRow,
    changeSettings,
    changeTemplates,
    reset,
    setEventMethods,
    toggleDisplayWorkingTime
} from "../../store/actions/planning";
import { changeGroups, changeUsers } from "../../store/actions/teamManagement";
import { toggleLoading } from "../../store/actions/user";
import { CaseType, MOMENT_FORMAT } from "../../utils/constants";
import {
    CalendarDataConfirmationState,
    CalendarDataType,
    PlanningEventOwner
} from "../../utils/enumerations";
import Network from "../../utils/network";
import {
    Course,
    Group,
    POI,
    PoiWithUpdate,
    RouterProps,
    Sector,
    StaffType,
    User,
    UserSummary
} from "../../utils/types/generalTypes";
import {
    CalendarDataBodyRequest,
    NetworkOccupancyRate,
    NetworkSettings
} from "../../utils/types/networkTypes";
import {
    PlanningCalendarData,
    PlanningCellEvent,
    PlanningEvent,
    PlanningOvertime,
    PlanningSettings,
    PlanningTemplate,
    Project,
    TypeOfDay
} from "../../utils/types/planningTypes";
import {
    ApplicationState,
    ConfigurationsDispatchProps,
    LocationDispatchProps,
    PlanningDispatchProps,
    PlanningState,
    StoreDispatch,
    TeamManagementDispatchProps,
    UserDispatchProps
} from "../../utils/types/storeTypes";
import {
    checkPrivilege,
    checkRBACRule,
    cloneEvent,
    convertNetworkCalendarDataToPlanningCalendarData, convertNetworkEventsToPlanningEventsV2, convertNetworkSettingsToPlanningSettings,
    displayErrorMessage,
    getCaseAndPlural,
    showNotification
} from "../../utils/utils";
import CircleButton from "../common/fields/circleButton";
import SpeedDial from "../common/fields/speedDial";
import Can from "../common/general/can";
import Container from "../common/navigations/container";
import Calendar from "./calendar";
import CreateEditModal from "./createEditModalOLD";
import EventDetailsModal from "./eventDetailsModal";
import OvertimeModal from "./overtimeModal";
import SettingsModal from "./settingsModal";
import UndertimeModal from "./undertimeModal";
// import Map from '../../images/valais4.webp';
import { ColumnProps } from "antd/lib/table";
import { BiMap } from "react-icons/bi";
import { FaRegCalendarCheck } from "react-icons/fa";
import {
    changeCourses,
    changeSectors,
    loadPois
} from "../../store/actions/location";
import DeleteButton from "../common/fields/deleteButton";
import Card from "../common/general/card";
import Header from "../common/general/header";
import MapLeaflet from "../common/general/map";
import { tableColumnTextFilterConfig } from "../courseManagement/tableSearch";
import OccupancyRatesControl from "../dashboard/components/occupancyRatesControl";

type ReduxProps = ConnectedProps<typeof connector>

interface IProps {
    loading: boolean;
    project?: Project[];
    templates?: PlanningTemplate[];
    courses?: Course[];
    sectors?: Sector[];
    pois?: PoiWithUpdate;
}

type Props = IProps & PlanningDispatchProps & ConfigurationsDispatchProps & PlanningState & RouterProps & UserDispatchProps & TeamManagementDispatchProps & LocationDispatchProps & ReduxProps;

interface State {
    calendarData: PlanningCalendarData | undefined;
    editEvent: PlanningEvent | undefined;
    detailsEvent: PlanningEvent | undefined;
    detailsUser: User | undefined;
    detailsGroup: Group | undefined;
    showSettings: boolean;
    occurenceOnly: boolean;
    settingDefaultStep: number | undefined;
    isEditTemplate: boolean;
    editTemplate: PlanningTemplate | undefined;
    editOvertime: PlanningOvertime | undefined;
    editUndertime: PlanningOvertime | undefined;
    // currentGroup: Group | undefined;
    currentGroup: Group[] | undefined;
    selectGroups?: number[];
    currentUsers?: User[];
    selectUsers?: number[];
    currentProjects: Project[] | undefined;
    currentUserIds: number[] | undefined;
    allGroups: Group[];
    displayFilter: boolean;
    displayOccupancyRate: boolean;
    selectPoiOnMap: boolean;
    currentPois?: POI[];
    currentSectors?: Sector[];
    currentCourses?: Course[];
    isDrawerTemplate: boolean;
    filterMapVisible: boolean;
    refreshOccupancyRateFilter: boolean;
}

class Planning extends React.Component<Props, State> {
    currentFilter: CalendarDataType = CalendarDataType.All;
    confirmationState: CalendarDataConfirmationState = CalendarDataConfirmationState.All;
    displayWorkingTime = true;
    displayDayView = Boolean(this.props.match.params.dayView);
    currentWeek = 0;
    currentDay = "";

    constructor(props: Props) {
        super(props);

        this.state = {
            calendarData: undefined,
            editEvent: undefined,
            detailsEvent: undefined,
            detailsUser: undefined,
            detailsGroup: undefined,
            showSettings: false,
            occurenceOnly: false,
            settingDefaultStep: undefined,
            editTemplate: undefined,
            isEditTemplate: false,
            editOvertime: undefined,
            editUndertime: undefined,
            currentGroup: undefined,
            currentProjects: undefined,
            currentUserIds: undefined,
            allGroups:
                this.props.groups?.sort((a, b) => (a.name! > b.name! ? 1 : -1)) ?? [],
            displayFilter: true,
            displayOccupancyRate: false,
            selectPoiOnMap: false,
            isDrawerTemplate: false,
            filterMapVisible: false,
            refreshOccupancyRateFilter: false,
        };
    }

    componentDidMount() {
        this.props.loadPois!();
        this.refreshSectors();
        this.refreshCourses();

        // set event's methods
        this.props.setEventMethods!(
            this.showEventDetails,
            this.onClickEditEvent,
            this.onDeleteEvent,
            this.onEditOvertime,
            this.onDeleteOvertime
        );

        // set current week
        const currentWeek = moment().week();
        this.currentWeek = currentWeek;
        this.currentDay = moment().format(MOMENT_FORMAT);

        // set default display day view
        this.displayDayView = Boolean(this.props.match.params.dayView);

        this.props.toggleLoading!(true);

        // if the user is admin or as privilege, load normal
        if (
            checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) ||
            checkPrivilege(
                Privileges.Planning.readOnlyAllPlanning,
                this.props.currentUser
            )
        ) {
            // check if there is a query
            // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true, comma: true });

            // if there is a query load data accroding to query otherwise, load normal data
            // if ((query.user_id || query.group_id)) {
            //     this.loadQuery(query);
            // } else {
            // get datas
            this.getUsers();
            Network.getGroups().then(
                (response: Group[]) => {
                    this.props.changeGroups!(response);
                    // const groupsToAdd: Group[] = response?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [];
                    // let { currentGroup } = this.state;

                    // currentGroup ?
                    //     currentGroup.push(groupsToAdd[0])
                    //     :
                    //     currentGroup = [groupsToAdd[0]];
                    // this.setState({ currentGroup }, () => {
                    //     this.refresh();
                    // });

                    this.refresh();
                },
                () =>
                    showNotification(
                        "Il y a eu un problème lors du chargement des groupes",
                        "warning"
                    )
            );
            // }
        }
        // otherwise this user can only see himself
        else {
            this.currentFilter = CalendarDataType.Users;
            this.refresh(undefined, undefined, [this.props.currentUser!.id], true);
        }

        // load general datas (vacations + templates + types of day + settings)
        this.refreshTemplates();
        Network.getTypeOfDay().then(
            (response) => this.props.changeTypesOfDay!(response),
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des types de jour",
                    "warning"
                )
        );
        Network.getTypeOfDayOff().then(
            (response) => this.props.changeTypesOfDayOff!(response),
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des types de jour",
                    "warning"
                )
        );
        Network.getSettings().then(
            (response) =>
                this.props.changeSettings!(
                    convertNetworkSettingsToPlanningSettings(response)
                ),
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des paramètres",
                    "warning"
                )
        );

        Network.getProjects().then(
            (response: Project[]) => this.props.changeProject!(response),
            () =>
                showNotification(
                    `Un problème est survenu pendant le chargement des ${getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', true, CaseType.FULL_LOWERCASE)}`,
                    "warning"
                )
        );
    }

    createNewEvent = (date: Moment, newOccupancyRate: NetworkOccupancyRate) => {
        this.setState({
            editEvent: {
                startDate:
                    newOccupancyRate.startDate && newOccupancyRate.startHour
                        ? moment(
                            newOccupancyRate.startDate + "T" + newOccupancyRate.startHour
                        )
                        : date,
                endDate:
                    newOccupancyRate.endDate && newOccupancyRate.endHour
                        ? moment(newOccupancyRate.endDate + "T" + newOccupancyRate.endHour)
                        : date.add(1, "hours"),
                typeOfDay: newOccupancyRate.typeOfDay,
                staffType: newOccupancyRate.staffType,
                poi: newOccupancyRate.poi,
                title: newOccupancyRate.title,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
        });
    };

    // componentWillUnmount() {
    //     this.props.reset!();
    // }

    refreshCourses = () => {
        Network.getCourse().then((response: Course[]) => {
            const courses = response;
            this.props.changeCourses!(courses);
        });
    };

    refreshSectors = () => {
        Network.getSector().then((response: Sector[]) => {
            const sectors = response;
            this.props.changeSectors!(sectors);
        });
    };

    /**
     * Load query data
     * @param query the query
     */
    loadQuery = (query: any) => {
        if (query.user_id) {
            //get all users
            Network.getAllUsers().then(
                (response: User[]) => {
                    this.props.changeUsers!(response);
                    //get user from search's user_id
                    const user = response.find((u) => u.id === query.user_id);

                    // if the id correspond to no user reload with no search
                    if (!user) {
                        this.props.history.replace({ search: "" });
                    } else {
                        this.currentFilter = CalendarDataType.Users;
                        this.setState({ currentUserIds: [user.id] });
                        this.refresh(undefined, undefined, [user.id], true);
                    }
                },
                () =>
                    showNotification(
                        "Il y a eu un problème lors du chargement des utilisateurs",
                        "warning"
                    )
            );
            this.getGroups();
        } else if (query.group_id) {
            //get all groups
            Network.getGroups().then(
                (response: Group[]) => {
                    this.props.changeGroups!(response);
                },
                () =>
                    showNotification(
                        "Il y a eu un problème lors du chargement des groupes",
                        "warning"
                    )
            );
            this.getUsers();
        }
    };

    /**
     * Fetch all users
     */
    getUsers = () => {
        //get all users
        Network.getAllUsers().then(
            (response) => this.props.changeUsers!(response),
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des utilisateurs",
                    "warning"
                )
        );
    };

    /**
     * Fetch all groups
     */
    getGroups = () => {
        //get all groups
        Network.getGroups().then(
            (response: Group[]) => {
                this.props.changeGroups!(response);
                // const groupsToAdd: Group[] = response?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [];
                // let { currentGroup } = this.state;

                // currentGroup ?
                //     currentGroup.push(groupsToAdd[0])
                //     :
                //     currentGroup = [groupsToAdd[0]];
                // this.setState({ currentGroup });
            },
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des groupes",
                    "warning"
                )
        );
    };

    /**
     * Refresh the content of the planning
     * @param message a message to display as a success message - optional
     * @param data the data accompanying the data type - optional
     * @param withLoading if the loading state must be toggled - optional
     */
    refresh = (
        message?: string,
        description?: string,
        data?: number | number[],
        withLoading = true
    ): void => {
        if (withLoading) this.props.toggleLoading!(true);

        const body: CalendarDataBodyRequest = {
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            dataType: this.currentFilter,
            confirmation: this.confirmationState,
            displayWorkingTime: this.displayWorkingTime,
            displayDayView: this.displayDayView,
            userIds:
                this.currentFilter === CalendarDataType.Users
                    ? data
                        ? (data as number[])
                        : this.state.currentUserIds
                    : undefined,
            // groupId: this.currentFilter !== CalendarDataType.Users ? (data ? data as number : this.state.currentGroup?.id) : undefined,
            groupId:
                this.currentFilter !== CalendarDataType.Users &&
                    this.state.currentGroup &&
                    this.state.currentGroup.length > 0 &&
                    this.state.currentGroup[0]
                    ? this.state.currentGroup.map((g) => g.id!)
                    : undefined,
        };

        if (!body.userIds && !body.groupId) {
            if (this.props.currentUser?.company_detail?.company === 47) {
                const selectUserId = [this.props.currentUser!.id];
                this.currentFilter = CalendarDataType.Users;
                this.setState(
                    {
                        selectUsers: selectUserId,
                        currentUserIds: selectUserId,
                        currentUsers: [this.props.currentUser!],
                    },
                    () => this.refresh()
                );
            } else {
                const group = this.props.groups?.find(
                    (g) => g.name === "Team" || g.name === "Toute l'équipe"
                );
                if (group && group.id) {
                    this.changeGroup([group.id], this.refreshGroupsData);
                } else {
                    // showNotification("Erreur lors du chargement de la Team.", "warning")
                    const selectUserId = [this.props.currentUser!.id];
                    this.currentFilter = CalendarDataType.Users;
                    this.setState(
                        {
                            selectUsers: selectUserId,
                            currentUserIds: selectUserId,
                            currentUsers: [this.props.currentUser!],
                        },
                        () => this.refresh()
                    );
                }
            }
        } else {
            //get all events
            Network.getCalendarData(body).then(
                (response) => {
                    this.setState(
                        {
                            calendarData:
                                convertNetworkCalendarDataToPlanningCalendarData(response),
                            editEvent: undefined,
                            occurenceOnly: false,
                            editOvertime: undefined,
                            editUndertime: undefined,
                        },
                        () =>
                            this.state.calendarData
                                ? this.props.changeCellsPerRow!(
                                    this.state.calendarData.cellsPerRow
                                )
                                : null
                    );
                    if (message) showNotification(message, "success", description);
                    if (withLoading) this.props.toggleLoading!(false);
                },
                () => {
                    if (withLoading) this.props.toggleLoading!(false);
                    showNotification(
                        "Un problème est survenu pendant le chargement du calendrier.",
                        "warning"
                    );
                }
            );
        }
    };

    /**
     * Refresh templates
     */
    refreshTemplates = () => {
        // get all templates
        Network.getTemplates().then(
            response => {
                if (response.error) showNotification("Un problème est survenu pendant l'actualisation des modèles", "warning")
                else {
                    this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data))
                }
            },
            () =>
                showNotification(
                    "Il y a eu un problème lors du chargement des modèles",
                    "warning"
                )
        );
    };

    /**
     * Get the from date of the current week/day
     * @return the from date
     */
    getFromDate = (): string => {
        if (this.displayDayView) {
            return moment(this.currentDay).startOf("day").format(MOMENT_FORMAT);
            // return moment().week(this.currentWeek).startOf('week').format(MOMENT_FORMAT);
        } else {
            return moment()
                .week(this.currentWeek)
                .startOf("week")
                .format(MOMENT_FORMAT);
        }
    };

    /**
     * Get the to date of the current week/day
     * @return the to date
     */
    getToDate = (): string => {
        if (this.displayDayView) {
            // return moment().week(this.currentWeek).startOf('week').format(MOMENT_FORMAT);
            return moment(this.currentDay).endOf("day").format(MOMENT_FORMAT);
        } else {
            return moment()
                .week(this.currentWeek)
                .endOf("week")
                .format(MOMENT_FORMAT);
        }
    };

    /**
     * Change current week to previous one
     */
    previousWeek = () => {
        const previousWeek = this.currentWeek - 1;
        // update current week
        this.currentWeek = previousWeek;
        // update current day to monday of the week
        this.currentDay = moment()
            .week(previousWeek)
            .startOf("week")
            .format(MOMENT_FORMAT);

        this.refresh();
    };

    /**
     * Change current week to next one
     */
    nextWeek = () => {
        const nextWeek = this.currentWeek + 1;
        // update current week
        this.currentWeek = nextWeek;
        // update current day to monday of the week
        this.currentDay = moment()
            .week(nextWeek)
            .startOf("week")
            .format(MOMENT_FORMAT);

        this.refresh();
    };

    /**
     * Change current week to the selected one
     * @param date the date containing the selected week
     */
    onChangeWeek = (date: Moment | null) => {
        if (!date) return;

        // compute difference between current week and the selected one
        const diff = date
            .startOf("week")
            .diff(moment().week(this.currentWeek).startOf("week"), "week");
        const week = this.currentWeek + diff;
        // update current week
        this.currentWeek = week;
        // update current day to monday of the week
        this.currentDay = moment().week(week).startOf("week").format(MOMENT_FORMAT);

        this.refresh();
    };

    /**
     * Change current day to previous one
     */
    previousDay = () => {
        const currentDay = moment(this.currentDay).subtract(1, "days");

        // update current day
        this.currentDay = currentDay.format(MOMENT_FORMAT);

        // update current week if the day is on the previous week
        if (currentDay.weekday() === 6) {
            this.currentWeek = currentDay.week();
        }

        this.refresh();
    };

    /**
     * Change current day to next one
     */
    nextDay = () => {
        const currentDay = moment(this.currentDay).add(1, "days");

        // update current day
        this.currentDay = currentDay.format(MOMENT_FORMAT);

        // update current week if the day is on the next week
        if (currentDay.weekday() === 0) {
            this.currentWeek = currentDay.week();
        }

        this.refresh();
    };

    /**
     * Change current day to the selected one
     * @param day the date of the selected day
     */
    onChangeDay = (day: Moment | null) => {
        if (!day) return;

        // update current day
        this.currentDay = day.format(MOMENT_FORMAT);

        // compute difference between current week and the selected one
        const diff = day
            .startOf("week")
            .diff(moment().week(this.currentWeek).startOf("week"), "week");

        const week = this.currentWeek + diff;

        // update current week if the day is another week
        if (Math.abs(week - this.currentWeek) > 0) {
            this.currentWeek = week;
        }

        this.refresh();
    };

    /**
     * Called when the user click on a cell
     * @param date the date corresponding to the clicked cell
     * @param user the user corresponding to the clicked cell
     */
    onClickCell = (date: Moment, user: User): void => {
        this.setState({
            editEvent: {
                startDate: date,
                endDate: moment(date).add(1, "hours"),
                title: "",
                userId: user.id,
                groupId: undefined,
                isGlobal: false,
                isDraggable: true,
                finish: undefined,
                owner: PlanningEventOwner.User,
            },
        });
    };

    /**
     * Called when a event is dropped in a cell
     * @param cellEvent the event that was dropped
     * @param date the date corresponding to the target cell
     * @param user the user corresponding to the target cell
     */
    onDropCell = (
        cellEvent: PlanningCellEvent,
        date: Moment,
        user: UserSummary
    ) => {
        const modal = Modal.info({});
        modal.update({
            title: (
                <span className="flex-center">
                    <InfoCircleOutlined
                        style={{ color: "var(--primary-color)", marginRight: "20px" }}
                    />
                    <p>{"Déplacer ou copier cet événement ?"}</p>
                </span>
            ),
            content: (
                <div className="planning-info-modal">
                    <Button
                        onClick={() => {
                            this.onDropCellForMove(cellEvent, date, user);
                            modal.destroy();
                        }}
                    >
                        {"Déplacer"}
                    </Button>
                    <Button
                        onClick={() => {
                            this.onDropCellForCopy(cellEvent, date, user);
                            modal.destroy();
                        }}
                    >
                        {"Copier"}
                    </Button>
                </div>
            ),
            okButtonProps: { style: { display: "none" } },
            maskClosable: true,
            icon: null,
            centered: true,
        });
    };

    /**
     * Called when a event is dropped in a cell to be moved there
     * @param cellEvent the event that was dropped
     * @param date the date corresponding to the target cell
     * @param user the user corresponding to the target cell
     */
    onDropCellForMove = (
        cellEvent: PlanningCellEvent,
        date: Moment,
        user: UserSummary
    ) => {

        this.onMoveDropCell(cellEvent, date, user);
    };

    /**
     * Called when a event is dropped in a cell to be copied
     * @param cellEvent the event that was dropped
     * @param date the date corresponding to the target cell
     * @param user the user corresponding to the target cell
     */
    onDropCellForCopy = (
        cellEvent: PlanningCellEvent,
        date: Moment,
        user: UserSummary,
    ): void => {

        this.onCopyDropCell(cellEvent, date, user);
    };

    /**
     * Move a dropped event
     * @param cellEvent the event that was dropped
     * @param date the date corresponding to the target cell
     * @param user the user corresponding to the target cell
     * @param occurenceOnly if only one occurence of an rrule event must be edited - optional
     */
    onMoveDropCell = (
        cellEvent: PlanningCellEvent,
        date: Moment,
        user: UserSummary
    ): void => {
        const { event } = cellEvent;

        // if the event as rrule and must be edited globally, get the original event

        const updatedEvent = cloneEvent(event);
        const diff = event.endDate.diff(event.startDate, "minutes");

        //update start and end dates
        updatedEvent.startDate = moment(date);
        updatedEvent.endDate = moment(updatedEvent.startDate).add(
            diff,
            "minutes"
        );


        //change event's user's id if needed
        if (event.userId !== user.id) updatedEvent.userId = user.id;

        //update event
        Network.updateEvent(
            updatedEvent!,
            undefined
        ).then(
            () => {
                this.refresh(
                    "Évenement modifié avec succès",
                    undefined,
                    undefined,
                    false
                );
            },
            () => {
                showNotification(
                    "Il y a eu un problème lors de la modification de l'événement",
                    "warning"
                );
            }
        );
    };

    /**
     * Copy a dropped event
     * @param cellEvent the event that was dropped
     * @param date the date corresponding to the target cell
     * @param user the user corresponding to the target cell
     */
    onCopyDropCell = (
        cellEvent: PlanningCellEvent,
        date: Moment,
        user: UserSummary,
        occurenceOnly?: boolean
    ): void => {
        Network.copyEvent(
            cellEvent.event.id!,
            user.id,
            date.format("YYYY-MM-DDTHH:mm:ss"),
            occurenceOnly
        ).then(
            () =>
                this.refresh(
                    "Événement copié avec succès",
                    undefined,
                    undefined,
                    false
                ),
            () =>
                showNotification(
                    "Il y a eu un problème pendant la copie de l'événement.",
                    "warning"
                )
        );
    };

    /**
     * Called when the user click on edit a event
     * Set the editEvent to open the modal
     * @param event the concerned event
     * @param editOccurence if only one occurence of an rrule event must be edited - optional
     */
    onClickEditEvent = (event: PlanningEvent, editOccurence?: boolean): void => {
        // if the event as rrule and must be edited globally, get the original event

        this.setState({
            editEvent: cloneEvent(event),
            occurenceOnly: Boolean(editOccurence),
        });
    };

    /**
     * Update an event
     * @param event the updated event
     * @param sendMessage if a message must be sent when editing the event
     */
    onEditEvent = (event: PlanningEvent, sendMessage?: boolean): void => {
        this.props.toggleLoading!(true);

        // method that update the event
        const update = () => {
            Network.updateEvent(
                event,
                undefined,
                sendMessage
            ).then(
                () => {
                    this.refresh("Évenement modifié avec succès");
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    this.refreshTemplates();
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    this.props.toggleLoading!(false);
                    showNotification(
                        "Il y a eu un problème lors de la modification de l'événement",
                        "warning"
                    );
                }
            );
        };

        // if event is global update directely, otherwise check availability
        if (event.isGlobal) {
            update();
        } else {
            // check availavility
            Network.eventCheckAvailability(
                event,
                event.userId ? [event.userId] : undefined,
                event.groupId ? [event.groupId] : undefined
            ).then(
                (response) => {
                    if (response.length > 0) {
                        this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                        this.props.toggleLoading!(false);
                        displayErrorMessage(
                            `${event.userId ? "L'utilisateur" : "Le groupe"
                            } n'as pas de disponibilités (périodes) pour la création de cet évenement.`,
                            "planning-modal-ok-button"
                        );
                    } else {
                        // update the event
                        update();
                    }
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    this.props.toggleLoading!(false);
                    showNotification(
                        "Il y a eu un problème lors de la modification de l'événement",
                        "warning"
                    );
                }
            );
        }
    };

    /**
     * Create an event
     * @param event the new event
     * @param userIds an array of all owner user'id
     * @param groupIds an array of all owner group'id
     * @param sendMessage if a message must be sent when creating the event
     * @param asTemplate if a template must be created with the event configuration
     */
    onCreateEvent = (
        event: PlanningEvent,
        userIds: number[],
        groupIds: number[],
        sendMessage?: boolean,
        asTemplate?: boolean
    ): void => {
        this.props.toggleLoading!(true);

        // method that create the event
        const create = () => {
            Network.createEvent(event, userIds, groupIds, sendMessage).then(
                () => {
                    if (asTemplate) {
                        Network.updateTemplate({
                            startDate: moment(event.startDate),
                            endDate: moment(event.endDate),
                            title: event.title,
                            color: event.color,
                            typeOfDay: event.typeOfDay,
                            typeOfDayOff: event.typeOfDayOff,
                        }).then(
                            () => {
                                // this.state.calendarData?.calendarUserData.some(cud => userIds.includes(cud.user.id))
                                if (
                                    this.state.currentGroup &&
                                    userIds.every(
                                        (uid) =>
                                            this.state.calendarData?.calendarUserData.findIndex(
                                                (ud) => ud.user.id === uid
                                            ) !== -1
                                    )
                                ) {
                                    this.refresh("Événement créé avec succès");
                                } else {
                                    // let currentUserIds: number[] = eventsResponse.filter(e => e.userId !== undefined).map(e => e.userId!);
                                    this.currentFilter = CalendarDataType.Users;
                                    this.setState(
                                        {
                                            currentUserIds: userIds,
                                            selectUsers: userIds,
                                            currentUsers: this.props.users?.filter((u) =>
                                                userIds.includes(u.id)
                                            ),
                                            currentGroup: undefined,
                                            selectGroups: undefined,
                                        },
                                        () =>
                                            this.refresh(
                                                "Événement créé avec succès",
                                                "La liste d'utilisateurs a changé."
                                            )
                                    );
                                }
                                this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                                this.refreshTemplates();
                            },
                            () => {
                                this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                                this.props.toggleLoading!(false);
                                showNotification(
                                    "Il y a eu un problème lors de la création du modèle",
                                    "warning"
                                );
                            }
                        );
                    } else {
                        if (
                            this.state.currentGroup &&
                            userIds.every(
                                (uid) =>
                                    this.state.calendarData?.calendarUserData.findIndex(
                                        (ud) => ud.user.id === uid
                                    ) !== -1
                            )
                        ) {
                            this.refresh("Événement créé avec succès");
                        } else {
                            // let currentUserIds: number[] = eventsResponse.filter(e => e.userId !== undefined).map(e => e.userId!);
                            this.currentFilter = CalendarDataType.Users;
                            this.setState(
                                {
                                    currentUserIds: userIds,
                                    selectUsers: userIds,
                                    currentUsers: this.props.users?.filter((u) =>
                                        userIds.includes(u.id)
                                    ),
                                    currentGroup: undefined,
                                    selectGroups: undefined,
                                },
                                () =>
                                    this.refresh(
                                        "Événement créé avec succès",
                                        "La liste d'utilisateurs a changé."
                                    )
                            );
                        }
                        this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    }
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    this.props.toggleLoading!(false);
                    showNotification(
                        "Il y a eu un problème lors de la création de l'événement",
                        "warning"
                    );
                }
            );
        };

        // if event is global update directely, otherwise check availability
        if (event.isGlobal) {
            create();
        } else {
            // check availavility
            Network.eventCheckAvailability(event, userIds, groupIds).then(
                (response) => {
                    if (response.length > 0) {
                        this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                        this.props.toggleLoading!(false);

                        if (userIds.length > 0) {
                            notification.error({
                                message:
                                    "Des utilisateurs n'ont pas de disponibilités (périodes) pour la création de cet évenement:",
                                description: (
                                    <ul>
                                        {response.map((u: UserSummary) => (
                                            <li key={`planning-onCreateEvent-user-${u.id}`}>{`${u.last_name} ${u.first_name}`}</li>
                                        ))}
                                    </ul>
                                ),
                                duration: null,
                            });
                        } else {
                            notification.error({
                                message:
                                    "Des groupes n'ont pas de disponibilités (périodes) pour la création de cet évenement:",
                                description: (
                                    <ul>
                                        {response.map((g: Group) => (
                                            <li key={`planning-onCreateEvent-group-${g.id}`}>{g.name}</li>
                                        ))}
                                    </ul>
                                ),
                                duration: null,
                            });
                        }
                    } else {
                        // create the event
                        create();
                    }
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    this.props.toggleLoading!(false);
                    showNotification(
                        "Il y a eu un problème lors de la création de l'événement",
                        "warning"
                    );
                }
            );
        }
    };

    /**
     * Delete an event
     * @param the event to delete
     * @param deleteOne if only one occurence of the event must be deleted
     */
    onDeleteEvent = (event: PlanningEvent, deleteOne = false): void => {
        // if deleteOne, delete just this event's occurence
        if (deleteOne) {
            Network.deleteEvent(event.id!, undefined).then(
                () => {
                    this.refresh("Occurence supprimée avec succès", undefined);
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    showNotification(
                        "Il y a eu un problème lors de la suppression de l'occurence",
                        "warning"
                    );
                }
            );
        } else {
            Network.deleteEvent(event.id!).then(
                () => {
                    this.refresh("Évenement supprimé avec succès", undefined);
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                },
                () => {
                    this.setState((state) => ({ refreshOccupancyRateFilter: !state.refreshOccupancyRateFilter }));
                    showNotification(
                        "Il y a eu un problème lors de la suppression de l'événement",
                        "warning"
                    );
                }
            );
        }
    };

    /**
     * Add a new overtime, open the overtime modal
     * @param user the concerned user
     */
    onAddOvertime = (user: User) => {
        this.setState({
            editOvertime: {
                userId: user.id,
                title: undefined,
                startDate: moment(this.getFromDate()),
                endDate: moment(this.getFromDate()).add(1, "hours"),
            },
        });
    };

    /**
     * Add a new undertime, open the undertime modal
     * @param user the concerned user
     */
    onAddUndertime = (user: User) => {
        this.setState({
            editUndertime: {
                userId: user.id,
                title: undefined,
                startDate: moment(this.getFromDate()),
                endDate: moment(this.getFromDate()).add(1, "hours"),
                isNegative: true,
            },
        });
    };

    /**
     * Edit an existing overtime, open the overtime modal
     * @param overtime the overtime to edit
     */
    onEditOvertime = (overtime: PlanningOvertime) => {
        this.setState({ editOvertime: overtime });
    };

    /**
     * Update an overtime
     * @param overtime the overtime to update
     */
    onUpdateOvertime = (overtime: PlanningOvertime) => {
        Network.updateOvertime(overtime).then(
            () => this.refresh("Heures supplémentaires mises à jour avec succès."),
            () =>
                showNotification(
                    "Un problème est survenu pendant la mise à jour des heures supplémentaires.",
                    "warning"
                )
        );
    };

    /**
     * Update an undertime
     * @param undertime the undertime to update
     */
    onUpdateUndertime = (undertime: PlanningOvertime) => {
        undertime.isNegative = true;
        Network.updateOvertime(undertime).then(
            () => this.refresh("Heures négatives mises à jour avec succès."),
            () =>
                showNotification(
                    "Un problème est survenu pendant la mise à jour des heures supplémentaires.",
                    "warning"
                )
        );
    };

    /**
     * Delete an overtime
     * @param overtime the overtime to delete
     */
    onDeleteOvertime = (overtime: PlanningOvertime) => {
        Network.deleteOvertime(overtime.id!).then(
            () => this.refresh("Heures supplémentaires supprimées avec succès."),
            () =>
                showNotification(
                    "Un problème est survenu pendant la suppression des heures supplémentaires.",
                    "warning"
                )
        );
    };

    /**
     * Show the event details modal
     * @param the event to show
     */
    showEventDetails = (event: PlanningEvent) => {
        this.setState({
            detailsEvent: event,
            detailsUser: event.userId
                ? this.props.users?.find((u) => u.id === event.userId)
                : undefined,
            detailsGroup: event.groupId
                ? this.props.groups?.find((g) => g.id === event.groupId)
                : undefined,
        });
    };

    /**
     * Called when the settings are updated
     */
    onUpdateSettings = (settings: NetworkSettings) => {
        this.setState({ showSettings: false, settingDefaultStep: undefined });
        this.props.changeSettings!(
            convertNetworkSettingsToPlanningSettings(settings)
        );
        if (!this.state.editEvent) {
            this.refresh("Paramètres mise à jour avec succès.");
            this.refreshTemplates();
        }
    };

    /**
     * Toggle the state of display day view
     */
    toggleDisplayDayView = (): void => {
        const pathname = this.props.location.pathname + this.props.location.search;

        if (this.displayDayView)
            this.props.history.replace(pathname.replace("/day-view", ""));
        else
            this.props.history.replace(
                pathname.replace("/planning", "/planning/day-view")
            );

        this.displayDayView = !this.displayDayView;
        this.refresh();
    };

    /**
     * Toggle display working time
     */
    toggleDisplayWorkingTime = (): void => {
        this.displayWorkingTime = !this.displayWorkingTime;
        this.props.toggleDisplayWorkingTime!();
        this.refresh();
    };

    /**
     * Change the current filter
     * @param currentFilter the new current filter
     */
    onChangeFilter = (currentFilter: CalendarDataType): void => {
        this.currentFilter = currentFilter;
        if (currentFilter === CalendarDataType.Users)
            this.setState({ currentGroup: undefined });
        this.refresh();
    };

    /**
     * Change the current confirmation state
     * @param confirmationState the new confirmation state
     */
    onChangeConfirmationState = (
        confirmationState: CalendarDataConfirmationState
    ): void => {
        this.confirmationState = confirmationState;
        this.refresh();
    };

    /**
     * Change the current group
     * @param group the new current group
     */
    // onChangeGroup = (group: Group): void => {
    //     this.props.history.replace(this.props.match.url + `?group_id=${group.id}`);
    //     this.currentFilter = CalendarDataType.Group;
    //     this.refresh(undefined, group.id, true);
    //     this.setState({ currentGroup: group });
    // }
    onChangeGroup = (
        group: Group,
        checked: boolean
    ): void => {
        // this.props.history.replace(this.props.match.url + `?group_id=${group.id}`);

        let { currentGroup } = this.state;

        if (checked) {
            currentGroup ? currentGroup.push(group) : (currentGroup = [group]);
        } else {
            currentGroup = currentGroup?.filter((g) => g.id !== group.id);
        }

        this.currentFilter = CalendarDataType.Group;

        this.setState({ currentGroup }, () => {
            this.refresh(undefined);
        });
    };

    // onChangeProject = (project: Project, checked: boolean): void => {

    //     let currentProjects = this.state.currentProjects ? [...this.state.currentProjects] : this.state.currentProjects;

    //     if (checked) {
    //         currentProjects ?
    //             currentProjects.push(project)
    //             :
    //             currentProjects = [project]
    //     } else {
    //         currentProjects = currentProjects?.filter(p => p.id !== project.id);
    //     }

    //     this.setState({ currentProjects });
    // }

    changeGroup = (values: number[], callback?: any) => {
        this.setState({ selectGroups: values }, () => callback && callback());
    };

    refreshGroupsData = () => {
        const { selectGroups } = this.state;
        let { currentGroup } = this.state;
        const groupsToAdd: Group[] =
            this.props.groups?.sort((a, b) => (a.name! > b.name! ? 1 : -1)) ?? [];
        currentGroup = groupsToAdd?.filter(
            (g) => selectGroups?.find((id) => id === g.id) !== undefined
        );
        this.currentFilter = CalendarDataType.Group;
        this.setState(
            {
                currentGroup,
                currentUserIds: undefined,
                currentUsers: undefined,
                selectUsers: undefined,
            },
            () => {
                this.refresh(undefined);
            }
        );
    };

    changeUser = (values: number[]) => {
        this.setState({ selectUsers: values });
    };

    refreshUsersData = () => {
        const { selectUsers } = this.state;
        let { currentUsers } = this.state;

        const usersToAdd: User[] =
            this.props.users?.sort((a, b) =>
                a.last_name! > b.last_name! ? 1 : -1
            ) ?? [];
        currentUsers = usersToAdd?.filter(
            (g) => selectUsers?.find((id) => id === g.id) !== undefined
        );
        this.currentFilter = CalendarDataType.Users;
        this.setState(
            {
                currentUsers,
                currentUserIds: currentUsers.map((cu) => cu.id!),
                currentGroup: undefined,
                selectGroups: undefined,
            },
            () => {
                this.refresh(undefined);
            }
        );
    };

    onChangeProject = (values: number[]): void => {
        const projectsToAdd: Project[] =
            this.props.project?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        let { currentProjects } = this.state;
        currentProjects = projectsToAdd?.filter(
            (g) => values.find((id) => id === g.id) !== undefined
        );
        this.setState({ currentProjects });
    };

    onChangePoi = (values: number[]): void => {
        const pois: POI[] =
            this.props.pois?.data?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ??
            [];
        let { currentPois } = this.state;
        currentPois = pois?.filter(
            (g) => values.find((id) => id === g.id) !== undefined
        );
        this.setState({ currentPois });
    };

    onChangeSector = (values: number[]): void => {
        const sectors: Sector[] =
            this.props.sectors?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        let { currentSectors } = this.state;
        currentSectors = sectors?.filter(
            (g) => values.find((id) => id === g.id) !== undefined
        );
        this.setState({ currentSectors });
    };

    onChangeCourse = (values: number[]): void => {
        const courses: Course[] =
            this.props.courses?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        let { currentCourses } = this.state;
        currentCourses = courses?.filter(
            (g) => values.find((id) => id === g.id) !== undefined
        );
        this.setState({ currentCourses });
    };

    renderFilter = () => {
        const { Option } = Select;
        const { Panel } = Collapse;
        const { currentProjects, currentPois, currentCourses, currentSectors } =
            this.state;
        const groupsToAdd: Group[] =
            this.props.groups?.sort((a, b) => (a.name! > b.name! ? 1 : -1)) ?? [];
        const usersToAdd: User[] =
            this.props.users
                ?.filter((u) => u.active === true)
                .sort((a, b) => (a.last_name! > b.last_name! ? 1 : -1)) ?? [];
        const projectsToAdd: Project[] =
            this.props.project?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        const poistoAdd: POI[] =
            this.props.pois?.data?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ??
            [];
        const sectors: Sector[] =
            this.props.sectors?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        const courses: Course[] =
            this.props.courses?.sort((a, b) => (a.title! > b.title! ? 1 : -1)) ?? [];
        return (
            <div>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }}>
                        <Radio.Group
                            onChange={(e) => this.onChangeFilter(e.target.value)}
                            value={this.currentFilter}
                            optionType="button"
                            buttonStyle="solid"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Radio.Button
                                style={{
                                    borderRadius: "18px 0px 0px 18px",
                                    width: "33.33%",
                                    textAlign: "center",
                                }}
                                value={CalendarDataType.All}
                            >
                                Tous
                            </Radio.Button>
                            <Radio.Button
                                style={{ width: "33.33%", textAlign: "center" }}
                                value={CalendarDataType.Planned}
                            >
                                Planifiés
                            </Radio.Button>
                            <Radio.Button
                                style={{
                                    borderRadius: "0px 18px 18px 0px",
                                    width: "33.33%",
                                    textAlign: "center",
                                }}
                                value={CalendarDataType.Available}
                            >
                                Disponibles
                            </Radio.Button>
                        </Radio.Group>
                        <Collapse
                            defaultActiveKey={[this.state.selectGroups ? "1" : "2"]}
                            style={{ backgroundColor: "transparent", border: "none" }}
                        >
                            <Panel
                                header="Groupes"
                                className={this.state.currentGroup ? "__panel-title-bold" : ""}
                                key="1"
                            >
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ paddingRight: "5px" }}>
                                                <Select
                                                    className="__filter-limit-size"
                                                    disabled={this.props.loading}
                                                    mode="multiple"
                                                    allowClear
                                                    placeholder="Groupes à afficher"
                                                    style={{ width: "100%" }}
                                                    value={this.state.selectGroups}
                                                    onChange={(e) => this.changeGroup(e)}
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    showArrow
                                                    maxTagCount={"responsive"}
                                                >
                                                    {groupsToAdd.length > 0 &&
                                                        (checkRBACRule(
                                                            Rules.Planning.Management,
                                                            this.props.currentUser?.role
                                                        ) ||
                                                            checkPrivilege(
                                                                Privileges.Planning.readOnlyAllPlanning,
                                                                this.props.currentUser
                                                            )) &&
                                                        groupsToAdd.map((g) => {
                                                            return (
                                                                <Option
                                                                    label={g.name}
                                                                    value={g.id!}
                                                                    key={"groups-" + g.id}
                                                                >
                                                                    {g.name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </td>
                                            <td style={{ width: "33px" }}>
                                                <CircleButton
                                                    loading={this.props.loading}
                                                    // disabled={this.props.loading}
                                                    small={true}
                                                    // title={"Valider"}
                                                    onClick={this.refreshGroupsData}
                                                    icon={<CheckOutlined />}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Panel>
                            <Panel
                                header="Utilisateurs"
                                className={this.state.currentUsers ? "__panel-title-bold" : ""}
                                key="2"
                            >
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ paddingRight: "5px" }}>
                                                <Select
                                                    disabled={this.props.loading}
                                                    mode="multiple"
                                                    allowClear
                                                    placeholder="Utilisateurs à afficher"
                                                    style={{ width: "100%" }}
                                                    value={this.state.selectUsers}
                                                    onChange={this.changeUser}
                                                    // filterOption={(input, option) => option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    showArrow
                                                    maxTagCount={"responsive"}
                                                >
                                                    {usersToAdd.length > 0 &&
                                                        (checkRBACRule(
                                                            Rules.Planning.Management,
                                                            this.props.currentUser?.role
                                                        ) ||
                                                            checkPrivilege(
                                                                Privileges.Planning.readOnlyAllPlanning,
                                                                this.props.currentUser
                                                            )) &&
                                                        usersToAdd.map((g) => {
                                                            return (
                                                                <Option
                                                                    value={g.id!}
                                                                    key={"users-" + g.id}
                                                                    label={`${g.last_name} ${g.first_name}`}
                                                                >
                                                                    {g.last_name} {g.first_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </td>
                                            <td style={{ width: "33px" }}>
                                                <CircleButton
                                                    loading={this.props.loading}
                                                    // disabled={this.props.loading}
                                                    small={true}
                                                    // title={"Valider"}
                                                    onClick={this.refreshUsersData}
                                                    icon={<CheckOutlined />}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Panel>
                            {projectsToAdd.length > 0 &&
                                ((checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) ||
                                    checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) ||
                                    checkPrivilege(Privileges.Planning.readOnlyAllPlanning, this.props.currentUser)) &&
                                (
                                    <Panel header={getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', true, CaseType.FIRST_LETTER_UPPERCASE)} key="3">
                                        <Select
                                            disabled={this.props.loading}
                                            mode="multiple"
                                            allowClear
                                            placeholder={`${getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', true, CaseType.FIRST_LETTER_UPPERCASE)} à afficher`}
                                            style={{ width: "100%" }}
                                            value={
                                                currentProjects
                                                    ? currentProjects
                                                        .filter((p) => p.id !== undefined)
                                                        .map((p) => p.id!)
                                                    : []
                                            }
                                            onChange={this.onChangeProject}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {projectsToAdd.map((p: Project) => {
                                                return (
                                                    <Option
                                                        label={p.title}
                                                        value={p.id!}
                                                        key={"project-" + p.id}
                                                    >
                                                        {p.title}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Panel>
                                )}

                            {poistoAdd.length > 0 &&
                                (checkRBACRule(
                                    Rules.CourseManagement.Visit,
                                    this.props.currentUser?.role,
                                    this.props.currentUser?.company_id
                                ) ||
                                    checkPrivilege(
                                        Privileges.Planning.readOnlyAllPlanning,
                                        this.props.currentUser
                                    )) &&
                                (
                                    <Panel header="Points d'intérêt" key="4">
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ paddingRight: "5px" }}>
                                                        <Select
                                                            disabled={this.props.loading}
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder="Points d'intérêt à afficher"
                                                            style={{ width: "100%" }}
                                                            value={
                                                                currentPois
                                                                    ? currentPois
                                                                        .filter((p) => p.id !== undefined)
                                                                        .map((p) => p.id!)
                                                                    : []
                                                            }
                                                            onChange={this.onChangePoi}
                                                            filterOption={true}
                                                            optionFilterProp="label"
                                                            showArrow
                                                            maxTagCount={"responsive"}
                                                        >
                                                            {poistoAdd.map((p: Project) => {
                                                                return (
                                                                    <Option
                                                                        label={p.title}
                                                                        value={p.id!}
                                                                        key={"poi-" + p.id}
                                                                    >
                                                                        {p.title}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </td>
                                                    <td style={{ width: "33px" }}>
                                                        <CircleButton
                                                            loading={this.props.loading}
                                                            small={true}
                                                            icon={<BiMap className="anticon-svg" />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({ selectPoiOnMap: true });
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Panel>
                                )}

                            {sectors.length > 0 &&
                                (checkRBACRule(
                                    Rules.CourseManagement.Visit,
                                    this.props.currentUser?.role,
                                    this.props.currentUser?.company_id
                                ) ||
                                    checkPrivilege(
                                        Privileges.Planning.readOnlyAllPlanning,
                                        this.props.currentUser
                                    )) &&
                                (
                                    <Panel header="Secteurs" key="5">
                                        <Select
                                            disabled={this.props.loading}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Secteurs à afficher"
                                            style={{ width: "100%" }}
                                            value={
                                                currentSectors
                                                    ? currentSectors
                                                        .filter((p) => p.id !== undefined)
                                                        .map((p) => p.id!)
                                                    : []
                                            }
                                            onChange={this.onChangeSector}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {sectors.map((s: Sector) => {
                                                return (
                                                    <Option
                                                        label={s.title}
                                                        value={s.id!}
                                                        key={"poi-" + s.id}
                                                    >
                                                        {s.title}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Panel>
                                )}

                            {courses.length > 0 &&
                                (checkRBACRule(
                                    Rules.CourseManagement.Visit,
                                    this.props.currentUser?.role,
                                    this.props.currentUser?.company_id
                                ) ||
                                    checkPrivilege(
                                        Privileges.Planning.readOnlyAllPlanning,
                                        this.props.currentUser
                                    )) &&
                                (
                                    <Panel header="Parcours" key="6">
                                        <Select
                                            disabled={this.props.loading}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Parcours à afficher"
                                            style={{ width: "100%" }}
                                            value={
                                                currentCourses
                                                    ? currentCourses
                                                        .filter((p) => p.id !== undefined)
                                                        .map((p) => p.id!)
                                                    : []
                                            }
                                            onChange={this.onChangeCourse}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {courses.map((c: Course) => {
                                                return (
                                                    <Option
                                                        label={c.title}
                                                        value={c.id!}
                                                        key={"poi-" + c.id}
                                                    >
                                                        {c.title}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Panel>
                                )}
                        </Collapse>
                    </Col>
                </Row>

                {/* {groupsToAdd.length > 0 && (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) || checkPrivilege(Privileges.Planning.readOnlyAllPlanning, this.props.currentUser)) &&
                    groupsToAdd.map((g: Group) => (
                        <p key={`planning-filters-groups-${g?.id}`}><Checkbox defaultChecked={currentGroup?.some(cg => cg?.id === g?.id)} onChange={(e) => this.onChangeGroup(g, e.target.checked, true)}>{g.name}</Checkbox></p>
                    ))
                } */}
            </div>
        );
    };

    // templateOnRow = (record: PlanningTemplate) => ({
    //     onClick: () => this.setState({ isShowPoi: true, poiEdit: record })
    // });

    /**
     * Change tab
     * @param tab the current tab key
     */
    onChangeTab = (tab: string): void => {
        this.props.history.replace(
            `/${this.props.match.params.lang}/planning/day-view/${tab}`
        );
    };

    /**
     * Delete a template
     * @param template the concerned template
     */
    onDeleteTemplate = (template: PlanningTemplate) => {
        Network.deleteTemplate(template.id!).then(
            () => {
                showNotification("Modèle supprimé avec succès.", "success");
                this.refreshTemplates();
            },
            () => showNotification("Un problème est survenu lors de la suppression du modèle.", "warning"),
        );
    }

    render() {
        const { displayFilter, displayOccupancyRate } = this.state;
        const { pois, courses, sectors } = this.props;
        // const groupsToAdd: Group[] = this.props.groups?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [];
        // const projectsToAdd: Project[] = this.props.project?.sort((a, b) => a.title! > b.title! ? 1 : -1) ?? [];
        const headerButtons = [
            (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) ||
                checkPrivilege(
                    Privileges.Planning.readOnlyAllPlanning,
                    this.props.currentUser
                )) && (
                // groupsToAdd.length > 0 &&
                // <Dropdown key="planning-button-add-group" trigger={['click']} overlay={
                //     <Menu>
                //         {
                //             groupsToAdd.map((g: Group) => (
                //                 <Menu.Item
                //                     onClick={() => this.onChangeGroup(g)}
                //                     key={`planning-select-groups-${g.id}`}>
                //                     {g.name}
                //                 </Menu.Item>
                //             ))
                //         }
                //     </Menu>
                // }>
                //     <CircleButton icon={<TeamOutlined />} title={"Afficher un groupe"} />
                // </Dropdown>,
                // projectsToAdd.length > 0 &&
                // <Dropdown key="planning-button-add-project" trigger={['click']} overlay={
                //     <Menu>
                //         {
                //             projectsToAdd.map((p: Project) => (
                //                 <Menu.Item
                //                     // onClick={() => this.onChangeGroup(g)}
                //                     // style={{ fontWeight: this.state.currentGroup && this.state.currentGroup.id === g.id ? 'bold' : undefined }}
                //                     key={`planning-select-projects-${p?.id}`}>
                //                     <Checkbox defaultChecked={currentGroup?.some(cg => cg?.id === p?.id)} onChange={(e) => this.onChangeProject(p, e.target.checked)}>{p.title}</Checkbox>
                //                 </Menu.Item>
                //             ))
                //         }
                //     </Menu>
                // }>
                //     <CircleButton icon={<ProfileOutlined />} title={"Filtrer par projet"} />
                // </Dropdown>,
                // groupsToAdd.length > 0 &&
                // <Dropdown key="planning-button-add-group" trigger={['click']} overlay={
                //     <Menu>
                //         {
                //             groupsToAdd.map((g: Group) => (
                //                 <Menu.Item
                //                     // onClick={() => this.onChangeGroup(g)}
                //                     // style={{ fontWeight: this.state.currentGroup && this.state.currentGroup.id === g.id ? 'bold' : undefined }}
                //                     key={`planning-select-groups-${g?.id}`}>
                //                     <Checkbox defaultChecked={currentGroup?.some(cg => cg?.id === g?.id)} onChange={(e) => this.onChangeGroup(g, e.target.checked, true)}>{g.name}</Checkbox>
                //                 </Menu.Item>
                //             ))
                //         }
                //     </Menu>
                // }>
                //     <CircleButton icon={<TeamOutlined />} title={"Afficher un groupe"} />
                // </Dropdown>,
                // <Can key="planning-button-filter" rule={Rules.Planning.Filters}>
                //     <Dropdown trigger={['click']} overlay={
                //         <Menu>
                //             <Menu.Item
                //                 onClick={() => this.onChangeFilter(CalendarDataType.All)}
                //                 style={{ fontWeight: this.currentFilter === CalendarDataType.All ? 'bold' : undefined }}
                //                 key={`planning-select-users-all`}>
                //                 {"Tous les utilisateurs"}
                //             </Menu.Item>
                //             <Menu.Item
                //                 onClick={() => this.onChangeFilter(CalendarDataType.Planned)}
                //                 style={{ fontWeight: this.currentFilter === CalendarDataType.Planned ? 'bold' : undefined }}>
                //                 {"Tous les utilisateurs planifiés"}
                //             </Menu.Item>
                //             <Menu.Item
                //                 onClick={() => this.onChangeFilter(CalendarDataType.Available)}
                //                 style={{ fontWeight: this.currentFilter === CalendarDataType.Available ? 'bold' : undefined }}>
                //                 {"Tous les utilisateurs disponibles"}
                //             </Menu.Item>
                //         </Menu>
                //     }>
                //         <CircleButton
                //             icon={
                //                 <span style={{ position: 'relative' }}>
                //                     <FilterOutlined />
                //                     {this.currentFilter === CalendarDataType.All && <CalendarOutlined className="planning-filter-icon" />}
                //                     {this.currentFilter === CalendarDataType.Planned && <CarryOutOutlined className="planning-filter-icon" />}
                //                     {this.currentFilter === CalendarDataType.Available && <CalendarFilled className="planning-filter-icon" />}
                //                 </span>}
                //             title={"Filtres"} />
                //     </Dropdown>
                // </Can>,
                <Dropdown
                    key="planning-button-display"
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            <Menu.Item
                                key="planning-button-display-week"
                                onClick={this.toggleDisplayDayView}
                            >
                                {this.displayDayView
                                    ? "Affichage par semaine"
                                    : "Affichage par jour"}
                            </Menu.Item>
                            {this.props.settings.workingDaysOfWeek && (
                                <Menu.Item
                                    key="planning-button-display-working-day"
                                    onClick={() => this.toggleDisplayWorkingTime()}
                                >
                                    {this.props.displayWorkingTime
                                        ? "Afficher toutes les heures"
                                        : "Afficher les heures de travail"}
                                </Menu.Item>
                            )}
                            <Menu.SubMenu
                                key="planning-button-display-sub-menu"
                                title={"Confirmations"}
                                className={
                                    this.confirmationState !== CalendarDataConfirmationState.All
                                        ? "bold-submenu"
                                        : undefined
                                }
                            >
                                <Menu.Item
                                    key="planning-button-display-sub-menu-all"
                                    className={
                                        this.confirmationState === CalendarDataConfirmationState.All
                                            ? "bold-submenu-item"
                                            : undefined
                                    }
                                    onClick={() =>
                                        this.onChangeConfirmationState(
                                            CalendarDataConfirmationState.All
                                        )
                                    }
                                >
                                    {"Tous"}
                                </Menu.Item>
                                <Menu.Item
                                    key="planning-button-display-sub-menu-confirmed"
                                    className={
                                        this.confirmationState ===
                                            CalendarDataConfirmationState.Confirmed
                                            ? "bold-submenu-item"
                                            : undefined
                                    }
                                    onClick={() =>
                                        this.onChangeConfirmationState(
                                            CalendarDataConfirmationState.Confirmed
                                        )
                                    }
                                >
                                    {"Événements confirmés"}
                                </Menu.Item>
                                <Menu.Item
                                    key="planning-button-display-sub-menu-not-confirmed"
                                    className={
                                        this.confirmationState ===
                                            CalendarDataConfirmationState.NotConfirmed
                                            ? "bold-submenu-item"
                                            : undefined
                                    }
                                    onClick={() =>
                                        this.onChangeConfirmationState(
                                            CalendarDataConfirmationState.NotConfirmed
                                        )
                                    }
                                >
                                    {"Événements non confirmés"}
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    }
                >
                    <CircleButton
                        title={"Affichage"}
                        icon={
                            <span style={{ position: "relative" }}>
                                <EyeOutlined />
                                {
                                    this.confirmationState === CalendarDataConfirmationState.Confirmed ?
                                        <CheckCircleOutlined className="planning-filter-icon" />
                                        : null
                                }
                                {
                                    this.confirmationState === CalendarDataConfirmationState.NotConfirmed ?
                                        <CloseCircleOutlined className="planning-filter-icon" />
                                        : null
                                }
                            </span>
                        }
                    />
                </Dropdown>
            ),
            <Can key="planning-button-filter-display" rule={Rules.Planning.Settings}>
                <CircleButton
                    type={displayFilter && !displayOccupancyRate ? "primary" : "default"}
                    icon={<FilterOutlined />}
                    onClick={() =>
                        this.setState({
                            displayOccupancyRate: false,
                            displayFilter: this.state.displayOccupancyRate
                                ? true
                                : !this.state.displayFilter,
                        })
                    }
                    title={"Filtres"}
                />
            </Can>,
            <Can
                key="planning-button-occupancy-rate-display"
                rule={Rules.Planning.Settings}
            >
                <CircleButton
                    type={displayOccupancyRate ? "primary" : "default"}
                    icon={
                        <span className="anticon">
                            <FaRegCalendarCheck />
                        </span>
                    }
                    onClick={() =>
                        this.setState({
                            displayFilter:
                                !this.state.displayOccupancyRate && this.state.displayFilter
                                    ? true
                                    : !this.state.displayFilter,
                            displayOccupancyRate: !this.state.displayOccupancyRate,
                        })
                    }
                    title={"Missions"}
                />
            </Can>,
            <Can key="planning-button-settings" rule={Rules.Planning.Settings}>
                <CircleButton
                    onClick={() => this.setState({ showSettings: true })}
                    icon={<SettingOutlined />}
                    title={"Paramètres"}
                />
            </Can>,
            // <Can key="planning-button-dnd" rule={Rules.Planning.DnDVersion}>
            //     <CircleButton onClick={() => this.toggleIsTouch()} icon={<ReactSVG className="planning-touch-svg" src={this.state.isTouch ? Click : Touch} />} title={this.state.isTouch ? "Version normale" : "Version digitale"} />
            // </Can>
        ];

        return (
            <Can
                rule={Rules.Planning.Weekly}
                privilege={Privileges.Planning.Visit}
                redirect="/dashboard"
            >
                <Container
                    className="planning-container planning-weekly"
                    breadcrumb={[{ title: "Planification", link: "/planning" }]}
                >

                    <div className="planning-header">
                        <div className="planning-week-buttons">
                            {this.displayDayView ? (
                                <div>
                                    <Button
                                        className="planning-week-button-left"
                                        onClick={this.previousDay}
                                    >
                                        <LeftOutlined />
                                        {this.props.isSmartphone ? null : "Jour précédent"}
                                    </Button>
                                    <DatePicker
                                        className="planning-week-datepicker"
                                        style={{
                                            backgroundColor:
                                                this.currentWeek === moment().week()
                                                    ? "#dfc2aa"
                                                    : undefined,
                                        }}
                                        picker="date"
                                        onChange={this.onChangeDay}
                                        format={"DD MMM YYYY"}
                                        placeholder={"Choisir un jour"}
                                        allowClear={false}
                                        value={moment(this.currentDay)}
                                    />
                                    <Button
                                        className="planning-week-button-right"
                                        onClick={this.nextDay}
                                    >
                                        {this.props.isSmartphone ? null : "Jour suivant"}
                                        <RightOutlined />
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        className="planning-week-button-left"
                                        onClick={this.previousWeek}
                                    >
                                        <LeftOutlined />
                                        {this.props.isSmartphone ? null : "Semaine précédente"}
                                    </Button>
                                    <DatePicker
                                        className="planning-week-datepicker"
                                        style={{
                                            backgroundColor:
                                                this.currentWeek === moment().week()
                                                    ? "#dfc2aa"
                                                    : undefined,
                                        }}
                                        picker="week"
                                        onChange={this.onChangeWeek}
                                        format={"wo, MMM YYYY"}
                                        placeholder={"Choisir une semaine"}
                                        allowClear={false}
                                        value={moment().week(this.currentWeek)}
                                    />
                                    <Button
                                        className="planning-week-button-right"
                                        onClick={this.nextWeek}
                                    >
                                        {this.props.isSmartphone ? null : "Semaine suivante"}
                                        <RightOutlined />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div>
                            {this.props.isSmartphone ? (
                                <SpeedDial
                                    title={"Actions"}
                                    icon={<MoreOutlined />}
                                    openIcon={<DashOutlined />}
                                    buttons={headerButtons}
                                />
                            ) : (
                                <div className="planning-header-buttons">
                                    {headerButtons}
                                </div>
                            )}
                        </div>
                    </div>
                    {
                        // this.currentFilter !== CalendarDataType.Users && this.state.currentGroup &&
                        // <div className="planning-group-name">
                        //     <p style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/group-details?id=${this.state.currentGroup!.id!}`)}>{this.state.currentGroup.name}</p>
                        // </div>
                    }

                    <Alert
                        style={{ marginBottom: 10 }}
                        type="info"
                        message="L'affichage des disponibilités n'est pas accessible dans cette présentation."
                    />
                    <Row
                        gutter={[10, 10]}
                        style={{
                            height: "calc(100vh - 295px)",
                            position: "relative",
                            width: "100%",
                        }}
                    >
                        <Drawer
                            className="__drawer_border_top_radius1"
                            // To delete (correction of bug , but shadown top not correct)
                            // contentWrapperStyle={{ marginRight: '5px' }}
                            // maskStyle={{ width: 'calc(100% - 10px', marginLeft: '5px'}}
                            // width={'calc(100% - 10px)'}
                            // End To delete (correction of bug , but shadown top not correct)
                            width={"100%"}
                            // END ELSE
                            // destroyOnClose={true}
                            bodyStyle={{ backgroundColor: "#e4e4e4" }}
                            title={
                                <>
                                    <Header style={{ top: 6.5 }} icon={<FilterOutlined />} />{" "}
                                    <span style={{ marginLeft: "45px" }}>
                                        Filtres {">"} Choisissez un point d'intérêt
                                    </span>
                                </>
                            }
                            placement="right"
                            onClose={() => this.setState({ selectPoiOnMap: false })}
                            open={this.state.selectPoiOnMap}
                            afterOpenChange={() =>
                                this.state.filterMapVisible === false && this.setState({ filterMapVisible: true })
                            }
                        // getContainer={false}
                        // style={{ position: 'absolute' }}
                        >
                            <div className="__filter-map-content1">
                                {this.state.filterMapVisible ? (
                                    <MapLeaflet
                                        key={`filter-map-${this.state.filterMapVisible}`}
                                        pois={pois?.data}
                                        sectors={sectors}
                                        courses={courses}
                                        isEditMode={false}
                                        onClick={(pid) =>
                                            this.setState({
                                                selectPoiOnMap: false,
                                                currentPois: this.props.pois?.data?.filter(
                                                    (p) => p.id === pid
                                                ),
                                            })
                                        }
                                    />
                                ) : (
                                    <Spin size="large" style={{ marginTop: "20px" }} />
                                )}
                            </div>
                        </Drawer>

                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={!displayFilter ? { span: 24 } : { span: 16 }}
                            lg={!displayFilter ? { span: 24 } : { span: 18 }}
                            xl={!displayFilter ? { span: 24 } : { span: 18 }}
                            xxl={!displayFilter ? { span: 24 } : { span: 19 }}
                            style={{ overflow: "hidden", maxHeight: "100%" }}
                        >
                            {this.state.calendarData !== undefined ? (
                                <Calendar
                                    currentProjects={this.state.currentProjects}
                                    calendarData={this.state.calendarData}
                                    displayDayView={this.displayDayView}
                                    onClickCell={this.onClickCell}
                                    onDropCell={this.onDropCell}
                                    onAddOvertime={this.onAddOvertime}
                                    onAddUndertime={this.onAddUndertime}
                                />
                            ) : this.props.loading ? (
                                <div
                                    className="horizontal-center"
                                    style={{ marginTop: "50px" }}
                                >
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        position: "absolute",
                                        right: "155px",
                                        top: "-20px",
                                    }}
                                >
                                    <ArrowUpOutlined className="planning-no-user-arrow" />
                                    <TeamOutlined className="planning-no-user-add" />
                                </div>
                            )}
                        </Col>
                        <Col
                            xs={{ span: 0 }}
                            sm={{ span: 0 }}
                            md={displayFilter ? { span: 8 } : { span: 0 }}
                            lg={displayFilter ? { span: 6 } : { span: 0 }}
                            xl={displayFilter ? { span: 6 } : { span: 0 }}
                            xxl={displayFilter ? { span: 5 } : { span: 0 }}
                            style={{ maxHeight: "100%" }}
                        >
                            {this.state.displayOccupancyRate ? (
                                <OccupancyRatesControl
                                    refresh={this.state.refreshOccupancyRateFilter}
                                    // refreshOff={() =>
                                    //     this.setState({ refreshOccupancyRateFilter: false })
                                    // }
                                    startDate={
                                        this.displayDayView
                                            ? moment(this.currentDay)
                                            : moment(
                                                moment()
                                                    .week(this.currentWeek)
                                                    .startOf("week")
                                                    .format(MOMENT_FORMAT)
                                            )
                                    }
                                    endDate={
                                        this.displayDayView
                                            ? moment(this.currentDay)
                                            : moment(
                                                moment()
                                                    .week(this.currentWeek)
                                                    .endOf("week")
                                                    .format(MOMENT_FORMAT)
                                            )
                                    }
                                    createNewEvent={this.createNewEvent}
                                    filter={true}
                                />
                            ) : (
                                <Card
                                    icon={<FilterOutlined />}
                                    title="Filtres"
                                    containerClassName="dashboard-scrollable-hvar"
                                >
                                    {this.renderFilter()}
                                </Card>
                            )}
                        </Col>
                    </Row>

                    {this.state.editEvent && (
                        <CreateEditModal
                            currentEvent={this.state.editEvent}
                            onEdit={this.onEditEvent}
                            onCreate={this.onCreateEvent}
                            loading={this.props.loading}
                            occurenceOnly={this.state.occurenceOnly}
                            onCancel={() =>
                                this.setState({
                                    detailsEvent: undefined,
                                    editEvent: undefined,
                                    occurenceOnly: false,
                                })
                            }
                            openSettings={() =>
                                this.setState({ showSettings: true, settingDefaultStep: 1 })
                            }
                        />
                    )}
                    {this.state.detailsEvent && (
                        <EventDetailsModal
                            onDeleteOvertime={this.onDeleteOvertime}
                            event={this.state.detailsEvent}
                            user={this.state.detailsUser}
                            group={this.state.detailsGroup}
                            onCancel={() =>
                                this.setState({
                                    detailsEvent: undefined,
                                    detailsUser: undefined,
                                    detailsGroup: undefined,
                                })
                            }
                            onEdit={this.onClickEditEvent}
                            onDelete={this.onDeleteEvent}
                            onCreateTemplate={this.refreshTemplates}
                        />
                    )}
                    {this.state.showSettings && (
                        <SettingsModal
                            visible={this.state.showSettings}
                            onCancel={() =>
                                this.setState({
                                    showSettings: false,
                                    settingDefaultStep: undefined,
                                })
                            }
                            onUpdate={this.onUpdateSettings}
                            onUpdateTemplate={(
                                editTemplate: PlanningTemplate,
                                isEditTemplate: boolean
                            ) => this.setState({ editTemplate, isEditTemplate })}
                            defaultStep={this.state.settingDefaultStep}
                        />
                    )}
                    {/* {
                                this.state.editTemplate &&
                                <TemplateModal
                                    visible={Boolean(this.state.editTemplate)}
                                    template={this.state.editTemplate}
                                    isEdit={this.state.isEditTemplate}
                                    onClose={() => this.setState({ editTemplate: undefined, isEditTemplate: false })} />
                            } */}
                    {this.state.editOvertime && (
                        <OvertimeModal
                            overtime={this.state.editOvertime}
                            onDone={this.onUpdateOvertime}
                            loading={this.props.loading}
                            onCancel={() => this.setState({ editOvertime: undefined })}
                        />
                    )}
                    {this.state.editUndertime && (
                        <UndertimeModal
                            undertime={this.state.editUndertime}
                            onDone={this.onUpdateUndertime}
                            loading={this.props.loading}
                            onCancel={() => this.setState({ editUndertime: undefined })}
                        />
                    )}

                </Container>
            </Can>
        );
    }

    templateColumnsActions = (record: PlanningTemplate) => {
        return [
            <DeleteButton
                text={"Voulez-vous supprimer ce modèle?"}
                key={`template-delete-${record.id}`}
                onConfirm={() => this.onDeleteTemplate(record)}
                placement="left"
            //buttonPlacement="right"
            />,
            <CircleButton
                key={`template-edit-${record.id}`}
                className="__card-button-space"
                icon={<EditOutlined />}
                title={"Modifier"}
                placement="left"
                onClick={() =>
                    this.setState({
                        isDrawerTemplate: true,
                        editTemplate: record,
                        isEditTemplate: false,
                    })
                }
            />,
        ];
    };

    templateColumns: ColumnProps<PlanningTemplate>[] = [
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) =>
                !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,
        },
        {
            title: "Type de jour",
            dataIndex: "typeOfDay",
            key: "typeOfDay",
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) =>
                !a.typeOfDay
                    ? -1
                    : !b.typeOfDay
                        ? 1
                        : a.typeOfDay < b.typeOfDay
                            ? -1
                            : 1,
        },
        {
            title: "Type de repos",
            dataIndex: "typeOfDayOff",
            key: "typeOfDayOff",
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) =>
                !a.typeOfDayOff
                    ? -1
                    : !b.typeOfDayOff
                        ? 1
                        : a.typeOfDayOff < b.typeOfDayOff
                            ? -1
                            : 1,
        },
        {
            title: "Point d'intérêt",
            dataIndex: "poi",
            key: "poi",
            render: (poi: POI) => poi?.title,
        },
        {
            title: "Aptitudes",
            dataIndex: "staffType",
            key: "staffType",
            render: (staffType: StaffType) => {
                if (staffType) {
                    return (
                        <Tag className="__report-groups-users-tags" color="#f5f5f5">
                            {staffType.name}
                        </Tag>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: "120px",
            className: "__poi-actions",
            render: (record: PlanningTemplate) => {
                return this.props.isSmartphone ? (
                    <SpeedDial
                        key="poi-actions-speeddial"
                        title={"Actions"}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        buttons={this.templateColumnsActions(record)}
                    />
                ) : (
                    this.templateColumnsActions(record)
                );
            },
        },
    ];

    templateColumnsMobile: ColumnProps<PlanningTemplate>[] = [
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            // onFilter: (value, record) => {
            //     return record.title!
            //         .toString()
            //         .toLowerCase()
            //         .includes(value.toString().toLowerCase())
            // },
            // ...tableColumnTextFilterConfig<POI>(),
            // sorter: (a: Template, b: Template) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: "Actions",
            key: "actions",
            width: "90px",
            className: "__poi-actions",
            render: (record: PlanningTemplate) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SpeedDial
                            key="poi-actions-speeddial"
                            title={"Actions"}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            buttons={this.templateColumnsActions(record)}
                        />
                    </div>
                );
            },
        },
    ];
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeProject: (p: Project[]) => dispatch(changeProject(p)),
    toggleDisplayWorkingTime: () => dispatch(toggleDisplayWorkingTime()),
    changeCellsPerRow: (c: number) => dispatch(changeCellsPerRow(c)),
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),
    setEventMethods: (
        c: (pe: PlanningEvent) => void,
        e: (pe: PlanningEvent) => void,
        d: (pe: PlanningEvent) => void,
        eO: (o: PlanningOvertime) => void,
        dO: (o: PlanningOvertime) => void
    ) => dispatch(setEventMethods(c, e, d, eO, dO)),
    reset: () => dispatch(reset()),
    changeCourses: (courses: Course[]) => dispatch(changeCourses(courses)),
    changeSectors: (sectors: Sector[]) => dispatch(changeSectors(sectors)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    project: state.configurations.project,
    isSmartphone: state.window.isSmartphone,
    displayWorkingTime: state.planning.displayWorkingTime,
    settings: state.planning.settings,
    loading: state.user.loading,
    templates: state.planning.templates,
    courses: state.location.courses,
    sectors: state.location.sectors,
    pois: state.location.pois,
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Planning);
