import { PlusOutlined, ReadOutlined, RocketOutlined } from '@ant-design/icons';
import { Col, DatePicker, Empty, List, Row } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeUser } from '../../../store/actions/teamManagement';
import { MOMENT_FORMAT } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Company, TypeOfVacations, User, UserJobTMP } from '../../../utils/types/generalTypes';
import { NetworkUserVacations, UserVacationsBodyRequest, UserVacationsSummary } from '../../../utils/types/networkTypes';
import { DaysOffByType, PlanningUserExtraVacations, PlanningUserVacations, TypeOfDayOff } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { alert, convertNetworkUserVacationsToPlanningUserVacations, displayErrorMessage } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';
import DaysOffByTypeItem from './daysOffByTypeItem';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

interface IProps {
    userId: number;
    user: User | undefined;
    company: Company | undefined;
}

type Props = IProps & TeamManagementDispatchProps & IntlProps;

interface State {
    year: number;
    yearlyVacationDays: number;
    typeOfVacations: TypeOfVacations[];
    typesOfDaysOff: DaysOffByType[];
    vacations: PlanningUserVacations[];
    extraVacations: PlanningUserExtraVacations[];
    editVacations: PlanningUserVacations | undefined;
    editExtraVacations: PlanningUserExtraVacations | undefined;
    vacationsLoading: boolean;
    extraVacationsLoading: boolean;
    summary: UserVacationsSummary | undefined;
    addDaysOffByType: DaysOffByType | undefined;
    currentUserJob?: UserJobTMP;
    daysOffNotUsed?: TypeOfDayOff[];
    displayAddDaysOffByType: boolean;
}

/**
 * Component that represent the user vacations tab
 */
class UserVacationsTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            yearlyVacationDays: (this.props.user && this.props.user.job && this.props.user.job[0] && this.props.user.job[0].yearly_vacation_days) ? this.props.user.job[0].yearly_vacation_days : 0,
            typeOfVacations: [],
            typesOfDaysOff: [],
            vacations: [],
            extraVacations: [],
            editVacations: undefined,
            editExtraVacations: undefined,
            vacationsLoading: false,
            extraVacationsLoading: false,
            summary: undefined,
            addDaysOffByType: undefined,
            currentUserJob: undefined,
            daysOffNotUsed: undefined,
            displayAddDaysOffByType: false,
        };
    }

    componentDidMount() {
        let { currentUserJob } = this.state;

        this.props.user?.job?.forEach((e: UserJobTMP) => {
            if (e.is_current === true) currentUserJob = e;
        });

        this.setState({ currentUserJob }, () => {
            this.refreshTypesOfDaysOffNotUsed();
        });

        this.refreshTypesOfDaysOff();

        // get user's vacations
        this.refreshVacations();

        // get summary
        this.refreshSummary();
    }

    componentDidUpdate(prevProps: Props) {
        // Update yearlyVacationDays
        if (prevProps.user !== this.props.user) {
            // if(this.props.user?.job && this.props.user?.job.length >= 1 && this.props.user.job[0].yearly_vacation_days) {
            //     this.setState({ yearlyVacationDays: this.props.user.job[0].yearly_vacation_days });
            // }else {
            //     this.setState({ yearlyVacationDays: 0 });
            // }
            let { currentUserJob } = this.state;

            this.props.user?.job?.forEach((e: UserJobTMP) => {
                if (e.is_current === true) currentUserJob = e;
            });

            this.setState({ currentUserJob }, () => {
                this.refreshTypesOfDaysOffNotUsed();
            });

            this.refreshSummary();
        }
    }

    refreshTypesOfDaysOffNotUsed = (): void => {
        Network.getTypeOfDayOff(undefined, this.props.userId, this.state.year).then(
            response => {
                this.setState({ daysOffNotUsed: response }, () => {
                    let { displayAddDaysOffByType } = this.state;
                    this.state.daysOffNotUsed && this.state.daysOffNotUsed.length > 0 ? displayAddDaysOffByType = true : displayAddDaysOffByType = false;
                    this.setState({ displayAddDaysOffByType });
                });
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations' }), "warning"),
        );
    };

    /**
     * Refresh the user's vacations
     * @param message a message to display as a success message - optional
     */
    refreshVacations = (message?: string): void => {
        this.setState({ vacationsLoading: true });
        Network.getUserVacations(this.getStartOfYear(), this.getEndOfYear(), this.props.userId).then(
            (response: NetworkUserVacations[]) => {
                this.setState({ vacations: response.map(v => convertNetworkUserVacationsToPlanningUserVacations(v)), editVacations: undefined });
                if (message) alert(message, "success");
                this.setState({ vacationsLoading: false });
            },
            () => {
                this.setState({ vacationsLoading: false });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations' }), "warning");
            },
        );
    };

    refreshTypesOfDaysOff = (): void => {
        Network.getUserTypesOfDays(this.state.year, this.props.userId).then(
            response => {
                this.setState({ typesOfDaysOff: response, addDaysOffByType: undefined });
                this.refreshTypesOfDaysOffNotUsed();
                this.refreshSummary();
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations summary' }), "warning"),
        );
    };

    /**
     * Refresh the user's vacations summary
     */
    refreshSummary = (): void => {
        Network.getUserVacationsSummary(this.state.year, this.props.userId).then(
            response => { this.setState({ summary: response }); },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the vacations summary' }), "warning"),
        );
    };

    /**
     * Get start of year date string
     * @returns a string containing the start of year
     */
    getStartOfYear = () => moment().year(this.state.year).startOf("year").format(MOMENT_FORMAT);

    /**
     * Get end of year date string
     * @returns a string containing the end of year
     */
    getEndOfYear = () => moment().year(this.state.year).endOf("year").format(MOMENT_FORMAT);

    /**
     * Check if the add type of day button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddTypeOfDayOff = (): boolean => this.state.addDaysOffByType === undefined && this.state.displayAddDaysOffByType;

    /**
     * Change the current year
     * @param date the moment date 
     */
    changeYear = (date: Moment | null | undefined) => {
        if (!date) return;
        const year = date.year();
        this.setState({ year, addDaysOffByType: undefined }, () => {
            this.refreshVacations();
            this.refreshSummary();
            this.refreshTypesOfDaysOff();
            this.refreshTypesOfDaysOffNotUsed();
        });
    };


    /**
     * Create or edit a user's vacations
     * @param vacations the vacations to update
     */
    updateVacations = (vacations: PlanningUserVacations): void => {
        const body: UserVacationsBodyRequest = {
            id: vacations.id,
            title: vacations.title,
            startDate: vacations.startDate.format(MOMENT_FORMAT),
            endDate: vacations.endDate.format(MOMENT_FORMAT),
            userId: vacations.user.id,
            typeOfVacationsId: vacations.typeOfVacations ? vacations.typeOfVacations.id : undefined,
        };

        Network.updateUserVacations(body).then(
            () => {
                this.refreshVacations(this.props.intl.formatMessage({ defaultMessage: 'The vacations have been successfully updated' }));
                this.refreshSummary();
            },
            error => {
                if (error.message === "Not enough remaining vacation days.") displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: "The user doesn't have enough vacation days left" }), "team-user-vacations-modal-ok-button");
                else alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the vacations' }), "warning");
            }
        );
    };

    /**
     * Render a user's vacations (a list item)
     * @param vacations the item to render
     * @returns the component to render
     */
    renderVacations = (vacations: PlanningUserVacations): React.ReactNode => {
        return (
            <List.Item
            >
                <div className="team-periods-list-item">
                    <p>{vacations.typeOfVacations?.title}</p>
                    <p>{vacations.startDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR_AND_TIME'))}</p>
                    <p>{vacations.endDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR_AND_TIME'))}</p>
                </div>
            </List.Item>
        );
    };

    renderTypesOfDaysOff = (daysOffByType: DaysOffByType): React.ReactNode => {
        return <DaysOffByTypeItem daysOffNotUsed={this.state.daysOffNotUsed} stopAddTypeOfDayOff={this.stopAddTypeOfDayOff} year={this.state.year} daysOffByType={daysOffByType} refreshTypesOfDaysOff={this.refreshTypesOfDaysOff} />;
    };

    stopAddTypeOfDayOff = () => {
        this.setState({ addDaysOffByType: undefined });
    };

    addTypeOfDayOff = () => {
        const addDaysOffByType: DaysOffByType = {
            user: {
                id: this.props.userId,
            },
            year: this.state.year,
        };

        this.setState({ addDaysOffByType }, () => {
            const objDiv = document.getElementById("addTypeOfDayOff");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        });
    };

    /**
     * Render days
     * @param days the days to render
     * @returns the string with the rendered days
     */
    renderDays = (days: number) => days !== undefined ? <FormattedMessage defaultMessage={'{count, plural, one {1 day} other {{days} days}}'} values={{ count: days, days: (Math.round(days * 10) / 10) }} /> : ' - ';

    render() {
        const { currentUserJob, addDaysOffByType, summary } = this.state;
        const { intl } = this.props;

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <div className="team-user-vacations-header">
                        <p><FormattedMessage defaultMessage={'Current contract : {contract}'} values={{ contract: (currentUserJob && currentUserJob.name) ? currentUserJob.name : intl.formatMessage({ defaultMessage: 'Contract without name' }) }} /></p>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <div className="team-user-vacations-header">
                        <p><FormattedMessage defaultMessage={'Contractual vacations : {days}'} values={{ days: (currentUserJob && currentUserJob.yearly_vacation_days) ? currentUserJob.yearly_vacation_days : '-' }} /></p>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <div className="team-user-vacations-header">
                        <p><FormattedMessage defaultMessage={'Select a year'} /></p>
                        <DatePicker
                            picker="year"
                            placeholder={intl.formatMessage({ defaultMessage: 'Year' })}
                            value={moment().year(this.state.year)}
                            onChange={this.changeYear}
                            allowClear={false} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card
                        title={<FormattedMessage defaultMessage={'Vacation settings {year}'} values={{ year: this.state.year }} />}
                        icon={<ReadOutlined />}
                        headerElements={[
                            <CircleButton
                                disabled={!this.showAddTypeOfDayOff()}
                                key="type_of_day_add_button"
                                style={{ marginRight: '8px' }}
                                title={intl.formatMessage({ defaultMessage: 'Add a day type' })}
                                onClick={this.addTypeOfDayOff}
                                icon={<PlusOutlined />}
                                placement="right" />
                        ]}>

                        <div className="content-card-max-height" id="addTypeOfDayOff">
                            {
                                (this.state.typesOfDaysOff.length > 0 || addDaysOffByType) &&
                                <div style={{ marginBottom: '15px' }} className="team-periods-list-item">
                                    <p style={{ fontSize: '102%', fontWeight: 'bold', width: '35%' }}><FormattedMessage defaultMessage={'Types of day off'} /></p>
                                    <p style={{ fontSize: '102%', fontWeight: 'bold', width: '35%' }}><FormattedMessage defaultMessage={'Contract'} /></p>
                                    <p style={{ fontSize: '102%', fontWeight: 'bold', width: '20%' }}><FormattedMessage defaultMessage={'Initial days'} /></p>
                                    <p style={{ fontSize: '102%', fontWeight: 'bold', width: '10%' }}><FormattedMessage defaultMessage={'Actions'} /></p>
                                </div>
                            }
                            {

                                (this.state.typesOfDaysOff.length > 0 || addDaysOffByType) ?
                                    <List dataSource={addDaysOffByType ? this.state.typesOfDaysOff.concat([addDaysOffByType]) : this.state.typesOfDaysOff} renderItem={this.renderTypesOfDaysOff} />
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No vacation'} />} />
                            }
                        </div>
                    </Card>
                </Col>
                {
                    this.props.company?.id === 70 ?
                        <Card className="team-user-vacations-card" title={<FormattedMessage defaultMessage={'Year {year} summary'} values={{ year: this.state.year }} />} icon={<ReadOutlined />}>
                            {
                                summary?.yearlyVacationDays !== undefined ?
                                    <div>
                                        {summary?.yearlyVacationDays !== undefined &&
                                            <div className="flex-row" style={{ lineHeight: '40px' }}>
                                                <p><FormattedMessage defaultMessage={'Vacation days'} /></p>
                                                <p>{this.renderDays(summary.yearlyVacationDays)}</p>
                                            </div>
                                        }
                                        {summary?.takenVacationDays !== undefined &&
                                            <div className="flex-row" style={{ lineHeight: '40px' }}>
                                                <p><FormattedMessage defaultMessage={'Planned vacations'} /></p>
                                                <p>{this.renderDays(summary.takenVacationDays)}</p>
                                            </div>
                                        }
                                        {summary?.remainingVacationDays !== undefined &&
                                            <div className="flex-row" style={{ lineHeight: '40px', fontWeight: 'bold' }}>
                                                <p><FormattedMessage defaultMessage={'Remaining contractual vacations'} /></p>
                                                <p>{this.renderDays(summary.remainingVacationDays)}</p>
                                            </div>
                                        }
                                        <div className="divider" style={{ margin: '10px 0px' }} />
                                        {summary?.extraVacationDays !== undefined &&
                                            <div className="flex-row" style={{ lineHeight: '40px' }}>
                                                <p><FormattedMessage defaultMessage={'Other vacations'} /></p>
                                                <p>{this.renderDays(summary.extraVacationDays)}</p>
                                            </div>
                                        }
                                        {summary?.totalVacationDays !== undefined &&
                                            <div className="flex-row" style={{ lineHeight: '40px' }}>
                                                <p><FormattedMessage defaultMessage={'Total vacations'} /></p>
                                                <p>{this.renderDays(summary.totalVacationDays)}</p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <p><FormattedMessage defaultMessage={'Please first define a number of vacation days per year for this user'} /></p>
                            }
                        </Card>
                        : null
                }
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Vacations planned for {year}'} values={{ year: this.state.year }} />} icon={<RocketOutlined />}>
                        <div className="content-card-max-height">
                            {
                                this.state.vacations.length > 0 ?
                                    <List dataSource={this.state.vacations.sort((a, b) => {
                                        const dateA = moment(a.startDate);
                                        const dateB = moment(b.startDate);
                                        return dateA.diff(dateB);
                                    })} renderItem={this.renderVacations} />
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No vacation'} />} />
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
});


const mapStateToProps = (state: ApplicationState) => ({
    user: state.teamManagement.user,
    company: state.user.company,
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserVacationsTab));