import { Button, Card, Col, Divider, Empty, message, Popconfirm, Popover, Row, Space, Spin, Tooltip } from "antd";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import getFormat from "../../../../utils/Lang";
import Network from "../../../../utils/network";
import { IUserExportHoursHotelaUserData } from "../../../../utils/types/networkTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { fixedDecimal, showNotification } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import CircleButton from "../../../common/fields/circleButton";

interface Props {
    selectedDate: Moment;
    selectedUserId: number;
    loading: boolean;
    userIds: number[];
    waitAggregated: boolean;
    waitTimeclock: boolean;
    setLoading: (loading: boolean) => void;
    loadUsers: () => void;
    reset: () => void;
}

const HotelaExport = (props: Props) => {
    const [userData, setUserData] = useState<IUserExportHoursHotelaUserData | undefined>(undefined);
    const [generateLoading, setGenerateLoading] = useState(false);
    const [isAuxiliary, setIsAuxiliary] = useState(false);
    const [monthId, setMonthId] = useState(-1);

    const intl = useIntl();
    const width = useSelector((state: ApplicationState) => state.window.width);

    const selectedUseIdRef = useRef(props.selectedUserId);

    const generatePreview = useCallback(() => {
        selectedUseIdRef.current = props.selectedUserId;
        const year = parseInt(props.selectedDate.format('YYYY'));
        const month = parseInt(props.selectedDate.format('MM'));
        if (props.selectedUserId === -1) {
            showNotification(intl.formatMessage({ defaultMessage: 'Please select an user' }), 'warn');
            return;
        }

        setGenerateLoading(true);
        Network.getHotelaUserData(year, month, props.selectedUserId).then(
            (response) => {
                setUserData(response.data.userData);
                setIsAuxiliary(response.data.isAuxiliary);
                setMonthId(response.data.userData.id);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the preview' }), 'error');
            }
        ).finally(() => setGenerateLoading(false));
    }, [intl, props.selectedDate, props.selectedUserId]);

    const cancelValidation = useCallback(() => {
        props.setLoading(true);
        Network.cancelHotelaUserData(monthId).then(
            () => {
                message.destroy('confirm-hotela-validation');
                message.success({
                    content: intl.formatMessage({ defaultMessage: 'Data sending canceled' }),
                    duration: 2,
                });
                props.loadUsers();
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), 'error');
            }
        ).finally(() => props.setLoading(false));
    }, [intl, monthId, props]);

    const validateData = useCallback(() => {
        setGenerateLoading(true);
        props.setLoading(true);
        Network.validateHotelaUserData(monthId).then(
            () => {
                message.success({
                    content: <>{intl.formatMessage({ defaultMessage: 'Sending data to Hotela...' })} <Button onClick={cancelValidation} type='link'>{intl.formatMessage({ defaultMessage: 'Cancel' })}</Button></>,
                    duration: 5,
                    key: 'confirm-hotela-validation',
                    className: 'hotela-confirm-message'
                });
                setUserData(undefined);
                props.reset();
            },
            (error) => {
                const json = JSON.parse(error.message);

                switch (json.code) {
                    case 'integrations-still-waiting-aggregated-confirmation-603':
                        showNotification(intl.formatMessage({ defaultMessage: 'Cannot validate the hours' }), 'error', intl.formatMessage({ defaultMessage: 'All events are not validated in the reports yet' }));
                        break;
                    default:
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), 'error');
                }
            }
        ).finally(() => { setGenerateLoading(false); props.setLoading(false); });
    }, [intl, cancelValidation, monthId, props]);

    useEffect(() => {
        setUserData(undefined);
        if (props.selectedUserId !== -1 && props.selectedUserId !== selectedUseIdRef.current)
            generatePreview();
    }, [generatePreview, props.selectedUserId]);

    useEffect(() => {
        if (props.selectedUserId === -1)
            selectedUseIdRef.current = -1;
        setUserData(undefined);
        setIsAuxiliary(false);
    }, [props.selectedUserId, props.userIds]);

    useEffect(() => {
        return () => {
            message.destroy('confirm-hotela-validation');
        };
    }, []);

    return (
        <Card
            size='small'
            title={
                <Space>
                    <FormattedMessage defaultMessage={'Data preview'} />
                    <Divider type='vertical' />
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='rotate' />}
                        onClick={generatePreview}
                        loading={generateLoading}
                        disabled={userData === undefined}
                    />
                    {
                        isAuxiliary &&
                        <Tooltip
                            title={<FormattedMessage defaultMessage={'This user is an auxiliary employee and therefor exporting his data may break on Hotela.'} />}
                        >
                            <FAIcon name='triangle-exclamation' prefix='far' color='red' style={{ marginLeft: 10 }} />
                        </Tooltip>
                    }
                </Space>
            }
            extra={[
                <Space key='export-hours-filter' >
                    {
                        props.waitAggregated || props.waitTimeclock ?
                            <Popover
                                title={<FormattedMessage defaultMessage={'Unable to validate'} />}
                                content={
                                    <>
                                        {
                                            props.waitAggregated &&
                                            <FormattedMessage defaultMessage={'Awaiting hours validations in reports'} />
                                        }
                                        {
                                            props.waitTimeclock &&
                                            <FormattedMessage defaultMessage={'Awaiting timeclock validation'} />
                                        }
                                    </>
                                }
                                placement='left'
                            >
                                <FAIcon name='circle-info' prefix='far' />
                            </Popover>
                            :
                            undefined
                    }
                    <Popconfirm
                        title={
                            <>
                                <p><strong><FormattedMessage defaultMessage={'Hours validation'} /></strong></p>
                                <br />
                                <p><FormattedMessage defaultMessage={"This action will validate and send the data to Hotela. Te user's planning will be blocked for the selected month."} /></p>
                                <br />
                                <p><FormattedMessage defaultMessage={'Do you want to continue?'} /></p>
                            </>
                        }
                        onConfirm={() => validateData()}
                        okText={<FormattedMessage defaultMessage={'Validate and send'} />}
                        disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                        placement='left'
                    >
                        {
                            width > 620 ?
                                <Button
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                >
                                    <FormattedMessage defaultMessage={'Validate and send to Hotela'} />
                                </Button>
                                :
                                <CircleButton
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                    small
                                    icon={<FAIcon name='check' prefix='far' />}
                                />
                        }

                    </Popconfirm>
                </Space>
            ]}
        >
            <Spin spinning={generateLoading}>
                {
                    userData === undefined ?
                        <Empty />
                        :
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <Card size='small' title={<strong><FormattedMessage defaultMessage={'Days'} /></strong>} className='integration-data-container'>
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Days off taken'} /><span>{fixedDecimal(userData.dayOff)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Public holidays taken'} /><span>{fixedDecimal(userData.publicDays)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Vacation days taken'} /><span>{fixedDecimal(userData.vacation)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Unpaid days'} /><span>{fixedDecimal(userData.unpaidDays)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid vacations'} /><span>{fixedDecimal(userData.paidDayOff)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid holidays'} /><span>{fixedDecimal(userData.paidPublicDays)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Paid vacation days'} /><span>{fixedDecimal(userData.paidVacation)}</span></div>
                                            <hr />
                                            <div className='integration-data-item'><FormattedMessage defaultMessage={'Military/CP days'} /><span>{fixedDecimal(userData.militaryDays)}</span></div>
                                            <hr />
                                            <br />
                                            <strong><div className='integration-data-item'><FormattedMessage defaultMessage={'Days to pay'} /><span>{fixedDecimal(userData.dayCount)}</span></div></strong>
                                        </Card>
                                    </Col>
                                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 24 }}>
                                                <Card size='small' title={<strong><FormattedMessage defaultMessage={'Hours'} /></strong>} className='integration-data-container'>
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Regular overtime'} /><span>{fixedDecimal(userData.regularOvertime)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Iregular overtime'} /><span>{fixedDecimal(userData.irregularOvertime)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Night work (10%)'} /><span>{fixedDecimal(userData.nightBonus10)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Night work (25%)'} /><span>{fixedDecimal(userData.nightBonus25)}</span></div>
                                                    <hr />
                                                    <br />
                                                    <strong><div className='integration-data-item'><FormattedMessage defaultMessage={'Hours to pay'} /><span>{fixedDecimal(userData.hourCount)}</span></div></strong>
                                                </Card>
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <Card size='small' title={<strong><FormattedMessage defaultMessage={'Balances'} /></strong>} className='integration-data-container'>
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Days off balance'} /><span>{fixedDecimal(userData.extraDaysBalance)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Vacation balance'} /><span>{fixedDecimal(userData.extraHolidaysDaysBalance)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Holiday balance'} /><span>{fixedDecimal(userData.extraPublicDaysBalance)}</span></div>
                                                    <hr />
                                                    <div className='integration-data-item'><FormattedMessage defaultMessage={'Overtime balance'} /><span>{fixedDecimal(userData.extraHoursBalance)}</span></div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Card size='small' title={<FormattedMessage defaultMessage={'Periods'} />}>
                                            {
                                                userData.periods.length > 0 ?

                                                    userData.periods.map(period => {
                                                        return (
                                                            <div className='integration-data-periods-container' key={`period-${period.id}`}>
                                                                <div style={{ width: '100px', display: 'inline-block' }}>
                                                                    {
                                                                        period.periodType === 'accident' ?
                                                                            <FormattedMessage defaultMessage={'Accident'} />
                                                                            :
                                                                            period.periodType === 'illness' ?
                                                                                <FormattedMessage defaultMessage={'Illness'} />
                                                                                :
                                                                                period.periodType === 'maternity' ?
                                                                                    <FormattedMessage defaultMessage={'Maternity'} />
                                                                                    :
                                                                                    <FormattedMessage defaultMessage={'Absent'} />
                                                                    }
                                                                </div>

                                                                <FormattedMessage defaultMessage={'From {start} to {end}'} values={{ start: moment(period.startDate, 'YYYY-MM-DD').format(getFormat('DATE')), end: moment(period.endDate, 'YYYY-MM-DD').format(getFormat('DATE')) }} />
                                                                <span><FormattedMessage defaultMessage={'At {rate}% rate'} values={{ rate: period.rate }} /></span>
                                                                <span><FormattedMessage defaultMessage={'{count, plural, one {1 day} other {{count} days}}'} values={{ count: moment(period.endDate, 'YYYY-MM-DD').diff(moment(period.startDate, 'YYYY-MM-DD'), 'days') + 1 }} /></span>
                                                            </div>
                                                        );
                                                    })
                                                    :
                                                    <p style={{ fontStyle: 'italic', color: '#8c8c8c' }}><FormattedMessage defaultMessage={'No period present for the selected month'} /></p>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                }
            </Spin>
        </Card>
    );
};

export default HotelaExport;