import React, { CSSProperties } from 'react';

interface Props {
    children: React.ReactNode;
    legend: React.ReactNode;
    style?: CSSProperties;
    legendStyle?: CSSProperties;
}

interface State { }

class Fieldset extends React.Component<Props, State> {
    render() {
        const { style, children, legend, legendStyle } = this.props;
        return (
            <fieldset style={{ borderRadius: 'var(--border-radius)', border: 'var(--border)', padding: '18px 15px 15px 15px', ...style }}>
                <legend style={{ margin: 'auto', width: 'auto', float: 'none', padding: '1px 10px', display: 'flex', gap: '10px', alignItems: 'center', ...legendStyle }}>{legend}</legend>
                {children}
            </fieldset>
        );
    }
}

export default Fieldset;