import { EyeOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Menu, Spin, Tabs } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { Privileges } from '../../privileges';
import { changeSMSAccount, changeSMSAccountLoading } from '../../store/actions/messages';
import Network from '../../utils/network';
import { FullSMSAccount, Message, MessageCardContent, RouterProps, User, UserSummary } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, checkPrivilege, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import CircleButton from '../common/fields/circleButton';
import Skeleton from '../common/general/skeleton';
import Container from '../common/navigations/container';
import MessageCard from './messageCard';
import SMS from './tabs/sms';


enum TabKeys {
    List = "list",
    SMS = "sms",
}

//Links for the different tabs
enum TabLink {
    List = "list",
    SMS = "sms",
}
type ReduxProps = ConnectedProps<typeof connector>;


interface IProps {
    isSmartphone: boolean;
    userId?: number;
    currentUser: User | undefined;

}

type Props = IProps & RouterProps & ReduxProps & IntlProps;

interface State {
    currentPage: number;
    messages: Message[];
    loading: boolean;
    scrollElement: HTMLElement | null;
    hasMoreMessages: boolean;
    filter: Filters;
}

enum Filters {
    All, Confirmed, NotConfirmed, WithoutConfirmation
}

class MessagesList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: -1,
            messages: [],
            loading: false,
            scrollElement: null,
            hasMoreMessages: true,
            filter: Filters.All,
        };
    }

    componentDidMount() {
        //fetch messages
        this.getMessages();

        //display alert message if needed
        if (this.props.location && this.props.location.state && this.props.location.state.successMessage) {
            alert(this.props.location.state.successMessage, "success");
            this.props.history.replace({ state: undefined });
        }
        if (this.props.match.params.tab === "sms") {
            this.refreshSMS();
        }

    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.match.params.tab !== this.props.match.params.tab && this.props.match.params.tab === "sms") {
            this.refreshSMS();
        }
    }

    refreshSMS = () => {
        this.props.changeSMSAccountLoading(true);

        Network.getFullSMS().then(
            (response: FullSMSAccount) => {
                this.props.changeSMSAccount(response);
                this.props.changeSMSAccountLoading(false);
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the SMS' }), "warning");
                this.props.changeSMSAccountLoading(false);
            }
        );
    };

    /**
     * Get the next page messages
     */
    getMessages = () => {
        this.setState({ loading: true });

        let { currentPage, messages } = this.state;
        ++currentPage;
        //fetch messages
        Network.getMessages(currentPage).then(
            response => {
                messages = messages.concat(response);
                this.setState({ messages, currentPage, loading: false, hasMoreMessages: response.length > 0 });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the messages' }), "warning");
                this.setState({ loading: false, currentPage });
            }
        );
    };

    /**
     * Translate the user first_name and last_name for the NewsCard username props
     */
    getCreator = (user: UserSummary): string => {
        if (user.last_name && user.first_name) return `${user.last_name} ${user?.first_name}`;
        else if (user.last_name) return `${user.last_name}`;
        else if (user.first_name) return `${user.first_name}`;
        else return "???";
    };

    /**
     * Fired every time the user scroll
     * check if more messages must be loaded
     */
    onScroll = (): void => {
        //if there is no more message or it is loading, return
        if (!this.state.hasMoreMessages && !this.state.loading) {
            this.getMessages();
        }
    };


    /**
     * Get a message's destinator
     * @param message the message
     * @returns the destinator
     */
    getDestinator = (message: Message): string => {
        if (message.group) return message.group!.name;
        else if (message.user) return `${message.user!.last_name} ${message.user!.first_name}`;
        else return "Global";
    };

    /**
     * Remove a message from state
     * @param messageId the message' id of the new that has been deleted
     */
    removeMessage = (messageId: number) => {
        let { messages } = this.state;
        messages = messages.filter(m => m.id !== messageId);
        this.setState({ messages });
    };

    /**
     * Handle the suppression of a news
     * @param content the news content
     */
    handleDelete = (content: MessageCardContent) => {
        Network.deleteMessage(content.id).then(
            () => {
                //add a animation
                const parent = document.getElementById(`messagecard-${content.id}`)!;
                parent.className += " newsfeed-delete";
                setTimeout(() => this.removeMessage(content.id as number), 2000);
                alert(this.props.intl.formatMessage({ defaultMessage: 'The message has been successfully deleted' }), "success");
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the messages' }), "warning")
        );
    };

    /**
     * Filter the messages according to the choosen filters
     * @returns the visible messages
     */
    filterMessages = (): Message[] => {
        switch (this.state.filter) {
            case Filters.All:
                return this.state.messages;
            case Filters.Confirmed:
                return this.state.messages.filter(m => m.optional && m.user_task && m.user_task.length > 0);
            case Filters.NotConfirmed:
                return this.state.messages.filter(m => m.optional && m.user_task && m.user_task.length === 0);
            case Filters.WithoutConfirmation:
                return this.state.messages.filter(m => !m.optional);
        }
    };
    onChangeTab = (tab: string): void => {
        let link = "";
        switch (tab) {
            case TabKeys.SMS:
                link = TabLink.SMS;
                break;
            default:
                link = TabLink.List;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/messages/${link}`);
    };

    isUserAdminOrGroupAdmin = () => {
        const { currentUser } = this.props;
        return currentUser ? [2, 3].includes(currentUser?.role) || currentUser.group_users?.some(gu => gu.is_admin) : false;
    };

    render() {
        const { intl } = this.props;
        let actions: any[] = [];

        if (this.props.match.params.tab !== 'sms') {
            actions = [<Dropdown key={`messagesList-sms-dropdown`} trigger={['click']} placement="bottomLeft" overlay={
                <Menu>
                    <Menu.Item onClick={() => this.setState({ filter: Filters.All })} className={this.state.filter === Filters.All ? "bold-submenu-item" : undefined}>
                        <FormattedMessage defaultMessage={'Show all messages'} />
                    </Menu.Item>
                    <Menu.Item onClick={() => this.setState({ filter: Filters.Confirmed })} className={this.state.filter === Filters.Confirmed ? "bold-submenu-item" : undefined}>
                        <FormattedMessage defaultMessage={'Show confirmed messages'} />
                    </Menu.Item>
                    <Menu.Item onClick={() => this.setState({ filter: Filters.NotConfirmed })} className={this.state.filter === Filters.NotConfirmed ? "bold-submenu-item" : undefined}>
                        <FormattedMessage defaultMessage={'Show unconfirmed messages'} />
                    </Menu.Item>
                    <Menu.Item onClick={() => this.setState({ filter: Filters.WithoutConfirmation })} className={this.state.filter === Filters.WithoutConfirmation ? "bold-submenu-item" : undefined}>
                        <FormattedMessage defaultMessage={'Display messages without confirmation'} />
                    </Menu.Item>
                </Menu>
            }>
                <CircleButton icon={<EyeOutlined />} title={intl.formatMessage({ defaultMessage: 'Display' })} placement="bottom" />
            </Dropdown>];
        }

        if (this.props.isSmartphone) actions.unshift(<CircleButton onClick={() => this.props.history.push(`/${this.props.match.params.lang}/messages/create`)} title={intl.formatMessage({ defaultMessage: 'Send a message' })} icon={<PlusOutlined />} placement="bottomLeft" />);
        const sms_privilege = !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && checkPrivilege(Privileges.SMS.Visit, this.props.currentUser);
        return (
            <Container
                backTop
                breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Messaging' }), link: '/messages/list' }, { title: intl.formatMessage({ defaultMessage: 'List' }), link: '/messages/list' }]}
                actions={actions}>
                <Tabs animated onChange={this.onChangeTab} activeKey={this.props.match.params.tab ? this.props.match.params.tab : "list"} className={sms_privilege ? "messagelist-tab-parent" : "messagelist-tab-parent messagelist-tab-parent-hidden"}>
                    <Tabs.TabPane key="list" tab={
                        <span>
                            <InboxOutlined className='anticon' />
                            <FormattedMessage defaultMessage={'Messages'} />
                        </span>
                    }>
                        {
                            !this.props.isSmartphone && this.isUserAdminOrGroupAdmin() &&
                            <CircleButton
                                className="newslist-add-button"
                                onClick={() => this.props.history.push(`/${this.props.match.params.lang}/messages/create`)}
                                title={intl.formatMessage({ defaultMessage: 'Send a message' })}
                                icon={<PlusOutlined />}
                                placement="bottomLeft"
                            />
                        }
                        {
                            !this.state.loading && this.state.messages.length === 0 ?
                                <div className="newslist-empty">
                                    <Empty description={<FormattedMessage defaultMessage={'No message'} />} />
                                </div>
                                :
                                <div className="newslist-content">
                                    <div className="newslist-vertical-line" />
                                    <Skeleton className="newslist-skeleton" loading={this.state.loading} repeat={3} avatar title paragraph={{ rows: 3 }} />
                                    <InfiniteScroll
                                        initialLoad={false}
                                        pageStart={0}
                                        loadMore={this.onScroll}
                                        hasMore={this.state.hasMoreMessages}>
                                        {
                                            this.filterMessages().map((message: Message) => <MessageCard handleDelete={this.handleDelete} content={{
                                                ...message,
                                                creator: this.getCreator(message.creator),
                                                avatar: message.creator?.image,
                                                destinator: this.getDestinator(message),
                                                can_delete: message.creator.id === this.props.userId,
                                                with_confirmation: Boolean(message.optional),
                                                confirmed: message.user_task !== undefined && message.user_task?.length > 0,
                                                confirmationDate: message.user_task !== undefined && message.user_task?.length > 0 ? message.user_task[0].created : undefined,
                                            }} key={`message-card-${message.id}`} />)
                                        }
                                    </InfiniteScroll>
                                    {
                                        this.state.loading ?
                                            <Spin style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }} size="large" spinning={this.state.loading} />
                                            : null
                                    }
                                </div>
                        }
                    </Tabs.TabPane>
                    {sms_privilege &&

                        <Tabs.TabPane key="sms" tab={
                            <span>
                                <InboxOutlined className='anticon' />
                                <FormattedMessage defaultMessage={'SMS'} />
                            </span>
                        }   >
                            {!this.props.smsAccountLoading && this.props.smsAccount !== undefined ?
                                <SMS />
                                :
                                <Spin
                                    style={{ marginTop: '20px', textAlign: "center", display: "block" }}
                                    size="large"
                                    spinning={this.props.smsAccountLoading} />

                            }
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Container>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    userId: state.user.currentUser?.id,
    isSmartphone: state.window.isSmartphone,
    currentUser: state.user.currentUser,
    smsAccount: state.messages.smsAccount,
    smsAccountLoading: state.messages.smsAccountLoading,
    groupsUsers: state.teamManagement.groupsUsers
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSMSAccountLoading: (loading: boolean) => dispatch(changeSMSAccountLoading(loading)),
    changeSMSAccount: (smsAccount: FullSMSAccount) => dispatch(changeSMSAccount(smsAccount)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(MessagesList));