import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Empty, Select, Space, Switch, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { type ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { GoPencil } from 'react-icons/go';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mandatesFetched } from '../../../store/features/customerManagement';
import { DisableForGlobalOffice } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { ICustomer, IMandate, IMandateState } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { isNullOrEmpty } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Anticon from '../../common/general/anticon';
import SpaceContent from '../../common/general/spaceContent';
import VirtualTable from '../../common/general/virtualTable';
import { FilterSidebar } from '../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
    customer?: ICustomer;
}

interface State {
    filteredMandates?: IMandate[];
    pageSize: number;
    currentPage: number;
    searchValue: string;
    searchValueByAdress: string;
    selectedCustomers: number[];
    startDate?: Moment;
    endDate?: Moment;
    columns: ColumnsType<IMandate>;
    customerMandates: IMandateState;
    hideOld: boolean;
}

export class Mandates extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 1,
            pageSize: 12,
            searchValue: '',
            searchValueByAdress: '',
            columns: this.getInitialColumns(),
            customerMandates: { loading: false },
            selectedCustomers: [],
            hideOld: false
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);


        this.mandatesFetched();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isEqual(this.props.mandates, prevProps.mandates) ||
            !isEqual(this.state.customerMandates, prevState.customerMandates)) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
        if (!isEqual(this.props.customers, prevProps.customers) ||
            this.hasFilterChange(prevState, this.state)) {
            this.setState({ columns: this.getInitialColumns() })
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);

        }
    }

    hasFilterChange = (prevState: Readonly<State>, state: Readonly<State>) => {
        return (
            prevState.searchValue !== state.searchValue ||
            prevState.searchValueByAdress !== state.searchValueByAdress ||
            !isEqual(prevState.selectedCustomers, state.selectedCustomers) ||
            prevState.hideOld !== state.hideOld
        )
    }

    getInitialColumns = (): ColumnsType<IMandate> => {
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;
        const { intl } = this.props;
        return [
            {
                title: <FormattedMessage defaultMessage={'Title'} />,
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                className: '__min-width-200',
            },
            {
                title: <FormattedMessage defaultMessage={'Code'} />,
                key: 'code',
                dataIndex: 'code',
                className: '__width_120',
            },
            {
                title: <FormattedMessage defaultMessage={'Customer'} />,
                key: 'customerId',
                dataIndex: 'customerId',
                className: '__width_300',
                sorter: (dataA, dataB) => {
                    const customerA = this.props.customers.data?.find(c => c.id === dataA.customerId)?.title.toLocaleLowerCase();
                    const customerB = this.props.customers.data?.find(c => c.id === dataB.customerId)?.title.toLocaleLowerCase();
                    if (!customerA && !customerB) return 0;
                    if (customerA && !customerB) return -1;
                    if (!customerA && customerB) return 1;
                    if (customerA && customerB) return customerA.localeCompare(customerB);
                    return 0;
                },
                render: (customerId) => {
                    return this.props.customers.data?.find(c => c.id === customerId)?.title
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Start date'} />,
                key: 'startDate',
                dataIndex: 'startDate',
                className: '__width_150',
                sorter: (a, b) => {
                    const dataA = a.startDate;
                    const dataB = b.startDate;
                    if (!dataA && !dataB) return 0;
                    if (dataA && !dataB) return -1;
                    if (!dataA && dataB) return 1;
                    if (dataA && dataB) return moment(dataA).diff(moment(dataB));
                    return 0;
                },
                render: (startDate) => {
                    return startDate ? moment(startDate).format(getFormat('DATE')) : '';
                }
            },
            {
                title: <FormattedMessage defaultMessage={'End date'} />,
                key: 'endDate',
                dataIndex: 'endDate',
                className: '__width_130',
                sorter: (a, b) => {
                    const dataA = a.endDate;
                    const dataB = b.endDate;
                    if (!dataA && !dataB) return 0;
                    if (dataA && !dataB) return -1;
                    if (!dataA && dataB) return 1;
                    if (dataA && dataB) return moment(dataA).diff(moment(dataB));
                    return 0;
                },
                render: (endDate) => {
                    return endDate ? moment(endDate).format(getFormat('DATE')) : '';
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Actions'} />,
                key: 'actions',
                fixed: 'right',
                className: '__width_120 __centered-text',
                render: () => {
                    return (
                        <Space>
                            <DeleteButton
                                text={"Voulez-vous supprimer ce mandat?"}
                                key="template-modal-delete-color"
                                onConfirm={() => console.log("DELETE")}
                                placement="topRight"
                                buttonPlacement="right"
                                title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : undefined}
                                disabled={isGlobalOfficeEnabled}
                            />
                            <CircleButton
                                icon={<Anticon><GoPencil /></Anticon>}
                                title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Edit' })}
                                placement="left"
                                onClick={() => console.log("EDIT")}
                                disabled={isGlobalOfficeEnabled} />
                        </Space>
                    );
                },
            }
        ];
    }

    getMandates = () => {
        if (this.props.customer) return this.state.customerMandates
        else return this.props.mandates
    }

    getExtra = () => {
        const mandates = this.getMandates();
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;
        const { intl } = this.props;
        return (
            <>
                <Tooltip placement='left' open={mandates.updatedAt ? undefined : false} title={<p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: moment(mandates.updatedAt).format(getFormat('DATE_AND_ON_TIME')) }} /></p>}>
                    <CircleButton
                        small
                        withoutTooltip
                        title={intl.formatMessage({ defaultMessage: 'Force update' })}
                        icon={<FAIcon prefix={'fad'} name="rotate" />}
                        onClick={() => this.mandatesFetched(true)}
                        loading={mandates.loading} />
                </Tooltip>
                <CircleButton
                    small
                    key="mandate-add"
                    title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Add a mandate' })}
                    icon={<PlusOutlined />}
                    withoutTooltip
                    disabled={isGlobalOfficeEnabled} />
            </>
        )
    }

    getSidebars = () => {
        const { intl } = this.props;
        const content = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Search
                    value={this.state.searchValue}
                    placeholder={intl.formatMessage({ defaultMessage: 'Search by title or code' })}
                    allowClear
                    key={`search-mandate`}
                    onChange={(event) => this.searchCustomers(event.target.value)} />
                <Search
                    value={this.state.searchValueByAdress}
                    placeholder={intl.formatMessage({ defaultMessage: 'Search by address' })}
                    allowClear
                    key={`search-mandate-by-adresse`}
                    onChange={(event) => this.searchAdress(event.target.value)} />
                {
                    !this.props.customer ?
                        <Select
                            value={this.state.selectedCustomers}
                            mode="multiple"
                            maxTagCount={"responsive"}
                            className="configurations-width-100"
                            onChange={(values) => this.setState({ selectedCustomers: values }, () => this.filterCustomers())}
                            allowClear
                            showSearch
                            showArrow
                            filterOption={true}
                            placeholder={<FormattedMessage defaultMessage={'Filter by customer(s)'} />}
                            optionFilterProp="label">
                            {this.props.customers.data?.map(c => <Select.Option label={c.title} value={c.id} key={`mandate-customers-select-${c.id}`}>{c.title}</Select.Option>)}
                        </Select>
                        : null
                }
                <SpaceContent>
                    <DatePicker placeholder={intl.formatMessage({ defaultMessage: 'Start date' })} format={getFormat('DATE')} onChange={this.changeStartDate} value={this.state.startDate} />
                    <span>-</span>
                    <DatePicker placeholder={intl.formatMessage({ defaultMessage: 'End date' })} format={getFormat('DATE')} onChange={this.changeEndDate} value={this.state.endDate} />
                </SpaceContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                    <span><FormattedMessage defaultMessage={'Hide mandates in the past'} /></span>
                    <Switch
                        checked={this.state.hideOld}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={this.hideOld}
                    />
                </div>
            </div>
        );

        return [FilterSidebar(content, intl)];
    }

    hideOld = (checked: boolean) => {
        this.setState({ hideOld: checked }, () => this.filterCustomers());
    }

    searchCustomers = (value: string) => {
        const search = value.toLocaleLowerCase()
        this.setState({ searchValue: search }, () => this.filterCustomers());
    }
    searchAdress = (value: string) => {
        const search = value.toLocaleLowerCase()
        this.setState({ searchValueByAdress: search }, () => this.filterCustomers());
    }

    changeStartDate = (date: Moment | null) => {
        this.setState({ startDate: date ?? undefined }, () => this.filterCustomers());
    }

    changeEndDate = (date: Moment | null) => {
        this.setState({ endDate: date ?? undefined }, () => this.filterCustomers());
    }

    filterCustomers = () => {
        const { searchValue, searchValueByAdress, selectedCustomers, hideOld, startDate, endDate } = this.state;
        let filteredMandates = cloneDeep(this.getMandates().data);

        if (isNullOrEmpty(searchValue) && isNullOrEmpty(searchValueByAdress) && isNullOrEmpty(selectedCustomers) && !hideOld && !startDate && !endDate)
            this.setState({ filteredMandates: undefined })
        //TODO: Chercher startDate dans les données enddate
        if (startDate) {
            filteredMandates = filteredMandates?.filter(mandate => !mandate.startDate || moment(mandate.startDate).isSameOrAfter(moment(startDate), "days"));
        }

        if (endDate) {
            filteredMandates = filteredMandates?.filter(mandate => !mandate.endDate || moment(mandate.endDate).isSameOrBefore(moment(endDate), "days"));
        }

        if (!isNullOrEmpty(searchValue)) {
            filteredMandates = filteredMandates?.filter(mandate => `${mandate.title} ${mandate.code}`.toLocaleLowerCase().indexOf(searchValue) >= 0);
        }
        if (!isNullOrEmpty(searchValueByAdress)) {
            filteredMandates = filteredMandates?.filter(mandate => `${mandate.adress} ${mandate.zip} ${mandate.city}`.toLocaleLowerCase().indexOf(searchValueByAdress) >= 0);
        }

        if (!isNullOrEmpty(selectedCustomers)) {
            filteredMandates = filteredMandates?.filter(mandate => selectedCustomers.find(c => c === mandate.customerId));
        }

        if (hideOld) {
            filteredMandates = filteredMandates?.filter(mandate => mandate.endDate === null || moment(mandate.endDate).isSameOrAfter(moment(), "days"));
        }

        this.setState({ filteredMandates: filteredMandates ?? [] })
    }

    mandatesFetched = (forceReload = false) => {
        const { customer } = this.props;
        if (customer) {
            this.setState(prevState => ({ customerMandates: { ...prevState, loading: true } }));
            Network.getCustomerMandates(customer.id).then(
                (response) => {
                    this.setState({ customerMandates: { data: response.data, loading: false, updatedAt: moment().toISOString() } });
                },
                () => {
                    this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
                }
            );
        } else {
            this.props.mandatesFetched(forceReload);
        }
    }

    goToMandate = (mandateId: number, customerId: number) => this.props.history.push(`/${this.props.match.params.lang}/crm/customer/${customerId}/mandate/${mandateId}/details`);

    render() {
        const { filteredMandates, currentPage, pageSize, columns } = this.state;
        const mandates = this.getMandates();
        const mandatesData = filteredMandates ?? mandates.data;

        let firstMandatePagination = (currentPage - 1) * pageSize;
        let lastMandatePagination = currentPage * pageSize;
        if (mandatesData && firstMandatePagination > mandatesData?.length) firstMandatePagination = 0;
        if (mandatesData && lastMandatePagination > mandatesData?.length) lastMandatePagination = pageSize;

        let tableHeight = this.props.height - 168;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <>
                <VirtualTable
                    onRow={(mandate: IMandate) => {
                        return {
                            onClick: () => { this.goToMandate(mandate.id, mandate.customerId) },
                        };
                    }}
                    loading={mandates.loading}
                    rowKey={(mandate: IMandate) => `mandate-${mandate.id}`}
                    dataSource={mandatesData}
                    scroll={{ x: true, y: tableHeight }}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No mandate'} />} /> }}
                />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    customers: state.customerManagement.customers,
    mandates: state.customerManagement.mandates,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    height: state.window.height
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    mandatesFetched: (forceReload?: boolean) => dispatch(mandatesFetched({ forceReload: forceReload ?? false })),
    // customerUpdated: (customerId: number, newData: Partial<ICustomer>) => dispatch(customerUpdated({ customerId, newData })),
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default withRouter(connector(injectIntl(Mandates)));