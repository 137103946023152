import { SidebarType } from '../../components/common/navigations/containerTabs';
import { StoreAction, WindowState } from '../../utils/types/storeTypes';

export const WINDOW_INIT_STATE: WindowState = {
    fullscreen: false,
    width: 0, //the current width of the window
    height: 0, //the current height of the window
    containerHeight: 0,
    isSmartphone: false, //if the window is of smartphone size (width <= 900)
    isTablet: false, //if the window is of smartphone size (width <= 1145)
    navigationCollapsed: false, //if the side navigation bar is collapsed
    darkMode: false,
    sidebarType: SidebarType.NONE,
}

export const TYPE_WINDOW_FULLSCREEN = ('WINDOW_FULLSCREEN');
export const TYPE_WINDOW_WIDTH = ('WINDOW_WIDTH');
export const TYPE_WINDOW_HEIGHT = ('WINDOW_HEIGHT');
export const TYPE_TOOGLE_DARK_MODE = ('TYPE_TOOGLE_DARK_MODE');
export const TYPE_SET_DARK_MODE = ('TYPE_SET_DARK_MODE');
export const TYPE_WINDOW_NAVIGATION_COLLAPSED = ('WINDOW_NAVIGATION_COLLAPSED');
export const TYPE_NAVIGATION_COLLAPSE = ('TYPE_NAVIGATION_COLLAPSE');
export const TYPE_NAVIGATION_NOT_COLLAPSE = ('TYPE_NAVIGATION_NOT_COLLAPSE');
export const TYPE_CHANGE_SIDEBAR_TYPE = ('TYPE_CHANGE_SIDEBAR');
export const RESET = ('RESET_WINDOW');

const window = (state: WindowState = WINDOW_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case TYPE_WINDOW_FULLSCREEN:
            return {
                ...state,
                fullscreen: action.data,
            };
        case TYPE_WINDOW_WIDTH:
            return {
                ...state,
                width: action.data,
                isSmartphone: action.data <= 900,
                isTablet: action.data <= 1145,
            };
        case TYPE_WINDOW_HEIGHT:
            return {
                ...state,
                height: action.data,
                containerHeight: action.data - 95
            };
        case TYPE_TOOGLE_DARK_MODE:
            localStorage.setItem("darkMode", `${!state.darkMode}`);
            if(!state.darkMode) document.getElementsByTagName( 'html' )[0].setAttribute('class', 'dark-theme')
            else document.getElementsByTagName( 'html' )[0].setAttribute('class', '')
            return {
                ...state,
                darkMode: !state.darkMode,
            }
        case TYPE_SET_DARK_MODE:
            localStorage.setItem("darkMode", action.data);
            if(action.data) document.getElementsByTagName( 'html' )[0].setAttribute('class', 'dark-theme')
            else document.getElementsByTagName( 'html' )[0].setAttribute('class', '')
            return {
                ...state,
                darkMode: action.data,
            }
        case TYPE_WINDOW_NAVIGATION_COLLAPSED:
            localStorage.setItem("collapsed", `${!state.navigationCollapsed}`);
            return {
                ...state,
                navigationCollapsed: !state.navigationCollapsed,
            }
        case TYPE_NAVIGATION_COLLAPSE:
            localStorage.setItem("collapsed", `${true}`);
            return {
                ...state,
                navigationCollapsed: true,
            }
        case TYPE_NAVIGATION_NOT_COLLAPSE:
            localStorage.setItem("collapsed", `${false}`);
            return {
                ...state,
                navigationCollapsed: false,
            }
        case TYPE_CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                sidebarType: action.data,
            }
        case RESET:
            return WINDOW_INIT_STATE;
        default:
            return state;
    }
}

export default window;