import { InboxOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsers } from '../../store/actions/teamManagement';
import { OvertimesCalculatedTypes } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { RouterProps, User } from '../../utils/types/generalTypes';
import { UserVacationsSummary } from '../../utils/types/networkTypes';
import { MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray } from '../../utils/types/planningTypes';
import { CalculatedUserHoursSummaryData, CalculatedUsersHoursSummaries, ContractEvents, GroupByDayMonthlyReportType, HoursSummary, InitialHoursRaw, MonthlyReportEvent, SimpleBreaktime, SimpleEvent, SimpleOvertime, UserHoursSummary, UsersHoursSummariesRaw } from '../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../utils/types/storeTypes';
import { convertNetworkSimpleEventsToSimpleEvents, roundDecimals, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import DatePickerSelect from '../common/fields/datePickerSelect';
import Card from '../common/general/card';

interface IProps {
    users?: User[];
    isSmartphone?: boolean;
    height: number;
}

type Props = IProps & RouterProps & TeamManagementDispatchProps & IntlProps;

interface State {
    year: number;
    isLoading: boolean;
    monthlyHoursArray?: MonthlyHoursOfTheYearArray;
    monthlyHours?: MonthlyHoursOfTheYear;
    initialHours?: InitialHoursRaw[];
    usersHoursSummaries?: CalculatedUsersHoursSummaries;
}

/**
 * Component that represent the vacations tab for the report page
 */
class HoursTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            isLoading: false,
        };
    }

    componentDidMount() {
        if (this.props.users === undefined || this.props.users.length === 0) {
            this.getUsers();
        }
        this.refreshHoursByMonth();
    }


    /**
    * Fetch all users
    */
    getUsers = () => {
        //get all users
        Network.getAllUsers().then(
            response => this.props.changeUsers!(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
        );
    }

    refreshHoursByMonth = () => {
        const { year } = this.state;
        Network.getMonthlyHours(year).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHoursArray: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                }
                this.setState({ monthlyHoursArray: monthlyHoursArray, monthlyHours: response }, () => this.getHoursData());
            },
            (error) => {
                if (error.message.includes(this.props.intl.formatMessage({ defaultMessage: 'No monthly hours' }))) {
                    this.setState({ monthlyHoursArray: undefined, monthlyHours: undefined }, () => this.getHoursData());
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                }
            },
        );
    }

    getHoursData = () => {
        const { users } = this.props;
        if (users === undefined || users.length === 0) return;
        this.setState({ isLoading: true });

        const { year, monthlyHours } = this.state;
        const startOfYear = moment(`${year}-01-01`, "YYYY-MM-DD");
        const endOfYear = moment(`${year}-12-31`, "YYYY-MM-DD");

        Network.userReportsSummary(year).then(
            (response: UsersHoursSummariesRaw) => {
                const parsedResponse = this.parseUsersHoursSummaries(response, startOfYear, endOfYear, monthlyHours);
                this.setState({ usersHoursSummaries: parsedResponse, isLoading: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours' }), "warning");
                this.setState({ isLoading: false });
            }
        );

    }

    componentDidUpdate(prevProps: Props) {
        if (!this.state.isLoading && prevProps.users !== this.props.users) {
            this.getHoursData();
        }
    }

    /**
     * It groups the events by day.
     * @param {MonthlyReportEvent[]} x - The array of MonthlyReportEvent objects to group.
     * @param {any} f - any
     * @returns An array of objects with a date and an array of events.
     */
    groupBy(x: MonthlyReportEvent[], f: any) {
        return x.reduce((a: GroupByDayMonthlyReportType[], b: MonthlyReportEvent) => {
            const found = a.find(t => t.date === f(b));
            if (found) {
                found.events.push(b)
            } else {
                a.push({
                    date: f(b),
                    events: [b]
                })
            }
            return a;
        }, []);
    }

    /**
     * It rounds a number to a given number of decimals.
     * @param {number} numberToDecimalize - the number to be converted to a decimal
     * @param {number} [nbDecimals=2] - the number of decimals to display.
     * @returns a string.
     */
    decimalize(numberToDecimalize: number, nbDecimals = 2) {
        return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
    }

    getInitialOvertimeHours = (userId: number, contractId: number | null, initialHours: InitialHoursRaw[]) => {
        let initialOvertimeHours = 0;

        const foundData = initialHours?.find(ih => (ih.contractId === contractId && ih.userId === userId) || (ih.contractId === null && ih.userId === userId));
        if (foundData) {
            initialOvertimeHours = foundData.initialOvertimeHours;
        }

        return initialOvertimeHours;

    }

    overtimeDuration = (overtime: SimpleOvertime) => {
        return roundDecimals(moment.duration(overtime.dateTo.diff(overtime.dateFrom)).asHours());
    }

    breaktimeDuration = (breaktime: SimpleBreaktime) => {
        return roundDecimals(moment.duration(breaktime.dateTo.diff(breaktime.dateFrom)).asHours());
    }

    eventDuration = (event: SimpleEvent) => {
        return roundDecimals(moment.duration(event.dateTo.diff(event.dateFrom)).asHours());
    }

    totalOvertimesHours = (events: SimpleEvent[], type: OvertimesCalculatedTypes) => {
        let totalHours = undefined;
        totalHours = events.reduce((total: number, event: SimpleEvent) => {
            const subTotal = event.overtimes.reduce((overtimeTotal: number, overtime: SimpleOvertime) => {
                switch (type) {
                    case OvertimesCalculatedTypes.OVERTIMES:
                        if (overtime.isNegative === true) return overtimeTotal;
                        break;
                    case OvertimesCalculatedTypes.UNDERTIMES:
                        if (overtime.isNegative === false) return overtimeTotal;
                        break;
                    case OvertimesCalculatedTypes.ALL:
                        break;
                }

                return overtimeTotal + this.overtimeDuration(overtime);
            }, 0.0);

            return total + subTotal;
        }, 0.0);

        return this.decimalize(totalHours, 2);
    }

    totalBreaktimesHours = (events: SimpleEvent[]) => {
        let totalHours = undefined;
        totalHours = events.reduce((total: number, event: SimpleEvent) => {
            const subTotal = event.breakTimes.reduce((breaktimeTotal: number, breaktime: SimpleBreaktime) => {
                return breaktimeTotal + this.breaktimeDuration(breaktime);
            }, 0.0);

            return total + subTotal;
        }, 0.0);

        return this.decimalize(totalHours, 2);
    }

    totalEventsHours = (events: SimpleEvent[]) => {
        let totalHours = undefined;
        totalHours = events.reduce((total: number, event: SimpleEvent) => {
            return total + this.eventDuration(event);
        }, 0.0);

        return this.decimalize(totalHours, 2);
    }

    calculateHoursOfMonth = (events: SimpleEvent[], prevBalance: number, workRate?: number, monthlyHours?: number) => {
        const hours: HoursSummary = {};

        if (workRate !== undefined && monthlyHours !== undefined) {
            const workRateDecimal = workRate / 100;
            hours.todoHours = this.decimalize((workRateDecimal * monthlyHours), 2);
        }
        // else if (workRate !== undefined) {

        // } else if (monthlyHours !== undefined) {

        // }



        hours.overtimeUndertimeBalance = this.totalOvertimesHours(events, OvertimesCalculatedTypes.ALL);
        hours.breaktimeBalance = this.totalBreaktimesHours(events);
        hours.effectiveHours = this.totalEventsHours(events);
        hours.totalCalculatedBalance = this.decimalize(parseFloat(hours.effectiveHours) - parseFloat(hours.breaktimeBalance) + parseFloat(hours.overtimeUndertimeBalance), 2);
        hours.prevBalance = this.decimalize(prevBalance, 2);
        hours.nextBalance = this.decimalize(parseFloat(hours.prevBalance) + parseFloat(hours.totalCalculatedBalance) - (hours.todoHours ? parseFloat(hours.todoHours) : 0.0));

        return hours;
    }


    calculateHoursByMonth = (contract: ContractEvents, startMonth: Moment, endMonth: Moment, monthlyHours?: MonthlyHoursOfTheYear) => {
        const hoursSummary: UserHoursSummary = {};
        const dateStart = startMonth.clone();
        const dateEnd = endMonth.clone();
        const events = cloneDeep(contract.events);

        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {

            let plannedMonthlyHours = undefined;
            const monthEvents: SimpleEvent[] = remove(events, event => {
                return event.dateFrom.format('M') === dateStart.format('M');
            });
            let prevBalance = 0.0;
            switch (dateStart.format('M')) {
                case "1":

                    if (monthlyHours?.janHours) plannedMonthlyHours = monthlyHours.janHours;
                    hoursSummary.janHours = this.calculateHoursOfMonth(monthEvents, contract.initialOvertimeHours, contract.workRate, plannedMonthlyHours);
                    break;
                case "2":
                    if (monthlyHours?.febHours) plannedMonthlyHours = monthlyHours.febHours;
                    if (hoursSummary.janHours?.nextBalance) prevBalance = parseFloat(hoursSummary.janHours.nextBalance);
                    hoursSummary.febHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "3":
                    if (monthlyHours?.marHours) plannedMonthlyHours = monthlyHours.marHours;
                    if (hoursSummary.febHours?.nextBalance) prevBalance = parseFloat(hoursSummary.febHours.nextBalance);
                    hoursSummary.marHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "4":
                    if (monthlyHours?.aprHours) plannedMonthlyHours = monthlyHours.aprHours;
                    if (hoursSummary.marHours?.nextBalance) prevBalance = parseFloat(hoursSummary.marHours.nextBalance);
                    hoursSummary.aprHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "5":
                    if (monthlyHours?.mayHours) plannedMonthlyHours = monthlyHours.mayHours;
                    if (hoursSummary.aprHours?.nextBalance) prevBalance = parseFloat(hoursSummary.aprHours.nextBalance);
                    hoursSummary.mayHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "6":
                    if (monthlyHours?.junHours) plannedMonthlyHours = monthlyHours.junHours;
                    if (hoursSummary.mayHours?.nextBalance) prevBalance = parseFloat(hoursSummary.mayHours.nextBalance);
                    hoursSummary.junHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "7":
                    if (monthlyHours?.julHours) plannedMonthlyHours = monthlyHours.julHours;
                    if (hoursSummary.junHours?.nextBalance) prevBalance = parseFloat(hoursSummary.junHours.nextBalance);
                    hoursSummary.julHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "8":
                    if (monthlyHours?.augHours) plannedMonthlyHours = monthlyHours.augHours;
                    if (hoursSummary.julHours?.nextBalance) prevBalance = parseFloat(hoursSummary.julHours.nextBalance);
                    hoursSummary.augHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "9":
                    if (monthlyHours?.sepHours) plannedMonthlyHours = monthlyHours.sepHours;
                    if (hoursSummary.augHours?.nextBalance) prevBalance = parseFloat(hoursSummary.augHours.nextBalance);
                    hoursSummary.sepHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "10":
                    if (monthlyHours?.octHours) plannedMonthlyHours = monthlyHours.octHours;
                    if (hoursSummary.sepHours?.nextBalance) prevBalance = parseFloat(hoursSummary.sepHours.nextBalance);
                    hoursSummary.octHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "11":
                    if (monthlyHours?.novHours) plannedMonthlyHours = monthlyHours.novHours;
                    if (hoursSummary.octHours?.nextBalance) prevBalance = parseFloat(hoursSummary.octHours.nextBalance);
                    hoursSummary.novHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
                case "12":
                    if (monthlyHours?.decHours) plannedMonthlyHours = monthlyHours.decHours;
                    if (hoursSummary.novHours?.nextBalance) prevBalance = parseFloat(hoursSummary.novHours.nextBalance);
                    hoursSummary.decHours = this.calculateHoursOfMonth(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    break;
            }
            dateStart.add(1, 'month');
        }

        return hoursSummary;
    }

    parseUsersHoursSummaries = (usersHoursSummariesRaw: UsersHoursSummariesRaw, startMonth: Moment, endMonth: Moment, monthlyHours?: MonthlyHoursOfTheYear) => {
        const usersHoursSummaries: CalculatedUsersHoursSummaries = { updated: moment(), data: [] };
        usersHoursSummariesRaw?.data.forEach((userDataRaw) => {
            const userDataCalculated: CalculatedUserHoursSummaryData = { id: userDataRaw.id, firstName: userDataRaw.firstName, lastName: userDataRaw.lastName, contractsEvents: [], selectedContractId: -1, }

            const events = cloneDeep(userDataRaw.events);
            userDataRaw.contracts?.forEach(((contract, idx) => {
                if (idx === 0) userDataCalculated.selectedContractId = contract.id;
                const contractEvents = remove(events, (event) => {
                    return moment(event.dateFrom).isBetween(moment(contract.startDate), moment(contract.endDate), "day", "[]");
                });
                const contractEvent: ContractEvents = {
                    id: contract.id,
                    name: contract.name,
                    startDate: moment(contract.startDate),
                    endDate: moment(contract.endDate),
                    dayWorkingHours: contract.weeklyWorkingHours / 5,
                    workRate: contract.workRate,
                    initialOvertimeHours: this.getInitialOvertimeHours(userDataRaw.id, contract.id, usersHoursSummariesRaw.initialHours),
                    withoutContract: false,
                    events: convertNetworkSimpleEventsToSimpleEvents(contractEvents),
                    calculatedEvents: {}
                }
                const calculatedEvents = this.calculateHoursByMonth(contractEvent, startMonth, endMonth, monthlyHours);
                contractEvent.calculatedEvents = calculatedEvents;
                userDataCalculated.contractsEvents.push(contractEvent);
            }));
            if (events.length > 0) {
                const withoutContractEvents = events;
                const contractEvent: ContractEvents = {
                    id: -1,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Without contract' }),
                    startDate: startMonth,
                    endDate: endMonth,
                    dayWorkingHours: -1,
                    workRate: -1,
                    initialOvertimeHours: 0,
                    withoutContract: true,
                    events: convertNetworkSimpleEventsToSimpleEvents(withoutContractEvents),
                    calculatedEvents: {}
                }
                const calculatedEvents = this.calculateHoursByMonth(contractEvent, startMonth, endMonth, monthlyHours);
                contractEvent.calculatedEvents = calculatedEvents;
                userDataCalculated.contractsEvents.push(contractEvent);
            }

            usersHoursSummaries.data.push(userDataCalculated);
        });
        return usersHoursSummaries;
    }

    /**
     * Render days
     * @param days the days to render
     * @returns the string with the rendered days
     */
    renderDays = (days: number) => days && days > 0 ? `${(Math.round(days * 10) / 10)} jour(s)` : ' 0 jour(s) ';

    /**
     * Give the users table's rows some attributes
     * @param record the data source for the row
     */
    onRow = (record: UserVacationsSummary) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/vacations?id=${record.user.id}`),
    })

    columnsUserHoursSummary = (): ColumnsType<CalculatedUserHoursSummaryData> => [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            fixed: 'left',
            className: '__report-hours-summary-user',
            render: (value, record) => {
                return (
                    <span>{record.lastName} {record.firstName}</span>
                );
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Rate'} />,
            key: 'contract',
            fixed: 'left',
            className: '__report-hours-summary-workrate',
            render: (value, record) => {
                const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                if (contractData === undefined) {
                    return <span>-</span>
                } else {
                    return (
                        <span>{contractData.workRate}%</span>
                    );
                }
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Initial'} />,
            key: 'hours-init',
            fixed: 'left',
            className: '__report-hours-summary-hours-init',
            render: (value, record) => {
                const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                if (contractData === undefined) {
                    return <span>-</span>
                } else {
                    return (
                        <Tag className="__report-groups-users-tags">{contractData.initialOvertimeHours}</Tag>
                    );
                }
            }
        },
        {
            title: <FormattedMessage defaultMessage={'January'} />,
            key: 'janHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'janHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.janHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'janHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.janHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'janHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.janHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'February'} />,
            key: 'febHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'febHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.febHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'febHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.febHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'febHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.febHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'March'} />,
            key: 'marHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'marHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.marHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'marHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.marHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'marHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.marHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'April'} />,
            key: 'aprHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'aprHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.aprHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'aprHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.aprHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'aprHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.aprHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'May'} />,
            key: 'mayHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'mayHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.mayHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'mayHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.mayHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'mayHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.mayHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'June'} />,
            key: 'junHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'junHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.junHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'junHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.junHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'junHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.junHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'July'} />,
            key: 'julHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'julHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.julHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'julHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.julHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'julHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.julHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'August'} />,
            key: 'augHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'augHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.augHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'augHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.augHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'augHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.augHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'September'} />,
            key: 'sepHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'sepHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.sepHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'sepHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.sepHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'sepHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.sepHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'October'} />,
            key: 'octHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'octHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.octHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'octHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.octHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'octHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.octHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'November'} />,
            key: 'novHours',
            className: '__report-hours-summary-fixed-odd-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'novHours_todoHours',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.novHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'novHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.novHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'novHours_nextBalance',
                    className: '__report-hours-summary-fixed-odd',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.novHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'December'} />,
            key: 'decHours',
            className: '__report-hours-summary-fixed-even-main',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: 'decHours_todoHours',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.decHours?.todoHours}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: 'decHours_totalCalculatedBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <span>{contractData.calculatedEvents.decHours?.totalCalculatedBalance}</span>
                            );
                        }
                    }

                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: 'decHours_nextBalance',
                    className: '__report-hours-summary-fixed-even',
                    render: (value, record) => {
                        const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                        if (contractData === undefined) {
                            return <span>-</span>
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{contractData.calculatedEvents.decHours?.nextBalance}</Tag>
                            );
                        }
                    }

                }
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'To report'} />,
            key: 'to_report',
            fixed: 'right',
            className: '__report-hours-summary-to-report',
            render: (value, record) => {
                const contractData = record.contractsEvents.find(c => c.id === record.selectedContractId)
                if (contractData === undefined) {
                    return <span>-</span>
                } else {
                    return (
                        <Tag className="__report-groups-users-tags-important">{contractData.calculatedEvents.decHours?.nextBalance}</Tag>
                    );
                }
            }
        },

    ]

    render() {
        const { usersHoursSummaries, isLoading, year } = this.state;
        const { isSmartphone, intl } = this.props;
        const date = moment().year(year);
        let tableHeight = this.props.height - 289;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }}>
                    <Card
                        icon={<UserOutlined />}
                        title={
                            <Space>
                                <DatePickerSelect
                                    key={`custom-datepicker-${date.format('YYYY')}`}
                                    className='planning-datepicker-input-container'
                                    date={date}
                                    onChange={(value) => this.setState({ year: value ? value.year() : moment().year() }, () => this.refreshHoursByMonth())}
                                    disabled={isLoading}
                                    loading={isLoading}
                                />
                                {
                                    usersHoursSummaries && !isSmartphone ?
                                        <>
                                            <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                            <CircleButton
                                                small
                                                style={{ marginRight: "5px" }}
                                                title={intl.formatMessage({ defaultMessage: 'Force update' })}
                                                icon={<FAIcon prefix={'fad'} name="rotate" />}
                                                onClick={this.getHoursData}
                                                disabled={isLoading} />
                                            <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {time}'} values={{ time: usersHoursSummaries?.updated.format(getFormat('DATE_LONG_AND_ON_TIME_SHORT')) }} /></p>
                                        </>
                                        : null
                                }
                            </Space>
                        }
                        headerElements={[]}>
                        {
                            isLoading || usersHoursSummaries === undefined ?
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
                                    <Spin size='large' style={{ marginBottom: '20px' }} indicator={<LoadingOutlined style={{ fontSize: 90 }} spin />} />
                                </div>
                                :
                                usersHoursSummaries.data.length > 0 ?
                                    <Table
                                        dataSource={usersHoursSummaries?.data}
                                        columns={this.columnsUserHoursSummary()}
                                        loading={isLoading}
                                        pagination={false}
                                        rowKey={s => `report-hours-summary-${s.id}`}
                                        scroll={{ x: true, y: tableHeight }} />
                                    :
                                    <span className="dashboard-empty">
                                        <InboxOutlined />
                                        <p><FormattedMessage defaultMessage={'No data'} /></p>
                                    </span>
                        }
                    </Card>
                </Col>
            </Row >
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HoursTab)));