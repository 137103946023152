import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Empty, List, Row, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { MdDriveEta } from 'react-icons/md';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeOccupancyRates, changeTypesOfDay, changeTypesOfVehicles, loadTypesOfVehicle } from '../../../store/actions/configurations';
import { changeTemplates } from '../../../store/actions/planning';
import getFormat from '../../../utils/Lang';
import { TypeOfVehicle, TypeOfVehicleWithUpdate } from '../../../utils/types/generalTypes';
import { PlanningOccupancyRate, PlanningTemplate, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, PlanningDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';
import VehicleItem from './vehicleItem';

interface IProps {
    //placesOfEngagement: POI[];
    vehicles: TypeOfVehicleWithUpdate;
    vehicle?: TypeOfVehicle;
    stopEdition: () => void;
}

type Props = IProps & ConfigurationsDispatchProps & PlanningDispatchProps & IntlProps;

interface State {
    // allPois?: POI[] | undefined; 
    // basePoi?: POI[] | undefined;
    // createDeparturePoint: POI | undefined;
    createTypeOfVehicle: TypeOfVehicle | undefined;
    vehicle: TypeOfVehicle | undefined;

}

/**
 * Component for the Travel and vehicle tab in the configurations page
 */
class TravelAndVehicleTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            // allPois:  undefined,
            // basePoi: this.state.allPois && this.state.allPois.filter(Pois => Pois.isBasePoi === true),
            // basePoi: undefined,
            // createDeparturePoint: undefined,
            createTypeOfVehicle: undefined,
            vehicle: undefined
        };

        // this.setState({allPois: this.refreshPois()})
        // this.setState({basePoi: this.state.allPois && this.state.allPois.filter(Pois => Pois.isBasePoi === true)})
    }

    componentDidMount(): void {
        this.refresh();
    }

    // refreshPois = ():POI[]|undefined => {
    //     Network.getPoi().then(
    //         (response: POI[]) => {
    //            return response;
    //         }
    //     );
    //     return undefined;
    // }

    /**
     * Refresh the types of day
     * @param message a message to display as a success message - optional
     */
    refresh = (forceReload = false) => {
        this.props.loadTypesOfVehicle!(forceReload);
    };

    /**
    * Check if the add Place of Engagement button can be visible
    * @returns true if the button can be visible, false otherwise
    */
    // showAddDeparturePoint = (): boolean => this.state.createDeparturePoint === undefined;

    /**
     * Check if the add Vehicles button can be visible
     * @returns true if the button can be visible, false otherwise
     */
    showAddVehicles = (): boolean => this.state.createTypeOfVehicle === undefined;

    /**
     * Add a Place of engagement
     */
    // addDeparturePoint = () => {
    //     const createDeparturePoint = {
    //         title: "",
    //     };
    //     this.setState({ createDeparturePoint });
    // }

    /**
     * Add a Vehicle
     */
    addVehicle = () => {
        const createTypeOfVehicle: TypeOfVehicle = {};
        this.setState({ createTypeOfVehicle });
    };


    /**
     * Render a vehicle (a list item)
     * @param vehicle the item to render
     * @returns the component to render
     */
    renderVehicle = (vehicle: TypeOfVehicle): React.ReactNode => {
        return <VehicleItem vehicle={vehicle} stopEdition={() => this.setState({ createTypeOfVehicle: undefined })} />;
    };

    renderHeader = () => {
        return (
            <div style={{ marginRight: "150px" }}>
                <Row style={{ width: "100%", marginRight: "150px" }}>
                    <Col xs={{ span: 6 }}>
                        <p style={{ marginLeft: '0px', marginBottom: '10px' }}><span ><strong><FormattedMessage defaultMessage={'Name'} /></strong></span></p>
                    </Col>
                    <Col xs={{ span: 9 }}>
                        <p style={{ marginLeft: '15px', marginBottom: '10px' }}>
                            <span ><strong><FormattedMessage defaultMessage={'Description'} /></strong></span>
                        </p>
                    </Col>
                    <Col xs={{ span: 9 }}>
                        <p style={{ marginLeft: '10px', marginBottom: '10px' }}>
                            <span ><strong><FormattedMessage defaultMessage={'Type of vehicle'} /></strong></span>
                            <Tooltip title={this.props.intl.formatMessage({ defaultMessage: 'Types of vehicles according to the CLA' })}>
                                <InfoCircleOutlined className="tab-tooltip-info" />
                            </Tooltip>
                        </p>
                    </Col>
                </Row>
            </div>
        );
    };

    /**
     * Render a place of engagement (a list item)
     * @param departurePoint the item to render
     * @returns the component to render
     */
    // renderDeparturePoint = (departurePoint: POI): React.ReactNode => {
    //     return <DeparturePointItem departurePoint={departurePoint}  basePoi={this.state.basePoi} />;
    // }

    render() {
        const { createTypeOfVehicle } = this.state;
        const parsedTypesOfVehicle = cloneDeep(this.props.vehicles);
        const { intl } = this.props;
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                    <Card className="vehicle-card" icon={<MdDriveEta />} title={<FormattedMessage defaultMessage={'Types of vehicle'} />} headerElements={[
                        <>
                            <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}>{parsedTypesOfVehicle?.updated ? <><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: parsedTypesOfVehicle?.updated?.format(getFormat('DATE_AND_ON_TIME')) }} /></> : <></>}</p>
                            <CircleButton
                                key="type-of-contract-refresh-button"
                                style={{ marginRight: "5px" }}
                                title={intl.formatMessage({ defaultMessage: 'Force update' })}
                                icon={<FAIcon prefix={'fad'} name="rotate" />}
                                placement="top"
                                onClick={() => this.refresh(true)} />
                        </>,
                        this.showAddVehicles() &&
                        <CircleButton
                            key="vehicle_add_button"
                            style={{ marginRight: '8px' }}
                            title={intl.formatMessage({ defaultMessage: 'Add a vehicle' })}
                            onClick={this.addVehicle}
                            icon={<PlusOutlined />}
                            placement="right" />
                    ]}>
                        <List
                            className="vehicle-list"
                            header={this.renderHeader()}
                            dataSource={createTypeOfVehicle ? parsedTypesOfVehicle.data ? parsedTypesOfVehicle.data.concat([createTypeOfVehicle]) : [createTypeOfVehicle] : parsedTypesOfVehicle.data}
                            renderItem={this.renderVehicle}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No type of vehicle'} />} /> }}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    loadTypesOfVehicle: (fr?: boolean) => dispatch(loadTypesOfVehicle(fr)),
    changeTypesOfVehicles: (c: TypeOfVehicle[]) => dispatch(changeTypesOfVehicles(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfContract: state.configurations.typesOfContract,
    placesOfEngagement: state.location.basePois,
    vehicles: state.configurations.vehicles
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TravelAndVehicleTab));