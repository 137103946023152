import {
    ApartmentOutlined,
    AuditOutlined,
    BankOutlined,
    BookOutlined,
    CalendarOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    ExperimentOutlined,
    FileTextOutlined,
    FolderOutlined,
    GiftOutlined,
    HomeOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    LockOutlined,
    MailOutlined,
    MobileOutlined,
    PaperClipOutlined,
    PhoneOutlined,
    PlusOutlined,
    ProfileOutlined,
    SaveOutlined,
    SolutionOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Avatar, Button, Checkbox, Col, DatePicker, Empty, Input, Modal, Popover, Row, Select, Space, Switch, TimePicker } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import { AiFillEye, AiOutlineExperiment } from 'react-icons/ai';
import { PiGenderIntersex } from 'react-icons/pi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CompanyDetailsPrivilege, Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { loadCountries } from '../../../store/actions/configurations';
import { changeUser } from '../../../store/actions/teamManagement';
import { MOMENT_FORMAT_DISPLAY_DATE, MOMENT_FORMAT_TO_NETWORK, MOMENT_TIME_FORMAT, WIDTH_3_COL } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { GenericFile, GroupUser, PreValidateFile, RouterProps, StaffType, User, UserEducation, UserEducations, UserField, UserInput, UserJobTMP, ValidateFile } from '../../../utils/types/generalTypes';
import { CreateUserBodyRequest, CreateUserInput, UserContract } from '../../../utils/types/networkTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { checkCompanyDetailsPrivilege, checkPrivilege, isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DateTimePicker, { DateTimePickerType, DateTimePickerVersion } from '../../common/fields/dateTimePicker';
import DeleteButton from '../../common/fields/deleteButton';
import FileUploader from '../../common/fields/fileUploader';
import ImageUploader from '../../common/fields/imageUploader';
import InputField from '../../common/fields/inputField';
import Password from '../../common/fields/password';
import TagField from '../../common/fields/tagField';
import Anticon from '../../common/general/anticon';
import Bloc from '../../common/general/bloc';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import SpaceContent from '../../common/general/spaceContent';
import DisplayPinModal from './displayPinModal';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
    userId: number;
}

type Props = IProps & RouterProps & TeamManagementDispatchProps & ReduxProps & IntlProps;

export type UserFieldValue = { fieldId: number; value: UserInput[keyof UserInput]; };

interface State {
    values: CreateUserBodyRequest | undefined;
    contracts: UserContract | undefined;
    loading: boolean;
    openUpdatePasswordModal: boolean;
    newPassword: string;
    passwordError: boolean;
    currentUserJob: UserJobTMP | undefined;
    previousUserJob: UserJobTMP | undefined;
    addingUserJob: boolean;
    displayPinModal: boolean;
    deleteAvatar: boolean;
    usernameIsValid: boolean;
    usernameIsValidError: string;

    loadingStaffTypes: boolean;
    currentStaffTypes?: StaffType[];
    staffTypes?: StaffType[];
    files: GenericFile[];
    loadingFiles: boolean;
    userFieldValues: UserFieldValue[];
    displayResetPasswordEmailModal: boolean;
}


/**
 * Component that represent the user's informations page
 */
class EditUserTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { user } = this.props;
        let currentUserJob: UserJobTMP | undefined;
        user?.job?.forEach((e: UserJobTMP) => {
            if (e.is_current === true) currentUserJob = e;
        });

        this.state = {
            loadingStaffTypes: false,
            values: undefined,
            contracts: undefined,
            loading: false,
            openUpdatePasswordModal: false,
            newPassword: "",
            passwordError: false,
            currentUserJob,
            previousUserJob: currentUserJob,
            addingUserJob: false,
            displayPinModal: false,
            deleteAvatar: false,
            usernameIsValid: true,
            usernameIsValidError: '',
            files: [],
            loadingFiles: false,
            userFieldValues: [],
            displayResetPasswordEmailModal: false,
        };
    }

    componentDidMount(): void {
        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );
        this.loadCompanyUserFiles();
        this.props.loadCountries();
    }



    loadCompanyUserFiles = () => {
        if (!this.state.loadingFiles) {
            this.setState({ loadingFiles: true }, () => {
                if (this.props.userId) {
                    Network.getCompanyUserFiles(this.props.userId).then(
                        (response) => {
                            if (response.error === false && response.data !== undefined) {
                                this.setState({ loadingFiles: false, files: response.data });
                            } else {
                                this.setState({ loadingFiles: false });
                            }
                        },
                        () => {
                            this.setState({ loadingFiles: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                        }
                    );
                } else {
                    this.setState({ loadingFiles: false });
                }

            });

        }
    };

    onChangeStaffTypes = (staffTypesIds: number[]): void => {
        const { staffTypes, values } = this.state;
        let { currentStaffTypes } = this.state;

        currentStaffTypes = staffTypes?.filter(g => staffTypesIds.find(id => id === g.id) !== undefined);
        if (values) {
            values.stafftypesIds = currentStaffTypes?.filter(s => s.id !== undefined).map(s => Number(s.id));
            this.setState({ currentStaffTypes, values });
        }

    };
    /**
     * Translate user payload information to CreateUserBodyRequest type values
     */
    userToValues = (): void => {
        const { currentUserJob } = this.state;
        const { user } = this.props;
        const educations = user!.educations?.map((e: UserEducation) => {
            e.start_date = moment(e.start_date);
            e.end_date = moment(e.end_date);
            return e;
        });
        if (this.props.user?.id === this.props.userId) {


            this.setState({
                values: {
                    user_id: user!.id,
                    general: {
                        pic: user!.image,
                        role: user!.role,
                        first_name: user!.first_name,
                        last_name: user!.last_name,
                        username: user!.username,
                        doj: user!.doj,
                        team_visibility: user?.team_visibility,
                        can_edit_planning: user?.can_edit_planning,
                        can_read_only_all_planning: user?.can_read_only_all_planning,
                        sex: user?.sex,
                        check_events_overlap: user?.checkEventsOverlap
                    },
                    contact: {
                        email: user!.email,
                        phone: user!.contact?.phone,
                        mobile: user!.contact?.mobile,
                        location: user!.contact?.location,
                        zip: user!.contact?.zip,
                        country: user!.contact?.country,
                        address: user!.contact?.address,
                    },
                    about_me: {
                        dept: user!.about?.dept ? user!.about?.dept.split(",") : undefined,
                        expertise: user!.about?.expertise ? user!.about?.expertise.split(",") : undefined,
                        skills: user!.about?.skills ? user!.about?.skills.split(",") : undefined,
                    },
                    educations: user!.educations ? educations as UserEducations : Array<UserEducation>(),
                    bio: {
                        bio: user!.about?.bio,
                    },
                    job: {
                        id: currentUserJob?.id,
                        weekly_working_hours: currentUserJob?.weekly_working_hours,
                        yearly_vacation_days: currentUserJob?.yearly_vacation_days,
                        overtime_hours: currentUserJob?.overtime_hours,
                        work_rate: currentUserJob?.work_rate,
                        contract: currentUserJob?.contract,
                        contract_expiry_date: currentUserJob?.contract_expiry_date,
                        date_in_report: currentUserJob?.date_in_report,
                        notes: currentUserJob?.notes,
                        is_current: currentUserJob?.is_current,
                        name: currentUserJob?.name,
                    },
                    files: cloneDeep(user?.files),
                    stafftypesIds: (user !== undefined && user.staffType !== undefined) ? user.staffType.filter(s => s.id !== undefined).map(s => Number(s.id)) : []
                }
            });
        } else {
            this.setState({ values: undefined });
        }

    };

    /**
     * Refresh user details
     * @param message a message to display as a success message - optional
     */
    refresh = (message: string): void => {
        //get current user's details
        Network.getUser(this.props.userId).then(
            response => {
                this.props.changeUser!({ ...response });
                this.setState({ values: undefined });
                showNotification(message, "success");
            },
            () => this.props.history.push(`/${this.props.match.params.lang}/team-management`)
        );
    };

    /**
     * Called when the file was uploaded
     * @param file the file
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onFileUploaded = (file: File, group: string, name: string): void => {
        const { values } = this.state;
        let { deleteAvatar } = this.state;
        values![group][name] = file;
        if (file === undefined) {
            deleteAvatar = true;
        } else {
            deleteAvatar = false;
        }
        this.setState({ values, deleteAvatar });
    };
    checkFile = (file: File): boolean => {
        const isLessThan20MB = file.size / 1024 / 1024 <= 20;

        if (!isLessThan20MB) showNotification(this.props.intl.formatMessage({ defaultMessage: 'The file must be less than {size}MB' }, { size: 20 }), "warning");

        return isLessThan20MB;
    };
    removeFile = (fieldId: number, file: (File | string | GenericFile | ValidateFile)) => {
        this.setState({ loadingFiles: true });
        if (this.props.user?.id === this.props.userId) {
            if (!(file instanceof File) && typeof file !== 'string' && ['ValidateFile', 'GenericFile'].includes(file.type)) {
                Network.removeCompanyUserFile(this.props.userId, fieldId, file.type === 'GenericFile' ? file.id : file.fileId).then(
                    (response: { data: GenericFile[], error: boolean; }) => {
                        if (response.error === false) {
                            this.setState({ files: response.data, loadingFiles: false });
                        } else {
                            this.setState({ loadingFiles: false });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while uploading the files' }), "warning");
                        this.setState({ loadingFiles: false });

                    }
                );
            }
        }

    };
    validateFile = (fieldId: number, validateFile: ValidateFile[]) => {
        if (!this.state.loadingFiles) {
            this.setState({ loadingFiles: true });
        }
        if (this.props.user?.id === this.props.userId) {
            Network.validateCompanyUserFiles(this.props.userId, fieldId, validateFile).then(
                (response) => {
                    if (response.error === false && response.data) {
                        this.setState({ loadingFiles: false, files: response.data });
                    } else {
                        this.setState({ loadingFiles: false });
                    }
                },
                () => {
                    this.setState({ loadingFiles: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the images' }), "warning");
                }
            );
        } else {
            this.setState({ loadingFiles: false });
        }
    };
    uploadFile = (fieldId: number, file: (File | string | GenericFile | ValidateFile)) => {
        this.setState({ loadingFiles: true });

        if (file instanceof File && this.checkFile(file) && this.props.user?.id === this.props.userId) {
            const newFile: PreValidateFile = { index: 1, file: file, file_name: file.name, res_type: 'FLE', type: 'PreValidateFile', fieldId: fieldId };
            Network.putCompanyUserFile([newFile]).then(
                (response: ValidateFile[]) => {
                    this.validateFile(fieldId, response);
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while uploading the files' }), "warning");
                    this.setState({ loadingFiles: false });

                }
            );
        }
    };


    onDynamicFileExpiryDate = (fieldId: number, expiry: Moment | null) => {
        const { company } = this.props;
        const { files } = this.state;

        if (isNullOrEmpty(company?.userFields)) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while uploading the files' }), "warning");
            return;
        }

        const field = company?.userFields.find(field => field.id === fieldId && ['IMG', 'VDO', 'FLE'].includes(field.type));
        if (files === undefined && field === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        const file = files.find(f => f.fieldId == fieldId);
        if (file === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }
        const newFile: ValidateFile = { index: file.id, fileId: file.id, file_name: file.file_name, expiryDate: expiry ? expiry.endOf('day').format(MOMENT_FORMAT_TO_NETWORK) : undefined, res_type: 'FLE', type: 'ValidateFile', fieldId: fieldId };

        this.validateFile(fieldId, [newFile]);
    };

    onDynamicFileUploaded = (fieldId: number, file: File | undefined): void => {
        const { company } = this.props;
        const { values } = this.state;

        if (isNullOrEmpty(company?.userFields)) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        const field = company?.userFields.find(field => field.id === fieldId);
        if (field === undefined || values === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The files has been successfully disabled for your account' }), "error");
            return;
        }

        if (file === undefined) {
            if (values.files == undefined) {
                return;
            }

            const fileIndex = values.files.findIndex(f => f.field.id == fieldId);
            if (fileIndex === -1) {
                return;
            }

            values.files.splice(fileIndex, 1);
        } else {
            if (values.files == undefined) {
                values.files = [];
            }

            let fileIndex = values.files.findIndex(f => f.field.id == fieldId);
            if (fileIndex === -1) {
                fileIndex = 0;
                values.files.push({
                    id: -1,
                    field: field,
                    file: file
                });
            } else {
                values.files[fileIndex].file = file;
            }
        }

        this.setState({ values });
    };

    /**
     * Called when an InputField changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeInputField = (event: any, group: string, name: string): void => {
        const { values } = cloneDeep(this.state);
        let errorMsg = this.props.intl.formatMessage({ defaultMessage: 'The username is not valid' });

        values![group][name] = event.target.value;
        this.setState({ values }, () => {
            if (name === 'username') {
                if (event.target.value.length < 3) {
                    this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                    return;
                }
                Network.usernameIsValid(event.target.value, values?.user_id).then(
                    (response) => {
                        if (response.error !== undefined && response.error === true) {
                            let errorMsg = '';
                            if (response.message) {
                                errorMsg = response.message;
                            }
                            this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                        } else {
                            this.setState({ usernameIsValid: true });
                        }
                    },
                    (error) => {
                        console.log('ERROR', error);
                        if (error.message) {
                            try {
                                const jsonObject: { error: boolean, message: string | { username: string[]; }, status?: string, data?: any; } = JSON.parse(error.message);
                                if (jsonObject.message)
                                    if (typeof jsonObject.message === 'string') {
                                        errorMsg = jsonObject.message;
                                    } else if (jsonObject.message.username && jsonObject.message.username.length > 0) {
                                        errorMsg = jsonObject.message.username.join('\n');
                                    }

                            } catch (e) {
                                errorMsg = error.message;
                            }
                        }
                        console.log("ERROR2", errorMsg);
                        this.setState({ usernameIsValid: false, usernameIsValidError: errorMsg });
                    }
                );
            }
        });
    };

    /**
     * Called when an InputNumber changed
     * @param number the number
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeInputNumber = (number: number | undefined, group: string, name: string): void => {
        const { values } = this.state;
        values && (values[group][name] = number ? number : undefined);
        this.setState({ values });
    };

    onChangeBoolean = (event: CheckboxChangeEvent) => {
        const { values } = this.state;
        values!.job.is_current = event.target.checked;
        this.setState({ values });
    };

    /**
     * Called when a Select changed
     * @param value the option's value
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeSelect = (value: number | string | 'undefined', group: string, name: string): void => {
        const { values } = this.state;
        values![group][name] = value === 'undefined' ? undefined : value;
        this.setState({ values });
    };

    /**
     * Called when a DatePicker changed
     * @param date the selected date
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeDate = (date: Moment, group: string, name: string): void => {
        const { values } = this.state;
        if (date) {
            values![group][name] = date.format("YYYY-MM-DD");
        } else {
            values![group][name] = undefined;
        }
        this.setState({ values });
    };

    /**
     * Called when a Checkbox changed
     * @param event the triggered event
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onChangeCheckbox = (event: CheckboxChangeEvent, group: string, name: string): void => {
        const { values } = this.state;
        values![group][name] = event.target.checked;
        this.setState({ values });
    };

    /**
     * Called when a tag is created
     * @param tag the new tag's title
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onTagCreated = (tag: string, group: string, name: string): void => {
        const { values } = this.state;
        if (!values![group][name]) values![group][name] = [];
        tag = tag.trim();
        if (tag.length === 0) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Impossible operation' }), "error", this.props.intl.formatMessage({ defaultMessage: 'Impossible to add an empty field' }));
            return;
        } else if ((values![group][name] as string[]).findIndex(v => v.toLowerCase() === tag.toLowerCase()) !== -1) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Impossible operation' }), "error", this.props.intl.formatMessage({ defaultMessage: 'Impossible to add an empty field' }));
        } else {
            values![group][name].push(tag);
            this.setState({ values });
        }
    };

    /**
     * Called when a tag is deleted
     * @param index the index of the tag to delete
     * @param group the name of the first level key of this.state.values
     * @param name the name of the second level key of this.state.values
     */
    onTagClosed = (index: number, group: string, name: string): void => {
        const { values } = this.state;
        values![group][name].splice(index, 1);
        this.setState({ values });
    };

    /**
     * Decide wether or not to show the edit button
     */
    showButton = () => {
        const { values } = this.state;
        return values!.general.first_name && values!.general.first_name.length > 0 &&
            values!.general.last_name && values!.general.last_name.length > 0 &&
            values!.contact.email && values!.contact.email.length > 0 &&
            values!.general.username && values!.general.username.length > 0;
    };

    /**
     * Add an education section fields
     */
    addEducation = () => {
        const { values } = this.state;

        values!.educations.push({
            school: undefined,
            degree: undefined,
            start_date: undefined,
            end_date: undefined,
        });
        this.setState({ values });
    };

    /**
     * Called when an InputField of an education section changed
     * @param event the triggered event
     * @param index the index of the education
     * @param name the name of the second level key of this.state.values
     */
    educationOnChangeInputField = (event: any, index: number, name: string) => {
        const { values } = this.state;
        values!.educations[index][name] = event.target.value;
        this.setState({ values });
    };

    /**
     * Called when an RangePicker of an education section changed
     * @param date the selected dates
     * @param index the index of the education
     */
    educationOnChangeDate = (dates: Moment[], index: number) => {
        const { values } = this.state;
        values!.educations[index].start_date = dates[0];
        values!.educations[index].end_date = dates[1];
        this.setState({ values });
    };

    /**
     * Called when an education is deleted
     * @param index the index of the education
     */
    educationOnDelete = (index: number) => {
        const { values } = this.state;
        values!.educations.splice(index, 1);
        this.setState({ values });
    };

    /**
     * Called when the edit button is clicked
     * Submit data to API
     */
    handleOnSubmit = () => {
        //make a copy of values
        const { values, currentStaffTypes } = this.state;
        const { intl } = this.props;
        const user = cloneDeep(values);

        if (user === undefined) {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while loading the user, please reload the page' }), "error");
            return;
        }

        // if (values?.general.pic) {
        //     user.general.pic = values?.general.pic;
        // }

        // if (values?.job.contract) {
        //     user.job.contract = values?.job.contract;
        // }

        // if (user.general.doj) {
        //     user.general.doj = moment(user.general.doj).format("YYYY-MM-DD");
        // }

        // check that email as @
        if (user.contact.email && !user.contact.email.includes("@")) {
            showNotification(intl.formatMessage({ defaultMessage: 'The specified email is not valid' }), "error");
            return;
        }
        if (user.general.sex === undefined) {
            user.general.sex = 'undefined';
        }

        // start loading
        this.setState({ loading: true });

        //change the tags to strings with comma separated values
        Object.keys(user.about_me).forEach((k: string) => { if (user.about_me[k] instanceof Array) user.about_me[k] = user.about_me[k].join(","); });

        //delete empty education
        user.educations = user.educations.filter((e: UserEducation) => Object.keys(e).length > 0);

        //delete avatar
        if (this.state.deleteAvatar) {
            user.image = "TO_DELETE";
        }


        user.stafftypesIds = currentStaffTypes ? currentStaffTypes.filter(s => s.id).map(s => s.id!) : [];

        if (this.state.userFieldValues.length > 0) {
            user.inputs = this.completeUserBodyRequestWithInputs();
        }

        //update user
        Network.createUser(user).then(
            () => {
                this.setState({ loading: false });
                this.refresh(intl.formatMessage({ defaultMessage: 'The user has been successfully created' }));
            },
            e => {
                this.setState({ loading: false });
                switch (e.message) {
                    case "Email taken":
                        showNotification(intl.formatMessage({ defaultMessage: 'The email is already used by another user' }), "warning");
                        break;
                    case "Username taken":
                        showNotification(intl.formatMessage({ defaultMessage: 'The username is already used by another user' }), "warning");
                        break;
                    case "Email taken, Username taken":
                        showNotification(intl.formatMessage({ defaultMessage: 'The email and the username are already used by another user' }), "warning");
                        break;
                    default:
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the user' }), "warning");
                        break;
                }
            }
        );
    };

    /**
     * Delete the current user
     */
    deleteUser = () => {
        Network.deleteUser(this.props.user!).then(
            () => this.props.history.replace({ pathname: `/${this.props.match.params.lang}/team-management/list/users`, state: { successMessage: "Utilisateur supprimé avec succès" } }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the user' }), "warning"),
        );
    };

    /**
     * Reset the user password
     */
    resetPassword = () => {
        Network.userResetPassword(this.props.user!.email, this.props.match.params.lang).then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An email has been sent to the user so they can reset their password' }), "success");
                this.setState({ newPassword: "", openUpdatePasswordModal: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while sending the email' }), "warning");
            },
        );
    };

    /**
     * Update the password
     */
    updatePassword = () => {
        if (!this.state.passwordError) {
            Network.userUpdatePassword(this.props.userId, this.state.newPassword).then(
                () => {
                    this.setState({ openUpdatePasswordModal: false, newPassword: "" });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The password has been successfully updated' }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the password' }), "warning"),
            );
        }
    };

    changeJob = (i: number) => {
        const { user } = this.props;
        const { values } = this.state;
        let currentUserJob: UserJobTMP | undefined;
        user?.job?.forEach((e: UserJobTMP) => {
            if (e.id === i) currentUserJob = e;
        });
        values!.job = {
            id: currentUserJob?.id,
            weekly_working_hours: currentUserJob?.weekly_working_hours,
            yearly_vacation_days: currentUserJob?.yearly_vacation_days,
            overtime_hours: currentUserJob?.overtime_hours,
            work_rate: currentUserJob?.work_rate,
            contract: currentUserJob?.contract,
            contract_expiry_date: currentUserJob?.contract_expiry_date,
            date_in_report: currentUserJob?.date_in_report,
            notes: currentUserJob?.notes,
            is_current: currentUserJob?.is_current,
            name: currentUserJob?.name,
        };
        this.setState({ values, previousUserJob: currentUserJob });
    };

    createJob = () => {
        this.setState({ addingUserJob: true }, () => {
            const { values } = this.state;
            values!.job!.contract = undefined;
            values!.job!.contract_expiry_date = undefined;
            values!.job!.date_in_report = undefined;
            values!.job!.id = undefined;
            values!.job!.is_current = false;
            values!.job!.notes = undefined;
            values!.job!.overtime_hours = 0;
            values!.job!.weekly_working_hours = 42;
            values!.job!.work_rate = 100;
            values!.job!.yearly_vacation_days = 0;
            values!.job!.name = "";

            this.setState({ values });
        });
    };
    undoCreateJob = () => {
        const { values, previousUserJob } = this.state;

        values!.job = {
            id: previousUserJob?.id,
            weekly_working_hours: previousUserJob?.weekly_working_hours,
            yearly_vacation_days: previousUserJob?.yearly_vacation_days,
            overtime_hours: previousUserJob?.overtime_hours,
            work_rate: previousUserJob?.work_rate,
            contract: previousUserJob?.contract,
            contract_expiry_date: previousUserJob?.contract_expiry_date,
            date_in_report: previousUserJob?.date_in_report,
            notes: previousUserJob?.notes,
            is_current: previousUserJob?.is_current,
            name: previousUserJob?.name,
        };
        this.setState({ values, addingUserJob: false });
    };

    completeUserBodyRequestWithInputs = (): CreateUserInput[] => {
        const { userFieldValues } = this.state;
        const fields = this.getUserInputFields();

        const createFieldValue = (field: UserField, value: UserInput[keyof UserInput] | undefined): Omit<CreateUserInput, 'fieldId'> => {
            const fields: Omit<CreateUserInput, 'fieldId'> = {};

            switch (field.type) {
                case 'BOOL':
                    fields['booleanValue'] = value as boolean;
                    break;
                case 'CHAR':
                    fields['textValue'] = value as string;
                    break;
                case 'TEXT':
                    fields['textareaValue'] = value as string;
                    break;
                case 'DATE':
                    fields['dateValue'] = moment(value as string).format("YYYY-MM-DD");
                    break;
                case 'DATETIME':
                    fields['datetimeValue'] = value as string;
                    break;
                case 'FLOAT':
                    fields['floatNumberValue'] = value as number;
                    break;
                case 'INT':
                    fields['integerNumberValue'] = Math.round(value as number);
                    break;
                case 'TIME':
                    fields['timeValue'] = moment(value as string, 'HH:mm:ss').format(MOMENT_TIME_FORMAT);
                    break;
            }

            return fields;
        };

        const inputs: CreateUserInput[] = [];

        if (fields) {
            fields.forEach(field => {
                const userValue = userFieldValues.find(fv => fv.fieldId === field.id);
                if (userValue !== undefined) {
                    inputs.push({
                        fieldId: userValue.fieldId,
                        ...createFieldValue(field, userValue.value),
                    });
                } else if (field.isRequired) {
                    inputs.push({
                        fieldId: field.id,
                        ...createFieldValue(field, this.getDefaultUserFieldValue(field.id))
                    });
                }
            });
        }

        return inputs;
    };

    editUserInputFieldValue = (fieldId: number, newValue: UserFieldValue['value']) => {
        const newFieldValues = [...this.state.userFieldValues];
        const targetFieldIndex = newFieldValues.findIndex(f => f.fieldId === fieldId);
        if (targetFieldIndex >= 0) {
            newFieldValues[targetFieldIndex] = {
                ...newFieldValues[targetFieldIndex],
                value: newValue
            };
        } else {
            newFieldValues.push({
                fieldId,
                value: newValue
            });
        }

        this.setState({ userFieldValues: [...newFieldValues] });
    };

    getActualUserInputFieldValue = (fieldId: number): UserInput[keyof UserInput] | undefined => {
        const currentUserValue = this.getCurrentUserFieldValue(fieldId);
        if (currentUserValue !== undefined)
            return currentUserValue;

        return this.getDefaultUserFieldValue(fieldId);
    };


    getUserFieldEditedValue = (fieldId: number): UserInput[keyof UserInput] | null | undefined => {
        const { userFieldValues } = this.state;

        const fieldValue = userFieldValues.find(f => f.fieldId === fieldId);
        if (fieldValue !== undefined && fieldValue.value !== undefined)
            return fieldValue.value;

        const currentUserValue = this.getCurrentUserFieldValue(fieldId);
        if (currentUserValue !== undefined)
            return currentUserValue;

        return this.getDefaultUserFieldValue(fieldId);
    };

    getCurrentUserFieldValue = (fieldId: number): UserInput[keyof UserInput] | undefined => {
        const user: User = this.props.user as User;
        const fieldDefinition = this.props.company?.userFields?.find(f => f.id === fieldId);
        if (user?.id === this.props.userId && fieldDefinition) {
            const f = user.inputs?.find(field => field.fieldId === fieldId);

            if (f) {
                switch (fieldDefinition.type) {
                    case 'BOOL':
                        return f.booleanValue;
                    case 'CHAR':
                        return f.textValue;
                    case 'TEXT':
                        return f.textareaValue;
                    case 'DATE':
                        return f.dateValue;
                    case 'DATETIME':
                        return f.datetimeValue;
                    case 'FLOAT':
                        return f.floatNumberValue;
                    case 'TIME':
                        return f.timeValue;
                    case 'INT':
                        return f.integerNumberValue;
                }
            }
        }

        return undefined;
    };

    getDefaultUserFieldValue = (fieldId: number): UserFieldValue['value'] | undefined => {
        const f = this.getUserInputFields()?.find(f => f.id === fieldId);

        if (f) {
            switch (f.type) {
                case 'BOOL':
                    return f.defaultBooleanValue;
                case 'CHAR':
                    return f.defaultTextValue;
                case 'TEXT':
                    return f.defaultTextareaValue;
                case 'DATE':
                    return f.defaultDateValue;
                case 'DATETIME':
                    return f.defaultDatetimeValue;
                case 'FLOAT':
                    return f.defaultFloatNumberValue;
                case 'TIME':
                    return f.defaultTimeValue;
                case 'INT':
                    return f.defaultIntegerNumberValue;
            }
        }

        return undefined;
    };

    getUserFileFields = () => this.props.company?.userFields?.filter(f => ['IMG', 'VDO', 'FLE'].includes(f.type));

    getUserInputFields = () => this.props.company?.userFields?.filter(f => !['IMG', 'VDO', 'FLE'].includes(f.type));


    renderUserInputField = (field: UserField, editMode?: boolean): ReactNode => {
        if (editMode) {
            const currentFieldValue = this.getUserFieldEditedValue(field.id);

            const onChange = (newValue: UserInput[keyof UserInput]) => {
                // if (e && e.preventDefault) e.preventDefault();
                this.editUserInputFieldValue(field.id, newValue);
            };

            let input: ReactNode | undefined = undefined;

            if (currentFieldValue !== undefined) {
                switch (field.type) {
                    case 'CHAR':
                        input = <Input required={field.isRequired} type='text' value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                        break;
                    case 'TEXT':
                        input = <TextArea required={field.isRequired} value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                        break;
                    case 'DATETIME':
                        input = <DateTimePicker
                            key={currentFieldValue?.toString() !== '' ? `${field.id}_datetime_empty` : `${field.id}_datetime_filled`}
                            valueFrom={currentFieldValue ? moment(currentFieldValue.toString()) : undefined}
                            titleFrom=''
                            onChangeFrom={d => onChange(d?.toISOString() ?? '')}
                            showTime={true}
                            type={DateTimePickerType.PICKER}
                            version={DateTimePickerVersion.FIELD}
                            allowClear={false}
                        />;
                        break;
                    case 'DATE':
                        input = <DatePicker
                            style={{ width: '130px' }}
                            format={getFormat('DATE')}
                            value={currentFieldValue ? moment(currentFieldValue.toString()) : undefined}
                            allowClear={false}
                            onChange={d => onChange(d?.toISOString() ?? '')}
                        />;
                        break;
                    case 'TIME':
                        input = <TimePicker
                            style={{ width: '120px' }}
                            format={getFormat('TIME')}
                            value={currentFieldValue ? moment(currentFieldValue.toString(), 'HH:mm:ss') : undefined}
                            onChange={e => onChange(e?.format('HH:mm:ss') ?? '')}
                        />;
                        break;
                    case 'BOOL':
                        input = <Switch
                            checked={currentFieldValue as boolean}
                            onChange={(e) => onChange(e)}
                        />;
                        break;
                    case 'FLOAT':
                        input = <Input required={field.isRequired} type='number' value={currentFieldValue?.toString()} onChange={(e) => onChange(e.target.value)} />;
                        break;
                    case 'INT':
                        input = <Input required={field.isRequired} type='number' value={currentFieldValue?.toString()} onChange={(e) => onChange(parseInt(e.target.value))} />;
                        break;
                }
            }

            if (input) {
                return <>
                    <div style={{ display: 'flex', margin: '10px 0px', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p>{field.name} {field.isRequired ? '*' : ''}</p>
                        <div>{input}</div>
                    </div>
                </>;
            }

            return <></>;
        }
        else {
            const value = this.getActualUserInputFieldValue(field.id);

            let input: ReactNode | undefined = undefined;

            if (value !== undefined && value !== null) {
                switch (field.type) {
                    case 'CHAR':
                        input = <>{value.toString()}</>;
                        break;
                    case 'TEXT':
                        input = <span style={{ whiteSpace: 'pre-line' }}>{value.toString()}</span>;
                        break;
                    case 'DATETIME':
                        input = <>
                            {moment(value.toString()).format(getFormat('DATE_AND_TIME_SHORT'))}
                        </>;
                        break;
                    case 'DATE':
                        input = <>
                            {moment(value.toString(), '').format(getFormat('DATE'))}
                        </>;
                        break;
                    case 'TIME':
                        input = <>
                            {moment(value.toString(), 'HH:mm:ss').local(true).format(getFormat('TIME'))}
                        </>;
                        break;
                    case 'BOOL':
                        input = <Checkbox
                            checked={value as boolean}
                        />;
                        break;
                    case 'FLOAT':
                        input = <>{value.toString()}</>;
                        break;
                    case 'INT':
                        input = <>{value.toString()}</>;
                        break;

                }
            } else {
                input = <span style={{ color: 'grey', fontStyle: 'italic' }}>Aucune valeure</span>;
            }

            if (input) {
                return <>
                    <div style={{ display: 'flex', margin: '10px 0px', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p>{field.name}</p>
                        <div>{input}</div>
                    </div>
                </>;
            }

            return <></>;
        }
    };

    /**
     * Render the normal content
     * @returns the content to render
     */
    renderNormal = () => {
        const user: User = this.props.user as User;
        const { files } = this.state;
        const { company, countries, intl } = this.props;
        const concatenedFiled: (GenericFile | ValidateFile)[] = files;
        const userFileFields = this.getUserFileFields();
        const userInputFields = this.getUserInputFields();

        return (
            <>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'General'} />} icon={<IdcardOutlined />}>
                        <div className="flex-row-sp">
                            <div className="team-edit-user-general-left">
                                <span className="team-edit-user-item">
                                    <UserOutlined />
                                    <p>{`${user.last_name} ${user.first_name}`}</p>
                                </span>
                                <span className="team-edit-user-item">
                                    <MailOutlined />
                                    <p>{user.email}</p>
                                </span>
                                {user.doj &&
                                    <span className="team-edit-user-item">
                                        <GiftOutlined />
                                        <p><FormattedMessage defaultMessage={'Born on {date}'} values={{ date: moment(user.doj).format("DD MMMM YYYY") }} /></p>
                                    </span>}
                                {user.sex && (user.sex === 'M' || user.sex === 'F' || user.sex === 'X') &&
                                    <span className="team-edit-user-item">
                                        <PiGenderIntersex />
                                        <p>{user.sex === 'M' ? <FormattedMessage defaultMessage={'Male'} /> : user.sex === 'F' ? <FormattedMessage defaultMessage={'Female'} /> : <FormattedMessage defaultMessage={'Not gendered'} />}</p>
                                    </span>
                                }
                                <span className="team-edit-user-item">
                                    <ApartmentOutlined />
                                    <p>{user.role === 1 ? <FormattedMessage defaultMessage={'User'} /> : <FormattedMessage defaultMessage={'Administrator'} />}</p>
                                </span>
                            </div>
                            <div className="team-edit-user-general-right">
                                <Avatar size={100} src={user.image} icon={<UserOutlined />} />
                                <p>{user.username}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'About'} />} icon={<ProfileOutlined />}>
                        <div className="team-edit-user-about">
                            {user.about?.expertise &&
                                <div>
                                    <span className="team-edit-user-item team-edit-user-list-title">
                                        <ExperimentOutlined />
                                        <p><FormattedMessage defaultMessage={'Expertises'} /></p>
                                    </span>
                                    <ul className="team-edit-user-list">
                                        {user.about!.expertise!.split(',').map((e, i) => <li key={`team-edit-user-expertises-${i}`}>{e}</li>)}
                                    </ul>
                                </div>
                            }
                            {user.about?.skills &&
                                <div>
                                    <span className="team-edit-user-item team-edit-user-list-title">
                                        <ThunderboltOutlined />
                                        <p><FormattedMessage defaultMessage={'Abilities'} /></p>
                                    </span>
                                    <ul className="team-edit-user-list">
                                        {user.about!.skills!.split(',').map((s, i) => <li key={`team-edit-user-skills-${i}`}>{s}</li>)}
                                    </ul>
                                </div>
                            }
                            {user.about?.dept &&
                                <div>
                                    <span className="team-edit-user-item team-edit-user-list-title">
                                        <BankOutlined />
                                        <p><FormattedMessage defaultMessage={'Departments'} /></p>
                                    </span>
                                    <ul className="team-edit-user-list">
                                        {user.about!.dept!.split(',').map((d: string, i: number) => <li key={`team-edit-user-dept-${i}`}>{d}</li>)}
                                    </ul>
                                </div>
                            }
                            {user.staffType && user.staffType.length > 0 &&
                                <div>
                                    <span className="team-edit-user-item team-edit-user-list-title">
                                        <AiOutlineExperiment className="anticon" />
                                        <p><FormattedMessage defaultMessage={'Skills'} /></p>
                                    </span>
                                    <ul className="team-edit-user-list">
                                        {user.staffType.map((staffStype: StaffType, i: number) => <li key={`team-edit-user-dept-${i}`}>{staffStype.name}</li>)}
                                    </ul>
                                </div>
                            }
                            {user.group_users && user.group_users.length > 0 &&
                                <div>
                                    <span className="team-edit-user-item team-edit-user-list-title">
                                        <TeamOutlined className="anticon" />
                                        <p><FormattedMessage defaultMessage={'Groups'} /></p>
                                    </span>
                                    <ul className="team-edit-user-list">
                                        {user.group_users.map((group_users: GroupUser, i: number) => <li key={`team-edit-user-dept-${i}`}>{group_users.group_name}</li>)}
                                    </ul>
                                </div>
                            }
                        </div>

                    </Card>
                </Col>
                {
                    (user.contact?.location || user.contact?.phone || user.contact?.mobile || user.contact?.address || user.contact?.zip || user.contact?.country) &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Card title={"Contact"} icon={<HomeOutlined />}>
                            <div className="team-create-user-body">

                                {(user.contact?.address || user.contact?.zip || user.contact?.country || user.contact?.location) &&
                                    <div className="team-edit-user-item" style={{ alignItems: 'baseline' }}>
                                        <HomeOutlined />
                                        <div>
                                            {user.contact?.address && <p>{`${user.contact?.address}`}</p>}
                                            {(user.contact?.zip || user.contact?.location) && <p>{`${user.contact?.zip || ''} ${user.contact?.location || ''}`}</p>}
                                            {user.contact?.country && <p>{`${user.contact?.country}${countries && countries.data && ` - ${countries.data.find(c => c.code === user.contact?.country)?.name}`}`}</p>}
                                        </div>
                                    </div>
                                }
                                {user.contact?.phone &&
                                    <span className="team-edit-user-item">
                                        <PhoneOutlined />
                                        <p>{user.contact!.phone}</p>
                                    </span>}
                                {user.contact?.mobile &&
                                    <span className="team-edit-user-item">
                                        <MobileOutlined />
                                        <p>{user.contact!.mobile}</p>
                                    </span>}
                            </div>
                        </Card>
                    </Col>
                }
                {
                    (user.about?.bio || user.educations) &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Card title={<FormattedMessage defaultMessage={'Other'} />} icon={<PaperClipOutlined />}>
                            <div className="team-create-user-body">
                                {user?.checkEventsOverlap !== undefined &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <Checkbox checked={user?.checkEventsOverlap} className="checkbox-readonly">
                                            <FormattedMessage defaultMessage={'Check events overlap on event creation/copy'} />
                                        </Checkbox>
                                    </span>
                                }
                                {user?.team_visibility !== undefined &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <Checkbox checked={user?.team_visibility} className="checkbox-readonly">
                                            <FormattedMessage defaultMessage={'Visible in the team tab (mobile application)'} />
                                        </Checkbox>
                                    </span>
                                }
                                {user.role === 1 && user?.can_edit_planning !== undefined &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <Checkbox checked={user?.can_edit_planning} className="checkbox-readonly">
                                            <FormattedMessage defaultMessage={'The user can modify his planning'} />
                                        </Checkbox>
                                    </span>
                                }
                                {user.role === 1 && user?.can_read_only_all_planning !== undefined &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <Checkbox checked={user?.can_read_only_all_planning} className="checkbox-readonly">
                                            <FormattedMessage defaultMessage={'The user can consult the global schedule (without modifying it)'} />
                                        </Checkbox>
                                    </span>
                                }
                                {user.about?.bio &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <SolutionOutlined />
                                        <p>{user.about.bio}</p>
                                    </span>
                                }
                                {user.educations && user.educations.length > 0 &&
                                    <div>
                                        <span className="team-edit-user-item team-edit-user-education-title">
                                            <AuditOutlined />
                                            <p><FormattedMessage defaultMessage={'Educations'} /></p>
                                        </span>
                                        {user.educations.map((e: UserEducation, i: number) => (
                                            <div className="team-edit-user-education" key={`team-edit-user-education-${i}`}>
                                                {i !== 0 && <div className="team-create-user-educations-divider" />}
                                                {e.school && <span className="team-edit-user-item">
                                                    <BankOutlined />
                                                    <p>{e.school}</p>
                                                </span>}
                                                {e.degree && <span className="team-edit-user-item">
                                                    <BookOutlined />
                                                    <p>{e.degree}</p>
                                                </span>}
                                                {e.start_date && e.end_date && <span className="team-edit-user-item">
                                                    <CalendarOutlined />
                                                    <p><FormattedMessage defaultMessage={'From {start} to {end}'} values={{ start: moment(e.start_date).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')), end: moment(e.end_date).format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')) }} /></p>
                                                </span>}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </Card>
                    </Col>
                }
                {company && userFileFields && userFileFields.length > 0 &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <div style={{ position: 'absolute', right: '30px', top: '25px', zIndex: '10' }}>
                            <Popover content={<><FormattedMessage defaultMessage={'Contact support@webevolutions.ch to request additional files'} /><br /></>} placement='bottomRight'>
                                <FAIcon prefix="far" name="circle-question" style={{ fontSize: '140%' }} color='gray' />
                            </Popover>
                        </div>
                        <Card title={<FormattedMessage defaultMessage={'Files'} />} icon={<FolderOutlined />}>
                            <div className="team-create-user-body" style={{ marginTop: '15px' }}>
                                <div>
                                    <Row gutter={[10, 10]}>
                                        {userFileFields.map(fileField => {
                                            const file = concatenedFiled?.find(file => file.fieldId === fileField.id);
                                            return (

                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }} key={`file-field-${fileField.id}`}>
                                                    <Bloc title={fileField.name}>
                                                        <div>
                                                            <span className="team-create-user-group team-create-user-group-inline">
                                                                <p><FormattedMessage defaultMessage={'File'} /></p>
                                                                <FileUploader
                                                                    className='__fullwidth-file-uploader'
                                                                    containerStyle={{ minWidth: '140px', width: '190px', maxWidth: '260px' }}
                                                                    file={file}
                                                                    accept=".pdf"
                                                                    loading={this.state.loadingFiles}
                                                                    deleteTextMaxWidth={140}
                                                                    onUploaded={(newFile) => {
                                                                        if (newFile !== undefined) {
                                                                            this.uploadFile(fileField.id, newFile);
                                                                        } else if (file !== undefined) {
                                                                            this.removeFile(fileField.id, file);
                                                                        }
                                                                    }} />
                                                            </span>

                                                            <span className="team-create-user-group team-create-user-group-inline">
                                                                <p><FormattedMessage defaultMessage={'Expiration date'} /></p>
                                                                <DatePicker
                                                                    disabled={file === undefined || this.state.loadingFiles}
                                                                    placeholder={intl.formatMessage({ defaultMessage: 'Expiration date' })}
                                                                    style={{ maxWidth: '180px' }}
                                                                    format={MOMENT_FORMAT_DISPLAY_DATE}
                                                                    value={file !== undefined && file.fieldExpiryDate ? moment(file.fieldExpiryDate) : undefined}
                                                                    onChange={(date) => this.onDynamicFileExpiryDate(fileField.id, date)} />
                                                            </span>
                                                        </div>
                                                    </Bloc>
                                                </Col>


                                            );
                                        })}
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </Col>
                }
                {this.props.company && userInputFields && userInputFields.length > 0 &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <div style={{ position: 'absolute', right: '30px', top: '25px', zIndex: '10' }}>
                            <Popover content={<><FormattedMessage defaultMessage={'Contact'} description='Additional fields tooltip' /> <a rel='noreferrer' href='mailto:support@webevolutions.ch' target="_blank" style={{ color: 'var(--primary-color)' }}>support@webevolutions.ch</a> <FormattedMessage defaultMessage={'to request additional fields'} description='Additional fields tooltip' /><br /></>} placement='bottomRight'>
                                <FAIcon prefix="far" name="circle-question" style={{ fontSize: '140%' }} color='gray' />
                            </Popover>
                        </div>
                        <Card title={<FormattedMessage defaultMessage={'Additional fields'} />} icon={<FileTextOutlined />}>
                            <div className="team-create-user-body">
                                <div>
                                    <div>
                                        {userInputFields.map(fileField => {
                                            return (<>
                                                {this.renderUserInputField(fileField)}
                                            </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                }
                {
                    checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) &&
                    checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Card title={<FormattedMessage defaultMessage={'Timeclock'} />} icon={<PaperClipOutlined />}>
                            <div className="team-create-user-body">
                                <span className="team-edit-user-item team-edit-user-bio">
                                    <Checkbox checked={user?.hasPin} className="checkbox-readonly">
                                        {user?.hasPin ? <FormattedMessage defaultMessage={'The user has a timeclock PIN'} /> : <FormattedMessage defaultMessage={'The user has no PIN'} />}
                                    </Checkbox>
                                </span>
                                {this.props.currentUser && ([2, 3].includes(this.props.currentUser.role) || user.id === this.props.currentUser.id) &&
                                    <span className="team-edit-user-item team-edit-user-bio">
                                        <Button
                                            className="fade-in-animation"
                                            style={{ animationDuration: '0.5s', marginRight: "5px", verticalAlign: "middle" }}
                                            loading={false}
                                            type="primary"
                                            onClick={() => this.setState({ displayPinModal: true })}
                                            icon={<AiFillEye className={'anticon'} />}
                                        >{user?.hasPin ? <FormattedMessage defaultMessage={'Show PIN'} /> : <FormattedMessage defaultMessage={'Generate PIN'} />}</Button>
                                    </span>
                                }

                            </div>
                        </Card>
                    </Col>
                }
            </>
        );
    };

    disableDateBeforeNow = (date: any): boolean => {
        return (date && moment(date).isSameOrBefore(moment(), 'day'));
    };

    /**
     * Render the content when editing
     * @returns the content to render
     */
    renderEdition = (): React.ReactNode => {
        const { files } = this.state;
        const { values, staffTypes } = this.state;
        const { countries, intl } = this.props;
        // const { Option } = Select;
        // const staffType: StaffType[] = [
        //     { id: 1, nbTimes: 2, name: 'Signaleur' },
        //     { id: 2, nbTimes: 2, name: 'Premiers secours' },
        //     { id: 3, nbTimes: 2, name: 'Sécurité' },
        // ];

        const userFileFields = this.getUserFileFields();
        const userInputFields = this.getUserInputFields();

        const xxxl = this.props.windowWidth > WIDTH_3_COL ? 8 : 12;
        const concatenedFiled: (GenericFile | ValidateFile)[] = files;
        return (
            <>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'General'} />} icon={<IdcardOutlined />}>
                        <div className="team-create-user-body">
                            <span className="team-create-user-group team-create-user-group-inline">
                                <p><FormattedMessage defaultMessage={'Avatar'} /></p>
                                <ImageUploader className="team-create-user-uploader" image={values?.general.pic} onUploaded={(i: any) => this.onFileUploaded(i, "general", "pic")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'First name'} /></p>
                                <InputField value={values?.general.first_name} placeholder={"Prénom"} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "general", "first_name")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Last name'} /></p>
                                <InputField value={values?.general.last_name} placeholder={"Nom"} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "general", "last_name")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Username'} /></p>
                                <InputField error={!this.state.usernameIsValid ? this.state.usernameIsValidError : undefined} value={values?.general.username} placeholder={intl.formatMessage({ defaultMessage: 'Username' })} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "general", "username")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Email'} /></p>
                                <InputField value={values?.contact.email} placeholder={"Email"} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "contact", "email")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Birthdate'} /></p>
                                <DatePicker
                                    value={values?.general.doj ? moment(values.general.doj) : undefined}
                                    format={getFormat('DATE')}
                                    className="team-create-user-inputs-large"
                                    onChange={(m: any) => this.onChangeDate(m, "general", "doj")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Sex'} /></p>
                                <Can rule={Rules.TeamManagement.MakeAdmin} otherwise={<p>{values?.general.role === 1 ? "Utilisateur" : "Admin"}</p>}>
                                    <Select defaultValue={'undefined'} defaultActiveFirstOption value={values?.general.sex || 'undefined'} className="team-create-user-inputs-large" onChange={(v: 'M' | 'F' | 'X' | 'undefined') => this.onChangeSelect(v, "general", "sex")}>
                                        <Select.Option default value={'undefined'}><FormattedMessage defaultMessage={'Not defined'} /></Select.Option>
                                        <Select.Option value={'M'}><FormattedMessage defaultMessage={'Male'} /></Select.Option>
                                        <Select.Option value={'F'}><FormattedMessage defaultMessage={'Female'} /></Select.Option>
                                        <Select.Option value={'X'}><FormattedMessage defaultMessage={'Not gendered'} /></Select.Option>
                                    </Select>
                                </Can>
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Role'} /></p>
                                <Can rule={Rules.TeamManagement.MakeAdmin} otherwise={<p>{values?.general.role === 1 ? "Utilisateur" : "Admin"}</p>}>
                                    <Select value={values?.general.role} className="team-create-user-inputs-large" onChange={(v: number) => this.onChangeSelect(v, "general", "role")}>
                                        <Select.Option value={1}><FormattedMessage defaultMessage={'User'} /></Select.Option>
                                        <Select.Option value={2}><FormattedMessage defaultMessage={'Administrator'} /></Select.Option>
                                    </Select>
                                </Can>
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'About'} />} icon={<ProfileOutlined />}>
                        <div className="team-create-user-body">
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Expertise'} /></p>
                                <TagField tags={values?.about_me.expertise as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "expertise")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "expertise")} className="team-create-user-inputs-large" withoutAnimation />
                            </span>
                            {/* <span className="team-create-user-group">
                                <p>{"Compétences"}</p>
                                <Select
                                    className='team-create-user-inputs-large'
                                    // disabled={this.props.loading}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder="Compétences à assigner"
                                    style={{ width: '100%' }}
                                    // value={this.state.selectGroups}
                                    // onChange={this.changeGroup}
                                    filterOption={true}
                                    optionFilterProp="label"
                                    showArrow
                                >
                                    {
                                        staffType.map((g) => {
                                            return <Option label={g.name} value={g.id!} key={"stafftype-" + g.id}>{g.name}</Option>
                                        })
                                    }
                                </Select>
                                <TagField tags={values?.about_me.skills as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "skills")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "skills")} className="team-create-user-inputs-large" withoutAnimation />
                            </span> 
                            */}
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Department'} /></p>
                                <TagField tags={values?.about_me.dept as string[]} onTagCreated={(t: string) => this.onTagCreated(t, "about_me", "dept")} onTagClosed={(t: string, i: number) => this.onTagClosed(i, "about_me", "dept")} className="team-create-user-inputs-large" withoutAnimation />
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Contact'} />} icon={<HomeOutlined />}>
                        <div className="team-create-user-body">
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Address'} /></p>
                                <InputField type={'textarea'} value={values?.contact.address} placeholder={intl.formatMessage({ defaultMessage: 'Address' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "address")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'NPA'} /></p>
                                <InputField value={values?.contact.zip} placeholder={intl.formatMessage({ defaultMessage: 'NPA' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "zip")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'City'} /></p>
                                <InputField value={values?.contact.location} placeholder={intl.formatMessage({ defaultMessage: 'City' })} className="team-create-user-inputs-large" onChange={(e: any) => this.onChangeInputField(e, "contact", "location")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Country'} /></p>
                                <Select
                                    className="team-create-user-inputs-large"
                                    showSearch
                                    allowClear
                                    showArrow
                                    maxTagCount='responsive'
                                    placeholder={countries.updated === undefined || countries.data.length === 0 ? <FormattedMessage defaultMessage={'No country available'} /> : <FormattedMessage defaultMessage={'Select a country'} />}
                                    disabled={countries.updated === undefined || countries.data.length === 0}
                                    onChange={(code: string) => this.onChangeSelect(code, "contact", "country")}
                                    value={values?.contact.country}
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {countries.data?.sort((a, b) => a.code === 'CH' ? -1 : b.code === 'CH' ? 1 : (a.code <= b.code) ? -1 : 1).map(c => <Select.Option value={c.code} label={c.name} key={`team-create-user-promotions-country-${c.code}`}>{c.name}</Select.Option>)}
                                </Select>
                            </span>

                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Phone number'} /></p>
                                <InputField value={values?.contact.phone} placeholder={intl.formatMessage({ defaultMessage: 'Phone number' })} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "contact", "phone")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Cell phone number'} /></p>
                                <InputField value={values?.contact.mobile} placeholder={intl.formatMessage({ defaultMessage: 'Cell phone number' })} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "contact", "mobile")} />
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                    <Card title={<FormattedMessage defaultMessage={'Other'} />} icon={<PaperClipOutlined />}>
                        <div className="team-create-user-body">
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Check events overlap on event creation/copy'} /></p>
                                <Checkbox checked={values?.general.check_events_overlap} onChange={e => this.onChangeCheckbox(e, "general", "check_events_overlap")} />
                            </span>
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Visible in the team tab (mobile application)'} /></p>
                                <Checkbox checked={values?.general.team_visibility} onChange={e => this.onChangeCheckbox(e, "general", "team_visibility")} />
                            </span>
                            {
                                values?.general.role && values.general.role === 1 &&
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'The user can modify his planning'} /></p>
                                    <Checkbox checked={values?.general.can_edit_planning} onChange={e => this.onChangeCheckbox(e, "general", "can_edit_planning")} />
                                </span>
                            }
                            {
                                values?.general.role && values.general.role === 1 &&
                                <span className="team-create-user-group">
                                    <p><FormattedMessage defaultMessage={'The user can consult the global planning (without modifying it)'} /></p>
                                    <Checkbox checked={values?.general.can_read_only_all_planning} onChange={e => this.onChangeCheckbox(e, "general", "can_read_only_all_planning")} />
                                </span>
                            }
                            <span className="team-create-user-group">
                                <p><FormattedMessage defaultMessage={'Biography'} /></p>
                                <InputField type="textarea" value={values?.bio.bio} placeholder={intl.formatMessage({ defaultMessage: 'Biography' })} className="team-create-user-inputs-large" onChange={(e) => this.onChangeInputField(e, "bio", "bio")} />
                            </span>
                            <div className="team-create-user-body">
                                <span className="team-create-user-group team-create-user-group-inline">
                                    <p><FormattedMessage defaultMessage={'Education'} />{':'}</p>
                                    {(values!.educations.filter((e) => e.school && e.school.length > 0 && e.degree && e.degree.length > 0 && e.start_date && e.end_date).length === values!.educations.length) && <CircleButton placement="bottomRight" small icon={<PlusOutlined />} title={"Ajouter une éducation"} onClick={this.addEducation} />}
                                </span>
                                {
                                    values && values.educations.length > 0 ?
                                        <Row gutter={[10, 10]} style={{ marginTop: '10px' }}>
                                            {
                                                values?.educations.map((e: any, index: number) => (
                                                    <Col xs={{ span: 24 }} key={`team-create-user-educations-${index}`}>
                                                        {index !== 0 ? <div className="team-create-user-educations-divider" /> : null}
                                                        <Bloc title={e.degree ?? `${<FormattedMessage defaultMessage={'Education'} />} 1`} >
                                                            <div className="flex-row">
                                                                <div style={{ width: 'calc(100% - 50px)' }}>
                                                                    <span className="team-create-user-group">
                                                                        <p><FormattedMessage defaultMessage={'School'} /></p>
                                                                        <InputField value={e.school} placeholder={"École"} className="team-create-user-inputs-large" onChange={(e) => this.educationOnChangeInputField(e, index, "school")} />
                                                                    </span>
                                                                    <span className="team-create-user-group">
                                                                        <p><FormattedMessage defaultMessage={'Degree'} /></p>
                                                                        <InputField value={e.degree} placeholder={"Diplome"} className="team-create-user-inputs-large" onChange={(e) => this.educationOnChangeInputField(e, index, "degree")} />
                                                                    </span>
                                                                    <span className="team-create-user-group">
                                                                        <p><FormattedMessage defaultMessage={'Date'} /></p>
                                                                        <DatePicker.RangePicker
                                                                            allowClear={false}
                                                                            className="team-create-user-inputs-large"
                                                                            value={[e.start_date, e.end_date]}
                                                                            placeholder={[intl.formatMessage({ defaultMessage: 'Start' }), intl.formatMessage({ defaultMessage: 'End' })]}
                                                                            format={getFormat('DATE_SHORT')}
                                                                            onChange={(m: any) => this.educationOnChangeDate(m, index)} />
                                                                    </span>
                                                                </div>
                                                                <CircleButton
                                                                    style={{ alignSelf: 'flex-start', marginTop: '10px' }}
                                                                    small
                                                                    icon={<DeleteOutlined />}
                                                                    title={intl.formatMessage({ defaultMessage: 'Delete' })}
                                                                    onClick={() => this.educationOnDelete(index)} />
                                                            </div>
                                                        </Bloc>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        :
                                        <span style={{ fontStyle: 'italic' }}><FormattedMessage defaultMessage={'No information'} /></span>
                                }

                            </div>
                        </div>
                    </Card>
                </Col>
                {
                    this.props.company && userFileFields && userFileFields.length > 0 ?
                        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Card title={<FormattedMessage defaultMessage={'Files'} />} icon={<FolderOutlined />}>
                                <div className="team-create-user-body" style={{ marginTop: '15px' }}>
                                    <Row gutter={[10, 10]} style={{ marginTop: '15px' }}>
                                        {
                                            userFileFields.map(fileField => {
                                                const file = concatenedFiled?.find(file => file.fieldId === fileField.id);
                                                return (
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }} key={`file-field-${fileField.id}`}>
                                                        <Bloc title={fileField.name}>
                                                            <div>
                                                                <span className="team-create-user-group team-create-user-group-inline">
                                                                    <p><FormattedMessage defaultMessage={'File'} /></p>
                                                                    <FileUploader
                                                                        className='__fullwidth-file-uploader'
                                                                        containerStyle={{ minWidth: '140px', width: '190px', maxWidth: '260px' }}
                                                                        file={file}
                                                                        accept=".pdf"
                                                                        deleteTextMaxWidth={140}
                                                                        loading={this.state.loadingFiles}
                                                                        onUploaded={(newFile) => {
                                                                            if (newFile !== undefined) {
                                                                                this.uploadFile(fileField.id, newFile);
                                                                            } else if (file !== undefined) {
                                                                                this.removeFile(fileField.id, file);
                                                                            }
                                                                        }} />
                                                                </span>

                                                                <span className="team-create-user-group team-create-user-group-inline">
                                                                    <p><FormattedMessage defaultMessage={'Expiration date'} /></p>
                                                                    <DatePicker
                                                                        disabled={file === undefined || this.state.loadingFiles}
                                                                        placeholder={intl.formatMessage({ defaultMessage: 'Expiration date' })}
                                                                        style={{ maxWidth: '180px' }}
                                                                        format={getFormat('DATE')}
                                                                        disabledDate={this.disableDateBeforeNow}
                                                                        value={file !== undefined && file.fieldExpiryDate ? moment(file.fieldExpiryDate) : undefined}
                                                                        onChange={(date) => this.onDynamicFileExpiryDate(fileField.id, date)} />
                                                                </span>
                                                            </div>
                                                        </Bloc>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        : null
                }
                {this.props.company && userInputFields && userInputFields.length > 0 &&
                    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                        <Card title={<FormattedMessage defaultMessage={'Additional fields'} />} icon={<FileTextOutlined />}>
                            <div className="team-create-user-body">
                                <div>
                                    <div>
                                        {userInputFields.map(fileField => {
                                            return (<>
                                                {this.renderUserInputField(fileField, true)}
                                            </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                }
                <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: xxxl }}>
                    <Card title={<FormattedMessage defaultMessage={'Abilities'} />} icon={<Anticon icon={<AiOutlineExperiment />} />}>
                        {
                            this.state.staffTypes == null || this.state.staffTypes?.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'Your company does not own any abilities'} />} />
                                :
                                <>
                                    <p style={{ marginBottom: '10px' }}>Veuillez choisir le ou les aptitudes que l'utilisateur possède, à partir de la liste des aptitudes disponibles pour votre entreprise, qui est présentée ci-dessus.</p>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={<FormattedMessage defaultMessage={'Abilities'} />}
                                        autoClearSearchValue={false}
                                        style={{ width: '100%' }}
                                        value={values?.stafftypesIds}
                                        onChange={this.onChangeStaffTypes}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                        options={staffTypes?.map((p: StaffType) => ({ label: p.name, value: p.id }))}
                                    />
                                </>
                        }
                    </Card>
                </Col>
            </>
        );
    };

    render() {
        const { values } = this.state;
        const { intl } = this.props;
        return (
            <>
                <Row gutter={[20, 20]} style={{ position: 'relative' }}>
                    {
                        values ?
                            this.renderEdition()
                            :
                            this.renderNormal()
                    }
                    {
                        values && this.showButton() &&
                        <div style={{ position: 'sticky', left: 0, bottom: '10px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Space>
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={() => this.setState({ values: undefined })}
                                    disabled={this.state.loading}>
                                    <FormattedMessage defaultMessage={'Cancel'} />
                                </Button>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    disabled={!this.state.usernameIsValid}
                                    onClick={this.handleOnSubmit}
                                    loading={this.state.loading}>
                                    <> <FormattedMessage defaultMessage={'Save'} /></>
                                </Button>
                            </Space>
                        </div>
                    }
                    {
                        values ?
                            null
                            :
                            <SpaceContent className="team-create-user-speed-dial team-header-button" >
                                <DeleteButton
                                    key={`team-edit-user-tab-action-delete`}
                                    text={<FormattedMessage defaultMessage={'This action will delete the user and all his events, periods and exclusions.'} />}
                                    onConfirm={this.deleteUser}
                                    placement="bottomLeft" />
                                <CircleButton title={intl.formatMessage({ defaultMessage: 'Edit' })} icon={<EditOutlined />} onClick={this.userToValues} />
                                <CircleButton withoutTooltip key={`team-edit-user-tab-action-modify-password`} title={intl.formatMessage({ defaultMessage: 'Edit password' })} icon={<span style={{ position: 'relative' }}><LockOutlined /><EditOutlined className="team-create-user-password" /></span>} onClick={() => this.setState({ openUpdatePasswordModal: true, passwordError: true })} />

                            </SpaceContent>
                    }

                    <Modal
                        keyboard={false}
                        maskClosable={false}
                        closable={false}
                        title={<FormattedMessage defaultMessage={'Edit password'} />}
                        open={this.state.openUpdatePasswordModal}
                        destroyOnClose={true}
                        footer={[
                            <div key={`password-modify-actions`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}>
                                <Button type="link" style={{ paddingLeft: 0 }} onClick={() => this.setState({ displayResetPasswordEmailModal: true })}>
                                    <FormattedMessage defaultMessage={'Reset by e-mail'} />
                                </Button>
                                <SpaceContent space={0}>
                                    <Button type="dashed" onClick={() => this.setState({ openUpdatePasswordModal: false, newPassword: "" })}>
                                        <FormattedMessage defaultMessage={'Cancel'} />
                                    </Button>
                                    <Button disabled={this.state.passwordError} type="primary" onClick={this.updatePassword}>
                                        <FormattedMessage defaultMessage={'Save'} />
                                    </Button>
                                </SpaceContent>
                            </div>
                        ]}

                    >
                        <div>
                            <Password
                                setError={(value) => this.setState({ passwordError: value })}
                                placeholder={intl.formatMessage({ defaultMessage: 'New password' })}
                                password={this.state.newPassword}
                                onChangePassword={value => this.setState({ newPassword: value })}
                                onPressEnter={this.updatePassword}
                                repeat
                            />
                            {/* <InputField
                                value={this.state.newPassword}
                                type="password"
                                placeholder={"Nouveau mot de passe"}
                                onChange={(e: InputFieldOnChangeEvent) => this.setState({ newPassword: e.target.value })}
                                onPressEnter={this.updatePassword} /> */}
                        </div>
                    </Modal >
                </Row >
                {this.state.displayPinModal && this.props.currentUser && <DisplayPinModal userId={this.props.user ? this.props.user?.id : this.props.currentUser.id} onClose={() => this.setState({ displayPinModal: false })} refresh={this.refresh} />}
                <Modal
                    open={this.state.displayResetPasswordEmailModal}
                    title={<><InfoCircleOutlined style={{ color: 'var(--primary-color)', marginRight: '20px' }} /> <FormattedMessage defaultMessage={'Send a password reset email'} /></>}
                    closable={false}
                    maskClosable={false}
                    okText={<FormattedMessage defaultMessage={'Send'} />}
                    onOk={() => {
                        this.resetPassword();
                        this.setState({ displayResetPasswordEmailModal: false });
                    }}
                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                    onCancel={() => this.setState({ displayResetPasswordEmailModal: false })}
                >
                    <FormattedMessage defaultMessage={'Do you want to send a password reset email to {user}?'} values={{ user: `${this.props.user?.last_name} ${this.props.user?.first_name} (${this.props.user?.email})` }} />
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
    loadCountries: () => dispatch(loadCountries())
});

const mapStateToProps = (state: ApplicationState) => ({
    windowWidth: state.window.width,
    company: state.user.company,
    currentUser: state.user.currentUser,
    user: state.teamManagement.user,
    countries: state.configurations.countries
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(injectIntl(EditUserTab)));