import { Alert } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import * as XLSX from 'xlsx';
import { CaseType } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import { EventForProject } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { getCaseAndPlural, val } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import { getDataFromTableColumns, getTitleFromTableColumns } from '../projectReports';
import { AbstractReportsGrouped, AbstractReportsGroupedProps } from './abstractReportsGrouped';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, AbstractReportsGroupedProps {
}

interface State { }

export class ReportsNotGroupedCore extends AbstractReportsGrouped<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    handleTableChange = (page: number, pageSize: number) => this.setState({ pagination: { currentPage: page, pageSize } });

    downloadProjectEvents = () => {
        const { intl, eventsForProjects } = this.props;

        if (eventsForProjects) {
            exportToExcel(eventsForProjects, this.eventsColumns, intl);
        }
    }

    render() {
        const { height, eventsForProjects } = this.props;

        const pageSize = Math.round((height - 175) / 50);

        const totalEffectiveSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.effectiveSeconds);
        }, 0.0);
        const totalOvertimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.totalSecondsWithOvertimes) - val(event.totalSeconds);
        }, 0.0);
        const totalPaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            return prevValue + val(event.totalSecondsWithoutPaidBreaktimes);
        }, 0.0);
        const totalUnpaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => {
            const totalSeconds = val(event.totalSeconds);
            const totalSecondsWithoutUnpaidBreaktimes = val(event.totalSecondsWithoutUnpaidBreaktimes);
            if (totalSeconds > totalSecondsWithoutUnpaidBreaktimes) {
                return prevValue + val(totalSeconds) - val(totalSecondsWithoutUnpaidBreaktimes);
            }
            return prevValue;
        }, 0.0);

        const totalEffectiveHours = totalEffectiveSeconds ? totalEffectiveSeconds / 3600 : 0;
        const totalOvertimesHours = totalOvertimesSeconds ? totalOvertimesSeconds / 3600 : 0;
        const totalPaidBreaktimesHours = totalPaidBreaktimesSeconds ? totalPaidBreaktimesSeconds / 3600 : 0;
        const totalNotPaidBreaktimesHours = totalUnpaidBreaktimesSeconds ? totalUnpaidBreaktimesSeconds / 3600 : 0;

        const sortedEventsForProjects = eventsForProjects?.sort((a, b) => {
            const dateA = new Date(a.startDate).getDate();
            const dateB = new Date(b.startDate).getDate();
            if (dateA !== dateB) return dateA - dateB;

            const fullNameA = `${a.userLastName} ${a.userFirstName}`;
            const fullNameB = `${b.userLastName} ${b.userFirstName}`;
            if (fullNameA !== fullNameB) return fullNameA.localeCompare(fullNameB);

            return a.projectTitle.localeCompare(b.projectTitle);
        })

        return (
            <>
                {
                    sortedEventsForProjects ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                            <Alert type='info'
                                message={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <div>
                                            <span><i><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</i> {val(totalNotPaidBreaktimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Paid breaks'} />{':'} {val(totalPaidBreaktimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Overtimes'} />{':'}  {val(totalOvertimesHours).toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Effective hours'} />{':'} </b> {val(totalEffectiveHours).toFixed(2)}</span>
                                        </div>
                                    </div>
                                }
                            />

                            <Table
                                bordered
                                columns={this.eventsColumns}
                                dataSource={sortedEventsForProjects}
                                scroll={{ x: true }}
                                rowKey={(record: EventForProject) => `normal-${record.id}-${record.projectId}-${record.userId}-${record.startDate}`}
                                pagination={{
                                    pageSize: pageSize,
                                    showSizeChanger: false,
                                    responsive: true,
                                    size: "small",
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            />
                        </div>
                        : <p>Problem data</p>
                }
            </>
        );
    }

    eventsColumns: ColumnsType<EventForProject> = [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'startDate',
            className: '__width_110 __centered-text',
            fixed: "left",
            render: (e: EventForProject) => {
                const c = e.startDate;
                return c && moment.utc(c).format(getFormat('DATE'));
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'userFullName',
            className: '__width_280 __ellipsis-text',
            render: (value) => {
                const fullName = `${value.userLastName} ${value.userFirstName}`;
                return <span title={fullName}>{fullName}</span>
            },
        },
        {
            title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
            key: 'projectTitle',
            className: '__width_250 __ellipsis-text',
            render: (e: EventForProject) => {
                const value = e.projectTitle;
                return <span title={value}>{value}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Event'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Title'} />,
                    key: 'title',
                    className: '__width_250 __ellipsis-text',
                    render: (e: EventForProject) => {
                        const value = e.title;
                        return <span title={value}>{value}</span>
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Start'} />,
                    key: 'startTime',
                    className: '__width_70 __centered-text',
                    render: (e: EventForProject) => {
                        const c = e.startDate;
                        return moment.utc(c).format(getFormat('TIME_SHORT'))
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'End'} />,
                    key: 'endTime',
                    className: '__width_70 __centered-text',
                    render: (e: EventForProject) => {
                        const c = e.endDate;
                        return moment.utc(c).format(getFormat('TIME_SHORT'))
                    }
                },
            ]
        },

        {
            title: <FormattedMessage defaultMessage={'Hours'} />,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'Over'} />,
                    key: 'overtimes',
                    className: '__width_70 __centered-text',
                    render: (e: EventForProject) => ((e.totalSecondsWithOvertimes - e.totalSeconds) / 3600).toFixed(2)
                },
                {
                    title: <FormattedMessage defaultMessage={'Total'} />,
                    key: 'effectiveSeconds',
                    className: '__width_70 __centered-text',
                    render: (e: EventForProject) => {
                        const effectiveSeconds = e.effectiveSeconds;
                        return (effectiveSeconds / 3600).toFixed(2)
                    }
                },
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Comment'} />,
            key: 'userNote',
            className: '__min-width-220 __ellipsis-text',
            render: (e: EventForProject) => {
                const value = e.userNote;
                return <span title={value}>{value}</span>
            }
        }
    ];

}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    sidebarType: state.window.sidebarType,
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

const ReportsNotGrouped = connector(ReportsNotGroupedCore);

const ForwardedReportsNotGrouped = forwardRef<ReportsNotGroupedCore, any>((props: Props, ref) => {
    const intl = useIntl();

    return <ReportsNotGrouped {...props} ref={ref as React.Ref<ReportsNotGroupedCore>} intl={intl} />;
});

ForwardedReportsNotGrouped.displayName = "ForwardedReportsNotGrouped";


export default ForwardedReportsNotGrouped;

// #region All utils
// Fonction utilitaire pour extraire le texte d'un JSX ou d'un composant React
type EventForProjectByProjectByUser = EventForProject;



const exportToExcel = (dataSource: EventForProjectByProjectByUser[], eventsColumnsByProjectByUser: ColumnsType<EventForProjectByProjectByUser>, intl: IntlShape) => {
    const headers: string[] = [];
    eventsColumnsByProjectByUser.forEach((column) => {
        if ("children" in column && column.children.length > 0) {
            column.children.forEach(subColumn => headers.push(getTitleFromTableColumns(subColumn, intl)));
        } else {
            headers.push(getTitleFromTableColumns(column, intl));
        }
    })

    const colWidths: number[] = new Array(headers.length).fill(0).map((e, index) => {
        return Math.max(0, headers[index].length);
    });

    const worksheetData: string[][] = [];
    worksheetData.push(headers);

    const merges: XLSX.Range[] = [];
    let currentRow = 1;

    dataSource.forEach((row) => {
        const rowData: string[] = [];
        let index = 0;
        eventsColumnsByProjectByUser.forEach((column) => {
            let valueString = "";
            if ("children" in column && column.children.length > 0) {
                column.children.forEach(subColumn => {
                    valueString = getDataFromTableColumns(subColumn, currentRow, row);
                    colWidths[index] = Math.max(colWidths[index], valueString.length);
                    rowData.push(valueString);
                    index++;
                });
            } else {
                const valueString = getDataFromTableColumns(column, currentRow, row)
                colWidths[index] = Math.max(colWidths[index], valueString.length);
                rowData.push(valueString);
                index++;
            }
        });

        worksheetData.push(rowData);

        currentRow++;
    });

    // Créer une feuille Excel avec les données
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Appliquer les fusions de cellules
    ws['!merges'] = merges;

    // Appliquer les styles : en-têtes en gras
    for (let i = 0; i < headers.length; i++) {
        const cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
        if (cell) {
            // Appliquer le style gras à chaque cellule d'en-tête
            cell.s = {
                font: { bold: true }, // Appliquer le gras
                alignment: { horizontal: 'center' }, // Centrer le texte des en-têtes
            };
        }
    }

    // Définir la largeur des colonnes en fonction du contenu
    const columnWidths = colWidths.map(width => ({
        wch: width + 2, // Largeur avec un petit tampon pour éviter que le texte soit coupé
    }));
    ws['!cols'] = columnWidths;

    // Créer et sauvegarder le fichier Excel
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'export_normal.xlsx');
};
// #endregion