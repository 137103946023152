import { ThunkAction } from 'redux-thunk';
import { SidebarType } from '../../components/common/navigations/containerTabs';
import { StoreAction, WindowState } from '../../utils/types/storeTypes';
import { RESET, TYPE_CHANGE_SIDEBAR_TYPE, TYPE_NAVIGATION_COLLAPSE, TYPE_NAVIGATION_NOT_COLLAPSE, TYPE_SET_DARK_MODE, TYPE_TOOGLE_DARK_MODE, TYPE_WINDOW_FULLSCREEN, TYPE_WINDOW_HEIGHT, TYPE_WINDOW_NAVIGATION_COLLAPSED, TYPE_WINDOW_WIDTH } from '../reducer/window';

export type Effect = ThunkAction<any, WindowState, any, StoreAction>;

/**
 * Update window's width props with given width
 * @param width the new width
 */
export const changeFullScreen = (fullscreen: boolean): Effect => (dispatch): void => {
    dispatch({ type: TYPE_WINDOW_FULLSCREEN, data: fullscreen });
};

/**
 * Update window's width props with given width
 * @param width the new width
 */
export const changeWidth = (width: number): Effect => (dispatch): void => {
    dispatch({ type: TYPE_WINDOW_WIDTH, data: width });
};

/**
 * Update window's height props with given height
 * @param height the new height
 */
export const changeHeight = (height: number): Effect => (dispatch): void => {
    dispatch({ type: TYPE_WINDOW_HEIGHT, data: height });
};

export const toggleDarkMode = (): Effect => (dispatch): void => {
    dispatch({ type: TYPE_TOOGLE_DARK_MODE });
}

export const setDarkMode = (darkMode: boolean): Effect => (dispatch): void => {
    dispatch({ type: TYPE_SET_DARK_MODE, data: darkMode });
}


/**
 * Toggle collpase state of the side navigation bar
 */
export const toggleNavigationCollapsed = (): Effect => (dispatch): void => {
    dispatch({ type: TYPE_WINDOW_NAVIGATION_COLLAPSED });
}

/**
 * Toggle collpase state of the side navigation bar
 */
export const changeSidebarType = (type: SidebarType): Effect => (dispatch): void => {
    dispatch({ type: TYPE_CHANGE_SIDEBAR_TYPE, data: type });
}

/**
 * Toggle collpase state of the side navigation bar
 */
export const navigationCollapse = (): Effect => (dispatch): void => {
    dispatch({ type: TYPE_NAVIGATION_COLLAPSE });
}

/**
 * Toggle collpase state of the side navigation bar
 */
export const navigationNotCollapse = (): Effect => (dispatch): void => {
    dispatch({ type: TYPE_NAVIGATION_NOT_COLLAPSE });
}
export const reset = () => ({ type: RESET });