import { Button, Col, Empty, Popover, Row, Select } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { EventWithEventClocked } from '../../../../utils/types/generalTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';

interface Props {
    event: EventWithEventClocked;
    loading?: boolean;
    onDone(template: number): void;
}

const ReplaceByTemplate = (props: Props) => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();
    const templates = useSelector((state: ApplicationState) => state.planning.templates?.filter(template => !!template.typeOfDayOff));
    const [selectedTemplate, setSelectedTemplate] = useState<number | undefined>(undefined);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (

        <Popover
            overlayStyle={{ width: 300 }}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="bottomRight"
            arrowPointAtCenter
            key={`event-popover-typeofday-${props.event.id}`}
            title={<span onClick={(e) => e.stopPropagation()}><FormattedMessage defaultMessage={'Replace event'} />{':'}</span>}
            content={
                <Row gutter={[10, 10]} onClick={(e) => e.stopPropagation()}>
                    <Col xs={{ span: 24 }}>
                        <Select
                            placeholder={<FormattedMessage defaultMessage={'Select an absence template'} />}
                            className="planning-modal-input-100"
                            showSearch
                            allowClear
                            onChange={(value) => setSelectedTemplate(value)}
                            value={selectedTemplate}
                            filterOption={true}
                            optionFilterProp="label">
                            {
                                templates ?
                                    templates.map(t =>
                                        <Select.Option label={t.title} value={t.id!} key={`planning-template-${t.id}`}>
                                            <span>{t.title}</span>
                                        </Select.Option>
                                    )
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No rest day model'} />} />
                            }

                        </Select>
                    </Col>

                    <Col span={24} style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onClick={hide}><FormattedMessage defaultMessage={'Cancel'} /></Button>
                        <Button disabled={selectedTemplate === undefined} type={'primary'} onClick={() => { selectedTemplate !== undefined && props.onDone(selectedTemplate); hide(); }}><FormattedMessage defaultMessage={'Confirm'} /></Button>
                    </Col>

                </Row>
            }
        >
            <CircleButton
                type='dashed'
                withoutTooltip
                key={`replace-circlebutton-template-${props.event.id}-edit`}
                icon={<FAIcon prefix='fad' name='pills' />}
                small
                title={intl.formatMessage({ defaultMessage: 'Absent' })}
                loading={props.loading}
                onClick={(e) => e.stopPropagation()}

            />
        </Popover>
    );
};

export default ReplaceByTemplate;