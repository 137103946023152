import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectParsedProjectName } from "../store/selectors/projectsSelectors";
import { CaseType } from "../utils/constants";
import { ApplicationState } from "../utils/types/storeTypes";

export enum ProjectDefaultMessage {
    PROJECT = 'project',
    PROJECTS =  'projects',
}

export type GetProjectNameParameters = { isPlural: boolean; caseType: CaseType }

//! Need to change, incompatible state types
const useProjectModuleName = (params: GetProjectNameParameters) => useSelector((state: ApplicationState) => selectParsedProjectName(state, params.isPlural, params.caseType));

const StatsTypesMessages = defineMessages<ProjectDefaultMessage>({
    [ProjectDefaultMessage.PROJECT]: { defaultMessage: 'Project' },
    [ProjectDefaultMessage.PROJECTS]: { defaultMessage: 'Projects' },
});

export const useProjectName = (params: GetProjectNameParameters) => {
    const projectModuleName = useProjectModuleName(params);
    const intl = useIntl();
    let translatedName: string = projectModuleName;

    if (projectModuleName.startsWith('formattedmessage_')) {
        const messageKey = projectModuleName.substring('formattedmessage_'.length);
        translatedName = intl.formatMessage(StatsTypesMessages[messageKey as ProjectDefaultMessage]);
    }

    return translatedName;
}