import { CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import '../../../styles/ccnt.css';
import { MOMENT_SHORT_TIME_FORMAT, VehicleTypesDescription, VehicleTypesIds } from '../../../utils/constants';
import { CctSecurityEventsByContractByMonth, CctSecurityUserEventsByMonth, CctSecurityUserEventsByMonthOverview, CctSecurityUserEventsByMonthRecap, CctSecurityUserEventsByMonthSummary, CctSecurityUserEventsByMonthTravelSummary, CctSecurityUserHoursSummaryInMonth } from '../../../utils/types/reportTypes';
import { isNullOrEmpty, val } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import { generateCCTReportToExcelNew, ReportEventType } from '../common/generateReportNew';
import ReportContent from './reportContent';

interface IProps {
    multipleReports?: CctSecurityEventsByContractByMonth[];
    type: ReportEventType;
    isLoading: boolean;
    startMonth: Moment;
    endMonth: Moment;
    close: () => void;
}

type Props = IProps & IntlProps;

interface State {
    showTools: boolean;

}


class MultipleReportPrintPage extends React.Component<Props, State> {


    constructor(props: Props) {
        super(props);

        this.escFunction = this.escFunction.bind(this);

        this.state = {
            showTools: false,
        };

    }

    //quits the current ccnt print page preview
    escFunction(event: any) {
        if (event.code === "Escape") {
            this.props.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    parseEventsByDay = (userEventSummary: CctSecurityEventsByContractByMonth) => {
        const parseEventsToDisplay = (month: CctSecurityUserHoursSummaryInMonth) => {
            //? Preparing arrays
            let numberOfTargetMonth = -1;
            let userEventsByMonthTravel: CctSecurityUserEventsByMonthTravelSummary[] = [];
            const userEventsByMonthOverview: CctSecurityUserEventsByMonthOverview[] = [];
            const userEventsByMonthRecap: CctSecurityUserEventsByMonthRecap[] = [];
            let userEventsByMonthSummary: CctSecurityUserEventsByMonthSummary[] = [];

            if (month !== undefined && month.summaryByDay) {
                //? Month recap
                //: Adding the values to display
                month.summaryByDay.forEach((day, idx) => {
                    day.events?.forEach((event, idx2) => {
                        //? Push data into array to show in main table
                        const typeOfVehicleName = VehicleTypesDescription.find(type => type.id === event.vehicle?.type)?.name;

                        userEventsByMonthRecap.push({
                            id: ((idx + 1) * 1000) + idx2,
                            color: 'ffffff',
                            date: day.date!,
                            title: (event.title === undefined ? "" : event.title),
                            schedule: `(${event.dateFrom.format(MOMENT_SHORT_TIME_FORMAT)} - ${event.dateTo.format(MOMENT_SHORT_TIME_FORMAT)})`,
                            typeOfDay: (event.typeOfDay?.title !== undefined ? event.typeOfDay.title : (event.typeOfDayOff?.title !== undefined ? event.typeOfDayOff.title : "")),
                            hoursPerEvent: val(event.summary?.eventHours, -1),
                            breaktime: val(event.summary?.breaktimesHours),
                            paidBreaktime: val(event.summary?.breaktimesPaidHours),
                            notPaidBreaktime: val(event.summary?.breaktimesNotPaidHours),
                            overtime: val(event.summary?.overtimesHours),
                            totalHours: val(event.summary?.totalHours, -1),
                            effectiveHours: val(event.summary?.effectiveHours),
                            eventHours: val(event.summary?.eventHours, -1),
                            increasedHours: val(event.summary?.increasedHours, 0),
                            vacationIncreasedHours: val(event.summary?.vacationIncreasedHours),
                            project: (event.summary?.project === undefined ? "" : event.summary?.project),
                            projectNumber: (event.summary?.projectNumber === undefined ? "" : event.summary?.projectNumber),
                            address: (event.poi?.address ? event.poi.address : "-"),
                            startingPoint: (event.basePoi?.title === undefined ? "" : event.basePoi?.title),
                            vehicle: typeOfVehicleName === undefined ? "" : this.props.intl.formatMessage(typeOfVehicleName),
                            travelZone: event.summary?.deplacement?.compensationText ? event.summary.deplacement.compensationText : '', //TODO Calculate travel zone
                            distanceMade: event.distance,
                            distanceCompensated: event.summary?.deplacement?.distanceTotalCompensated, //TODO Calculate compensated distance
                            remarks: (event.summary?.userRemarks === undefined ? "" : event.summary?.userRemarks),
                        });

                        //? setting month Number
                        numberOfTargetMonth = parseInt(this.props.endMonth.format("MM"));

                        //? Month Travel
                        //: Adding the travel zones
                        userEventsByMonthTravel = [
                            {
                                id: month.totEngagementZone?.travelZone?.id!,
                                name: month.totEngagementZone?.travelZone?.name!,
                                passengerVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance),
                                ownVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance),
                                workVehicle: val(month.totEngagementZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance),
                            },
                            {
                                id: month.totFlatRateZone1?.travelZone?.id!,
                                name: month.totFlatRateZone1?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone1?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totFlatRateZone2?.travelZone?.id!,
                                name: month.totFlatRateZone2?.travelZone?.name!,
                                passengerVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totFlatRateZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totEngagementZone2?.travelZone?.id!,
                                name: month.totEngagementZone2?.travelZone?.name!,
                                passengerVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totEngagementZone2?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                            {
                                id: month.totDynamicRateZone?.travelZone?.id!,
                                name: month.totDynamicRateZone?.travelZone?.name!,
                                passengerVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PASSENGER)?.distance!,
                                ownVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.PERSONAL)?.distance!,
                                workVehicle: month.totDynamicRateZone?.distances?.find(d => d.id === VehicleTypesIds.SERVICE)?.distance!,
                            },
                        ]
                    });
                });
            }

            //? Month Overview tot hours
            //: Adding the total hours (at the end to get the totHours value)
            userEventsByMonthOverview.push({
                id: 0,
                name: this.props.intl.formatMessage({ defaultMessage: 'Total hours' }),
                prevMonthBalance: val(month.monthSummary?.prevTotalHours),
                currentMonthBalance: val(month.monthSummary?.totalHours),
                nextMonthBalance: val(month.monthSummary?.nextTotalHours)
            });

            month.monthSummary?.lstTypeDayOff?.forEach(element => {
                userEventsByMonthOverview.push({
                    id: element.typeDayOff?.id!,
                    name: element.typeDayOff?.title!,
                    prevMonthBalance: val(element.previousBalance),
                    currentMonthBalance: val(element.actualBalance),
                    nextMonthBalance: val(element.nextBalance),
                })
            });

            //? Month Summary
            //: Add month summary (hours, increased hours and vacation) to display
            userEventsByMonthSummary = [
                {
                    id: 0,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Brut hours' }),
                    value: val(month.monthSummary?.eventHours)
                },
                {
                    id: 1,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Overtimes' }),
                    value: val(month.monthSummary?.overtimeUndertimeBalance)
                },
                {
                    id: 2,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Paid break hours' }),
                    value: val(month.monthSummary?.breaktimePaidBalance)
                }, {
                    id: 3,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Unpaid break hours' }),
                    value: val(month.monthSummary?.breaktimeNotPaidBalance)
                },
                {
                    id: 4,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Effective hours' }),
                    value: val(month.monthSummary?.effectiveHours)
                },
                {
                    id: 10,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus hours' }),
                    value: val(month.monthSummary?.increasedHours)
                },
                {
                    id: 20,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Bonus vacations' }),
                    value: val(month.monthSummary?.vacationIncreasedHours)
                },
                {
                    id: 30,
                    name: this.props.intl.formatMessage({ defaultMessage: 'Total balance' }),
                    value: val(month.monthSummary?.totalHours)
                }
            ]

            return {
                monthNumber: numberOfTargetMonth,
                recap: userEventsByMonthRecap,
                summary: userEventsByMonthSummary,
                overview: userEventsByMonthOverview,
                travel: userEventsByMonthTravel
            }
        }

        let monthData: CctSecurityUserHoursSummaryInMonth | undefined;
        switch (this.props.endMonth.month()) {
            case 0:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
            case 1:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.febHours;
                break;
            case 2:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.marHours;
                break;
            case 3:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.aprHours;
                break;
            case 4:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.mayHours;
                break;
            case 5:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.junHours;
                break;
            case 6:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.julHours;
                break;
            case 7:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.augHours;
                break;
            case 8:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.sepHours;
                break;
            case 9:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.octHours;
                break;
            case 10:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.novHours;
                break;
            case 11:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.decHours;
                break;
            default:
                monthData = userEventSummary.cctSecurityCalculatedEvents?.janHours;
                break;
        }

        if (monthData) {
            const cctSecurityUserEventsByMonth: CctSecurityUserEventsByMonth = {
                user: userEventSummary.user,
                contractName: userEventSummary.name,
                contractType: userEventSummary.typeOfContract,
                months: [
                    parseEventsToDisplay(monthData)
                ]
            };

            return cctSecurityUserEventsByMonth;
        }

        return undefined;
    }

    print = () => {
        const { multipleReports, type, intl } = this.props;

        if (multipleReports) {
            const cctSecurityEventsByMonthlist: CctSecurityUserEventsByMonth[] = [];

            multipleReports.filter(data => data.user).map(d => {
                const data = this.parseEventsByDay(d);
                if (data) cctSecurityEventsByMonthlist.push(data);
            });

            generateCCTReportToExcelNew(
                { creator: 'Sunkhronos', orientation: 'landscape', type },
                cctSecurityEventsByMonthlist.filter(data => data.user).map(data => {
                    const overview: CctSecurityUserEventsByMonthOverview[] = isNullOrEmpty(data.months) || !data.months[0].overview ? [] : data.months[0].overview;
                    const recap: CctSecurityUserEventsByMonthRecap[] = isNullOrEmpty(data.months) || !data.months[0].recap ? [] : data.months[0].recap;
                    const summary: CctSecurityUserEventsByMonthSummary[] = isNullOrEmpty(data.months) || !data.months[0].summary ? [] : data.months[0].summary;
                    const travel: CctSecurityUserEventsByMonthTravelSummary[] = isNullOrEmpty(data.months) || !data.months[0].travel ? [] : data.months[0].travel;

                    return ({
                        user: data.user!,
                        contract: {
                            name: data.contractName || intl.formatMessage({ defaultMessage: 'Contract without name' }),
                            type: data.contractType || { hoursMax: 10, hoursMin: 10, name: intl.formatMessage({ defaultMessage: 'Unnamed' }) }
                        },
                        data: {
                            month: data.months?.[0].monthNumber || 10,
                            overview,
                            recap,
                            summary,
                            travel
                        }
                    });
                }),
                this.props.endMonth,
                this.props.intl
            );
        }
        this.props.close();
    }

    render() {
        const { multipleReports, endMonth, type } = this.props;

        return (
            <>
                <div className='cctPrintBody' style={{ backgroundColor: "white" }}>
                    <div>
                        {/* Ccnt Header for printed version */}
                        <div style={{ display: 'flex', gap: '40px', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }} id='ccntPaper'
                            onMouseEnter={() => this.setState({ showTools: true })}
                            onMouseLeave={() => this.setState({ showTools: false })}
                            onClick={(e) => e.stopPropagation()}
                        >

                            {
                                multipleReports?.map((report) => {
                                    return (
                                        <div
                                            key={`multiple-report-cct-${report.id}`}
                                            style={{
                                                width: "calc(100% - 60px)",
                                                // height: 'fit-content',
                                                border: '1px solid var(--border-color)',
                                                borderRadius: 'var(--border-radius)',
                                                padding: '20px'
                                            }}
                                        >
                                            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{`${report.user?.last_name} ${report.user?.first_name}`}</div>
                                            <ReportContent
                                                type={type}
                                                cctSecurity={report}
                                                print={false}
                                                togglePrint={() => console.log("YEAHHHH")}
                                                monthReport={endMonth.clone()} />
                                        </div>
                                    );


                                })
                            }
                        </div>
                    </div>

                    <div className={`ccntPrintButtons`}>
                        <CircleButton icon={<CloseOutlined />} title={this.props.intl.formatMessage({ defaultMessage: 'Close' })} onClick={() => this.props.close()} style={{ marginRight: 8, opacity: 0.8 }} />
                        <CircleButton title={this.props.intl.formatMessage({ defaultMessage: 'Print' })} icon={<PrinterOutlined />} type="primary" onClick={() => this.print()} />
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(MultipleReportPrintPage);