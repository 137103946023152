import * as Sentry from '@sentry/react';
import moment from 'moment/';
import React, { ReactChild } from "react";
import { IntlProvider, WrappedComponentProps } from "react-intl";
import { ConnectedProps } from 'react-redux';
import { withRouter } from "react-router-dom";
import de from '../../compiled-lang/de.json';
import en from '../../compiled-lang/en.json';
import fr from '../../compiled-lang/fr.json';
import { connect, RootState } from '../../store/store';
import { RouterProps } from "../../utils/types/generalTypes";

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends RouterProps, ReduxProps {
    children?: ReactChild;
}

interface State {
    smsLoading: boolean;
}

export interface IntlProps extends WrappedComponentProps { }

class LanguageProvider extends React.Component<IProps, State> {
    getLanguage = () => {
        const lang = this.props.location.pathname.split("/")[1];
        switch (lang) {
            case 'en':
                return { locale: 'en', messages: en };
            case 'de':
                return { locale: 'de', messages: de };
            case 'fr':
            default:
                return { locale: 'fr', messages: fr };
        }
    };

    render() {
        const { locale, messages } = this.getLanguage();
        moment.locale(locale);

        return (
            <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale="en"
                formats={{
                    date: {
                        fr: {
                            day: '2-digit',
                            month: 'long',
                            year: '2-digit',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        }
                    }
                }}
                onError={(e) => {
                    console.log("KROK", e);
                    Sentry.captureException(e);
                    Sentry.captureMessage(`CODE:${e.code} | VERSION: ${process.env.REACT_APP_VERSION} | LANG: ${this.getLanguage().locale} | COMPANY:${this.props.company?.id} | USER:${this.props.currentUser?.id}`);
                }}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
});

const connector = connect(mapStateToProps);
export default withRouter(connector(LanguageProvider));