import { useProjectName } from '../../hooks/useProjectName';
import { CaseType } from '../constants';

export interface ProjectNameProps {
    projectName: string;
    projectNamePlural: string;
}

export function withProjectName<T extends ProjectNameProps = ProjectNameProps>(
    WrappedComponent: React.ComponentType<T>
) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithProjectName = (props: Omit<T, keyof ProjectNameProps>) => {
        const projectName = useProjectName({
            caseType: CaseType.FIRST_LETTER_UPPERCASE,
            isPlural: false,
        });

        const projectNamePlural = useProjectName({
            caseType: CaseType.FIRST_LETTER_UPPERCASE,
            isPlural: true,
        });

        const nameProps = { projectName, projectNamePlural };
        return <WrappedComponent {...nameProps}  {...(props as T)} />;
    };

    ComponentWithProjectName.displayName = `withProjectName(${displayName})`;

    return ComponentWithProjectName;
}