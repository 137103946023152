import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Logo from '../../images/logo/logo_sunkhronos.png';
import { RouterProps, User } from '../../utils/types/generalTypes';
import InputField from '../common/fields/inputField';

import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { changeUserDetails } from '../../store/actions/user';
import '../../styles/login.css';
import Network from '../../utils/network';
import { StoreDispatch } from '../../utils/types/storeTypes';
import { alert, displayErrorMessage, handleForgotPassword } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends RouterProps, ReduxProps, IntlProps {
}

interface State {
    [index: string]: any;
    username: string;
    password: string;
    showModal: boolean;
    email: string;
    errors: {
        [index: string]: any;
        username: string | undefined;
        password: string | undefined;
    };
    loading: boolean;
    rememberMe: boolean;
}

/**
 * Login page
 */
class Login extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            showModal: false,
            email: "",
            errors: {
                username: undefined,
                password: undefined,
            },
            loading: false,
            rememberMe: false,
        };
    }

    /**
     * Handle when the user focus an input
     * @param name the name of the value corresponding to the input
     */
    handleFocus = (name: string) => {
        const { errors } = this.state;
        if (errors[name]) {
            errors[name] = undefined;
            this.setState({ errors });
        }
    };

    /**
     * Handle to forgot my password process
     */
    handleForgotPassword = (): void => {
        this.setState({ loading: true });
        const path = this.props.location.pathname.split("/");
        handleForgotPassword({ email: this.state.email, language: path.length > 2 ? path[1] : 'fr' },
            () => {
                this.setState({ loading: false, showModal: false });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An e-mail containing information about resetting your password has been sent to you.' }), "success");
            },
            () => {
                this.setState({ loading: false });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while sending the reset email' }), "warning");
            },
        );
    };

    /**
     * Handle the connection of the user
     */
    handleConnect = () => {
        const { username, password, errors, rememberMe } = this.state;
        const { intl } = this.props;
        errors.username = errors.password = undefined;

        //check that the field are filled
        if (username.length === 0) {
            errors.username = intl.formatMessage({ defaultMessage: 'Must be filled' });
        }

        if (password.length === 0) {
            errors.password = intl.formatMessage({ defaultMessage: 'Must be filled' });
        }

        if (!errors.username && !errors.password) {
            this.setState({ loading: true });
            Network.login(username, password, rememberMe).then(
                response => {
                    this.props.changeUserDetails(response);

                    this.props.history.replace({ pathname: this.props.location.state?.redirectToUrl ?? `/${this.props.match.params.lang}/dashboard`, state: { fromLogin: true } });
                },
                () => {
                    this.setState({ loading: false });
                    displayErrorMessage(intl.formatMessage({ defaultMessage: 'Wrong credentials' }), "shake-that-error", 3);
                }
            );
        }

        this.setState({ errors });
    };

    render() {
        return (
            <div>
                <div className="login-background-image" />
                <div className="login-background" id="container">
                    <div className="login-parent">
                        <div className="login-content">
                            <div className="login-header">
                                <img src={Logo} className="login-logo" alt="Sunkhronos logo" />
                            </div>
                            <div className="login-body">
                                <div id="shake-that-error" style={{ width: '100%' }}>
                                    <InputField
                                        className="login-input"
                                        onChange={(e) => this.setState({ username: e.target.value })}
                                        onPressEnter={this.handleConnect}
                                        value={this.state.username}
                                        placeholder={this.props.intl.formatMessage({ defaultMessage: 'Username' })}
                                        error={this.state.errors.username}
                                        suffix={<UserOutlined />}
                                        onFocus={() => this.handleFocus("username")} />
                                    <InputField
                                        type="password"
                                        className="login-input"
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        onPressEnter={this.handleConnect}
                                        value={this.state.password}
                                        error={this.state.errors.password}
                                        onFocus={() => this.handleFocus("password")}
                                        placeholder="Mot de passe" />


                                </div>
                                {/* <Checkbox onChange={() => this.setState({ rememberMe: !this.state.rememberMe})} value={this.state.rememberMe}>{"Se souvenir de moi ?"}</Checkbox> */}
                                <Button
                                    className="login-submit"
                                    onClick={this.handleConnect}
                                    type="primary"
                                    loading={this.state.loading}>
                                    <FormattedMessage defaultMessage={'Login'} />
                                </Button>
                                <span className="login-footer-links">
                                    <Button
                                        onClick={() => this.setState({ showModal: true })}
                                        type="link">
                                        <FormattedMessage defaultMessage={'Forgot password'} />{'?'}
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <span className="login-rights" >{`© ${moment().year()} - Sunkhronos - All rights reserved`}</span>
                    <Modal
                        visible={this.state.showModal}
                        title={<FormattedMessage defaultMessage={'Forgot password'} />}
                        onOk={undefined}
                        onCancel={() => this.setState({ showModal: false })}
                        footer={[
                            <Button key={`login-moda-password-forget-cancel`} onClick={() => this.setState({ showModal: false })}>
                                <FormattedMessage defaultMessage={'Cancel'} />
                            </Button>,
                            <Button
                                key={`login-moda-password-forget-send`}
                                onClick={this.handleForgotPassword}
                                loading={this.state.loading}
                                disabled={!this.state.email || !this.state.email.includes("@")}>
                                <FormattedMessage defaultMessage={'Send'} />
                            </Button>
                        ]}>
                        <InputField
                            placeholder={this.props.intl.formatMessage({ defaultMessage: 'Your email' })}
                            addonAfter={<MailOutlined />}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            value={this.state.email}
                            onPressEnter={this.handleForgotPassword} />
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUserDetails: (u: User) => dispatch(changeUserDetails(u)),
});
const connector = connect(undefined, mapDispatchToProps);

export default connector(withRouter(injectIntl(Login)));