import * as Sentry from "@sentry/react";
import Cookie from 'js-cookie';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { reset as resetConfiguration } from '../../store/actions/configurations';
import { reset as resetCRM } from '../../store/actions/crm';
import { reset as resetLocation } from '../../store/actions/location';
import { reset as resetPlanning } from '../../store/actions/planning';
import { reset as resetPosts } from '../../store/actions/posts';
import { reset as resetTeamManagement } from '../../store/actions/teamManagement';
import { reset as resetUser } from '../../store/actions/user';
import { reset as resetCustomerManagement } from '../../store/features/customerManagement';
import { RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import Configurations from '../configurations/configurations';
import CourseManagement from '../courseManagement/courseManagement';
import CRM from '../crm/crm';
import CustomerManagement from '../customersManagement/customers/customerManagement';
import CustomersManagement from '../customersManagement/customersManagement';
import MandateManagement from '../customersManagement/mandates/mandateManagement';
import Dashboard from '../dashboard/dashboard';
import Help from '../documentation/index';
import integrations from "../integrations/integrations";
import Login from '../login/login';
import CreateMessage from '../messages/createMessage';
import MessagesList from '../messages/messagesList';
import MobileApp from '../mobileApp/mobileApp';
import CreateNewsfeed from '../newsfeed/createNewsfeed';
import NewsList from '../newsfeed/newsfeedList';
import PlanningOld from '../planning/planning';
import Planning from '../planningNew/planning';
import PlanningPerf from '../planningPerf/planningPerf';
import OldProjectManagement from '../project/project';
import ProjectManagement from '../projectV2/projectManagement';
import Report from '../report/report';
import Reportv2 from '../reportv2/reportv2';
import PlanningStatistics from '../stats/planningStatistics';
import SunkhronosLiveMap from '../sunkhronosLive/sunkhronosLiveMap';
import CreateGroup from '../teamManagement/groups/createGroup';
import GroupDetails from '../teamManagement/groups/groupDetails';
import ImportUsers from '../teamManagement/import/importUsers';
import TeamManagement from '../teamManagement/teamManagement';
import CreateUser from '../teamManagement/users/createUser';
import UserDetails from '../teamManagement/users/userDetails';
import WorkControl from '../workControl/workControl';
import AddLanguage from './addLanguage';
import NotFound from './notFound';
import ResetPassword from './resetPassword';
import SplashScreen from './splashScreen';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends RouterProps, ReduxProps {
}

interface State {
    userId?: string;
}

class RouteSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userId: Cookie.get("userId")
        };
    }

    componentDidMount() {
        //check if connected, otherwise go to login page
        const userId = Cookie.get("userId");
        Sentry.setTag("a_userId", userId);

        if (!userId && !this.props.location.pathname.includes('reset-password') && !this.props.location.pathname.includes('planning-statistics')) {
            this.props.history.push({ pathname: `/${this.getLang()}/login`, state: { redirectToUrl: this.props.location.pathname } });
        } else if ((this.props.location.pathname.includes('login') && userId) || this.props.location.pathname.length <= 4) {
            this.props.history.push(`/${this.getLang()}/dashboard`);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const userId = Cookie.get("userId");
        Sentry.setTag("a_userId", userId);

        if (!userId && !this.props.location.pathname.includes('reset-password') && !this.props.location.pathname.includes('login') && !this.props.location.pathname.includes('planning-statistics')) {
            this.resetAllStore();
            this.props.history.push({ pathname: `/${this.getLang()}/login`, state: { redirectToUrl: this.props.location.pathname } });
        } else if ((this.props.location.pathname.includes('login') && userId) || this.props.location.pathname.length <= 4) {
            this.props.history.push(`/${this.getLang()}/dashboard`);
        }

        if (!isEqual(prevProps.currentUser, this.props.currentUser)) {
            this.setState({ userId: Cookie.get("userId") });
        }
    }

    getLang = () => {
        const splitted_pathname = this.props.location.pathname.split('/');
        return (['fr', 'en', 'de'].includes(splitted_pathname[1])) ? splitted_pathname[1] : 'fr';
    };

    resetAllStore = () => {
        this.props.resetPosts();
        this.props.resetPlanning();
        this.props.resetTeamManagement();
        this.props.resetCRM();
        this.props.resetConfiguration();
        this.props.resetLocation();
        this.props.resetUser();
        this.props.resetCustomerManagement();
    };

    render() {
        return (
            <div id="anchor" style={{ width: '100%', height: '100%' }}>
                {
                    this.state.userId ?
                        <SplashScreen>
                            <Switch>
                                <Route exact path="/:lang(en|fr|de)/planning-statistics" component={PlanningStatistics} />
                                <Route exact path="/:lang(en|fr|de)/login" component={Login} />
                                <Route exact path="/:lang(en|fr|de)/dashboard" component={Dashboard} />
                                <Route exact path="/:lang(en|fr|de)/workcontrol/:tab?" component={WorkControl} />
                                <Route exact path="/:lang(en|fr|de)/newsfeed/list" component={NewsList} />
                                <Route exact path="/:lang(en|fr|de)/newsfeed/create" component={CreateNewsfeed} />
                                <Route exact path="/:lang(en|fr|de)/messages/create" component={CreateMessage} />
                                <Route exact path="/:lang(en|fr|de)/messages/:tab(list|sms)" component={MessagesList} />
                                <Route exact path="/:lang(en|fr|de)/team-management/list/:tab(users|groups|staff-types)?" component={TeamManagement} />
                                <Route exact path="/:lang(en|fr|de)/team-management/create-user" component={CreateUser} />
                                <Route exact path="/:lang(en|fr|de)/team-management/import-users" component={ImportUsers} />
                                <Route exact path="/:lang(en|fr|de)/team-management/create-group" component={CreateGroup} />
                                <Route exact path="/:lang(en|fr|de)/team-management/user-details/:tab?" component={UserDetails} />
                                <Route exact path="/:lang(en|fr|de)/team-management/group-details/:tab?" component={GroupDetails} />
                                <Route exact path="/:lang(en|fr|de)/course-management/list/:tab?" component={CourseManagement} />
                                <Route exact path="/:lang(en|fr|de)/crm/customer/:customerId?/mandate/:mandateId?/:tab?" component={MandateManagement} />
                                <Route exact path="/:lang(en|fr|de)/crm/customer/:customerId?/:tab?" component={CustomerManagement} />
                                <Route exact path="/:lang(en|fr|de)/crm/:tab?" component={CustomersManagement} />
                                <Route exact path="/:lang(en|fr|de)/mobile-app/:tab(overview|emergencies|side-panel)?/:item(menu|sub-menu)?/:id?" component={MobileApp} />
                                <Route exact path="/:lang(en|fr|de)/projects/:tab(listOfProjects|projectReport)?" component={ProjectManagement} />
                                <Route exact path="/:lang(en|fr|de)/old-projects/:tab?" component={OldProjectManagement} />
                                {this.props.currentUser?.role !== 1 && <Route exact path="/:lang(en|fr|de)/planning-old/:isTouch(touch)?/:dayView(day-view)?/:tab?" component={PlanningOld} />}
                                <Route exact path="/:lang(en|fr|de)/planning-v2/:tab(view|requirements|templates|colors)?" component={PlanningPerf} />
                                <Route exact path="/:lang(en|fr|de)/planning/:tab(monthly|weekly|requirements|templates|colors)?" component={Planning} />
                                <Route exact path="/:lang(en|fr|de)/report/:tab?" component={Report} />
                                <Route exact path="/:lang(en|fr|de)/reportv2/:tab?" component={Reportv2} />
                                <Route exact path="/:lang(en|fr|de)/sunkhronos-live-map/" component={SunkhronosLiveMap} />
                                <Route exact path="/:lang(en|fr|de)/customer-relationship-management/:tab?/:subtab?" component={CRM} />
                                <Route exact path="/:lang(en|fr|de)/help/:tab?" component={Help} />
                                <Route exact path="/:lang(en|fr|de)/configurations/:tab?" component={Configurations} />
                                <Route exact path="/:lang(en|fr|de)/integrations/:tab?" component={integrations} />
                                <Route path="/:lang(en|fr|de)/:notFound" component={NotFound} />
                                <Route path="/:lang(en|fr|de)?" component={AddLanguage} />
                                <Redirect from="/:lang(en|fr|de)" to="/:lang(en|fr|de)/dashboard" />
                            </Switch>
                        </SplashScreen>
                        :
                        <Switch>
                            <Route exact path="/:lang(en|fr|de)/planning-statistics" component={PlanningStatistics} />
                            <Route exact path="/:lang(en|fr|de)/login" component={Login} />
                            <Route path="/:lang(en|fr|de)/reset-password" component={ResetPassword} />
                            <Route path="/:lang(en|fr|de)?" component={AddLanguage} />
                            <Redirect from="/:lang(en|fr|de)" to="/:lang(en|fr|de)/login" />
                        </Switch>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    resetConfiguration: () => dispatch(resetConfiguration()),
    resetCRM: () => dispatch(resetCRM()),
    resetLocation: () => dispatch(resetLocation()),
    resetPlanning: () => dispatch(resetPlanning()),
    resetPosts: () => dispatch(resetPosts()),
    resetTeamManagement: () => dispatch(resetTeamManagement()),
    resetUser: () => dispatch(resetUser()),
    resetCustomerManagement: () => dispatch(resetCustomerManagement())
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(RouteSelector);