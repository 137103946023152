import { TypedUseSelectorHook, connect as reduxConnect, useDispatch, useSelector } from 'react-redux';
import rootReducer from './reducer/index';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
    // devTools: process.env.NODE_ENV === "development"
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector
export const connect = <T>(mappedState: (state: RootState) => T) => reduxConnect(mappedState)
