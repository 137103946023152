

// Duotone
import { faCalendarDays, faCalendarXmark, faChevronsDown, faChevronsLeft, faChevronsRight, faChevronsUp, faCircleEllipsis, faCircleQuestion, faClipboardCheck, faCrown, faBox as fadBox, faBoxArchive as fadBoxArchive, faBoxOpen as fadBoxOpen, faCircleExclamation as fadCircleExclamation, faClone as fadClone, faDownload as fadDownload, faEyeSlash as fadEyeSlash, faFileContract as fadFileContract, faFileXls as fadFileXls, faDiagramProject, faInfo as fadInfo, faListUl as fadListUl, faPencil as fadPencil, faPills as fadPills, faArrowDownToLine as faDuoArrowDownToLine, faBoxArchive as faDuoBoxArchive, faCalendar as faDuoCalendar, faCircleCheck as faDuoCircleCheck, faCirclesOverlap as faDuoCirclesOverlap, faCircleXmark as faDuoCircleXmark, faClock as faDuoClock, faEmptySet as faDuoEmptySet, faExclamationTriangle as faDuoExclamationTriangle, faEye as faDuoEye, faFileExport as faDuoFileExport, faFlag as faDuoFlag, faFolderClosed as faDuoFolderClosed, faFolderTree as faDuoFolderTree, faHouse as faDuoHouse, faLink as faDuoLink, faLinkSlash as faDuoLinkSlash, faMaximize as faDuoMaximize, faMinimize as faDuoMinimize, faPlus as faDuoPlus, faRightFromBracket as faDuoRightFromBracket, faRotate as faDuoRotate, faRotateRight as faDuoRotateRight, faUser as faDuoUser, faUserClock as faDuoUserClock, faUserGear as faDuoUserGear, faUserSlash as faDuoUserSlash, faUserTieHair as faDuoUserTieHair, faUser as faDUser, faUserMagnifyingGlass as fadUserMagnifyingGlass, faUsers as faDUsers, faFileInvoice, faFilter, faFilterList, faFilters, faFlaskGear, faGauge, faGear, faGrid2Plus, faLayerGroup, faLocationDot, faMagnifyingGlass, faMessage, faMessages, faMobileScreen, faNewspaper, faRectangleHistoryCircleUser, faSitemap, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
// Light
import { faArrowDownToLine, faBoxArchive, faEye, faCheck as falCheck, faChevronLeft as falChevronLeft, faChevronRight as falChevronRight, faRotateLeft, faScreenUsers } from '@fortawesome/pro-light-svg-icons';
// Regular
import { faArrowRight, faBroomWide, faCalendar, faCheck, faChevronLeft, faChevronRight, faCircleCheck, faCirclePlus, faCirclesOverlap, faCircleXmark, faClock, faFlag, faFloppyDisk, faFolderClosed, faFolderOpen, faFolderTree, faHashtag, faHourglassClock, faInfoCircle, faLink, faMerge, faMessageLines, faMug, faPuzzlePieceSimple, faEmptySet as farEmptySet, faGrid2Plus as farGrid2Plus, faRightFromBracket, faLinkSlash as farLinkSlash, faPlus as farPlus, faTriangleExclamation as farTriangleExclamation, faUser as farUser, faXmark as farXmark, faSackDollar, faTreePalm, faUserGear, faUserSlash, faUserTieHair } from '@fortawesome/pro-regular-svg-icons';
// Solid
import { faArrowUpRightDots, faCaretLeft, faCaretRight, faCircleInfo, faClone, faFileContract, faGlobeStand, faGridHorizontal, faHotel, faLinkSlash, faLock, faPlus, faRotateReverse, faEmptySet as fasEmptySet, faShield, faLinkSlash as fasLinkSlash, faMug as faSolidMug, faSpinnerThird, faTriangleExclamation as fasTriangleExclamation, faUser as fasUser, faUsers, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faHorizontalRule } from '@fortawesome/pro-thin-svg-icons';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';


/**
 * Pack used for the sunkhronos library
 */
export const sunkhronosPack = FA.createIconPack(
    IconPacks.Sunkhronos,
    fadPills,
    fadEyeSlash,
    farXmark,
    fadPencil,
    fadBox,
    fadBoxOpen,
    farPlus,
    fadBoxArchive,
    fadListUl,
    fadInfo,
    fadFileContract,
    fadCircleExclamation,
    fadClone,
    fadDownload,
    fadFileXls,
    fadUserMagnifyingGlass,
    falCheck,
    faDuoLinkSlash,
    faDuoExclamationTriangle,
    faDuoClock,
    faDuoCalendar,
    faDuoEmptySet,
    faDuoLink,
    faDuoUser,
    faDuoMinimize,
    faDuoMaximize,
    falChevronLeft,
    falChevronRight,
    faDuoRotateRight,
    faDuoHouse,
    faDuoFileExport,
    faDuoUserClock,
    faDuoCircleCheck,
    faDuoCircleXmark,
    faDuoPlus,
    faDuoFlag,
    faDuoFolderClosed,
    faDuoUserTieHair,
    faDuoUserSlash,
    faDuoCirclesOverlap,
    faDuoUserGear,
    faFolderTree,
    faHorizontalRule,
    faSackDollar,
    faArrowRight,
    faCirclePlus,
    faMessageLines,
    faSolidMug,
    faMug,
    faClock,
    faCircleCheck,
    faHourglassClock,
    fasTriangleExclamation,
    faRightFromBracket,
    faCircleQuestion,
    faBoxArchive,
    faXmark,
    faInfoCircle,
    faUsers,
    faCircleXmark,
    farTriangleExclamation,
    faClone,
    fasEmptySet,
    faBroomWide,
    faShield,
    faLinkSlash,
    faFloppyDisk,
    faCaretRight,
    faCaretLeft,
    faCircleInfo,
    faRotateLeft,
    faFileContract,
    fasUser,
    faCalendar,
    faMerge,
    faArrowDownToLine,
    faEye,
    faDuoBoxArchive,
    faTrashCan,
    faDuoEye,
    faDuoArrowDownToLine,
    faFilter,
    faFilters,
    faGauge,
    faNewspaper,
    faMessage,
    faMessages,
    faDUser,
    faDUsers,
    faMobileScreen,
    faLocationDot,
    faRectangleHistoryCircleUser,
    faDiagramProject,
    faCalendarDays,
    faClipboardCheck,
    faFileInvoice,
    faCircleQuestion,
    faGear,
    faDuoRightFromBracket,
    faDuoRotate,
    faChevronsRight,
    faChevronsLeft,
    faChevronsDown,
    faChevronsUp,
    faCrown,
    faFilterList,
    faLayerGroup,
    faSitemap,
    faFlaskGear,
    faMagnifyingGlass,
    faInfoCircle,
    faScreenUsers,
    farLinkSlash,
    fasLinkSlash,
    faLink,
    farUser,
    faUsers,
    faGrid2Plus,
    faRotateReverse,
    faGridHorizontal,
    faHotel,
    faGlobeStand,
    faArrowUpRightDots,
    faLock,
    faPuzzlePieceSimple,
    faSpinnerThird,
    faUserTieHair,
    faDuoFolderTree,
    faCirclePlus,
    faFlag,
    faFolderClosed,
    faFolderOpen,
    faCircleXmark,
    faDuoRotate,
    faChevronRight,
    faChevronLeft,
    faPlus,
    faUserSlash,
    faCirclesOverlap,
    faUserGear,
    faHashtag,
    faCheck,
    farEmptySet,
    farGrid2Plus,
    faTreePalm,
    faCalendarXmark,
    faCircleEllipsis
);